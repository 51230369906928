import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { authenticationService } from "../../services/basis/AuthenticationService"
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ValidateInput from "../../components/UI/Input/ValidateInput";
import PasswordHelp from '../../components/PasswordHelp/PasswordHelp';
import Accepts from "../../components/Accepts/Accepts";
import swal from "../../utils/swal";
import SignupDatepicker from "./SignupDatepicker";
import SignupBanner from "../../images/signup-banner.png"
import SignupSuccessPopupModal from "./SignupSuccessPopupModal";
// import { ReactComponent as FacebookIcon } from "../../images/facebook-cta.svg"
// import Accepts from "../../components/Accepts/Accepts";

import "./signup.styles.scss";

function Signup(props) {
    const { t } = useTranslation();
    const user = useSelector(state => state.user.user)
    const [show, setShow] = useState(false);
    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const [birthDate, setBirthdate] = useState();

    useEffect(() => {
        register(
            { name: "birthDate" },
            { required: t("required.field") },
        )
        setValue("birthDate", birthDate);
    }, [register, birthDate, setValue, t])

    const handleChange = date => {
        setValue("birthDate", date);
        setBirthdate(date);
        if (date) {
            errors.birthDate = {};
            errors.birthDate.message = "";
        } else {
            errors.birthDate = {};
            errors.birthDate.message = t("required.field");
        }
    }

    const onSubmit = data => {
        authenticationService.signup(data)
            .then(response => {
                console.log(response);
                setShow(true);
            }).catch((error) => {
                if (error.errorCode === 13) {
                    swal.error("Password non valida");
                } else {
                    swal.error(error.message);
                }
            })
    }


    if (user) return <Redirect to="/home" />
    else return(
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
            className={`signup needs-validation ${errors ? "had-validation" : ""}`}>

            <div className="signup-banner" style={{ backgroundImage: `url(${SignupBanner})` }} alt="">
                <div className="text text-center">
                    <span>
                        {t("signup.registerToAccessServices")}</span>
                </div>
            </div>
            <div className="signup-content mobile-padding">
                {/* <div className="signup-facebook">
                    <span className="title">Accedi tramite Facebook</span>
                    <div>
                        <button type="button" className="facebook-cta">
                            <FacebookIcon />
                            <span>Accedi con Facebook</span>
                        </button>
                    </div>
                </div>
                <div className="separator"><span>Oppure</span></div> */}
                <div className="signup-card">
                    <h4>{t("signup.personalData")}</h4>
                    <hr className="hr" />
                    <Row className="signup-row">
                        <Col md={6}>
                            <ValidateInput
                                name="firstName"
                                label="Nome*"
                                pattern="[A-Za-z\s]"
                                type="text"
                                watch={watch("firstName")}
                                errors={errors.firstName}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                        </Col>
                        <Col md={6}>
                            <ValidateInput
                                name="lastName"
                                label="Cognome*"
                                pattern="[A-Za-z\s]"
                                type="text"
                                watch={watch("lastName")}
                                errors={errors.lastName}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                        </Col>
                    </Row>
                    <Row className="signup-row">
                        <Col md={6}>
                            <SignupDatepicker
                                label="Data di nascita*"
                                onChange={handleChange}
                                value={birthDate}
                                errors={errors.birthDate}
                            />
                        </Col>
                        <Col md={6}>
                            <ValidateInput
                                name="profileCard"
                                label="profileCard"
                                type="text"
                                pattern="[0-9]"
                                watch={watch("profileCard")}
                                errors={errors.profileCard}
                                register={register({
                                    pattern: {
                                        value: /^0[0-9]{12}$/,
                                        message: t("error.invalidCard")
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                    <Row className="signup-row">
                        <Col md={6}>
                            <ValidateInput
                                name="username"
                                label="Indirizzo email*"
                                type="email"
                                watch={watch("username")}
                                errors={errors.username}
                                register={register({
                                    required: t("required.field"),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: t("error.invalidEmail")
                                    }
                                })}
                            />
                        </Col>
                        <Col md={6}>
                            <ValidateInput
                                name="mobilePhone"
                                label="Telefono*"
                                type="tel"
                                pattern="[0-9]"
                                watch={watch("mobilePhone")}
                                errors={errors.mobilePhone}
                                register={register({
                                    required: t("required.field"),
                                    pattern: {
                                        value: /^\d{10}$/i,
                                        message: t("error.invalidPhone")
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                    <Row className="signup-row">
                        <Col md={6}>
                            <ValidateInput
                                name="password"
                                label="password"
                                type="password"
                                watch={watch("password")}
                                errors={errors.password}
                                register={register({
                                    required: t("required.field"),
                                    minLength: { value: 8, message: t("password.length") }
                                })}
                            />
                        </Col>
                        <Col md={6}>
                            <ValidateInput
                                name="passwordConfirm"
                                label="passwordConfirm"
                                type="password"
                                watch={watch("passwordConfirm")}
                                errors={errors.passwordConfirm}
                                register={register({
                                    required: t("required.field"),
                                    validate: {
                                        passwordMatch: value => value === watch('password')
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                    <PasswordHelp />
                </div>

                <div style={{ marginTop: " 21px" }} className="signup-card">
                    <h4>{t("signup.conditionsOfServiceAndPrivacy")}</h4>
                    <hr className="hr" />
                    <Accepts register={register} />
                </div>

                <div className="signup-actions text-center">
                    <button className="cta" type="submit">
                        <span>{t("signup.register")}</span>
                    </button>
                </div>
            </div>
            <SignupSuccessPopupModal show={show} setShow={setShow} />
        </form>
    );
}

export default Signup;