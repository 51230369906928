import React from "react";
import timeslots from "./Service";
export default class Timeslots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeslots: [],
    };
  }

  componentDidMount() {
    timeslots(this.props.cartId, this.props.day, this.props.deliveryMode)
      .then(response => {
        this.setState({
          timeslots: response.data,
        })
      }).catch((error) => {
        console.log(error);
      })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { day, deliveryMode } = this.props;
    if (day !== nextProps.day || deliveryMode !== nextProps.deliveryMode) {
      timeslots(this.props.cartId, nextProps.day, nextProps.deliveryMode)
        .then(response => {
          this.setState({
            timeslots: response.data,
          })
        }).catch((error) => {
          console.log(error);
        })
    }
  }

  render() {
    const { timeslots } = this.state;
    const { selectedTimeslot } = this.props;
    return (
      <div className="disponibility-timeslots">
        {timeslots.length === 0 &&
          <div className="text-center disponibility-timeslots-item">
            <span style={{fontFamily: "Montserrat-Bold", fontSize: "20px"}}>NESSUNA DISPONIBILITA'</span>
          </div>
        }
        {timeslots.length > 0 && timeslots.map((item) => (
          <div key={item.id} className="text-center disponibility-timeslots-item">
            {/* <span> DISPONIBILE</span> */}
            <button
              className={`pill ${item.id === selectedTimeslot ? "pill-active" : ""}`}
              disabled={!item.available}
              onClick={() => this.props.selectTimeslot(item)}
            >
              {item.validFrom} - {item.validTo}
            </button>
          </div>
        ))}
      </div>
    );
  }
}