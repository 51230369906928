import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRight } from "../../../images/arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../../images/arrow_left.svg";
import image6 from "../../../images/departments/Image6.png"
import image7 from "../../../images/departments/Image7.png"
import image8 from "../../../images/departments/Image8.png"
import image9 from "../../../images/departments/Image9.png"
import ColouredCard1 from "../../../images/colored_card1.svg";
import ColouredCard2 from "../../../images/colored_card2.svg";
import ColouredCard3 from "../../../images/colored_card3.svg";
import ColouredCard4 from "../../../images/colored_card4.svg";
import ColouredCard5 from "../../../images/colored_card5.svg";
import ColouredCard1M from "../../../images/departments/convenienza@2x-100.jpg";
import ColouredCard2M from "../../../images/departments/rispettonatura@2x-100.jpg";
import ColouredCard3M from "../../../images/departments/sicurezza alimentare@2x-100.jpg";
import ColouredCard4M from "../../../images/departments/benesseremisura@2x-100.jpg";
import ColouredCard5M from "../../../images/departments/territorio@2x-100.jpg";
import { history } from "../../../App";

import "./home-values.style.scss";

const HomeValues = () => {
    const { t } = useTranslation();
    return (
        <div className="home-values">
            <h2 className="text-center">I nostri valori</h2>
            <Slider {...settings} style={{maxWidth: '1600px'}}>
                <div className="home-values-items" >
                    <div className="link" onClick={() => history.push('/ourvalues/convenienza_quotidiana')}>
                        <img className="desktop" src={ColouredCard1} alt="" />
                        <img className="mobile" src={ColouredCard1M} alt="" />
                    </div>
                </div>
                <div className="home-values-items" >
                    <div className="link" onClick={() => history.push('/ourvalues/rispetto_della_natura')}>
                        <img className="desktop" src={ColouredCard2} alt="" />
                        <img className="mobile" src={ColouredCard2M} alt="" />
                    </div>
                </div>
                <div className="home-values-items" >
                    <div className="link" onClick={() => history.push('/ourvalues/sicurezza_alimentare')}>
                        <img className="desktop" src={ColouredCard3} alt="" />
                        <img className="mobile" src={ColouredCard3M} alt="" />
                    </div>
                </div>
                <div className="home-values-items" >
                    <div className="link" onClick={() => history.push('/ourvalues/benessere_a_tua_misura')}>
                        <img className="desktop" src={ColouredCard4} alt="" />
                        <img className="mobile" src={ColouredCard4M} alt="" />
                    </div>
                </div>
                <div className="home-values-items" >
                    <div className="link" onClick={() => history.push('/ourvalues/il_nostro_territorio')}>
                        <img className="desktop" src={ColouredCard5} alt="" />
                        <img className="mobile" src={ColouredCard5M} alt="" />
                    </div>
                </div>
            </Slider>
            <div className="carousel-dots"></div>
        </div>
    );
}

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleArrow><ArrowRight /></SampleArrow>,
    prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>,
    responsive: [
        {
            breakpoint: 1919,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1599,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1439,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1279,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 799,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 544,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                //dots: true
            }
        }
    ]
};

function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
}

export default HomeValues;