import React from "react";
import ProfileCard from "../../../../components/ProfileCard/ProfileCard";
import { ReactComponent as Alert } from "../../../../images/card-alert.svg"
import { useTranslation } from 'react-i18next';
import { storeIcons } from "../../../../utils/renderStoreIcon"

const StoreInfo = ({ store }) => {
    const { t } = useTranslation();
    return (
        <div>
            <h3>{store.name}</h3>
            {store.address &&
                <a className="tiny-cta" href={`https://maps.google.com/?q=${store.address.latitude},${store.address.longitude}`} target="__blank">
                    {store.address?.address} {store.address?.streetNumber}, {store.address.zip} {store.address.city} ({store.address.county})
                            </a>
            }
            <div className="store-info">
                {(store.phone || store.email) &&
                    <ProfileCard>
                        <h5>{t("contacts")}</h5>
                        {store.email &&
                            <div className="info">
                                email: {store.email}
                            </div>
                        }
                        {store.phone &&
                            <div className="info">
                                {t("phone")}: {store.phone}
                            </div>
                        }
                    </ProfileCard>
                }
                {store.timetables &&
                    <ProfileCard>
                        <h5>{t("timetables")}</h5>
                        {store.timetables.map((timetable, index) => (
                            <div className="info" key={index}>{timetable}</div>
                        ))}
                    </ProfileCard>
                }
            </div>
            {store.closingDays &&
                <div className="closingDays">
                    <div className="closingDays-title">
                        <Alert />
                        <h5>{t("closingDays.alert")}</h5>
                    </div>
                    {t("closingDays.message1")} {store.closingDays.map(day => (
                        <b key={day.id}>
                            {day.dayOfMonth}/{day.month},
                        </b>
                    ))}
                    {t("closingDays.message2")}
                </div>
            }
            <div className="store-info">
                {store.services &&
                    <ProfileCard>
                        <h5>{t("services")}</h5>
                        <div className="info services">
                            {store.services.map((service, index) => (
                                <React.Fragment key={index}>
                                    {storeIcons.renderServices(service.toLowerCase())}
                                </React.Fragment>
                            ))}
                        </div>

                    </ProfileCard>
                }
                {store.departments &&
                    <ProfileCard>
                        <h5>{t("departments")}</h5>
                        <div className="info departments">
                            {store.departments.map((department, index) => (
                                <React.Fragment key={index}>
                                    {storeIcons.renderDepartments(department.toLowerCase())}
                                </React.Fragment>
                            ))}
                        </div>
                    </ProfileCard>
                }
            </div>
        </div>
    );
}

export default StoreInfo;


