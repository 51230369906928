import React from "react";
import PaymentsLogo from "../../images/payment_circuits.png"
import { ReactComponent as Card } from '../../images/Card.svg';
import { paymentsService } from "./Service";
import { Translation } from 'react-i18next';
import Popup from "../../components/UI/Popup/Popup";
import config from "../../config/config";
import i18n from "../../i18n";
import { connect } from "react-redux";

class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethods: [],
            isBoarded: false,
        };
    }

    //aggiunta per jwt da webview
    componentDidUpdate() {
        if (this.props.jwt) {
            paymentsService.paymenthMethods(this.props.jwt)
                .then(response => {
                    console.log(response);
                    if (response.data.length > 0) {
                        response.data.forEach(method => {
                            if (method.paymentMethodType === "XPAY") {
                                this.setState({ isBoarded: true })
                            }
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                })
            window.addEventListener("focus", this.onFocus)
            paymentsService.startBoarding(this.props.jwt)
                .then(response => {
                    // Inizializzazione SDK
                    XPay.init();

                    // Oggetto contenente la configurazione del pagamento
                    var configuration = {
                        baseConfig: {
                            apiKey: process.env.REACT_APP_NEXI_APIKEY,
                            enviroment: XPay.Environments.PROD
                        },
                        paymentParams: {
                            amount: "0",
                            transactionId: response.transactionCode,
                            currency: response.currency,
                            timeStamp: response.timestamp,
                            mac: response.mac,
                            urlPost: `${config.baseUrl}/cli/pay/v2/xpay/board?tid=01&profileId=${this.props.userId !== null ? this.props.userId : this.props.profileId}`
                        },
                        customParams: {
                            num_contratto: response.contractNumber,
                            tipo_servizio: 'paga_oc3d',
                            tipo_richiesta: 'PP'
                        },
                        language: XPay.LANGUAGE.ITA,

                    };

                    // Configurazione lightbox
                    XPay.initLightbox(configuration);
                })
                .catch(error => { console.log(error); })
            window.addEventListener("XPay_Payment_Result", this.onFocus);
        }
    }

    componentDidMount() {
        paymentsService.paymenthMethods(this.props.jwt)
            .then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "XPAY") {
                            this.setState({ isBoarded: true })
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
        window.addEventListener("focus", this.onFocus)
        paymentsService.startBoarding(this.props.jwt)
            .then(response => {
                // Inizializzazione SDK
                XPay.init();

                // Oggetto contenente la configurazione del pagamento
                var configuration = {
                    baseConfig: {
                        apiKey: process.env.REACT_APP_NEXI_APIKEY,
                        enviroment: XPay.Environments.PROD
                    },
                    paymentParams: {
                        amount: "0",
                        transactionId: response.transactionCode,
                        currency: response.currency,
                        timeStamp: response.timestamp,
                        mac: response.mac,
                        urlPost: `${config.baseUrl}/cli/pay/v2/xpay/board?tid=01&profileId=${this.props.userId !== null ? this.props.userId : this.props.profileId}`
                    },
                    customParams: {
                        num_contratto: response.contractNumber,
                        tipo_servizio: 'paga_oc3d',
                        tipo_richiesta: 'PP'
                    },
                    language: XPay.LANGUAGE.ITA,

                };

                // Configurazione lightbox
                XPay.initLightbox(configuration);
            })
            .catch(error => { console.log(error); })
        window.addEventListener("XPay_Payment_Result", this.onFocus);
    }

    onFocus = () => {
        paymentsService.paymenthMethods(this.props.jwt)
            .then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "XPAY") {
                            this.setState({ isBoarded: true })
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    removePaymenMethod = () => {
        paymentsService.removePaymentMethod(this.props.jwt)
            .then(response => {
                this.setState({
                    paymentMethods: response.data,
                    isBoarded: false
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    renderAllocateCard = () => {
        return (
            <Translation>
                {t =>
                    <Popup
                        action={() => XPay.openLightbox()}
                        title={i18n.t("payment.allocate")}
                        content={i18n.t("payment.allocate.question")}
                        button={i18n.t("confirm")}
                        trigger={
                            <button className="cta justify-content-center">
                                <span>Associa la carta</span>
                            </button>
                        }
                    />
                }
            </Translation>
        )
    }

    renderRemoveCard = () => {
        return (
            <Translation>
                {t =>
                    <Popup
                        action={() => this.removePaymenMethod()}
                        title={i18n.t("payment.remove.title")}
                        content={i18n.t("payment.remove.question")}
                        button={i18n.t("payment.remove")}
                        trigger={
                            <button className="cta justify-content-center">
                                <span>Rimuovi la carta</span>
                            </button>
                        }
                    />
                }
            </Translation>
        )
    }

    render() {
        const { isBoarded } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="payments-container">
                        <h2>{t('payments')}</h2>
                        <div className="payments-card">
                            <div className="description">{t('payments.title')}</div>
                            {!isBoarded &&
                                <div className="text-center">
                                    <div className="pb-4">
                                        <Card />
                                    </div>
                                    <div className="pb-3 description text-danger">
                                        Non hai ancora associato la tua carta di credito, puoi farlo ora
                                    </div>
                                    <div>
                                        {/* <a href={`${config.baseUrl}/cli/pay/v2/xpay/boarding`}
                                            target="_blank" rel="noopener noreferrer">
                                            <button className="cta"><span>Associa la carta</span></button>
                                        </a> */}
                                        {this.renderAllocateCard()}
                                    </div>
                                    <img style={{ width: "174px" }} src={PaymentsLogo} alt="" />
                                </div>
                            }
                            {isBoarded &&
                                <div className="text-center">
                                    <div className="card-boarded pb-4">
                                        <Card />
                                    </div>
                                    <div className="pb-3 description text-success">
                                        Hai già impostato un metodo di pagamento online
                                    </div>
                                    <div>
                                        {/* <button className="cta" onClick={this.removePaymenMethod}>
                                            <span>Rimuovi</span>
                                        </button> */}
                                        {this.renderRemoveCard()}
                                    </div>
                                    <img style={{ width: "174px" }} src={PaymentsLogo} alt="" />
                                </div>
                            }
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        profileId: state.user?.user?.id
    };
}

export default connect(mapStateToProps)(Payments)