import React from "react";
import Slider from "react-slick";
import { Translation } from "react-i18next";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { ReactComponent as ArrowRight } from "../../../images/arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../../images/arrow_left.svg";
import Placeholder from "../../../images/product-placeholder.png"
import image1 from "../../../images/departments/Image11.png"
import image2 from "../../../images/departments/Image12.png"
import image3 from "../../../images/departments/Image13.png"
import image4 from "../../../images/departments/Image14.png"
import { connect } from "react-redux"
import getBanners from "./Service"
import { history } from "../../../App";


import "./home-suggestions.styles.scss";

class HomeSuggestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            banners: []
        };
    }

    componentDidMount() {
        /* const storeId = this.props.cart ? this.props.cart.storeId :
            (this.props.defaultStore ? this.props.defaultStore.id : null); */
        // ecommerceService.featuredProducts(storeId).then(response => {
        getBanners().then(response => {
            console.log(response.data)
            this.setState({
                isLoaded: true,
                banners: response.data
            })
        });
    }

   /*  UNSAFE_componentWillReceiveProps(nextProps) {
        const storeId = this.props.cart ? this.props.cart.storeId :
            (this.props.defaultStore ? this.props.defaultStore.id : null);
        if (nextProps && nextProps.cart) {
            if (storeId !== nextProps.cart.storeId) {
                ecommerceService.featuredProducts(nextProps.cart.storeId).then(response => {
                    this.setState({
                        isLoaded: true,
                        products: response.data
                    })
                });
            }
        } else {
            ecommerceService.featuredProducts(this.props.defaultStore.id).then(response => {
                this.setState({
                    isLoaded: true,
                    products: response.data
                })
            });
        }
    }
 */
    goToDetails = (item) => {
        history.push({
            pathname: `/banners/${item.banner.id}/products`,
            state: {
                bannerId: `${item.banner.id}`,
            }
        })
    }


    render() {
        const { banners, isLoaded } = this.state;
        if (isLoaded) {

            return (
                <Translation>
                    {t =>
                        <div className="home-suggestions">
                            <h2 className="text-center">Le nostre proposte per te</h2>
                            <Slider {...settings} style={{maxWidth: '1600px'}}>
                                  {banners && banners.map((item, index) => (
                                    <div className="home-suggestions-items" key={item.id +  "" + index}>
                                                <div className="link" onClick={() => this.goToDetails(item)}>
                                                    <img src={item.banner.imageUrl ? item.banner.imageUrl : Placeholder} alt=""
                                                        onError={(e) => {
                                                            e.target.src = Placeholder
                                                        }}
                                                    />
                                                 </div> 
                                        <div className="description">
                                            {item.banner.title}
                                        </div>
                                    </div>
                                ))} 

                               {/*  <div className="home-suggestions-items">

                                    <img src={image1} alt="" />

                                    <div className="description">FORMAGGI</div>
                                </div>
                                <div className="home-suggestions-items">

                                    <img src={image2} alt="" />

                                    <div className="description">FORMAGGI</div>
                                </div>
                                <div className="home-suggestions-items">

                                    <img src={image3} alt="" />

                                    <div className="description">FORMAGGI</div>
                                </div>
                                <div className="home-suggestions-items">

                                    <img src={image4} alt="" />

                                    <div className="description">FORMAGGI</div>
                                </div>
                                <div className="home-suggestions-items">

                                    <img src={image1} alt="" />

                                    <div className="description">FORMAGGI</div>
                                </div>
                                <div className="home-suggestions-items">

                                    <img src={image2} alt="" />

                                    <div className="description">FORMAGGI</div>
                                </div>
                                <div className="home-suggestions-items">

                                    <img src={image1} alt="" />

                                    <div className="description">FORMAGGI</div>
                                </div>
                                <div className="home-suggestions-items">

                                    <img src={image2} alt="" />

                                    <div className="description">FORMAGGI</div>
                                </div> */}
                            </Slider>
                            <div className="carousel-dots"></div>
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }

    }
}

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleArrow><ArrowRight /></SampleArrow>,
    prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>,
    responsive: [
        {
            breakpoint: 1919,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1599,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1439,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1279,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 799,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 543,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
       /*  {
            breakpoint: 544,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        } */
    ]
};

function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        defaultStore: state.user.defaultStore,
    };
}

export default connect(mapStateToProps)(HomeSuggestions);