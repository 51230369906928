const config = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    maxSize: 20,
    DEFAULT_TID: "01",
    SHOW_CART_LISTS: false,

    ENABLED_SLIST: true,
    ENABLED_CART: true,
    ENABLED_PAYMENTS: true
}

export default config;