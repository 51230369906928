import React, { useState } from "react"
import NewCounter from "../../../components/UI/NewCounter/NewCounter";
import Col from 'react-bootstrap/Col'
import Placeholder from "../../../images/product-placeholder.png";
import ImageGallery from 'react-image-gallery';
import renderPrice from "../../../utils/renderPrice";
import config from "../../../config/config";
import { tags } from "../../../utils/renderTags";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from 'react-router-dom';
import * as actionCreators from "../../../redux/store/actions/index";
//import ImagePopup from "../ImagePopup/ImagePopup";
import LoginPopup from "../../Login/LoginPopup/LoginPopup";
import getStoredState from "redux-persist/es/getStoredState";
import SlistPopup from "../../Smartlist/SlistPopup/SlistPopup";
import { ReactComponent as CartIcon } from "../../../images/cart.svg"
//import "../react-slick.styles.scss";
import ReactImageMagnify from 'react-image-magnify';
import ReactSlick from 'react-slick';
//import SpacedSpan from "./SpacedSpan";
//import ReactImageMagnified from "./ReactImageMagnified";
import "./product-details.styles.scss";



const ProductDetails = ({
    product,
    productImages,
    amount,
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user)
    const cart = useSelector(state => state.cart.cart)
    const [isOpen, setIsOpen] = useState(false)
    const [imageSource, setImageSource] = useState()
    console.log(product)

    const renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <div className="product-popup-price">
                    <span className="mr-1 text-strike">
                        {`${renderPrice((item.price * amount)?.toFixed(2))} \u20AC`}
                    </span>
                    <span className="primary-color">
                        {`${renderPrice((item.netPrice * amount)?.toFixed(2))} \u20AC`}
                    </span>
                </div>
        } else {
            result =
                <div className="product-popup-price">
                    <span>{`${renderPrice((item.netPrice * amount)?.toFixed(2))} \u20AC`}</span>
                </div>
        }
        return result
    }

    const openPopUp = (e) => {
        setImageSource(e.target.src)
        setIsOpen(true)
    }

    const renderPromo = (item) => {
        let result = <div className="product-popup-detail-promotion">
            {t("promo")}
        </div>
        if (item.labelCode) {
            switch (item.labelCode) {
                case "SOTTOCOSTO":
                    result = <div className="isundercostcart">SOTTOCOSTO</div>
                    break;
                case "2x1":
                    result = <div className="is2x1cart">2 al prezzo di 1</div>
                    break;
                case "2x1V":
                    result = <div className="is2x1cart">-{item.valueGuest}€ con 2pz</div>
                    break;
                case "3x2":
                    result = <div className="is3x2cart">3 al prezzo di 2</div>
                    break;
                case "2x1P":
                    result = <div className="is3x2cart">-{item.valueGuest}% sul 2˚ pz</div>
                    break;
                default:
                    break;
            }
        }
        return result;
    }

    return (
        <div className="product-popup">
            {/*  <div className="product-image">
                <ReactImageMagnified
                    {...{
                        productImages: productImages,
                        product:product,
                        rimProps: {
                           // isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false,
                            enlargedImagePosition: 'over'
                        }
                    }}
                />
            </div>  */}
            <div className="product-popup-image">
                {!productImages &&
                    <img src={product.imageUrl ? product.imageUrl : Placeholder} alt=""
                        onError={(e) => e.target.src = Placeholder}
                    />
                }
                {productImages &&
                    <ImageGallery
                        items={productImages}
                        showFullscreenButton={false}
                        autoPlay={false}
                        // showBullets={productImages.length > 1 ? true : false}
                        showBullets={true}
                        showPlayButton={false}
                        showThumbnails={false}
                        onErrorImageURL={Placeholder}
                        onClick={(e) => openPopUp(e)}
                    />
                }
                {/*  {
                    isOpen &&
                    <ImagePopup
                        show={isOpen}
                        imageSource={imageSource}
                        onClose={() => setIsOpen(false)}
                    />
                } */}
            </div>
            <div className="product-popup-detail">
                {/* <div className="product-popup-detail-ref">{t("product.ref")}: {product.ref}</div> */}
                {
                    product.description2 ? <h2>{product.description2}</h2> : <h2>{product.description}</h2>
                }
                {
                    product.brand &&
                    <div style={{ fontSize: "15px", fontFamily: "Montserrat-Bold", color: "#a1a1a1" }}>
                        {product.brand}
                    </div>
                }
                {
                    product.weightString &&
                    <div style={{ fontSize: "10px", fontFamily: "Montserrat-Bold", color: "#a1a1a1" }}>
                        {product.weightString}
                    </div>
                }
                {/* <h2>{product.description2}</h2> */}
                {product.pricePerUm && product.umPerUm &&
                    <div className="product-popup-detail-pricePerUm" product-detail-ref>
                        {`${renderPrice(product.pricePerUm?.toFixed(2))} \u20AC / ${product.umPerUm}`}
                    </div>
                }
                {product.promotions && product.promotions[0] &&
                    renderPromo(product.promotions[0])
                }
                <div className="product-popup-detail-tag">
                    {product.tags && product.tags.split(",").map(tag => (
                        <span key={tag}>{tags.renderDetailTags(tag)}</span>
                    ))}
                </div>
                {product.price &&
                    renderPrices(product)
                }
                {product.pricePerUM && product.umPerUM &&
                    <div className="product-popup-detail-netPrice">
                        {`${product.pricePerUM?.toFixed(2)} \u20AC / ${product.umPerUM} `}
                    </div>
                }
                {/* <NewCounter
                    value={amount}
                    um={product.purchaseUm}
                    increment={incrementAmount}
                    decrement={decrementAmount}
                    showUm={true}
                /> */}
                {/*   {config.ENABLED_SLIST &&
                    <button className="secondary-cta" onClick={() => {
                        if (user) {
                            setShowPopup(true);
                        } else {
                            dispatch(actionCreators.showLogin(true))
                        }
                    }}>
                        {user ? t("smartlist.add") : t("access")}
                    </button>
                } */}
            </div>
        </div>
    );
}

export default withRouter(ProductDetails);