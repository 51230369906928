import React from "react"
import { ReactComponent as AccordionDown } from "../../../images/accordion-red-down.svg";
import { ReactComponent as AccordionUp } from "../../../images/accordion-red-up.svg";
import { Accordion, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { format, parseISO } from "date-fns";


const OrderHeader = ({ item, index, activeKey, selectOrder }) => {
    const { t } = useTranslation();

    const renderTotal = (item) => {
        let result;
        if (item.operatorCart && item.operatorCart.totalToPay) {
            result = `${item.operatorCart.totalToPay?.toFixed(2)} \u20AC`
        } else {
            result = `${item.cart.totalToPay?.toFixed(2)} \u20AC`
        }
        return result;
    }

    return (
        <div className="orders-card-header">
            <Row className="order-row">
                <Col className='orders-card-item order-col'>
                    <h5>{item.deliveryDate && format(parseISO(item.deliveryDate), "dd/MM/yyyy")}</h5>
                </Col>
                <Col className='orders-card-item order-col' style={{ maxWidth: '100px' }}>
                    <h5>{item.cart.code}</h5>
                </Col>
                <Col className='orders-card-item order-col'>
                    <h5>
                        {item?.cart?.deliveryMode === "IN_STORE" ? item?.status === "ISSUING_RECEIPT" ? t(`order.status.header.${item.cart?.deliveryMode}.${item.status}`) : t(`order.status.header.${item.status}`) : t(`order.status.header.${item.status}`)}
                    </h5>
                </Col>
                <Col className='orders-card-item order-col'>
                    <h5>{item.cart.storeDescription}</h5>
                </Col>
                <Col className='orders-card-item order-col'>
                    <h5>{item.cart.paymentMode}</h5>
                </Col>
                <Col className='orders-card-item order-col'>
                    <h5>{renderTotal(item)}</h5>
                    <div className="order-icon-top-right">
                        <div className="icon-order-container">
                            <div className={`icon order-filter-${item.status}`}>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                    {/* 
                    <div className="order-toggle-container">
                    </div> */}
                    <Accordion.Toggle as="div" eventKey={item.id} className="tiny-cta"
                        onClick={() => selectOrder(item.id, item.status, index)}>
                        {activeKey === item.id ?
                            <>
                                <AccordionUp />
                            </>
                            :
                            <>
                                <AccordionDown />
                            </>
                        }
                    </Accordion.Toggle>
                </Col>
            </Row>
            <div className="left">
                <div className="orders-card-item">
                    <span>{t("deliveryDate")}: </span>
                    <h5>{item.deliveryDate && format(parseISO(item.deliveryDate), "dd/MM/yyyy")}</h5>
                </div>
                <div className="orders-card-item">
                    <span>{t("order.number")}:</span>
                    <h5>{item.cart.title}</h5>
                </div>
                <div className="orders-card-item">
                    <span>{t("order.status")}:</span>
                    <h5>
                        {t(`order.status.${item.status}`)}
                    </h5>
                </div>
                <div className="orders-card-item">
                    <span>{t("order.store")}:</span>
                    <h5>{item.cart.storeDescription}</h5>
                </div>
                <div className="orders-card-item">
                    <span>{t("payment")}:</span>
                    <h5>{item.cart.paymentMode}</h5>
                </div>
                {/* <div className="vertical-line-order"></div>  */}
                {/* <div className="vertical-line-order"></div>  */}
                <div className="orders-card-item">
                    <span>{t("total")}:</span>
                    <h5>{renderTotal(item)}</h5>
                </div>
                {/* <div className="vertical-line-order"></div>   */}
            </div>
            <div className="mobile-accordion">
                <Accordion.Toggle as="div" eventKey={item.id} className="tiny-cta"
                    onClick={() => selectOrder(item.id, item.status, index)}>
                    {activeKey === item.id ?
                        <>
                            <span className="d-none d-sm-inline">{t("closeDetail")}</span>
                            <AccordionUp />
                        </>
                        :
                        <>
                            <span className="d-none d-sm-inline">{t("showDetail")}</span>
                            <AccordionDown />
                        </>
                    }
                </Accordion.Toggle>
                <div style={{ position: "relative", height: '100px' }}>
                    <div className="order-icon-top-right-m">
                        <div className="icon-order-container">
                            <div className={`icon order-filter-${item.status}`}>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderHeader;