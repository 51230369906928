import React from "react";
import StickyBox from "react-sticky-box";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import * as actionCreators from "../../../../redux/store/actions/index";
import Bene_Si_Details from "../../../../images/bene_si_details.jpg";
import Casa_Coop_Details from "../../../../images/newBrands/coopcasa/Coop-Casa_interno.jpg";
import Vivi_Verde_Details from "../../../../images/Vivi_Verde_Details.jpg";
import Coop_Solidal_Details from "../../../../images/Coop_Solidal_Details.jpg";
import Coop_Origine_Details from "../../../../images/Coop_Origine_Details.jpg";
import Coop_IO_Details from "../../../../images/newBrands/iocoop/Io-Coop_interno.jpg";
import Fior_Fiore_Details from "../../../../images/Fior_Fiore_Details.jpg";
import Dosa_Details from "../../../../images/newBrands/dosa/DOsa_interno.jpg";
import Crescendo_Details from "../../../../images/newBrands/crescendo/Crescendo_interno.jpg";
import Coop_Details from "../../../../images/Coop_Details.jpg";
import Amici_animali_Details from "../../../../images/Amici_animali_Details.jpg";
import { withRouter } from "react-router";
import CategoryTree from "../../../SearchCategory/CategoryTree/CategoryTree";
import { ReactComponent as ArrowLeft } from "../../../../images/arrow_left1.svg";
import "./banner_details.styles.scss";
import { history } from "../../../../App";
import BannerDetailsVideo from "./BannerDetailsVideo/BannerDetailsFiorFioreVideo";
import BannerDetailsFiorFioreVideo from "./BannerDetailsVideo/BannerDetailsFiorFioreVideo";
import BannerDetailsAmiciAnimaliVideo from "./BannerDetailsVideo/BannerDetailsAmiciAnimaliVideo";

const BannerDetails = ({ location }) => {
    const tag = location?.state?.tag ? location?.state?.tag : "";

    const goToDetails = (tag) => {
        history.push({
            pathname: `/brand/${tag}`,
            state: {
                tag: `${tag}`,
            }
        })
    }

    const renderBannerDetails = (selectedTag) => {
        switch (selectedTag) {
            case "COOP_BENE_SI":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Bene_Si_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                    </div>
                )
            case "CASA":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Casa_Coop_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                    </div>
                )
            case "COOP_VIVI_VERDE":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Vivi_Verde_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                    </div>
                )
            case "COOP_SOLIDAL":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Coop_Solidal_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                    </div>
                )
            case "COOP_ORIGINE":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Coop_Origine_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                    </div>
                )
            case "COOP_IO":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Coop_IO_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                    </div>
                )
            case "COOP_FIOR_FIORE":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Fior_Fiore_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                        <div className="banner_details-video">
                            <BannerDetailsFiorFioreVideo/>
                        </div>
                    </div>
                )
            case "D'OSA":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Dosa_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                    </div>
                )
            case "CRESCENDO":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Crescendo_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                    </div>
                )
            case "COOP":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Coop_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                    </div>
                )
            case "AMICI_SPECIALI":
                return (
                    <div className="banner_details">
                        <img style={{ width: "100%" }} src={Amici_animali_Details} alt="" />
                        <div className="banner_details-backbutton">
                            <button className="banner_details-backbutton-style" onClick={() => goToDetails(selectedTag)} ><ArrowLeft /> TORNA AI PRODOTTI</button>
                        </div>
                        <div className="banner_details-video">
                            <BannerDetailsAmiciAnimaliVideo/>
                        </div>
                    </div>
                )
            default:
                return null;
        }
    }

    return (
        <Translation>
            {t =>
                <div style={{ padding: "unset" }} className="page-grid mobile-view-search">
                    <div className="grid-left" offsettop={0} offsetbottom={0}>
                        <CategoryTree />
                    </div>
                    {/* <div style={{ paddingTop: "20px" }} className="grid-right"> */}
                    <div className="grid-right" >
                        {renderBannerDetails(tag)}
                        {/*  <div className="banner_details">
                            {renderBannerDetails(tag)}
                            <div className="banner_details-backbutton">
                                <button className="banner_details-backbutton-style" onClick={() => goToDetails(tag)} ><ArrowLeft/> TORNA AI PRODOTTI</button>
                            </div>
                        </div> */}
                    </div>
                </div>
            }
        </Translation>
    );
}


const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        current: () => dispatch(actionCreators.current()),
        getSlists: () => dispatch(actionCreators.getSlists()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BannerDetails));
