import React from 'react';
import { ReactComponent as EmailSuccessLogo } from '../../images/EmailSuccess.svg';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import "./signup.styles.scss";

function SignupSuccessPopupModal(props) {

    const { t } = useTranslation();

    const handleClose = () => props.setShow(true);

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header>
            </Modal.Header>
            <div className="modal-popup d-flex flex-column align-items-center">

                <div className="email-success-log"><EmailSuccessLogo /></div>
                <div className="modal-popup-heading">
                    {t("signup.success.title")}
                </div>
                <div>
                    <p className="modal-popup-text">
                        {t("signup.success.text")}
                    </p>
                </div>
                <div className="modal-popup-btn-div">
                    <Link to="/">
                        <button className="cta" onClick={handleClose}>
                            <span>{t("signup.success.back")}</span>
                        </button>
                    </Link>
                </div>
            </div>
        </Modal>
    );
}

export default SignupSuccessPopupModal;