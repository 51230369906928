import React, { useEffect, useState } from 'react';
import { history } from "../../App";
import { useForm } from 'react-hook-form';
import { Translation } from 'react-i18next';
import toaster from "../../utils/toaster";
import { ReactComponent as AccordionDown } from "../../images/accordion-down.svg";
import { ReactComponent as AccordionUp } from "../../images/accordion-up.svg";
import { Accordion, Modal } from "react-bootstrap";
import ValidateInput from '../../components/UI/Input/ValidateInput';
import "./newsignup.styles.scss";
import { ReactComponent as Eye } from "../../images/eye.svg";
import { ReactComponent as CrossedEye } from "../../images/eye-crossed.svg";
import * as actionCreators from '../../redux/store/actions/index';
import { useDispatch, useSelector } from "react-redux";
import SignupDatepicker from './SignupDatepicker';
import i18n from '../../i18n';
import { ReactComponent as Alert } from "../../images/card-alert.svg"
import { authenticationService } from '../../services/basis/AuthenticationService';
import { Link } from 'react-router-dom';

const NewSignup = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const dispatch = useDispatch();
    const [birthDate, setBirthdate] = useState();
    const [isOpen, setIsOpen] = useState(true);
    const [password, setPassword] = useState();
    const [privacy, setPrivacy] = useState(false);
    const [privacyReq, setPrivacyReq] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [timeSaver, setTimeSaver] = useState(false);
    const [show, setShow] = useState(false);

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    useEffect(() => {
        register(
            { name: "birthDate" },
            { required: i18n.t("required.field") },
        )
        setValue("birthDate", formatDate(birthDate));
    }, [register, birthDate, setValue]);

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const onSubmit = (data) => {
        if (privacy) {
            authenticationService.coopSignup(data, privacy, password, marketing, timeSaver).then((response) => {
                if (response.responseCode !== 0) {
                    switch (response.responseCode) {
                        case 42:
                            toaster.standard(i18n.t("Utente già registrato fare la login"));
                            history.push("/home");
                            break;
                        case 43:
                            toaster.standard(i18n.t("Le password non corrispondono"));
                            break;
                        case 44:
                            toaster.standard(i18n.t("Utente non maggiorenne"));
                            break;
                        default:
                            toaster.standard(i18n.t("Qualcosa è andato storto"));
                            break;
                    }
                } else {
                    dispatch(actionCreators.setEmailPhone(watch('email'), watch('phone')));
                    console.log(response.data);
                    history.push("/sendemail");
                }
            })
        } else {
            setPrivacyReq(true);
        }
    }

    const handlePrivacy = (flag) => {
        setPrivacy(flag);
        if (flag) {
            setPrivacyReq(false);
        } else {
            setPrivacyReq(true);
        }
    }

    const handleChange = date => {
        setValue("birthDate", date);
        setBirthdate(date);
        if (date) {
            errors.birthDate = {};
            errors.birthDate.message = "";
        } else {
            errors.birthDate = {};
            errors.birthDate.message = i18n.t("required.field");
        }
    }

    const checkPassword = (pass) => {
        setPassword(pass);
        setValue("password", pass)
        if (pass.length < 8) {
            errors.password = {};
            errors.password.message = i18n.t("signup.password.error");
        } else {
            var hasUpperCase = /[A-Z]/.test(pass);
            var hasLowerCase = /[a-z]/.test(pass);
            var hasNumbers = /\d/.test(pass);
            var hasNonalphas = /\W/.test(pass);
            if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
                errors.password = {};
                errors.password.message = i18n.t("signup.password.error");
            } else {
                errors.password = null;
            }
        }
    }

    return (
        <Translation>
            {t =>
                <div className='signup-container'>
                    <Modal show={show} centered={true} onHide={() => setShow(false)} dialogClassName="whyask-modal">
                        <div style={{ padding: '30px' }}>
                            <div style={{ marginBottom: '1rem' }}>
                                <span style={{ fontFamily: 'Montserrat-Bold', marginBottom: '1rem' }}>Perchè ti chiediamo questi dati?</span><br /><br />
                                La registrazione al portale Click&Coop avviene sui sistemi di gestione soci di SAIT soc. coop. - Consorzio delle Cooperative di Consumo Trentine.<br /><br />
                                Il Codice Fiscale è l'elemento univoco e distintivo per la tua registrazione.<br /><br />
                                Per verificare che il codice fiscale riportato sia corretto devono essere recepiti anche il Nome, il Cognome e la Data di nascita.<br /><br />
                                Senza questi dati non è possibile validare il tuo profilo.<br /><br />
                                In ogni caso, questi dati non verranno utilizzati per altri scopi senza il tuo consenso esplicito.<br /><br />
                            </div>
                            <button
                                className="secondary-cta"
                                onClick={() => { setShow(false) }}
                            >
                                <span>{t("close")}</span>
                            </button>
                        </div>
                    </Modal>
                    <div className='signup-container-center'>
                        <div className='signup-container-title'>{t('signup.register')}</div>
                        <div className='signup-container-label'>{t('signup.label')}</div>
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                            <div className='signup-container-form'>
                                <ValidateInput
                                    name="name"
                                    label="firstName"
                                    type="text"
                                    placeholder="name"
                                    watch={watch("name")}
                                    errors={errors.name}
                                    register={register({
                                        required: t("required.field")
                                    })}
                                />
                                <ValidateInput
                                    name="surname"
                                    label="lastName"
                                    type="text"
                                    placeholder="signup.lastName"
                                    watch={watch("surname")}
                                    errors={errors.surname}
                                    register={register({
                                        required: t("required.field")
                                    })}
                                />
                                <ValidateInput
                                    name="email"
                                    label="email"
                                    type="email"
                                    placeholder="storeEmail"
                                    watch={watch("email")}
                                    errors={errors.email}
                                    register={register({
                                        required: t("required.field"),
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: t("error.invalidEmail")
                                        }
                                    })}
                                />
                                {/* <div className='whyask'>
                                    <SignupDatepicker
                                        name="birthDate"
                                        label="Data di nascita*"
                                        onChange={handleChange}
                                        value={birthDate}
                                        errors={errors.birthDate}
                                    />
                                    <label className='whyask-label'
                                        onClick={() => setShow(true)}>
                                        {t('whyask')}
                                    </label>
                                </div> */}
                                <ValidateInput
                                    onChange={e => e.target.value = e.target.value.toUpperCase()}
                                    name="cf"
                                    label="ssnCode"
                                    type="text"
                                    placeholder="billing.addresses.ssnCode"
                                    watch={watch("cf")}
                                    errors={errors.cf}
                                    maxLength={16}
                                    register={register({
                                        required: t("required.field"),
                                        pattern: {
                                            value: /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/,
                                            message: t("error.ssnCode")
                                        }
                                    })}
                                />
                                <ValidateInput
                                    name="phone"
                                    label="mobilePhone"
                                    type="text"
                                    placeholder="signup.mobilePhone"
                                    watch={watch("phone")}
                                    errors={errors.phone}
                                    register={register({
                                        required: t("required.field")
                                    })}
                                />
                                <div>
                                    <ValidateInput
                                        name="password"
                                        label="password"
                                        value={password}
                                        type={!showPassword ? "password" : "text"}
                                        placeholder="signup.password"
                                        watch={watch("password")}
                                        errors={errors.password}
                                        onChange={(e) => checkPassword(e.target.value)}
                                    />
                                    <div className={errors.password ? 'password-eyes-error' : 'password-eyes'} onClick={() => setShowPassword(!showPassword)}>
                                        {!showPassword ?
                                            <Eye height={25} width={25} />
                                            :
                                            <CrossedEye height={25} width={25} />
                                        }
                                    </div>
                                </div>
                                <div>
                                    <ValidateInput
                                        name="passwordConfirmation"
                                        label="passwordConfirmation"
                                        type={!showPasswordConfirm ? "password" : "text"}
                                        placeholder="signup.passwordConfirmation"
                                        watch={watch("passwordConfirmation")}
                                        errors={errors.passwordConfirmation}
                                        register={register({
                                            required: t("required.field"),
                                            validate: {
                                                passwordMatch: value => value === password
                                            }
                                        })}
                                    />
                                    <div className={errors.passwordConfirmation ? 'password-eyes-error' : 'password-eyes'} onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                                        {!showPasswordConfirm ?
                                            <Eye height={25} width={25} />
                                            :
                                            <CrossedEye height={25} width={25} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='signup-checks'>
                                <div className="custom-checkbox">
                                    <input className="custom-control-input" type="checkbox" id="time_saver" name="time_saver" onChange={(e) => setTimeSaver(e.target.checked)} />
                                    <label className="custom-control-label" style={{ color: 'white', fontFamily: 'Montserrat-Bold', fontSize: '18px' }} htmlFor="time_saver">
                                        {t("signup.check1")}
                                    </label>
                                    <br />
                                    <label style={{ color: 'white', fontSize: '14px', wordBreak: 'break-word' }} htmlFor="time_saver">
                                        {t("signup.check1-1")}
                                    </label>

                                </div>
                            </div>
                            <div className='checks-container'>
                                <Accordion defaultActiveKey='0'>
                                    <div className='accordion-top'>
                                        {t("signup.terms")}
                                        <Accordion.Toggle as="div" className="tiny-cta toggle"
                                            onClick={() => setIsOpen(!isOpen)} eventKey='0'>
                                            {isOpen ?
                                                <>
                                                    <AccordionUp fill='#272f47' />
                                                </>
                                                :
                                                <>
                                                    <AccordionDown fill='#272f47' />
                                                </>
                                            }
                                        </Accordion.Toggle>
                                    </div>
                                    <Accordion.Collapse eventKey={'0'}>
                                        <>
                                            <div style={{ paddingLeft: '10px' }}>
                                                <label className='bold'>
                                                    {t("signup.terms1")}
                                                </label>
                                                <label>
                                                    {t("signup.terms2")}
                                                </label>
                                                <label>
                                                    {t("signup.terms3")}
                                                    <Link to="/termsandcondition">
                                                        {t("signup.terms4")}
                                                    </Link>
                                                </label>
                                            </div>
                                            <div className='accordion-checks'>
                                                <div className="custom-checkbox">
                                                    <input className="custom-control-input" type="checkbox"
                                                        id="privacy" name="privacy" onChange={(e) => handlePrivacy(e.target.checked)} />
                                                    <label className="custom-control-label" htmlFor="privacy">
                                                        {t("signup.check2")}
                                                    </label>
                                                    {privacyReq &&
                                                        <div className="invalid-message">
                                                            <Alert />
                                                            {t("required.field")}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="custom-checkbox">
                                                    <input className="custom-control-input" type="checkbox"
                                                        id="marketing" name="marketing" onChange={(e) => setMarketing(e.target.checked)} />
                                                    <label className="custom-control-label" htmlFor="marketing">
                                                        {t("signup.check3")}
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    </Accordion.Collapse>
                                </Accordion>
                            </div>
                            <div className='signup-container-buttons'>
                                <button style={{ width: "unset" }} className="mt-1 cancel-button">
                                    <span>{t("cancel")}</span>
                                </button>
                                <button style={{ width: "unset" }} type="submit" className="mt-1 confirm-button">
                                    <span>{t("next")}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }

        </Translation>
    )
}

export default NewSignup;