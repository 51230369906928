import React, { useEffect, useState } from "react"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../../../redux/store/actions/index";

import "../breadcrumb.styles.scss";

const ProductBreadcrumb = ({ product, history }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const categories = useSelector(state => state.cart.categories);
    const [categoryIndex, setCategoryIndex] = useState();

    /*  useEffect(() => {
         const index = categories.findIndex(category => (
             category.id === product.level1CategoryCode
         ))
         setCategoryIndex(index);
     }, [categories, product.level1CategoryCode]) */

    useEffect(() => {
        const index = categories.findIndex(category => (
            category.id === product.firstLevelCategory
        ))
        setCategoryIndex(index);
    }, [categories, product.firstLevelCategory])

    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <span onClick={() => history.push("/home")}>{t("home")}</span>
            </Breadcrumb.Item>
           {/* <Breadcrumb.Item>
                <span onClick={() => dispatch(actionCreators.changeCategory(product.firstLevelCategory, categoryIndex))}>
                    {product.firstLevelCategory}
                </span>
            </Breadcrumb.Item>  */}
            {product.secondLevelCategory && product.secondLevelCategory &&
                <Breadcrumb.Item>
                    <span onClick={() =>
                        /*  dispatch(actionCreators.changeAllCategories(
                             product.secondLevelCategory,
                             product.firstLevelCategory,
                             product.secondLevelCategory,
                             "",
                             true,
                             categoryIndex
                         )) */
                        dispatch(actionCreators.changeSubCategory(
                            product.secondLevelCategoryId, product.firstLevelCategory, product.secondLevelCategory
                        ))
                    }>
                        {product.secondLevelCategory}
                    </span>
                </Breadcrumb.Item>
            }
            {
                product.thirdLevelCategory && product.thirdLevelCategory &&
                <Breadcrumb.Item>
                    <span onClick={() =>
                        /*  dispatch(actionCreators.changeAllCategories(
                             product.thirdLevelCategory,
                             product.firstLevelCategory,
                             product.secondLevelCategory,
                             product.thirdLevelCategory,
                             true,
                             categoryIndex
                         )) */
                        dispatch(actionCreators.changeThirdLevelCategory(
                            product.categoryId, product.firstLevelCategory, product.secondLevelCategory, product.thirdLevelCategory
                        ))
                    }>
                        {product.thirdLevelCategory}
                    </span>
                </Breadcrumb.Item>
            }
            {/*  <Breadcrumb.Item active>
                <span>{product.description2}</span>
            </Breadcrumb.Item> */}
        </Breadcrumb>
    );
}

export default withRouter(ProductBreadcrumb);