import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import * as actionCreators from "../../../redux/store/actions/index"

import "./slist-popup.styles.scss";

const SlistPopup = ({ show, barcode, amount, onClose }) => {
    const [slistId, setSlistid] = useState();
    const slists = useSelector(state => state.slists.slists);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const onSubmit = (slistId) => {
        if (slistId) {
            dispatch(actionCreators.itemCreate(slistId, barcode, amount));
            onClose();
        }
    }
    return (
        <Modal show={show} centered={true} onHide={onClose} dialogClassName="slist-popup">
            <Modal.Header
                className="slist-popup-header"
                closeButton>
                {t("smartlist.popup.header")}
            </Modal.Header>
            <div className="slist-popup-content">
                <div className="slist-popup-title">{t("smartlist.popup.title")}</div>
                <div className="slist-popup-lists">
                    {slists && slists.map(slist => (
                        <button className={`secondary-cta ${slist.id === slistId ? "selected" : ""}`}
                            style={{padding:"unset", overflow:"hidden", display:"flex",justifyContent:"center",alignItems:"center"}}
                            key={slist.id}
                            // onClick={() => setSlistid(slist.id)}>
                            onClick={() => onSubmit(slist.id)}>
                            <span style={{overflow:"hidden",textOverflow:"ellipsis",display:"inline-block",width:"180px",whiteSpace:"nowrap"}}>{slist.title}</span>
                        </button>
                    ))}
                </div>
               {/*  <div className="slist-popup-actions">
                    <button className="cta" disabled={!slistId} onClick={onSubmit}>
                        <span>{t("smartlist.popup.action")}</span>
                    </button>
                </div> */}
            </div>
        </Modal>
    );
}

export default SlistPopup;