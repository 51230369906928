import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainTemplate from './components/MainComponent/Template/MainTemplate';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import LoginPopup from './features/Login/LoginPopup/LoginPopup';
import Confirm from './features/Confirm/Confirm';
import Home from './features/Home/Home';
import BannerDetails from './features/Home/HomeBrands/BannerDetails/BannerDetails';
import HomeValuesRedCard from './features/Home/HomeValues/HomeValuesRedCard/HomeValuesRedCard';
import HomeValuesGreenCard from './features/Home/HomeValues/HomeValuesGreenCard/HomeValuesGreenCard';
import HomeValuesYellowCard from './features/Home/HomeValues/HomeValuesYellowCard/HomeValuesYellowCard';
import HomeValuesBrownCard from './features/Home/HomeValues/HomeValuesBrownCard/HomeValuesBrownCard';
import HomeValuesBlueCard from './features/Home/HomeValues/HomeValuesBlueCard/HomeValuesBlueCard';
import Recipies from './features/Recipies/Recipies';
import Cart from "./features/Cart/Cart"
import DiscoverProducts from "./features/DiscoverProducts/DiscoverProducts"
import Producter from "./features/Home/Producter/Producter"
import Signup from "./features/Signup/Signup"
import CartCreate from "./features/CartCreate/CartCreate"
import CartUpdate from './features/CartUpdate/CartUpdate';
import Stores from "./features/Stores/Stores"
import Store from './features/Stores/Store/Store';
import Product from './features/Product/Product';
import BannerProductList from './features/Home/HomeCarousel/BannerProductList';
import BrandProductList from './features/Home/HomeBrands/BrandProductList';
import SearchCategory from './features/SearchCategory/NewSearchCategory';
import NewSearch from './features/SearchResults/SearchResults';
import Leaflets from "./features/Leaflets/Leaflets";
import Leaflet from './features/Leaflets/Leaflet/Leaflet';
import PasswordReset from './features/PasswordReset/PasswordReset';
import ProfileContainer from './features/ProfileContainer/ProfileContainer';
import Payments from './features/Payments/Payments';
import DefaultStore from './features/DefaultStore/DefaultStore';
import PrivacyPolicy from "./components/MainComponent/Footer/PivacyPolicy/PrivacyPolicy";
import Faq from "./components/MainComponent/Footer/FAQ/Faq";
import CookiePolicy from "./components/MainComponent/Footer/CookiePolicy/CookiePolicy";
import TermsAndCondi from "./components/MainComponent/Footer/TermsAndCondi/TermsAndCondi";
import Lg from "./components/MainComponent/Footer/LegalGuarantee/LG";
import TermsAndConditions from './features/Checkout/TermsAndConditions/TermsAndConditions';
import { authenticationService } from "./services/basis/AuthenticationService"
import config from './config/config';
import NewSingUp from './features/Signup/NewSignUp';
import SignUpEmail from './features/Signup/SignUpEmail';
import SignUpPhone from './features/Signup/SignUpPhone';
import SignUpSuccess from './features/Signup/SignUpSuccess';
import RegistrationError from './features/ErrorPages/RegistrationError';
import Tutorial from './components/MainComponent/Footer/Tutorial/Tutorial';

import './App.scss';

// custom router
import { Redirect, Router } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'

// redux
import * as actionCreators from "./redux/store/actions/index"
import { connect } from "react-redux"

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faPlus, faClipboardList, faCopy, faCreditCard,
  faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faChevronRight, faChevronDown,
  faCaretDown, faCaretUp
} from '@fortawesome/free-solid-svg-icons'

import paymentFailed from './features/PaymentFailed/paymentFailed';

import ContactUs from './components/MainComponent/Footer/ContactUs/ContactUs';
import AboutUs from './components/MainComponent/Footer/AboutUs/AboutUs';



library.add(fab, faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faClipboardList, faCopy, faChevronRight, faChevronDown,
  faPlus, faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faCreditCard, fab.faFacebookSquare,
  faCaretDown, faCaretUp)

// custom history
export const history = createBrowserHistory({ basename: "/app" });

class App extends Component {
  componentDidMount() {
    if (this.props.user) {
      // authenticationService.isLogged()
      // .then(response => {
      //   // if (config.ENABLED_SLIST) {
      //   //   this.props.getSlists();
      //   // }
      // })
      // .catch((error) => {
      //   this.props.logout();
      //   this.props.loginAction({}, false)
      // })
      // this.props.current();
      this.props.tags();
    }
    this.props.setFlagClickCoop();
    this.props.categories();
  }

  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <MainTemplate>
              <Switch>
                {/* <Route exact path='/' component={DefaultStore} /> */}
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                <Route exact path='/home' component={Home} />
                <Route exact path='/cart' component={Cart} />
                <Route exact path='/discoverproducts' component={DiscoverProducts} />
                <Route exact path='/producter' component={Producter} />
                <Route exact path='/recipies' component={Recipies} />
                <Route exact path='/checkout' component={Cart} />
                <Route exact path='/availability' component={Cart} />
                <Route exact path='/summary' component={Cart} />
                <Route exact path='/cart/create' component={CartCreate} />
                <Route exact path='/cart/update' component={CartUpdate} />
                <Route exact path='/signup' component={Signup} />
                <Route exact path='/signupsuccess' component={SignUpSuccess} />
                <Route exact path='/sendemail' component={SignUpEmail} />
                <Route exact path='/sendOtp/:otp/profile/:profileId/phone/:mobilePhoneLastDigits/activate' component={SignUpPhone} />
                <Route exact path='/registration' component={NewSingUp} />
                <Route exact path='/profile' component={ProfileContainer} />
                <Route exact path='/profile/update' component={ProfileContainer} />
                <Route exact path='/profile/addresses' component={ProfileContainer} />
                <Route exact path='/profile/billing' component={ProfileContainer} />
                <Route exact path='/profile/password' component={ProfileContainer} />
                <Route exact path='/profile/orders' component={ProfileContainer} />
                <Route exact path='/profile/smartlists' component={ProfileContainer} />
                <Route exact path='/profile/payments' component={ProfileContainer} />
                <Route exact path='/leaflets' component={Leaflets} />
                <Route exact path='/leaflets/:leafletId' component={Leaflet} />
                <Route exact path='/reset' component={PasswordReset} />
                <Route exact path='/stores' component={Stores} />
                <Route exact path='/stores/:storeId' component={Store} />
                <Route exact path='/search' component={NewSearch} />
                <Route exact path='/payments' component={Payments} />
                <Route exact path='/product/:ref' component={Product} />
                <Route exact path='/banners/:bannerId/products' component={BannerProductList} />
                <Route exact path='/brand/:tag' component={BrandProductList} />
                {/* <Route exact path='/category/:categoryId' component={SearchCategory} /> */}
                <Route exact path='/category/:firstLevelCategory' component={SearchCategory} />
                <Route exact path='/category/:firstLevelCategory/:secondLevelCategory' component={SearchCategory} />
                <Route exact path='/category/:firstLevelCategory/:secondLevelCategory/:thirdLevelCategory' component={SearchCategory} />
                <Route exact path='/confirm/:orderCode' component={Confirm} />
                <Route exact path='/paymentfailed/:orderCode' component={paymentFailed} />
                <Route exact path='/cookie' component={CookiePolicy} />
                <Route exact path='/termsandcondition' component={TermsAndCondi} />
                <Route exact path='/privacy' component={PrivacyPolicy} />
                <Route exact path='/faq' component={Faq} />
                <Route exact path='/lg' component={Lg} />
                <Route exact path='/terms' component={TermsAndConditions} />
                <Route exact path='/contactus' component={ContactUs} />
                <Route exact path='/tutorial' component={Tutorial} />
                <Route exact path='/aboutus' component={AboutUs} />
                <Route exact path='/ourvalues/convenienza_quotidiana' component={HomeValuesRedCard} />
                <Route exact path='/ourvalues/rispetto_della_natura' component={HomeValuesGreenCard} />
                <Route exact path='/ourvalues/sicurezza_alimentare' component={HomeValuesYellowCard} />
                <Route exact path='/ourvalues/benessere_a_tua_misura' component={HomeValuesBrownCard} />
                <Route exact path='/ourvalues/il_nostro_territorio' component={HomeValuesBlueCard} />
                <Route exact path='/ourbrands/:tag' component={BannerDetails} />
                <Route exact path='/signupError' component={RegistrationError} />
              </Switch>
            </MainTemplate>
          </Switch>
          <LoginPopup
            open={this.props.showLogin}
            closePopup={this.props.closePopup}
          />
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    showLogin: state.user.showLogin
  };
}

const mapDispatchToProps = dispatch => {
  return {
    current: () => dispatch(actionCreators.current()),
    loginAction: () => dispatch(actionCreators.loginAction()),
    logout: () => dispatch(actionCreators.logout()),
    getSlists: () => dispatch(actionCreators.getSlists()),
    closePopup: () => dispatch(actionCreators.showLogin(false)),
    categories: () => dispatch(actionCreators.categories()),
    tags: () => dispatch(actionCreators.getTags()),
    setFlagClickCoop: (flag) => dispatch(actionCreators.setFlagClickAndCoop(flag))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
