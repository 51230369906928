import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import axios from 'axios'

export default function zipCodesCities(q, zipCode) {
    return (
        axios.get(`${config.baseUrl}/cli/addresses/zipcodescities?q=${q}${zipCode ? `&zipCode=${zipCode}` : ""}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}