import React from "react";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import Logo from "../../../../images/Logo_Click&Coop.png";
import HeaderItem from "../HeaderItems/HeaderItem";
import HeaderCartItem from "../HeaderItems/HeaderCartItem";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import { ReactComponent as UserIcon } from '../../../../images/landing-user-sticky.svg';
import * as actionCreators from "../../../../redux/store/actions"
import { ReactComponent as CartIcon } from '../../../../images/new-cart-icon5.svg';
import reactStringReplace from 'react-string-replace';
import { ReactComponent as Search } from '../../../../images/search.svg';
import { ReactComponent as Pin } from '../../../../images/pin.svg';
import { ReactComponent as Calender } from '../../../../images/calender-icon.svg';
import { ReactComponent as Cancel } from '../../../../images/close.svg';
import "./mobile-header.styles.scss";
import HeaderProfileItem from "../HeaderItems/HeaderProfileItem";
import HeaderTags from "../HeaderTags/HeaderTags";
import { Service } from "../NewHeader/Service";
import dayOfWeek from "../../../../utils/dateUtils";
import MobileHeaderTags from "../MobileHeaderTags/MobileHeaderTags";
import GoBackButton from "../../../UI/Buttons/GoBackButton";
import { history } from "../../../../App";
import { connect } from "react-redux"


class MobileHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultSlot: null
        }
    }

    componentDidMount() {
        this.getFirstSlot();
    }

    getFirstSlot = () => {
        Service.defaultCalender()
            .then(response => {
                this.setState({ defaultSlot: response.data })
            }).catch(error => {
                console.log(error);
            })
    }
    collapseTree = () => {
        this.props.changeCategory("", 0);
    }
    render() {
        const { user, cart, searchInput, onChange, showModalLogin, submitSearch, defaultStore, handleChangeTips, handleChangeCategories, tips, categories, isOpen, inputFocus, cancelSearchItem } = this.props;
        const inputClass = `${isOpen ? "form-with-two-round-borders" : "form-with-all-round-borders"} `
        const menuClass = `dropdown-items ${isOpen ? "dropdown--isOpen" : ""}
        dropdown-menu${isOpen ? " show" : ""} `;
        const { defaultSlot } = this.state
        const availableDay = defaultSlot && dayOfWeek(defaultSlot.weekDay)
        return (
            <Translation>
                {t =>
                    <div className="mobile-header">
                        <div className="mobile-header-wrapper">
                            <div className="container-fluid">
                                <div className="row mobile-header-top">
                                    <div className="col-7 default-pin">
                                        <Pin />
                                        <Link to="/" style={{textDecoration: 'none'}}>
                                            {cart && cart.storeDescription ? cart.storeDescription : (defaultStore ? defaultStore.name : "")}
                                        </Link>
                                    </div>
                                    {defaultSlot &&
                                        <div className="col-5 calendar">
                                            <Calender />
                                            <div>
                                                <span className="mr-1">{availableDay.dayNumber} {t(`months.${availableDay.month}`)}</span>
                                                <span> {defaultSlot.validFrom}/{defaultSlot.validTo}</span>
                                            </div>
                                        </div>
                                    }
                                    {/*   <div className="col-2 user text-right">
                                        {user ?
                                            <Link className="link" to="/profile">
                                                <div className="user-logged-container">
                                                    <div className="user-logged">
                                                        {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                    </div>
                                                </div>
                                            </Link>
                                            :
                                            <UserIcon onClick={showModalLogin} />
                                        }
                                    </div> */}
                                </div>

                                <div className="mobile-header-bottom ">
                                    <div className="logo align-items-center col-6" onClick={() => { this.props.selectTag(""); this.collapseTree() }}>
                                        <Link to={defaultStore ? "/home" : "/"}>
                                            <img src={Logo} alt="" />
                                            {/* <Logo /> */}
                                        </Link>
                                    </div>
                                    {/*  {(this.props.location.pathname !== "/" && this.props.location.pathname !== "/signup") ?
                                        <>
                                            <HeaderCartItem
                                                path="/cart"
                                                total={this.props.renderTotal(cart)}
                                                items={cart ? cart.itemsCount : "0"}
                                                cart={cart}
                                                user={user}
                                                onClick={showModalLogin}
                                            >
                                                <CartIcon />
                                            </HeaderCartItem>
                                        </> : <div>&nbsp;</div>
                                    } */}

                                    {this.props.location.pathname !== "/" &&
                                        <div className="actions col-6">
                                            {user ?
                                                <HeaderProfileItem
                                                    path="/profile"
                                                    label={user?.firstName}
                                                >
                                                    <UserIcon />
                                                    {/* <div className="user-logged">
                                                        {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                    </div> */}
                                                </HeaderProfileItem>
                                                :
                                                <HeaderItem
                                                    label={t("login_or_signup")}
                                                    onClick={showModalLogin}>
                                                    <UserIcon />
                                                </HeaderItem>
                                            }
                                            <HeaderCartItem
                                                path="/cart"
                                                total={this.props.renderTotal(cart)}
                                                items={cart ? cart.itemsCount : "0"}
                                                label={t("cart")}
                                                cart={cart}
                                                user={user}
                                                onClick={showModalLogin}
                                            >
                                                <CartIcon />
                                            </HeaderCartItem>
                                        </div>
                                    }
                                </div>
                            </div>

                            {(this.props.location.pathname === "/home" ||
                                this.props.location.pathname === "/search" ||
                                this.props.location.pathname === "/cart" ||
                                this.props.location.pathname.includes("/category") ||
                                this.props.location.pathname.includes("/product") ||
                                this.props.location.pathname.includes("/brand") ||
                                this.props.location.pathname.includes("/ourvalues") ||
                                this.props.location.pathname.includes("/ourbrands") ||
                                this.props.location.pathname.includes("/profile") ||
                                this.props.location.pathname.includes("/confirm") ||
                                this.props.location.pathname.includes("/checkout") ||
                                this.props.location.pathname.includes("/availability") ||
                                this.props.location.pathname.includes("/summary")
                            ) &&
                                <div className="mobile-header-search">
                                    <div className="col-2 burger">
                                        {(this.props.location.pathname !== "/" && this.props.location.pathname !== "/signup") ?
                                            <BurgerMenu /> : <div>&nbsp;</div>
                                        }
                                    </div>
                                    <form className={inputClass} onSubmit={e => submitSearch(e)} autoComplete="off">
                                        {/*  <form className={inputClass} onSubmit={e => {
                                        e.preventDefault();
                                        this.props.history.push({
                                            pathname: "/search",
                                            state: { searchInput: searchInput }
                                        })
                                    }}> */}
                                        <input
                                            name="input"
                                            placeholder={t("header.mobileMobilePlaceHolder")}
                                            onChange={onChange}
                                            value={searchInput}
                                            type="search"
                                            ref={inputFocus.ref}
                                            onKeyPress={event => {
                                                if (event.key === 'Enter') {
                                                    submitSearch(event)
                                                }
                                            }}
                                        />
                                        {
                                            tips &&
                                            <div className={menuClass} aria-labelledby="dropdownMenuButton" style={this.props.location.pathname === "/search" ? {top: '67%'} : {}}>
                                                <div className="tips-items">
                                                    <h5 style={{ color: "grey", paddingLeft: "25px" }}>Suggerimenti</h5>
                                                    {tips && tips.map((item, index) => (
                                                        <span key={index} className="dropdown-item"
                                                            onClick={() => handleChangeTips(item)}>
                                                            {reactStringReplace(item, `${searchInput}`, (match, i) => (
                                                                <span key={i} style={{ fontWeight: "bold" }}>{match}</span>
                                                            ))}
                                                        </span>
                                                    ))}

                                                </div>
                                                <div className="category-items">
                                                    <h5 style={{ color: "grey", paddingLeft: "25px" }}>Categorie</h5>
                                                    {categories && categories.map((item, index) => (
                                                        <span key={index} className="dropdown-item"
                                                            onClick={() => handleChangeCategories(item)}>
                                                            <div style={{ display: "flex" }}>
                                                                <div>
                                                                    {reactStringReplace(item.label, `${searchInput}`, (match, i) => (
                                                                        <span key={i} style={{ fontWeight: "bold" }}>{match}</span>
                                                                    ))}
                                                                </div>
                                                                <div className="count">{item.count}</div>
                                                            </div>
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                        {
                                            searchInput &&
                                            <button onClick={(e) => cancelSearchItem(e)} className="mobile-button-cancel" >
                                                <Cancel />
                                            </button>
                                        }
                                        <button type="submit" className="mobile-button-submit">
                                            <Search />
                                            {/* <span>{t("search")}</span>  */}
                                        </button>
                                    </form>
                                </div>
                            }


                            {(this.props.location.pathname === "/home" ||
                                this.props.location.pathname === "/search" ||
                                this.props.location.pathname === "/cart" ||
                                this.props.location.pathname.includes("/category") ||
                                this.props.location.pathname.includes("/product") ||
                                this.props.location.pathname.includes("/brand") ||
                                this.props.location.pathname.includes("/ourvalues") ||
                                this.props.location.pathname.includes("/ourbrands") ||
                                this.props.location.pathname.includes("/profile") ||
                                this.props.location.pathname.includes("/confirm") ||
                                this.props.location.pathname.includes("/checkout") ||
                                this.props.location.pathname.includes("/availability") ||
                                this.props.location.pathname.includes("/summary")
                            ) &&
                                <div className="mobile-header-bottom1">
                                    {/* <HeaderTags />   */}
                                    <MobileHeaderTags searchInput={searchInput} />
                                </div>
                            }
                            {
                                (
                                    this.props.location.pathname === "/search" ||
                                    this.props.location.pathname === "/cart" ||
                                    this.props.location.pathname.includes("/category") ||
                                    this.props.location.pathname.includes("/product") ||
                                    this.props.location.pathname.includes("/ourvalues") ||
                                    this.props.location.pathname.includes("/discoverproducts") ||
                                    this.props.location.pathname.includes("/profile") ||
                                    this.props.location.pathname.includes("/checkout") ||
                                    this.props.location.pathname.includes("/availability") ||
                                    this.props.location.pathname.includes("/summary") 
                                ) &&
                                <GoBackButton style={{ zIndex: "-1", position: "relative" }}
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                />
                            }
                            {
                                this.props.location.pathname.includes("/brand")
                                &&
                                <GoBackButton style={{ zIndex: "-1", position: "relative" }}
                                    onClick={() => {
                                        history.push("/home")
                                    }}
                                />
                            }
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeKey: state.categories.activeKey,
        mainCategory: state.categories.mainCategory,
        subCategory: state.categories.subCategory,
        isSubCategoryOpen: state.categories.isSubCategoryOpen,
        thirdLevelCategory: state.categories.thirdLevelCategory,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        selectTag: (tag) => dispatch(actionCreators.tag(tag)),
        changeCategory: (categoryId, index) => dispatch(actionCreators.changeCategory(categoryId, index)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileHeader));