import React from "react"

import "./header-item.styles.scss";

const HeaderItem = (props) => {
    return (
        <div className={`header-item ${props.actived ? "header-item-actived" : ""}`}
            onClick={props.onClick}
        >
            <div className="header-item-content">
                <div className="header-item-content-signinlogo">
                    {props.children}
                </div>
                {props.label &&
                    <div style={{textAlign:"center"}}>{props.label}</div>
                }
            </div>
        </div>
    );
}

export default HeaderItem;