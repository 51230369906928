import React, {useState} from "react"
import ValidateInput from '../../../components/UI/Input/ValidateInput';
import PasswordHelp from '../../../components/PasswordHelp/PasswordHelp';
import swal from "../../../utils/swal"
import passwordChange from "./Service"
import { Row, Col } from 'react-bootstrap';
import { Translation } from 'react-i18next';
import { useForm } from 'react-hook-form'
import { ReactComponent as Eye } from "../../../images/eye.svg";
import { ReactComponent as CrossedEye } from "../../../images/eye-crossed.svg";
import "./password-change.styles.scss";

function PasswordChange(props) {

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const { register, handleSubmit, watch, errors } = useForm({
        mode: 'onBlur',
    });

    const changePassword = data => {
        passwordChange(data.oldPassword, data.newPassword)
            .then(response => {
                swal.success("Password aggiornata correttamente");
            }).catch((error) => {
                swal.error(error.message);
            })
    }

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(changePassword)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                    <div className="password-change">
                        <h2>{t("passwordChange.title")}</h2>
                        <div>
                            <Row className="signup-row">
                                <Col md={6}>
                                    <ValidateInput
                                        name="oldPassword"
                                        label="oldPassword"
                                        type={!showPasswordOld ? "password" : "text"}
                                        watch={watch("oldPassword")}
                                        errors={errors.oldPassword}
                                        register={register({
                                            required: t("required.field")
                                        })}
                                    />
                                    <div className={errors.oldPassword ? 'password-eyes-error' : 'password-eyes'} onClick={() => setShowPasswordOld(!showPasswordOld)}>
                                        {!showPasswordOld ?
                                            <Eye height={25} width={25}/>
                                            :
                                            <CrossedEye height={25} width={25}/>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row className="signup-row">
                                <Col md={6}>
                                    <ValidateInput
                                        name="newPassword"
                                        label="newPassword"
                                        type={!showPassword ? "password" : "text"}
                                        watch={watch("newPassword")}
                                        errors={errors.newPassword}
                                        register={register({
                                            required: t("required.field"),
                                            minLength: { value: 8, message: t("password.length") }
                                        })}
                                    />
                                    <div className={errors.passwordConfirm || errors.newPassword ? 'password-eyes-error' : 'password-eyes'} onClick={() => setShowPassword(!showPassword)}>
                                        {!showPassword ?
                                            <Eye height={25} width={25}/>
                                            :
                                            <CrossedEye height={25} width={25}/>
                                        }
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <ValidateInput
                                        name="passwordConfirm"
                                        label="passwordConfirm"
                                        type={!showPasswordConfirm ? "password" : "text"}
                                        watch={watch("passwordConfirm")}
                                        errors={errors.passwordConfirm}
                                        register={register({
                                            required: t("required.field"),
                                            validate: {
                                                passwordMatch: value => value === watch('newPassword')
                                            }
                                        })}
                                    />
                                    <div className={errors.passwordConfirm || errors.newPassword ? 'password-eyes-error' : 'password-eyes'} onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                                        {!showPasswordConfirm ?
                                            <Eye height={25} width={25}/>
                                            :
                                            <CrossedEye height={25} width={25}/>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <PasswordHelp />
                            <div className="text-right">
                                <button type="submit" className="cta">
                                    <span>{t("passwordChange.send")}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </Translation>
    );
}

export default PasswordChange;



