import React, { useState, useEffect } from "react";
import StickyBox from "react-sticky-box";
import Categories from "../Categories/Categories";
import GoBackButton from "../../components/UI/Buttons/GoBackButton";
import ProductBreadcrumb from "../../components/UI/Breadcrumb/ProductBreadcrumb/ProductBreadcrumb";
import SlistPopup from "../Smartlist/SlistPopup/SlistPopup";
import ProductDetails from "./ProductDetails/ProductDetails";
import ProductInfo from "./ProductInfo/ProductInfo";
import { connect } from "react-redux";
import { productsService } from "./Service";
import { withRouter } from "react-router";
import toaster from "../../utils/toaster";
import "./product.styles.scss";
import CategoryTree from "../SearchCategory/CategoryTree/CategoryTree";

const Product = ({
    match,
    location,
    cart,
    defaultStore,
    history,
}) => {
    const [amount, setAmount] = useState();
    const [umStep, setUmStep] = useState();
    const [unity, setUnity] = useState();
    const [replacement, setReplacemnet] = useState(false);
    const [incremented, setIncremented] = useState(false);
    const [decremented, setDecremented] = useState(false);
    const [actualUnity, setActualUnity] = useState();
    const [info, setInfo] = useState();
    const [product, setProduct] = useState();
    const [productImages, setProductImages] = useState();

    // const [available, setAvailable] = useState(true);
    const [showPopup, setShowPopup] = useState(false)

    const ref = match.params.ref;
    const isDetail = location.state ? location.state.isDetail : null;
    const detailProduct = location.state ? location.state.product : null;
    const storeId = cart ? cart.storeId : (defaultStore ? defaultStore.id : "");

    useEffect(() => {
        productsService.fetchByRef(ref, storeId)
            .then(response => {
                const product = response.data;
                setProduct(product);
                setUmStep(parseFloat(product.umStep?.toFixed(2)));;
                setActualUnity(parseInt(product.attrib3));
                setProductImages(
                    product && product.images
                        ? product.images.map(item => { return { original: item } }) : null
                );
            }).catch((error) => {
                console.log(error);
            })
    }, [ref, detailProduct, isDetail, storeId])

    useEffect(() => {
        var isAlreadyBookedItem = false;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === product?.ref) {
                    isAlreadyBookedItem = true;
                    if (element.um === "PZ") {
                        setAmount(element.amount);
                    } else {
                        setAmount(parseFloat(element.weight?.toFixed(2)));
                    }
                }
            });
        }
        if (!isAlreadyBookedItem){
            setAmount(parseFloat(product?.umStep?.toFixed(2)));
        }
        if (product?.attrib3) {
            var isAlreadyBooked = false;
            if (cart && cart.items) {
                cart.items.forEach(element => {
                    if (element.ref === product?.ref) {
                        if (product?.attrib3) {
                            isAlreadyBooked = true;
                            if (element.umStep < element.weight) {
                                let steps = Math.ceil(element.weight / parseFloat(element.umStep?.toFixed(2)));
                                let newUnity = parseInt(element.attrib3) * steps;
                                setUnity(parseInt(newUnity));
                            } else {
                                setUnity(parseInt(element.attrib3));
                            }
                        }
                    }
                });
            }
            if (!isAlreadyBooked){
                setUnity(parseInt(product?.attrib3));
            }
        }
    }, [cart, product])

    useEffect(() => {
        if (product?.barcode) {
            productsService.info(product?.barcode).then(response => {
                if (response.data) {
                    setInfo(response.data);
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [product?.barcode])

    const checkIsBooked = () => {
        // const { cart, item } = this.props;
        var isAlreadyBooked = false;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === product.ref) {
                    isAlreadyBooked = true;
                }
            });
        }
        return isAlreadyBooked;
    }


    const incrementAmount = () => {
        let roundStep = parseFloat(umStep?.toFixed(2));
        let newAmount = amount + roundStep;
        newAmount = newAmount?.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (product && product.maxAmount) {
            if (newAmount <= product.maxAmount) {
                setAmount(newAmount);
            }
        } else {
            setAmount(newAmount);
        }
        if (product.attrib3) {
            let newUnity = unity + parseInt(product.attrib3);
            setUnity(newUnity);
        }
        if (checkIsBooked()) {
            const cartItem = cart.items.find(element => element.ref === product.ref);
            if (newAmount !== cartItem.amount && !incremented) {
                setIncremented(true);
                toaster.standard("Dopo aver modificato la quantità aggiornare il carrello");
            }
        }
    }

    const decrementAmount = () => {
        let roundStep = parseFloat(umStep?.toFixed(2));
        let newAmount = amount - roundStep;
        newAmount = newAmount?.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (amount > umStep) {
            setAmount(newAmount);
        }
        if (product.attrib3 && unity > product.attrib3) {
            let newUnity = unity - product.attrib3;
            setUnity(newUnity);
        }
        if (checkIsBooked()) {
            const cartItem = cart.items.find(element => element.ref === product.ref);
            if (newAmount !== cartItem.amount && !decremented) {
                setDecremented(true);
                toaster.standard("Dopo aver modificato la quantità aggiornare il carrello");
            }
        }
    }

    if (product) {
        return (
            <div className="product-container mobile-padding">
                <div className="page-grid">
                    <div className="grid-left" offsettop={0} offsetbottom={0}>
                        {/* <Categories /> */}
                        <CategoryTree />
                    </div>
                    <div className="grid-right">
                        {/* <ProductBreadcrumb product={product} /> */}
                        <div className="breadcrumb-container">
                            <ProductBreadcrumb product={product} />
                        </div>
                        <ProductDetails
                            product={product}
                            productImages={productImages}
                            incrementAmount={incrementAmount}
                            decrementAmount={decrementAmount}
                            setIncremented={setIncremented}
                            setDecremented={setDecremented}
                            incremented={incremented}
                            decremented={decremented}
                            amount={amount}
                            unity={unity}
                            replacement={replacement}
                            setShowPopup={setShowPopup}
                        />
                        {info &&
                            <ProductInfo info={info} />
                        }
                    </div>
                </div>
                {showPopup &&
                    <SlistPopup
                        show={showPopup}
                        barcode={product?.barcode}
                        amount={product.purchaseUm === "PZ" ? amount : 1}
                        onClose={() => setShowPopup(false)}
                    />
                }
            </div>
        );
    } else {
        return null;
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        defaultStore: state.user.defaultStore,
    };
}

export default withRouter(connect(mapStateToProps)(Product));