import React, { Component } from 'react';

import "./PrivacyPolicy.scss"

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="container d-flex justify-content-center flex-column mt-5 mb-5 privacy-policy">
                <div className="policy-heading text-center">INFORMATIVA PRIVACY
                    INFORMATIVA AI SENSI DELL’ART. 13 DEL REGOLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO E DEL
                    CONSIGLIO
                </div>
                <div className="policy-terms">
                    <b>Gentile Signora/e,</b> <br />
                    nell'ottica del costante impegno di Sait Consorzio Delle Cooperative Di Consumo Trentine Soc. Coop. (di seguito “Sait"), di tutelare la privacy dei suoi Soci/Clienti garantendo il rispetto del Regolamento (UE) 2016/679 del Parlamento Europeo e del Consiglio relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali (di seguito "GDPR"), la scrivente, in qualità di Titolare del trattamento, intende comunicarLe le finalità e le modalità del trattamento a cui i Suoi dati personali sono destinati, nonché i diritti che, a seguito di queste attività, la legge Le riconosce. Come può ben comprendere, infatti, per la navigazione, nonché soprattutto per l'esecuzione della Sua richiesta d'iscrizione attraverso il nostro sito web e per la gestione del Suo account personale (di seguito i "SERVIZI"), Sait deve inevitabilmente poter conoscere e trattare alcuni Suoi dati personali, ovvero, ad esempio, il Suo nome e cognome, la data di nascita, l'indirizzo e-mail, il numero di telefono, il codice fiscale (di seguito i "DATI"). Nel caso di richiesta di fattura sarà inoltre necessario conoscere P.IVA, indirizzo della sede.
                    La presente comunicazione, pertanto, intende informarLa specificatamente sul tipo di dati trattati e sull'uso che, qualora Lei acconsenta, ne potrà essere fatto.
                    La preghiamo, dunque, di prendere attentamente visione della seguente Informativa privacy: si specifica sin d’ora che per quanto attiene ai dati trattati mediante cookie, si rimanda alla privacy policy del sito internet mentre per quanto riguarda il trattamento effettuato qualora si intenda utilizzare la carta “In Cooperazione”, si rimanda all’informativa ricevuta al momento della richiesta della carta stessa.
                </div>
                <div className="policy-title">
                    1. TITOLARE DEL TRATTAMENTO
                    <div className="policy-terms">
                        Il Titolare del trattamento è SAIT – CONSORZIO DELLE COOPERATIVE DI CONSUMO TRENTINE SOC. COOP., con sede legale a Trento (TN), in Via Innsbruck, n. 2 (di seguito “TITOLARE DEL TRATTAMENTO”) È possibile contattare il Titolare presso la sua sede oppure telefonando al numero 0461 / 808 100 o scrivendo a info@sait.tn.it.
                    </div>
                </div>
                <div className="policy-title">
                    2. DATA PROTECTION OFFICER
                    <div className="policy-terms">
                        Il titolare del trattamento ha inoltre nominato un Responsabile per la protezione dei dati (o Data Protection Officer –DPO–), la società Effizient Srl con sede in 39100 Bolzano (BZ), via Galvani 6/A.
                        Per esercitare i diritti previsti dalla normativa e meglio specificati sopra è possibile contattare il Responsabile per la protezione dei dati presso la sua sede oppure telefonando al numero 0471 / 053 533 o scrivendo a dpo@sait.tn.it
                    </div>
                </div>
                <div className="policy-title">3. FINALITÀ E BASE GIURIDICA DEL TRATTAMENTO
                    <div className="policy-terms">
                        I dati personali di cui Sait viene in possesso sono esclusivamente dati di tipo comune e sono quelli da Lei forniti in occasione della registrazione al nostro sito (creazione dell'account personale) e dell'utilizzazione dei nostri servizi. Questi dati verranno trattati esclusivamente per:
                        <ul>
                            <li>
                                A) consentirLe di fruire dei Servizi telematici offerti da Sait attraverso questo sito web e per la realizzazione degli adempimenti connessi e consequenziali all’esecuzione degli stessi;
                            </li>
                            <li>
                                B) realizzare analisi statistiche su dati aggregati o anonimi. Tale trattamento non permette l’identificazione dell’utente, ma semplicemente vuole verificare l’adeguatezza delle campagne di web marketing adottate e/o il corretto funzionamento del sito, misurando il traffico di utenti che genera. Il trattamento di dati aggregati o anonimi, che non permettono l’identificazione dell’utente, non rientra nel campo di applicazione della normativa in materia di protezione dei dati personali e pertanto non è richiesto alcun consenso per il loro trattamento;
                            </li>
                            <li>
                                C) fornire un riscontro alla Sua richiesta di assistenza;
                            </li>
                            <li>
                                D) consentirLe di ricevere materiale pubblicitario e/o comunicazioni ed informazioni di natura commerciale e di marketing diretto sui prodotti messi in vendita e sui servizi offerti all’interno dei nostri punti vendita, sulle relative offerte, sugli sconti e su ogni altra iniziativa promozionale e di fidelizzazione adottata da Sait, anche attraverso sistemi di contatto automatizzati, come, a mero scopo esemplificativo, mediante l’utilizzo del Suo indirizzo di residenza e/o di posta elettronica, ovvero anche attraverso l’invio di messaggi SMS inviati direttamente al Suo numero di telefonia mobile.
                            </li>
                            La base giuridica su cui si fonda il trattamento dei Suoi dati personali è l’adempimento di un contratto o di misure precontrattuali adottate su Sua richiesta per la finalità A) e C) mentre per la finalità D) la base giuridica è il consenso da Lei prestato, il quale dovrà risultare in modo esplicito e specifico ed è del tutto facoltativo. Ciò significa che se Lei decidesse di non prestare il consenso al trattamento dei Suoi dati personali per finalità di marketing, potrà usufruire ugualmente del servizio richiesto. Le ricordiamo infine che in ogni momento potrà decidere di revocare il consenso eventualmente prestato, comunicandolo al Titolare del Trattamento senza alcuna formalità.
                        </ul>
                    </div>
                </div>
                <div className="policy-title">
                    4. PERIODO DI CONSERVAZIONE DEI DATI PERSONALI
                    <div className="policy-terms">
                        Sait ha stabilito questa tempistica di conservazione: (a) i dati rilevanti ai fini amministrativo-contabili verranno mantenuti in base alla tempistica stabilita dalla normativa civilistica pari a 10 anni (b) i dati di accesso all’area riservata (account) verranno mantenuti sino alla richiesta di chiusura dell’utente, salvo che gli stessi siano rilevanti ai fini amministrativo-contabili o debbano essere ulteriormente conservati per l’adempimento di obblighi legali (c) per l’eventuale finalità di marketing i dati verranno conservati sino alla disiscrizione dal servizio o comunque per un termine di 2 anni (d) i dati relativi alle richieste di supporto ed assistenza verranno conservati per 2 anni, salvo che gli stessi siano rilevanti ai fini amministrativo-contabili o debbano essere ulteriormente conservati per l’adempimento di obblighi legali.
                    </div>
                </div>
                <div className="policy-title">
                    5. CATEGORIE DI SOGGETTI DESTINATARI DEI DATI
                    <div className="policy-terms">
                        I dati trattati non saranno oggetto di diffusione ma potranno essere comunicati a soggetti specificatamente individuati che supportano Sait nella fornitura dei Servizi. Tali soggetti sono:
                        <ul>
                            <li>
                                consulenti legali e fiscali, fornitori di servizi informatici e società che effettuano la gestione e manutenzione dei sistemi necessari a rendere possibile la fruizione dei Servizi, società che ci supportano nella logistica e nelle attività di magazzino;
                            </li>
                            <li>
                                società che rendono possibile il pagamento online
                            </li>
                            <li>
                                il punto vendita e/o l’esercizio presso il quale avviene il ritiro dei prodotti
                            </li>
                            <li>
                                enti pubblici o pubbliche amministrazioni
                            </li>
                            <li>
                                servizi postali e agenzie di spedizione e consegna a domicilio, partner commerciali, società che offrono servizi pubblicitari e di marketing, istituti bancari e assicurativi, società di recupero crediti.
                            </li>
                        </ul>
                        I soggetti sopra individuati potranno agire, a seconda dei casi, in qualità di Responsabili del trattamento (di cui Sait mantiene un elenco aggiornato che verrà esibito su richiesta dell’interessato), autonomi Titolari del trattamento e/o Destinatari.
                        L’eventuale comunicazione dei Suoi dati personali avverrà nel pieno rispetto delle disposizioni di Legge previste dal Regolamento Europeo e delle misure tecniche e organizzative predisposte da Sait per garantire un adeguato livello di sicurezza e verrà effettuata solo per garantire il rispetto degli obblighi legali o contrattuali derivanti dall’utilizzo del servizio.
                    </div>
                </div>
                <div className="policy-title">
                    6. TRASFERIMENTO DI DATI PERSONALI VERSO PAESI TERZI
                    <div className="policy-terms">
                        Il Titolare del trattamento non intende trasferire i Suoi dati personali verso Paesi terzi. L’intero trattamento dei dati personali, infatti, si svolge all’interno dei confini del territorio italiano, oppure in alcuni e limitati casi entro quelli dell’Unione Europea. Qualora ciò avvenga, verranno rispettate le disposizioni previste dal Capo V del GDPR.
                        L’hosting del sito si trova all’interno dell’Unione Europea.
                    </div>
                </div>
                <div className="policy-title">
                    7. PROCESSI DECISIONALI AUTOMATIZZATI
                    <div className="policy-terms">
                        Il Titolare del trattamento non intende utilizzare processi decisionali automatizzati, ivi compresa la profilazione di cui all’articolo 22, paragrafi 1 e 4, del Regolamento Europeo.
                    </div>
                </div>
                <div className="policy-title">
                    8. NATURA DEL CONFERIMENTO
                    <div className="policy-terms">
                        Il conferimento dei Suoi dati personali, contrassegnati come necessari, per le finalità di cui al paragrafo 3.A, ha natura obbligatoria: il mancato, parziale o erroneo conferimento potrebbe rendere impossibile per Sait l’esecuzione dei servizi da Lei richiesti.
                        Il conferimento dei Suoi dati personali per la finalità di cui al paragrafo 3.D ha natura facoltativa, ma la mancata autorizzazione al loro trattamento, pur non impedendo in alcun modo la Sua iscrizione al nostro sito e la fruizione dei nostri Servizi, potrebbe non consentirci di informarLa correttamente sulle ulteriori comunicazioni ed informazioni di natura pubblicitaria, commerciale e di marketing diretto, nonché sui servizi aggiuntivi e promozionali che Sait si prefigge di offrirLe.
                    </div>
                </div>
                <div className="policy-title">
                    9. DIRITTI DELL'INTERESSATO
                    <div className="policy-terms">
                        In relazione al trattamento dei Suoi dati personali, ai sensi del Regolamento Europeo, Ella in qualità di interessato ha il diritto di:
                        <ul>
                            <li>
                                revocare il Suo consenso al trattamento in qualsiasi momento. Occorre evidenziare, tuttavia, che la revoca del consenso non pregiudica la liceità del trattamento basata sul consenso prima della revoca, così come previsto dall’art. 7, comma 3, del Regolamento Europeo;
                            </li>
                            <li>
                                chiedere al Titolare del trattamento l’accesso ai Suoi dati personali, così come previsto dall’art. 15 del Regolamento Europeo;
                            </li>
                            <li>
                                chiedere al Titolare del trattamento l’accesso ai Suoi dati personali, così come previsto dall’art. 15 del Regolamento Europeo;
                            </li>
                            <li>
                                ottenere dal Titolare del trattamento la cancellazione dei Suoi dati personali qualora sussista anche solo uno dei motivi previsti dall’art. 17 del Regolamento Europeo;
                            </li>
                            <li>
                                ottenere dal Titolare del trattamento la limitazione del trattamento dei Suoi dati personali qualora ricorrano una delle ipotesi previste dall’art. 18 del Regolamento Europeo;
                            </li>
                            <li>
                                ricevere dal Titolare del trattamento i dati personali che La riguardano in un formato strutturato, di uso comune e leggibile da dispositivo automatico, nonché ha il diritto di trasmettere tali dati a un altro titolare del trattamento senza impedimenti, così come previsto dall’art. 20 del Regolamento Europeo;
                            </li>
                            <li>
                                opporsi in qualsiasi momento, per motivi connessi alla Sua situazione particolare, al trattamento dei Suoi dati personali svolto ai sensi dell’art. 6, paragrafo 1, lettere e) o f), compresa la profilazione sulla base di tali disposizioni, così come previsto dall’art. 21 del Regolamento Europeo;
                            </li>
                            <li>
                                non essere sottoposto a decisioni basate unicamente sul trattamento automatizzato, compresa la profilazione, che producano effetti giuridici che La riguardino o che incidano significativamente sulla Sua persona, qualora non abbia preventivamente ed esplicitamente acconsentito, così come previsto dall’art. 22 del Regolamento Europeo. A mero titolo esemplificativo e non esaustivo, rientra in questa categoria qualsiasi forma di trattamento automatizzato di dati personali teso ad analizzare o prevedere aspetti riguardanti le scelte di consumo e di acquisto, la situazione economica, gli interessi, l’affidabilità, il comportamento;
                            </li>
                            <li>
                                proporre reclamo ad un’autorità di controllo, qualora ritenga che il trattamento che La riguarda violi il Regolamento Europeo. Il reclamo può essere proposto nello Stato membro in cui risiede abitualmente, lavora oppure nel luogo ove si è verificata la presunta violazione, così come previsto dall’art. 77 del Regolamento Europeo.
                            </li>
                        </ul>
                        Per esercitare ciascuno dei Suoi diritti, Lei può contattare il Titolare del trattamento, nella persona del legale rappresentante - indirizzando una comunicazione presso la sede legale di Via Innsbruck, 2, Trento, o telefonando al numero 0461 / 808 100 o scrivendo a info@sait.tn.it - oppure al Data Protection Officer, in 39100 Bolzano (BZ), via Galvani 6/A, oppure telefonando al numero 0471 / 053 533 o scrivendo a dpo@sait.tn.it.
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;