import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import axios from 'axios'

export const Service = {
    banners, bannersProductList, bannersBarcodesProductList
};

function banners(category) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners/search?type=PRODUCT`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function bannersProductList(bannerId) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners/${bannerId}/productForCategories`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function bannersBarcodesProductList(barcodes) {
    return (
        axios.post(`${config.baseUrl}/cli/cms/banners/products`, barcodes)
            .then(handleResponse)
            .then(checkResponse)
    );
}