import React, { useState, useEffect, useCallback } from 'react';
import Order from './Order/Order';
import OrderHeader from './OrderHeader/OrderHeader';
import OrderFilters from './OrderFilters/OrderFilters';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner'
import toaster from '../../utils/toaster';
import { Accordion, Row, Col } from "react-bootstrap";
import { ecommerceService } from "../../services/ecommerce/EcommerceService";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import * as actionCreators from "../../redux/store/actions/index";
import { Service } from '../../components/MainComponent/Header/NewHeader/Service'
import "./orders.styles.scss"

const Orders = ({ copy, modify, cart }) => {
    const { t } = useTranslation();
    const [orders, setOrders] = useState([])
    const [currentOrder, setCurrentOrder] = useState()
    const [activeKey, setActiveKey] = useState()
    const [defaultSlot, setDefaultSlot] = useState()
    const [items, setItems] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [page, setPage] = useState(0)
    const [hasMore, setHasMore] = useState(true);
    const [totalPages, setTotalPages] = useState()
    const [totalElements, setTotalElements] = useState()
    const [filter, setFilter] = useState("")

    const readOrders = useCallback(
        (page) => {
            ecommerceService.orders(page, filter)
                .then(response => {
                    setOrders(response.data)
                    setTotalPages(response.totalPages)
                    setPage(response.number)
                    setHasMore(!response.last)
                    setIsLoaded(true)
                    setTotalElements(response.totalElements)
                }).catch((error) => {
                    console.log(error);
                })
        },
        [filter],
    );

    useEffect(() => {
        readOrders(0);
        getFirstSlot()
    }, [readOrders])

    const getFirstSlot = () => {
        Service.defaultCalender()
            .then(response => {
                setDefaultSlot(response.data)
            }).catch(error => {
                console.log(error);
            })
    }

    const abort = (orderId) => {
        ecommerceService.abortOrder(orderId)
            .then(response => {
                setCurrentOrder(response.data.order)
                setActiveKey(null);
                readOrders(page);
            }).catch((error) => {
                toaster.error(error.message)
                console.log(error);
            })
    }

    const loadMore = () => {
        const newPage = page + 1;
        if (newPage < totalPages) {
            ecommerceService.orders(newPage, filter)
                .then(response => {
                    const newResponse = orders;
                    newResponse.push(...response.data);
                    setOrders(newResponse)
                    setTotalPages(response.totalPages)
                    setPage(response.number)
                    setHasMore(!response.last)
                    setIsLoaded(true)
                    setTotalElements(response.totalElements)
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const selectOrder = (orderId, status, index) => {
        if (orderId !== currentOrder?.id) {
            ecommerceService.fetchOrder(orderId)
                .then(response => {
                    if (status === "TO_BE_PREPARED" ||
                        status === "IN_PREPARATION" ||
                        status === "PAYMENT_PENDING" ||
                        status === "ABORTED"
                    ) {
                        setCurrentOrder(response.data)
                        setItems(response.data.cart.items)
                    } else {
                        setCurrentOrder(response.data)
                        setItems(response.data.preparedCartItems)
                    }
                    setActiveKey(orderId);
                }).catch((error) => {
                    console.log(error);
                })
        } else {
            setActiveKey(null);
            setCurrentOrder(null);
        }
    }

    if (isLoaded) {
        return (
            <div className="orders">
                <h2>{t("orders")}</h2>
                <OrderFilters
                    filter={filter}
                    setFilter={setFilter}
                    totalElements={totalElements}
                />
                <InfiniteScroll
                    style={{ overflowX: "hidden" }}
                    dataLength={orders.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<Loader
                        type="ThreeDots"
                        color="#333"
                        height={70}
                        width={70}
                        timeout={3000} //3 secs
                    />}
                >
                    <Row style={{ margin: '0' }} className='orders-tab-header'>
                        <Col>
                            <span>{t("date")}</span>
                        </Col>
                        <Col style={{ maxWidth: '100px' }}>
                            <span>{t("id")}</span>
                        </Col>
                        <Col>
                            <span>{t("order.status")}</span>
                        </Col>
                        <Col>
                            <span>{t("order.store")}</span>
                        </Col>
                        <Col>
                            <span>{t("payment")}</span>
                        </Col>
                        <Col>
                            <span>{t("total")}</span>
                        </Col>
                    </Row>
                    <Accordion activeKey={activeKey}>
                        {orders && orders.map((item, index) => (
                            <div className="orders-card" key={item.id}>
                                <OrderHeader
                                    item={item}
                                    index={index}
                                    activeKey={activeKey}
                                    selectOrder={selectOrder}
                                />
                                <Accordion.Collapse eventKey={item.id}>
                                    <Order
                                        order={currentOrder}
                                        items={items}
                                        abort={abort}
                                        copy={copy}
                                        modify={modify}
                                        cart={cart}
                                        defaultSlot={defaultSlot}
                                    />
                                </Accordion.Collapse>
                            </div>
                        ))}
                        {orders && orders.length === 0 &&
                            <span className="title">
                                {t("orders.empty")}
                            </span>
                        }
                    </Accordion>
                </InfiniteScroll>
            </div>
        );
    } else {
        return null;
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart
    };
}

const mapDispatchToProps = dispatch => {
    return {
        copy: (orderId) => dispatch(actionCreators.copy(orderId)),
        modify: (orderId, terminalId) => dispatch(actionCreators.modify(orderId, terminalId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);