import React from "react";
import LandingCarousel from "../DefaultStore/LandingCarousel/LandingCarousel";
import StoreSelect from "../CartCreate/StoreSelect/StoreSelect"
import AddressSelect from "./AddressSelect/AddressSelect"
import DropdownInput from "../DefaultStore/DropdownInput/DropdownInput";
import { Translation } from "react-i18next";
import { Redirect } from 'react-router'
import { ecommerceService } from "../../services/ecommerce/EcommerceService";
import { storesService } from "../../services/basis/StoresService";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"

import "../DefaultStore/default-store.styles.scss";

class CartCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryModes: [],
            selectedDeliveryMode: this.props.defaultDelivery ? this.props.defaultDelivery : null,
            zipCode: this.props.defaultZipCode ? this.props.defaultZipCode : "",
            cityName: this.props.defaultCityName ? this.props.defaultCityName : "",
            selectedStore: this.props.defaultStore ? this.props.defaultStore : null,
            addressId: null,
            firstSlot: null
        }
    }

    componentDidMount() {
        ecommerceService.deliveryModes()
            .then(data => {
                this.setState({
                    deliveryModes: data
                })
            });
        if (this.state.selectedStore) {
            this.getFirstSlot();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedStore !== this.state.selectedStore) {
            if (this.state.selectedStore && this.state.selectedStore.id) {
                this.getFirstSlot();
            } else {
                this.setState({ firstSlot: null })
            }
        }
    }

    getFirstSlot = () => {
        const { selectedStore, selectedDeliveryMode } = this.state;
        storesService.firstSlot(selectedStore?.id, selectedDeliveryMode)
            .then(response => {
                this.setState({ firstSlot: response.data })
            }).catch(error => {
                console.log(error);
            })
    }

    onClick = (e) => this.setState({ [e.target.name]: e.target.value });

    selectStore = (item) => this.setState({ selectedStore: item });

    setZipCode = (item) => this.setState({ zipCode: item.zipCode, cityName: item.cityName });

    selectAddress = (item) => this.setState({ addressId: item.id });

    render() {
        const { deliveryModes, selectedDeliveryMode, zipCode, selectedStore, firstSlot, addressId } = this.state;
        const { open, cart } = this.props;
        if (cart) return <Redirect to="/home" />
        else return (
            <Translation>
                {t =>
                    <div className="landing" style={{ paddingTop: "30px" }}>
                        <div className="landing-content">
                            <h1>{t("landingPage.title")}</h1>

                            {/* Delivery mode */}
                            <div className="landing-step">
                                <h3>{t("landingPage.service")}</h3>
                                <div className="deliveryMode">
                                    {deliveryModes && deliveryModes.map(item => (
                                        <div key={item.id}>
                                            <button
                                                className={`outline secondary-cta 
                                                    ${item.name === selectedDeliveryMode ? "selected" : ""} `}
                                                onClick={this.onClick}
                                                name="selectedDeliveryMode"
                                                value={item.name}>
                                                {t(`cart.deliveryMode.${item.name}`)}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Zip code */}
                            <div className={`landing-step ${selectedDeliveryMode ? "" : "step-disabled"}`}>
                                <h3>{t("landingPage.cap")}</h3>
                                <div className="zipCode">
                                    <DropdownInput
                                        setZipCode={this.setZipCode}
                                        placeholder="Comune o cap"
                                        defaultZipCode={this.props.defaultZipCode}
                                        defaultCityName={this.props.defaultCityName}
                                    />
                                </div>
                            </div>

                            {/* Store select */}
                            <div className={`landing-step ${(selectedDeliveryMode && zipCode && zipCode !== "") ? "" : "step-disabled"}`}>
                                <h3>{t("landingPage.store")}</h3>
                                <div className="storeSelect">
                                    <StoreSelect
                                        zipCode={zipCode}
                                        deliveryMode={selectedDeliveryMode}
                                        select={this.selectStore}
                                        label={t("create.cart.store")}
                                        defaultStore={selectedStore ? selectedStore.name : t("create.cart.store")}
                                    />
                                </div>
                                {firstSlot &&
                                    <div className="first-slot">
                                        {selectedDeliveryMode === "AT_HOME" ? t("landingPage.firstSlot-delivery") : t("landingPage.firstSlot-pickup")}      
                                        <b> {firstSlot.description}</b> {t("landingPage.atTime")}
                                        <b> {firstSlot.validFrom}-{firstSlot.validTo} </b>
                                    </div>
                                }
                                {!firstSlot && selectedStore && selectedDeliveryMode && zipCode &&
                                    <div className="no-stores">
                                        {t("landingPage.noSlot")} {selectedDeliveryMode === "AT_HOME" ? t("landingPage.delivery") : t("landingPage.pickup")} {t("landingPage.ofShopping")}
                                    </div>
                                }
                            </div>

                            {/* Address select */}
                            {selectedDeliveryMode && (selectedDeliveryMode === "AT_HOME" || selectedDeliveryMode === "SHIPPED") &&
                                <div className={`landing-step ${(selectedDeliveryMode && zipCode && selectedStore) ? "" : "step-disabled"}`}>
                                    <div className="home-step-description">
                                        <h3>{t("landingPage.address")}</h3>
                                        <div className="storeSelect">
                                            <AddressSelect
                                                select={this.selectAddress}
                                                label={t("cartCreate.deliveryAddress")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="landing-step">
                                <button className="cta"
                                    disabled={(selectedDeliveryMode === "AT_HOME" || selectedDeliveryMode === "SHIPPED") ?
                                        (!selectedStore || !addressId) : !selectedStore}
                                    onClick={() => open(selectedDeliveryMode, selectedStore.id, addressId)}>
                                    <span>{t("create.cart")}</span>
                                </button>
                            </div>
                        </div>
                        <LandingCarousel />
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        defaultStore: state.user.defaultStore,
        defaultDelivery: state.user.defaultDelivery,
        defaultZipCode: state.user.defaultZipCode,
        defaultCityName: state.user.defaultCityName
    };
}

const mapDispatchToProps = dispatch => {
    return {
        open: (deliveryMode, storeId, addressId) => dispatch(actionCreators.open(deliveryMode, storeId, addressId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartCreate);
