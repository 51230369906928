import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import * as actionCreators from "../../../redux/store/actions/index"
import toaster from "../../../utils/toaster";
import i18n from '../../../i18n';
import "./note-popup.styles.scss";
import notes from "../Notes/Service";

const NotePopup = ({ show, cartId, notess, onClose, setDisabled, setOldNote }) => {

    const user = useSelector(state => state.user.user)
    const onSubmit = (notess) => {
        if (user) {
            notes(cartId, notess)
                .then(response => {
                    console.log(response);
                    toaster.standard(i18n.t("succesfully.request"))
                    setDisabled(true);
                    setOldNote(notess);
                }).catch((error) => {
                    toaster.error(error.message);
                })
        }
        onClose()
    }
    return (
        <Modal show={show} centered={true} onHide={onClose} dialogClassName="note-popup">
            <Modal.Header
                className="note-popup-header">
                Salva note
            </Modal.Header>
            <div className="note-popup-content">
                <div className="note-popup-title">Vuoi salvare le note?</div>
                <div className="note-popup-note">
                    <button onClick={onClose} style={{height:"48px", width:"192px", backgroundColor:"#e9eef2", border:"1px solid #e9eef2"}}>
                        <span style={{color:"#343e59",fontFamily:"Montserrat-Regular", fontWeight:"bold", fontSize:"18px"}}>ANNULLA</span>
                    </button>
                    <button onClick={() => onSubmit(notess)} style={{height:"48px", width:"192px",backgroundColor:"#272f47", border:"1px solid #272f47" }}>
                        <span style={{color:"#fff",fontFamily:"Montserrat-Regular", fontWeight:"bold", fontSize:"18px"}}>CONFERMA</span>
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default NotePopup;