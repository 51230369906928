import React, { useState, useEffect, useCallback } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import StickyBox from "react-sticky-box";
import Loader from 'react-loader-spinner'
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux"
import Categories from "../../Categories/Categories";
import Filters from "../../../components/Filters/Filters";
import BannerCardProduct from "../../../components/Products/BannerCardProduct/BannerCardProduct";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
import Banner_benesi from "../../../images/Banner_benesi.jpg"
import { ReactComponent as BenesiLogo } from "../../../images/Benesi_logo.svg"
import Banner_casa_coop from "../../../images/newBrands/coopcasa/Banner_Coop-Casa.jpg"
import { ReactComponent as CasaCoopLogo } from "../../../images/Casa_Coop_logo.svg"
import Banner_vivi_verde from "../../../images/Banner_vivi_verde.jpg"
import ViviVerdiLogo from "../../../images/Vivi_Verdi_logo1.png"
import Banner_solidal from "../../../images/Banner_solidal.jpg"
import { ReactComponent as SolidalLogo } from "../../../images/Solidal_logo.svg"
import Banner_origine from "../../../images/Banner_origine.jpg"
import { ReactComponent as OrigineLogo } from "../../../images/Origine_logo.svg"
import Banner_io from "../../../images/newBrands/iocoop/Io-Coop_banner.jpg"
import { ReactComponent as IOLogo } from "../../../images/IO_logo.svg"
import Banner_fiorfiore from "../../../images/Banner_fiorfiore.jpg"
import { ReactComponent as FiorFioreLogo } from "../../../images/FiorFiore_logo.svg"
import Banner_dosa from "../../../images/newBrands/dosa/DOsa_banner.jpg"
import { ReactComponent as DosaLogo } from "../../../images/Dosa_logo.svg"
import Banner_crescendo from "../../../images/newBrands/crescendo/Crescendo_Banner.jpg"
import { ReactComponent as CrescendoLogo } from "../../../images/Crescendo_logo.svg"
import Banner_coop from "../../../images/Banner_coop.jpg"
import { ReactComponent as CoopLogo } from "../../../images/Coop_logo.svg"
import Banner_Amici_animali from "../../../images/Banner_Amici_Animali.jpg"
import { ReactComponent as AmiciSpecialiLogo } from "../../../images/Amici_Speciali_logo.svg"
import { ReactComponent as ArrowRight } from "../../../images/arrow_right2.svg";
import { history } from "../../../App";
import "./home-brands.styles.scss";
import CustomBreadcrumb from "../../../components/UI/Breadcrumb/CustomBreadcrumb";
import CardProduct from "../../../components/Products/CardProduct/CardProduct";
import CategoryTree from "../../SearchCategory/CategoryTree/CategoryTree";
import GoBackButton from "../../../components/UI/Buttons/GoBackButton";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";


const BrandProductList = ({ location }) => {
    const { t } = useTranslation();
    const [isLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [bannersWithProducts, setBannersWithProducts] = useState([]);
    const cart = useSelector(state => state.cart.cart)
    const defaultStore = useSelector(state => state.user.defaultStore)
    const onlyPromotions = useSelector(state => state.categories.onlyPromotions)
    const onlyBranded = useSelector(state => state.categories.onlyBranded)
    const storeId = cart ? cart.storeId : (defaultStore ? defaultStore.id : "");
    const searchInput = location?.state?.searchInput ? location?.state?.searchInput : "";
    const categoryId = location?.state?.categoryId ? location?.state?.categoryId : "";
    const tag = location?.state?.tag ? location?.state?.tag : "";
    const [tagDescription, setTagDescription] = useState();
    const tags = useSelector(state => state.categories.tags);

    useEffect(() => {
        let tagSelected = tags?.find(el => el.tag === tag);
        if (tagSelected) {
            setTagDescription(tagSelected.description);
        } else {
            setTagDescription(tag);
        }
    }, [tag])


    const bannersProducts = useCallback(
        (input, page, tag) => {
            ecommerceService.searchProducts(input, storeId, page, categoryId, onlyPromotions, onlyBranded, tag)
                .then(response => {
                    setProducts(response.data);
                    setIsLoaded(true);
                    setTotalPages(response.totalPages)
                    setPage(response.number)
                    setHasMore(!response.last)
                }).catch((error) => {
                    console.log(error);
                })
        },
        [tag],
    );

    useEffect(() => {
        bannersProducts(searchInput, 0, tag);
    }, [bannersProducts, searchInput, tag])

    const loadMore = () => {
        const newPage = page + 1;
        if (newPage < totalPages) {
            ecommerceService.searchProducts(searchInput, storeId, newPage, categoryId, onlyPromotions, onlyBranded, tag)
                .then(response => {
                    const newResponse = products;
                    newResponse.push(...response.data);
                    setProducts(newResponse);
                    setIsLoaded(true);
                    setTotalPages(response.totalPages)
                    setPage(response.number)
                    setHasMore(!response.last)
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const goToDetails = (tag) => {
        history.push({
            pathname: `/ourbrands/${tag}`,
            state: {
                tag: `${tag}`,
            }
        })
    }

    const renderBanner = (selectedTag) => {
        switch (selectedTag) {
            case "COOP_BENE_SI":
                return (
                    <div className="link" onClick={() => goToDetails("COOP_BENE_SI")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_benesi})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    <BenesiLogo />
                                </div>
                                <button className="brand-banner-button-style">SCOPRI IL MONDO BENE SI COOP <ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )
            case "CASA":
                return (
                    <div className="link" onClick={() => goToDetails("CASA")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_casa_coop})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    <CasaCoopLogo style={{opacity: 0}}/>
                                </div>
                                <button className="brand-banner-button-style ml-1">SCOPRI IL MONDO CASA COOP <ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )
            case "COOP_VIVI_VERDE":
                return (
                    <div className="link" onClick={() => goToDetails("COOP_VIVI_VERDE")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_vivi_verde})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    {/* <ViviVerdiLogo /> */}
                                    <img src={ViviVerdiLogo} alt="" />
                                </div>
                                <button className="brand-banner-button-style">SCOPRI IL MONDO VIVI VERDE COOP<ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )
            case "COOP_SOLIDAL":
                return (
                    <div className="link" onClick={() => goToDetails("COOP_SOLIDAL")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_solidal})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    <SolidalLogo />
                                </div>
                                <button className="brand-banner-button-style">SCOPRI IL MONDO SOLIDAL COOP <ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )
            case "COOP_ORIGINE":
                return (
                    <div className="link" onClick={() => goToDetails("COOP_ORIGINE")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_origine})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    <OrigineLogo />
                                </div>
                                <button className="brand-banner-button-style">SCOPRI IL MONDO ORIGINE COOP <ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )
            case "COOP_IO":
                return (
                    <div className="link" onClick={() => goToDetails("COOP_IO")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_io})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    <IOLogo style={{opacity: 0}}/>
                                </div>
                                <button className="brand-banner-button-style ml-1">SCOPRI IL MONDO IO COOP <ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )
            case "COOP_FIOR_FIORE":
                return (
                    <div className="link" onClick={() => goToDetails("COOP_FIOR_FIORE")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_fiorfiore})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    <FiorFioreLogo />
                                </div>
                                <button className="brand-banner-button-style">SCOPRI IL MONDO FIOR FIORE COOP <ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )
            case "D'OSA":
                return (
                    <div className="link" onClick={() => goToDetails("D'OSA")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_dosa})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    <DosaLogo style={{opacity: 0}}/>
                                </div>
                                <button className="brand-banner-button-style ml-1">SCOPRI IL MONDO D'OSA COOP <ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )
            case "CRESCENDO":
                return (
                    <div className="link" onClick={() => goToDetails("CRESCENDO")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_crescendo})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    <CrescendoLogo style={{opacity: 0}}/>
                                </div>
                                <button className="brand-banner-button-style ml-1">SCOPRI IL MONDO CRESCENDO COOP <ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )
            case "COOP":
                return (
                    <div className="link" onClick={() => goToDetails("COOP")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_coop})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    <CoopLogo />
                                </div>
                                <button className="brand-banner-button-style">SCOPRI IL MONDO COOP <ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )
            case "AMICI_SPECIALI":
                return (
                    <div className="link" onClick={() => goToDetails("AMICI_SPECIALI")}>
                        <div className="brand-banner" style={{ backgroundImage: `url(${Banner_Amici_animali})` }}>
                            <div className="brand-banner-button">
                                <div className="brand-banner-button-logo">
                                    <AmiciSpecialiLogo />
                                </div>
                                <button className="brand-banner-button-style">SCOPRI IL MONDO AMICI SPECIALI COOP <ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                )

            default:
                return null;
        }
    }

    return (
        <div className="">
            <div style={{ padding: "unset" }} className="page-grid mobile-view-search">
                <div className="grid-left" offsettop={0} offsetbottom={0}>
                    {/* <Filters showDefaultFilters={true} />  */}
                    <CategoryTree />
                </div>
                <div className="grid-right">
                    {/* <div className="category-banner" style={{ backgroundImage: `url(${CategoryBannerImg})` }} /> */}
                    {renderBanner(tag)}
                    <div className="brand-breadcrumb-container">
                        <CustomBreadcrumb
                            level0={{
                                path: `${tag}`,
                                label: `${tagDescription}`,
                                active: true
                            }}
                        />
                    </div>

                    <InfiniteScroll
                        style={{ overflowX: "hidden" }}
                        dataLength={products.length}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={<Loader
                            type="ThreeDots"
                            color="#333"
                            height={70}
                            width={70}
                            timeout={3000} //3 secs
                        />}
                    >
                        <div className="products-grid">
                            {products && products.length > 0 && products.map((item) => (
                                <CardProduct key={item.id} item={item} />
                            ))}
                            {isLoaded && products && products.length === 0 &&
                                <span className="title">
                                    {t("newSearch.noProductsFound")}
                                </span>
                            }
                        </div>
                    </InfiniteScroll>
                    <ScrollToTopButton />
                </div>
            </div>
        </div>
    );
}

export default BrandProductList;



