import React from "react"
import { useTranslation } from "react-i18next";


const SpecialTag = ({ filters, register, handleCheckboxValues }) => {
    const { t } = useTranslation();
    return (
        <>
            {
                filters && filters?.map((item, index) => {
                    if (item.specialTag) {
                        return (
                            <div className="custom-checkbox specialTag" key={item.id}>
                                <div style={{ color: "#272f47", fontSize: "10px", paddingTop: "5px" }}> Questo mese ti consigliamo</div>
                                <div style={{ paddingLeft: "20px" }}>
                                    <input className="custom-control-input" type="checkbox" id={item.id} name={item.description}
                                        defaultChecked={item && item.checked}
                                        disabled={item && item.specialTag}
                                        ref={register}
                                        onChange={() => handleCheckboxValues(item)}
                                    />
                                    <label className="custom-control-label" htmlFor={item.id}>
                                        {item.description}
                                    </label>
                                </div>
                            </div>
                        )
                    }
                })
            }
        </>
    );
}

export default SpecialTag;



