import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import HeaderTag from "./HeaderTag/HeaderTag";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { ReactComponent as Offerta } from "../../../../images/tags/offers.svg";
import { useSelector, useDispatch } from 'react-redux'
import * as actionCreators from "../../../../redux/store/actions"
import { ReactComponent as ArrowRight } from "../../../../images/arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../../../images/arrow_left.svg";
import { ReactComponent as Pen } from '../../../../images/pen-icon.svg';
import { ReactComponent as Vegano } from '../../../../images/vegano.svg';
import { ReactComponent as Mensile } from '../../../../images/MensileNew.svg';
import { ReactComponent as Vegetariano } from '../../../../images/vegetariano.svg';
import { ReactComponent as CoopOrigine } from '../../../../images/coop_origine_new.svg';
import { ReactComponent as Doc } from '../../../../images/doc-new.svg';
import { ReactComponent as Benesi } from '../../../../images/benesi-new.svg';
import { ReactComponent as Bio } from '../../../../images/bio.svg';
import { ReactComponent as SenzaGlutine } from '../../../../images/senza_glutine.svg';
import { ReactComponent as SenzaLattosio } from '../../../../images/senza_lattosio.svg';
import { ReactComponent as DocG } from '../../../../images/docg-new.svg';
import { ReactComponent as FiorFiore } from '../../../../images/fior_fiore.svg';
import { ReactComponent as Coop } from '../../../../images/coop-new.svg';
import { ReactComponent as CasaCoop } from '../../../../images/casa-new.svg';
import { ReactComponent as Solidal } from '../../../../images/solidal-new.svg';
import { ReactComponent as Integrale } from '../../../../images/integrale.svg';
import { ReactComponent as IO } from '../../../../images/io-new.svg';
import { ReactComponent as Dosa } from '../../../../images/dosa-new.svg';
import { ReactComponent as Crescendo } from '../../../../images/crescendo-new.svg';
import { ReactComponent as ViviVerde } from '../../../../images/vivi-verde.svg';
import { ReactComponent as AmiciAnimali } from '../../../../images/amici-animali-new.svg';
import { ReactComponent as IGP } from '../../../../images/igp-new.svg';
import { ReactComponent as DOP } from '../../../../images/dop-new.svg';
import { ecommerceService } from "../../../../services/ecommerce/EcommerceService"
import { useForm } from 'react-hook-form'
import toaster from "../../../../utils/toaster"
import i18n from "../../../../i18n"
import swal from "../../../../utils/swal"
import "./header-tags.styles.scss";
import FilterPopup from "../../../../features/FilterPopup/FilterPopup";
import UpdateTags from "./UpdateTags/UpdateTags";
import { filter } from "lodash";
import { history } from "../../../../App";
import { connect } from "react-redux"
import PropTypes from 'prop-types';
import NewSearchCategory from "../../../../features/SearchCategory/NewSearchCategory";
import SpecialTag from "./UpdateTags/SpecialTag";
import { authenticationService } from "../../../../services/basis/AuthenticationService";

const HeaderTags = ({ selectTag, tag, searchInput, selectSearchInput, categoryId, changeCategory, logout, loginAction }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const tags = useSelector(state => state.categories.tags)
    // const producttags = useSelector(state => state.categories.productTags)
    const user = useSelector(state => state.user.user)
    const [isOpen, setIsOpen] = useState(false)
    const [filters, setFilters] = useState([])
    const [checkboxValues, setCheckboxValues] = useState()
    // const [tags, setTags] = useState()
    const [value, setValue] = useState()
    const [tagId, setTagId] = useState("")

    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        let result = data.filter(item => item = item?.checked);
        let special = data.find(item => item?.specialTag === true);
        if (special) {
            if (result.length < 6) {
                dispatch(actionCreators.updateFilters(result))
                setIsOpen(false)
            }
            else {
                toaster.standard(i18n.t("Attenzione: Puoi selezionare al massimo 4 filtri"))
                setIsOpen(true)
            }
        } else {
            if (result.length < 5) {
                dispatch(actionCreators.updateFilters(result))
                setIsOpen(false)
            }
            else {
                toaster.standard(i18n.t("Attenzione: Puoi selezionare al massimo 4 filtri"))
                setIsOpen(true)
            }
        }
    }

    const node = useRef();

    const clickOutside = (e) => {
        if (node.current && node.current.contains(e.target)) {
            return;
        }
        setIsOpen(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside);
        }
    }, [isOpen])

    useEffect(() => {
        if (user) {
            authenticationService.isLogged()
                .then(response => {
                    ecommerceService.productsTagsComplete()
                        .then(response => {
                            console.log(response.data)
                            setFilters(response.data)
                        }).catch((error) => {
                            console.log(error);
                        })
                })
                .catch((error) => {
                    logout();
                    loginAction({}, false)
                })
        }
    }, [user])

    const goToDetails = (input, tag) => {
        history.push({
            pathname: `/search`,
            state: {
                searchInput: `${input}`,
                tag: `${tag}`,
            }
        })
    }

    useEffect(() => {
        // reset filters on component unmount 
        return () => {
            // selectTag("");
            selectSearchInput("")
        }
    }, [])


    const renderIcon = (tag, tagId) => {
        switch (tag) {
            case "MENSILE":
                return <Mensile />
            case "VEGETARIANO":
                return <Vegetariano />
            case "COOP_ORIGINE":
                return <CoopOrigine />
            case "DOC":
                return <Doc />
            case "VEGANO":
                return <Vegano />
            case "COOP_BENE_SI":
                return <Benesi />
            case "BIO":
                return <Bio />
            case "BIOLOGICO":
                return <Bio />
            case "SENZA_GLUTINE":
                return <SenzaGlutine />
            case "SENZA GLUTINE":
                return <SenzaGlutine />
            case "SENZA_LATTOSIO":
                return <SenzaLattosio />
            case "SENZA LATTOSIO":
                return <SenzaLattosio />
            case "DOCG":
                return <DocG />
            case "COOP_FIOR_FIORE":
                return <FiorFiore />
            case "COOP":
                return <Coop />
            case "CASA":
                return <CasaCoop />
            case "COOP_SOLIDAL":
                return <Solidal />
            case "INTEGRALE":
                return <Integrale />
            case "COOP_IO":
                return <IO />
            case "D'OSA":
                return <Dosa />
            case "D_OSA_COOP":
                return <Dosa />
            case "CRESCENDO":
                return <Crescendo />
            case "COOP_VIVI_VERDE":
                return <ViviVerde />
            case "PRODOTTI_PER_ANIMALI":
                return <AmiciAnimali />
            case "PET":
                return <AmiciAnimali />
            case "IGP":
                return <IGP />
            case "DOP":
                return <DOP />
            default:
                return null;
        }
    }



    const handleCheckboxValues = (item) => {
        const temp = [...filters]
        const result = temp?.find(x => x.id == item?.id);
        if (result) {
            result.checked = !item?.checked
        }
        const temp1 = [...temp]
        let temp2 = temp?.filter(item => item = item?.checked);
        let special = temp?.find(item => item?.specialTag === true);
        if (special) {
            if (temp2.length > 5) {
                document.getElementById(item?.id).checked = false;
                result.checked = false;
                toaster.standard(i18n.t("Attenzione: Puoi selezionare al massimo 4 filtri"))
            }
            else {
                setFilters(temp1)
            }
        } else {
            if (temp2.length > 4) {
                document.getElementById(item?.id).checked = false;
                result.checked = false;
                toaster.standard(i18n.t("Attenzione: Puoi selezionare al massimo 4 filtri"))
            }
            else {
                setFilters(temp1)
            }
        }
    }

    const closeFilterPopup = () => {
        setIsOpen(false)
    }

    const handleInput = (searchInput) => {
        selectSearchInput(searchInput)
    }

    return (
        <>
            <div className="header-tags">
                <div className="col-3">

                </div>
                <div className="col-7 header-tags-slider">
                    <Slider {...settings}>
                        {/*  <div className="home-tags-item unset-width">
                    <div className="text-center">
                        <div className="home-tags-link" onClick={() => {
                            dispatch(actionCreators.onlyPromotions(true))
                            props.history.push({ pathname: "/search", })
                        }}>
                            <Offerta />
                            <div className="text">{t("promo")}</div>
                        </div>
                    </div>
                </div> */}
                        {user && tags && tags?.map(item => {
                            return tag ?
                                <button className={`${item?.specialTag ? "header-tags-items-special-tag" : "header-tags-items"} ${item?.tag === tag ? "tag-button-selected" : ""}`}
                                    key={item?.id}
                                    // onClick={() => { goToDetails(item?.tag); dispatch(actionCreators.tag(item?.tag)); setTagId(item?.id); setIsSelectedTag(!isSelectedTag) }}>
                                    onClick={() => {
                                        if (searchInput) {
                                            if (item?.tag === tag) {
                                                selectTag("");
                                                setTagId(item?.tag);
                                                handleInput(searchInput)
                                                goToDetails(searchInput, item?.tag)
                                            }
                                            else {
                                                selectTag(item?.tag);
                                            }
                                        }
                                        else if (categoryId) {
                                            if (item?.tag === tag) {
                                                selectTag("");
                                            }
                                            else {
                                                selectTag(item?.tag);
                                            }
                                        }
                                        else {
                                            if (item?.tag === tag) {
                                                selectTag("");
                                                history.push("/home")
                                            }
                                            else {
                                                selectTag(item?.tag);
                                            }
                                        }
                                    }}>
                                    <div style={{ display: "flex", height: "25px", alignItems: "center", justifyContent: "center" }} >
                                        {renderIcon(item?.tag, item?.id)}
                                        <div className="header-tags-link">{item?.description}</div>
                                    </div>
                                </button>
                                :
                                <button className={`${item?.specialTag ? "header-tags-items-special-tag" : "header-tags-items"}`}
                                    key={item?.id}
                                    // onClick={() => { goToDetails(item?.tag); dispatch(actionCreators.tag(item?.tag)); setTagId(item?.id); setIsSelectedTag(!isSelectedTag) }}>
                                    onClick={() => {
                                        if (categoryId) {
                                            changeCategory(categoryId, item?.id); selectTag(item?.tag); setTagId(item?.tag)
                                        }
                                        else {
                                            goToDetails(searchInput, item?.tag); selectTag(item?.tag); setTagId(item?.tag)
                                        }
                                    }}>
                                    <div style={{ display: "flex", height: "25px", alignItems: "center", justifyContent: "center" }} >
                                        {renderIcon(item?.tag, item?.id)}
                                        <div className="header-tags-link">{item?.description}</div>
                                    </div>
                                </button>
                            /*  <HeaderTag key={item?.id} tag={item?.tag} description={item?.description} isSelectedTag={isSelectedTag} handleSelectedTag={handleSelectedTag}>
                                                            {renderIcon(item?.tag)}
                                                        </HeaderTag> */
                        })}
                    </Slider>
                </div>
                {
                    user ?
                        <div className="col-2 header-tags-plus" >
                            <button className="header-tags-filter-button" onClick={() => setIsOpen(true)} >
                                <Pen />MODIFICA FILTRI
                            </button>
                        </div>
                        :
                        <div className="col-2 header-tags-plus" >
                            <button className="header-tags-filter-button" onClick={() => dispatch(actionCreators.showLogin(true))} >
                                <Pen />MODIFICA FILTRI
                            </button>
                        </div>
                }
            </div>
            {
                isOpen &&
                <form onSubmit={handleSubmit(() => onSubmit(filters))}>
                    <div className="header-tags-modify"  >
                        <div ref={node}>
                            <div className="header-tags-modify-filters" >
                                <UpdateTags filters={filters} register={register} handleCheckboxValues={handleCheckboxValues} />
                            </div>
                            <div className="header-tags-modify-special-tag">
                                <SpecialTag filters={filters} register={register} handleCheckboxValues={handleCheckboxValues} />
                            </div>
                            <div className="header-tags-modify-tags-dropdown">
                                <div style={{ fontSize: "15px" }}>max. 4 filtri</div>
                            </div>
                            <div className="header-tags-modify-button" >
                                <div>
                                    <button className="header-tags-modify-button-annula" onClick={() => setIsOpen(!isOpen)}>
                                        ANNULLA
                                    </button>
                                </div>
                                <div>
                                    <button className="header-tags-modify-button-conferma" type="submit">
                                        CONFERMA
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        tag: state.categories.tag,
        tags: state.categories.tags,
        categoryId: state.categories.categoryId,
        //searchInput:state.categories.searchInput
    };
}

const mapDispatchToProps = dispatch => {
    return {
        selectTag: (tag) => dispatch(actionCreators.tag(tag)),
        selectSearchInput: (input) => dispatch(actionCreators.search(input)),
        changeCategory: (categoryId, index) => dispatch(actionCreators.changeCategory(categoryId, index)),
        loginAction: () => dispatch(actionCreators.loginAction()),
        logout: () => dispatch(actionCreators.logout()),
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(HeaderTags);


const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    variableWidth: true,
    nextArrow: <SampleArrow><ArrowRight /></SampleArrow>,
    prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>,
    responsive: [
        {
            breakpoint: 1550,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 1480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 1310,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        /*  {
             breakpoint: 1024,
             settings: {
                 slidesToShow: 3,
                 slidesToScroll: 1,
             }
         },
         {
             breakpoint: 800,
             settings: {
                 slidesToShow: 2,
                 slidesToScroll: 2,
             }
         },
         {
             breakpoint: 544,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 2,
             }
         }, */
        /*  {
             breakpoint: 576,
             settings: {
                 slidesToShow: 2,
                 slidesToScroll: 2,
             }
         } */
    ]
};

function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
}





