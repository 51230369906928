import React from "react";
import Categories from "../../Categories/Categories";
import StickyBox from "react-sticky-box";
import HomeCarousel from "../../Home/HomeCarousel/HomeCarousel"
// import HomeTags from "./HomeTags/HomeTags";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import config from "../../../config/config"
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import * as actionCreators from "../../../redux/store/actions/index";
import HomeBrands from "../HomeBrands/HomeBrands";
import HomeSuggestions from "../HomeSuggestions/HomeSuggestions";
import HomeHolidays from "../HomeHolidays/HomeHolidays";
import LocalProducts from "../LocalProducts/LocalProducts";
import Banner from "../../DiscoverProducts/Banner/Banner";
import producter from "../../../images/departments/Producter.png"
import producterBanner from "../../../images/departments/ProducterBanner.png"
import CategoryTree from "../../SearchCategory/CategoryTree/CategoryTree";

class Producter extends React.Component {
    componentDidMount() {
        if (this.props.user) {
            this.props.current();
            if (config.ENABLED_SLIST) {
                this.props.getSlists();
            }
        }
    }

    render() {
        return (
            <Translation>
                {t =>
                    <div style={{ padding: "unset" }} className="page-grid mobile-view-search">
                        <StickyBox className="grid-left" offsettop={0} offsetbottom={0}>
                            {/* <Categories /> */}
                            <CategoryTree/>
                        </StickyBox>
                        <div style={{ paddingTop: "20px" }} className="grid-right">
                            <div className="producter"></div>
                            <img src={producterBanner} alt="" />
                            <img src={producter} alt="" />
                           
                            {/* <Banner/> */}
                            {/* <HomeCarousel /> */}
                            {/*  <HomeBrands />
                            <HomeSuggestions /> */}
                            {/* <HomeTags /> */}
                            {/* <FeaturedProducts /> */}
                            {/* <HomeHolidays /> */}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        current: () => dispatch(actionCreators.current()),
        getSlists: () => dispatch(actionCreators.getSlists()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Producter);
