import React from "react";
import Days from "./Days/Days"
import Timeslots from "./Timeslots/Timeslots"
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import * as actionCreators from "../../redux/store/actions/index"

import "./disponibility.styles.scss";

const Disponibility = ({
  cart,
  day,
  selectTimeslot,
  timeSlotId,
  open,
  togglePopup,
  selectDay,
  choosedDay
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const submit = () => {
    // dispatch(actionCreators.bookTimeslot(cart.id, timeSlotId, choosedDay.date))
    togglePopup(false);
  }

  return (
    <Modal show={open} centered={true} onHide={() => togglePopup(false)} dialogClassName="disponibility">
      <Modal.Header className="disponibility-header" closeButton>SELEZIONA FASCIA ORARIA</Modal.Header>
      <div className="disponibility-content">
        {/* <h2>{t("availability.title")}</h2> */}
        <Days selectDay={selectDay} cartId={cart.id} selectedDay={day} />
        {day &&
          <Timeslots day={day} selectTimeslot={selectTimeslot} cartId={cart.id} selectedTimeslot={timeSlotId} />
        }
        <button
          className="cta"
          onClick={submit}
          disabled={!day || !timeSlotId}
        >
          <span>{t("confirm")}</span>
        </button>
      </div>
    </Modal>
  );
}

export default Disponibility;