import React, {useState} from "react";
import StickyBox from "react-sticky-box";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import * as actionCreators from "../../redux/store/actions/index";
import Categories from "../Categories/Categories";
import config from "../../config/config";
import Banner from "./Banner/Banner";
import FeaturedProducts from "../Home/FeaturedProducts/FeaturedProducts";
import { withRouter } from "react-router";
import CategoryTree from "../SearchCategory/CategoryTree/CategoryTree";
import GoBackButton from "../../components/UI/Buttons/GoBackButton";
import { history } from "../../App";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";

const DiscoverProducts = ({ location }) => {
    const bannerId = location?.state?.bannerId ? location?.state?.bannerId : "";
    
    return (
        <Translation>
            {t =>
                <div style={{ padding: "unset" }} className="page-grid mobile-view-search">
                    <div className="grid-left" offsettop={0} offsetbottom={0}>
                        <CategoryTree />
                    </div>
                    <div className="grid-right">
                        <Banner />
                        <div className="discover-products"></div>
                        <FeaturedProducts bannerId={bannerId} />
                        <ScrollToTopButton />
                    </div>
                </div>
            }
        </Translation>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        current: () => dispatch(actionCreators.current()),
        getSlists: () => dispatch(actionCreators.getSlists()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscoverProducts));
