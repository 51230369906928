import config from '../../config/config';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import axios from 'axios';
import { authHeader } from '../../utils/authHeader';

export const authenticationService = { login, logout, signup, isLogged, updateUser, coopSignup, otpVerify, checkFiscalCode, checkClickCoop, updateClickCoop, deleteUser };

function login(data) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/login`,
            data)
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem('user', JSON.stringify(responseData.data));
                return responseData;
            })
    );
}
function checkClickCoop(data) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/checkClickCoop`,
            data)
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem('user', JSON.stringify(responseData.data));
                return responseData;
            })
    );
}
function updateClickCoop(data) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/updateClickCoopFlag`,
            {
                username: data.username,
                password: data.password,
                click_coop: true
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user")
    return (
        axios.post(`${config.baseUrl}/cli/profiles/logout`,
        {},
        {headers: authHeader()})
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function signup(data) {
    console.log(data);
    return (
        axios.post(`${config.baseUrl}/cli/profiles/signup`,
            {
                username: data.username,
                password: data.password,
                profile: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    birthDate: data.birthDate,
                    mobilePhone: data.mobilePhone,
                    email: data.username,
                    cards: data.profileCard !== "" ?
                        [{
                            code: data.profileCard,
                            principal: true,
                            enabled: true
                        }]
                        : null,
                    accept1: data.accept1,
                    accept2: data.accept2,
                    accept3: data.accept3,
                    accept4: data.accept4,
                }
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function isLogged() {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/login`,
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function updateUser(data) {
    console.log(data);
    return (
        axios.put(`${config.baseUrl}/cli/profiles`,
            {
                firstName: data.firstName,
                lastName: data.lastName,
                mobilePhone: data.mobilePhone,
                ssnCode: data.ssnCode,
                // birthDate: data.birthDate,
                cards: [{
                    code: data.profileCard,
                    principal: true,
                    enabled: true
                }],
                // email: data.email,
                // gender: data.gender,
                accept1: data.accept1,
                accept2: data.accept2,
                accept3: data.accept3,
                accept4: data.accept4,
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function deleteUser() {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/deleteRequest`,
            {},
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                return responseData;
            })
    );
}

function coopSignup(data, privacy, password, marketing, timeSaver) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/registration`,
            {
                name: data.name,
                lastname: data.surname,
                cf: data.cf,
                // birthdate: data.birthDate,
                username: data.email,
                email: data.email,
                password: password,
                passwordForCheck: data.passwordConfirmation,
                phone: data.phone,
                privacy: privacy,
                marketing: marketing,
                time_saver: timeSaver,
                click_coop: true
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    );
}
function otpVerify(otp, phoneOtp, profileId) {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/otp/email/${otp}/phone/${phoneOtp}/profile/${profileId}/activate`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function checkFiscalCode(fiscalCode) {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/exists/${fiscalCode}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}