import React from "react";
import { Link } from "react-router-dom"
import { useDispatch } from 'react-redux'
import * as actionCreators from "../../../../redux/store/actions"

const HomeTag = ({ children, tag, description }) => {
    const dispatch = useDispatch()
    return (
        <div className="home-tags-item">
            <div className="text-center">
                <Link className="home-tags-link" to="/search"
                    onClick={() => dispatch(actionCreators.tag(tag))} >
                    {children}
                    <div className="text">{description}</div>
                </Link>
            </div>
        </div>
    );
}

export default HomeTag;
