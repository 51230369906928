export const CART = "CART"
export const CARTS = "CARTS"
export const CATEGORIES = "CATEGORIES"
export const AVAILABILITY = "AVAILABILITY"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const IS_LOGGED = "IS_LOGGED"
export const CLICK_COOP = "CLICK_COOP"
export const USER_UPDATE = "USER_UPDATE"
export const USER_DELETE = "USER_DELETE"
export const FAVORITES = "FAVORITES"
export const ADD_FAVORITE = "ADD_FAVORITE"
export const DELETE_FAVORITE = "DELETE_FAVORITE"
export const COORDINATE = "COORDINATE"
export const SLISTS = "SLISTS"
export const SLISTS_ITEM_CREATE = "SLISTS_ITEM_CREATE"
export const SLISTS_ITEM_CREATE_CUSTOM = "SLISTS_ITEM_CREATE_CUSTOM"
export const SLISTS_ITEM_DELETE = "SLISTS_ITEM_DELETE"
export const SLISTS_ITEM_UPDATE = "SLISTS_ITEM_UPDATE"
export const FETCH_SLIST = "FETCH_SLIST"
export const CREATE_SLIST = "CREATE_SLIST"
export const DELETE_SLIST = "DELETE_SLIST"
export const RENAME_SLIST = "RENAME_SLIST"
export const COPY_SLIST = "COPY_SLIST"
export const SET_PRODUCT_TO_ADD = "SET_PRODUCT_TO_ADD"
export const ADD_PRODUCT_TO_ADD = "ADD_PRODUCT_TO_ADD"
export const OVERLAY = "OVERLAY"
export const FREEZE_CHECK = "FREEZE_CHECK"
export const CLOSE_FREEZE_POPUP = "CLOSE_FREEZE_POPUP"
export const SHOW_LOGIN = "SHOW_LOGIN"
export const DEFAULT_STORE = "DEFAULT_STORE"
export const CHANGE_CATEGORY = "CHANGE_CATEGORY"
export const CHANGE_SUB_CATEGORY = "CHANGE_SUB_CATEGORY"
export const CHANGE_THIRD_LEVEL_CATEGORY = "CHANGE_THIRD_LEVEL_CATEGORY"
export const CHANGE_ALL_CATEGORIES = "CHANGE_ALL_CATEGORIES"
export const ONLY_PROMOTIONS = "ONLY_PROMOTIONS"
export const ONLY_BRANDED = "ONLY_BRANDED"
export const TAG = "TAG"
export const SEARCHINPUT = "SEARCHINPUT"
export const TAGS = "TAGS"
export const FILTERS = "FILTERS"
export const BOOK_TIMESLOT = "BOOK_TIMESLOT"
export const SET_EMAIL_PHONE = "SET_EMAIL_PHONE"
export const SAVE_SCROLLEDPRODUCT = "SAVE_SCROLLEDPRODUCT"
export const RESET_SCROLLEDPRODUCT = "RESET_SCROLLEDPRODUCT"
export const CHANGE_DELIVERYMODE = "CHANGE_DELIVERYMODE"
