import React, { useEffect, useState } from "react";
import ValidateInput from "../../components/UI/Input/ValidateInput";
import BillingAddressSelect from "./BillingAddressSelect/BillingAddressSelect";
import PaymentsMode from "./PaymentsMode/PaymentsMode";
import CustomBreadcrumb from "../../components/UI/Breadcrumb/CustomBreadcrumb";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux"
import { addressesService } from "../ProfileContainer/ProfileAddresses/Service";
import { useHistory } from "react-router-dom"
import { ReactComponent as Cal } from "../../images/cal-icon.svg";
import { ReactComponent as AccordionDown } from "../../images/accordion-down.svg";
import { ReactComponent as AccordionUp } from "../../images/accordion-up.svg";
import "./checkout.styles.scss";
import CartMenu from "../Cart/CartMenu/CartMenu";

const Checkout = ({
    paymentMethod,
    setPaymentMethod,
    removedItems,
    togglePopup,
    choosedDay,
    choosedTimeslot,
    termsAndCondition,
    goToCheckout,
    freezeCheck,
    abort,
    checkFreeze,
    showFreezePopup,
    closeFreezePopup,
    updatedItems,
}) => {
    const { t } = useTranslation();
    const user = useSelector(state => state.user.user);
    const cart = useSelector(state => state.cart.cart);
    const [addresses, setAddresses] = useState([]);
    let history = useHistory();
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        addressesService.getBillingAddresses()
            .then(response => {
                if (response.data.length > 0) {
                    setAddresses(response.data)
                }
            }).catch((error) => {
                console.log(error);
            })
    }, [])

    return (
        <div className="">
            <div className="checkout-heading-bar desktop" style={{ marginBottom: "30px" }}>
                <div className="col-lg-6 col-md-4 col-sm-5 checkout-heading-bar-text">
                    <span className="checkout-heading-bar-text-digit-text">3</span>
                    <span className="checkout-heading-bar-text-alpha-text">PAGAMENTO</span>
                </div>
                <div className="col-lg-6 col-md-8 col-sm-7 checkout-heading-bar-buttons">
                    <button style={{ width: "160px", height: "36px", borderRadius: "18px", backgroundColor: "white", color: "#d80014", fontWeight: "bold", border: "solid 1px rgba(255, 255, 255, 0.5)" }}
                        onClick={() => history.push("/availability")}>
                        INDIETRO
                    </button>
                    <button className="avanti-button"
                        onClick={() => {
                            checkFreeze("/summary")
                        }}
                        disabled={
                            // !paymentMethod || (!choosedDay || !choosedTimeslot)
                            !paymentMethod
                        }
                    >
                        AVANTI
                    </button>
                </div>
            </div>
            <div className="checkout-cart-menu-dropdown">
                <div className="col-6" style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <Cal className="mr-4" />
                    <h5>RIEPILOGO</h5>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? <AccordionUp /> : <AccordionDown />}
                </div>
            </div>
            {
                isOpen &&
                <CartMenu
                    cart={cart}
                    freezeCheck={freezeCheck}
                    abort={abort}
                    open={showFreezePopup}
                    closePopup={closeFreezePopup}
                    updatedItems={updatedItems}
                    removedItems={removedItems}
                    togglePopup={togglePopup}
                    choosedDay={choosedDay}
                    choosedTimeslot={choosedTimeslot}
                    termsAndCondition={termsAndCondition}
                    goToCheckout={goToCheckout}
                    // onSubmit={handleSubmit(submit)}
                    // enableProductsReplacement={enableProductsReplacement}
                    paymentMethod={paymentMethod}
                    user={user}
                />
            }
            <PaymentsMode
                cart={cart}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
            />
            <hr />
            {paymentMethod === "XPAY" &&
                <div>
                    <span style={{ fontFamily: 'Montserrat-Bold' }}>N.B.</span><br />
                    <span style={{ fontFamily: 'Montserrat-Bold' }}>NON PAGHI ORA</span>, l’importo ti verrà addebitato solo al momento dell’emissione dello scontrino.<br />
                    Si tratta di una preautorizzazione che impegna un importo pari al totale spesa maggiorato del 5% per la gestione dei prodotti a peso variabile.<br />
                    Al momento dell’emissione dello scontrino ti verrà addebitato l’esatto importo in base ai prodotti effettivamente consegnati e al valore di quelli a peso variabile.<br />
                </div>
            }
            <div className="checkout-heading-bar mobile" style={{ marginBottom: "30px" }}>
                <div className="col-lg-6 col-md-4 col-sm-5 checkout-heading-bar-text">
                    <span className="checkout-heading-bar-text-digit-text">3</span>
                    <span className="checkout-heading-bar-text-alpha-text">PAGAMENTO</span>
                </div>
                <div className="col-lg-6 col-md-8 col-sm-7 checkout-heading-bar-buttons">
                    <button style={{ width: "160px", height: "36px", borderRadius: "18px", backgroundColor: "white", color: "#d80014", fontWeight: "bold", border: "solid 1px rgba(255, 255, 255, 0.5)" }}
                        onClick={() => history.push("/availability")}>
                        INDIETRO
                    </button>
                    <button className="avanti-button"
                        onClick={() => {
                            checkFreeze("/summary")
                        }}
                        disabled={
                            // !paymentMethod || (!choosedDay || !choosedTimeslot)
                            !paymentMethod
                        }
                    >
                        AVANTI
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Checkout;