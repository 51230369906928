import React, { useEffect, useState, useCallback } from "react"
import CategoryBannerImg from "../../images/category-banner.jpg"
import CategoryTree from "./CategoryTree/CategoryTree";
import Filters from "../../components/Filters/Filters";
import StickyBox from "react-sticky-box";
import CardProduct from "../../components/Products/CardProduct/CardProduct";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner'
import { ecommerceService } from "../../services/ecommerce/EcommerceService";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux"
import * as actionCreators from "../../redux/store/actions"
import CustomBreadcrumb from "../../components/UI/Breadcrumb/CustomBreadcrumb";
// import CategoryBanner from "./CategoryBanner";

import "./search-category.styles.scss";
import ProductBreadcrumb from "../../components/UI/Breadcrumb/ProductBreadcrumb/ProductBreadcrumb";
import GoBackButton from "../../components/UI/Buttons/GoBackButton";
import { history } from "../../App";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";

const NewSearchCategory = ({
    match,
    cart,
    defaultStore,
    categoryId,
    changeCategory,
    onlyPromotions,
    onlyBranded,
    tag,
    location
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const storeId = cart ? cart.storeId : (defaultStore ? defaultStore.id : "");
    const categories = useSelector(state => state.categories);
    const allCategories = useSelector(state => state.cart.categories);
    const [loadedProducts, setLoadedProducts] = useState(categories.loadedProducts ? categories.loadedProducts : null);
    const [savedScroll, setSavedScroll] = useState(location.state ? location.state.savedScroll : 0);
    const [page, setPage] = useState(categories.page ? categories.page : 0);
    const [totalPages, setTotalPages] = useState(categories.totalPages ? categories.totalPages : null);
    const [scrollToProduct, setScrollToProduct] = useState(false);
    // const [products, setProducts] = useState(loadedProducts ? loadedProducts : []);
    const [products, setProducts] = useState([]);
    const [hasMore, setHasMore] = useState(true);


    const getProducts = useCallback(
        (page) => {
            const newCategory = categoryId === "" || categoryId === undefined ? getCategoryId() : categoryId;
            let first = newCategory?.split('_');
            if (first && first.length > 1){
                ecommerceService.searchProducts("", storeId, page, newCategory, onlyPromotions, onlyBranded, tag)
                    .then(response => {
                        console.log(response.data)
                        setProducts(response.data)
                        setTotalPages(response.totalPages)
                        setHasMore(!response.last)
                        setPage(response.number);
                    }).catch((error) => {
                        console.log(error);
                    })
            }
        }, [categoryId, match.params.categoryId, onlyBranded, onlyPromotions, storeId, tag]
    );

    const getCategoryId = () => {
        let first = match.params.firstLevelCategory;
        let second = match.params.secondLevelCategory;
        let third = match.params.thirdLevelCategory;
        let result;
        if (first){
            result = allCategories.find(categorie => first === categorie.description);
            if(second){
                result = result?.items.find(categorie => second === categorie.description);
                if (third){
                    result = result?.items.find(categorie => third === categorie.description);
                }
            }
        }
        return result ? result?.id : null; 
    }

    useEffect(() => {
        if (categoryId === "") {
            changeCategory(match.params.categoryId)
            dispatch(actionCreators.resetScrolledProduct());
        }
    })

    useEffect(() => {
        if (!loadedProducts) {
            dispatch(actionCreators.resetScrolledProduct());
            getProducts(0);
            window.scrollTo(0, 0)
        } else {
            setLoadedProducts(null);
            setProducts(categories.loadedProducts.map(item => {return item}));// per poter aggiungere gli altri prodotti caricati dopo
            window.scrollTo(0, categories.scroll)
        }
    }, [categoryId, onlyPromotions, onlyBranded, tag, getProducts]) // eslint-disable-line

    const loadMore = () => {
        const newPage = page + 1;
        if (newPage < totalPages) {
            const newCategory = categoryId === "" || categoryId === undefined ? getCategoryId() : categoryId;
            let first = newCategory?.split('_');
            if (first && first.length > 1){
                ecommerceService.searchProducts("", storeId, newPage, newCategory, onlyPromotions, onlyBranded, tag)
                    .then(response => {
                        const newResponse = products;
                        newResponse.push(...response.data);
                        setProducts(newResponse)
                        setTotalPages(response.totalPages)
                        setHasMore(!response.last)
                        setPage(response.number);
                    }).catch(error => {
                        console.log(error);
                    })
            }
        }
    }

    return (
        <div className="search-category">
            <div className="page-grid mobile-view-search">
                <div className="grid-left" offsettop={0} offsetbottom={0}>
                    <CategoryTree />
                    {/* <Filters /> */}
                </div>
                {/* <div style={{ paddingTop: "20px" }} className="grid-right"> */}
                <div className="grid-right">
                    {/* <div className="category-banner" style={{ backgroundImage: `url(${CategoryBannerImg})` }} />  */}
                    {/* <CategoryBanner categoryId={category && category.id} className="category-banner" /> */}
                    <div className="category-container">
                        <div className="category-breadcrumb-container">
                            {products && products.length > 0 &&
                                <ProductBreadcrumb product={products[0]} />
                            }
                        </div>
                        {/* <Filters /> */}
                    </div>

                    <InfiniteScroll
                        style={{ overflowX: "hidden" }}
                        dataLength={products.length}
                        next={loadMore}
                        hasMore={hasMore}
                        onScroll={() => setSavedScroll(window.scrollY)}
                        loader=
                        {<Loader
                            type="ThreeDots"
                            color="#333"
                            height={70}
                            width={70}
                            timeout={3000} //3 secs
                        />}
                    >
                        {products && products.length > 0 &&
                            <div className="products-grid">
                                {products.map((item, index) => (
                                    <CardProduct
                                        key={item.id}
                                        item={item}
                                        activeKey={categories.categoryId}
                                        loadedProducts={products}
                                        savedScroll={savedScroll}
                                        from={location.pathname}
                                        page={page}
                                        totalPages={totalPages}
                                    />
                                ))}
                            </div>
                        }
                        {products && products.length === 0 &&
                            <div className="d-flex justify-content-center">
                                <span className="title">{t("searchCategory.noItemsPresent")}</span>
                            </div>
                        }
                    </InfiniteScroll>
                    <ScrollToTopButton />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        defaultStore: state.user.defaultStore,
        categoryId: state.categories.categoryId,
        onlyPromotions: state.categories.onlyPromotions,
        onlyBranded: state.categories.onlyBranded,
        tag: state.categories.tag,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        changeCategory: (categoryId, index) => dispatch(actionCreators.changeCategory(categoryId, index)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSearchCategory);