import React from "react"
import { Translation } from "react-i18next";
import i18n from "../../../i18n";

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            btnTitle: this.props.defaultStore ? this.props.defaultStore : this.props.label
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.globalClickListener)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.items !== nextProps.items) {
            if (nextProps.items && nextProps.items.length <= 0) {
                this.setState({ btnTitle: nextProps.label })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultStore !== this.props.defaultStore) {
            this.setState({ btnTitle: this.props.defaultStore })
        }
    }

    globalClickListener = () => {
        this.setState({ isOpen: false }, () => {
            document.removeEventListener('click', this.globalClickListener)
        })
    }

    toggleOpen = () => {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => {
            if (this.state.isOpen) {
                document.addEventListener('click', this.globalClickListener)
            }
        })
    }

    handleChange = (item) => {
        this.setState({
            isOpen: false,
            btnTitle: this.props.isDeliveryMode ? `${i18n.t(`cart.deliveryMode.${item.name}`)}` : item.name
        })
        this.props.select(item)
    }

    render() {
        const { items, isDeliveryMode, label } = this.props;
        const { btnTitle } = this.state;
        const menuClass = `dropdown-items ${this.state.isOpen ? "dropdown--isOpen" : ""} dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return (
            <Translation>
                {t =>
                    <div className={`dropdown mb-3 ${items && items.length > 0 ? "" : "step-disabled"}`}>
                        <button className="dropdown-button outline dropdown-toggle"
                            onClick={this.toggleOpen} type="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className={`${btnTitle !== label ? "dropdown-button-bold" : ""}`}>{btnTitle}</span>
                        </button>
                        <div className={menuClass} aria-labelledby="dropdownMenuButton">
                            {items && items.length > 0 && items.map((item) => (
                                <span key={item.id ? item.id : item.name} className="dropdown-item" onClick={() => this.handleChange(item)}>
                                    {isDeliveryMode ? `${t(`cart.deliveryMode.${item.name}`)}` : item.name}
                                </span>
                            ))}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

