import React, { Component } from 'react';

import "./LG.scss";
class Lg extends Component {
    render() {
        return (
            <div className="container d-flex justify-content-center flex-column mt-5 mb-5 legal-policy">
                <div className="policy-heading text-center">GARANZIA LEGALE DI CONFORMITA’
                </div>
                <div className="policy-title">CHE COS'È LA GARANZIA LEGALE
                    <div className="policy-terms">
                        La garanzia legale di conformità è prevista dal Codice del Consumo (art. 128 e ss. D.Lgs.
                        206/2005 e s.m.i.) e tutela il consumatore in caso di acquisto di prodotti difettosi, che
                        funzionano male o non rispondono all'uso dichiarato dal Venditore al quale quel bene è
                        generalmente destinato.
                    </div>
                </div>
                <div className="policy-title">
                    CHI PUÒ FARLA VALERE
                    <div className="policy-terms">
                        Il consumatore, persona fisica, che agisce per scopi estranei all'attività imprenditoriale,
                        commerciale, artigianale o professionale eventualmente svolta. Pertanto, in caso di acquisti per
                        attività imprenditoriale o professionale (vendita con fattura e partita IVA) la garanzia valida
                        è solo quella commerciale o anche detta garanzia convenzionale del produttore.
                    </div>
                </div>
                <div className="policy-title">
                    NEI CONFRONTI DI CHI PUÒ ESSERE FATTA VALERE ?
                    <div className="policy-terms">
                        Il consumatore può far valere i propri diritti in materia di garanzia legale di conformità
                        rivolgendosi direttamente al venditore del bene, anche se diverso dal produttore. Quindi, in
                        base alla garanzia legale TN Sviluppo, con sede legale in Via Innsbruck, 2, a Trento (TN), è
                        responsabile nei confronti del consumatore per i difetti di conformità non riscontrabili al
                        momento dell'acquisto.
                    </div>
                </div>
                <div className="policy-subtitle">
                    QUANDO SI HA UN DIFETTO DI CONFORMITÀ
                </div>
                <div className="policy-terms">
                    Quando il bene acquistato non è:
                    <ul>
                        <li>idoneo all'uso al quale deve servire abitualmente;</li>
                        <li>conforme alla descrizione o non possiede le qualità promesse dal venditore;</li>
                        <li>non presenta le qualità e prestazioni abituali di un bene dello stesso tipo, che il
                            consumatore può ragionevolmente aspettarsi, tenuto conto anche delle dichiarazioni fatte
                            nella pubblicità o nella etichettatura;
                        </li>
                        <li>idoneo all'uso particolare voluto dal consumatore e che sia stato da questi portato a
                            conoscenza del venditore al momento della conclusione del contratto e che il venditore abbia
                            accettato.
                        </li>
                    </ul>
                    Pertanto, sono esclusi dal campo di applicazione della garanzia legale tutti i guasti o
                    malfunzionamenti determinati da fatti accidentali o da responsabilità del Consumatore ovvero da un
                    uso del prodotto non conforme alla sua destinazione d'uso e/o a quanto previsto nella documentazione
                    tecnica allegata al prodotto.
                </div>
                <div className="policy-title">
                    CONTENUTO DELLA GARANZIA LEGALE
                    <div className="policy-subtitle">In caso di difetto di conformità debitamente denunciato nei
                        termini, il Consumatore ha
                        diritto:</div>
                    <div className="policy-terms">
                        <ul>
                            <li>in via primaria, alla riparazione o sostituzione gratuita del bene, a sua scelta, salvo
                                che il rimedio richiesto sia oggettivamente impossibile o eccessivamente oneroso
                                rispetto all'altro;
                            </li>
                            <li>
                                in via secondaria (nel caso cioè in cui la riparazione o la sostituzione siano
                                impossibili o eccessivamente onerose ovvero la riparazione o la sostituzione non siano
                                state realizzate entro termini congrui ovvero la riparazione o la sostituzione
                                precedentemente effettuate abbiano arrecato notevoli inconvenienti al Consumatore) alla
                                riduzione del prezzo o alla risoluzione del contratto, a sua scelta. Il rimedio
                                richiesto è eccessivamente oneroso se impone al Venditore spese irragionevoli in
                                confronto ai rimedi alternativi che possono essere esperiti, tenendo conto del valore
                                che il Prodotto avrebbe se non vi fosse il difetto di conformità; dell'entità del
                                difetto di conformità; dell'eventualità che il rimedio alternativo possa essere esperito
                                senza notevoli inconvenienti per il consumatore. Le riparazioni o le sostituzioni devono
                                essere effettuate entro un congruo termine dalla richiesta del Consumatore (tenendo
                                conto della natura del bene e dello scopo per il quale è stato acquistato).
                            </li>
                        </ul>
                    </div>
                    <div className="policy-title">
                        DURATA DELLA GARANZIA LEGALE
                        <div className="policy-terms">
                            Il Venditore, ai sensi del vigente Codice del Consumo, è responsabile nei confronti del
                            Consumatore per qualsiasi difetto di conformità esistente al momento della consegna del
                            Prodotto e che si manifesti entro due anni dalla data di consegna.
                            Il difetto di conformità deve essere denunciato al Venditore, a pena di decadenza dalla
                            garanzia, nel termine di due mesi dalla data in cui è stato scoperto.
                            Salvo prova contraria, si presume che i difetti di conformità che si manifestano entro i sei
                            mesi dalla consegna del Prodotto esistessero già a tale data, a meno che tale ipotesi sia
                            incompatibile con la natura del Prodotto o con la natura del difetto di conformità. Per
                            poter usufruire della Garanzia Legale, il Consumatore deve fornire innanzitutto prova della
                            data dell'acquisto e della consegna del Prodotto. È opportuno, quindi, che il Consumatore, a
                            fini di tale prova, presenti il documento che possa attestare la data di effettuazione
                            dell'acquisto del prodotto che presenta il difetto di conformità come sopra riportato a
                            norma di legge.
                        </div>
                        <div className="policy-title">
                            GARANZIA CONVENZIONALE DEL PRODUTTORE
                            <div className="policy-terms">
                                I Prodotti venduti sul Sito possono, a seconda della loro natura, essere coperti da una
                                garanzia convenzionale rilasciata dal Produttore. Il Consumatore può far valere tale
                                garanzia solo nei confronti di quest'ultimo, e, se lo preferisce, anche in alternativa
                                alla Garanzia Legale di Conformità gravante comunque sul Venditore alle condizioni e nei
                                termini sopra ricordati. La durata, la estensione, anche territoriale, le condizioni e
                                le modalità di fruizione, i tipi di danni/difetti coperti e le eventuali limitazioni
                                della Garanzia Convenzionale dipendono dal singolo Produttore e sono indicati nel c.d.
                                certificato di garanzia contenuto nella confezione del Prodotto.
                                Si ricorda che i danni da prodotto difettoso, ai fini di un eventuale risarcimento, non
                                sono di competenza del Venditore, anche laddove il difetto rientri nella garanzia legale
                                del Venditore, bensì del Produttore a cui dovranno essere rivolte le eventuali
                                richieste. Quando il Produttore non sia individuato, il Venditore è sottoposto a
                                responsabilità per danni da prodotto difettoso, se omette di comunicare al Consumatore
                                danneggiato, entro il termine di tre mesi dalla richiesta, l'identità ed il domicilio
                                del Produttore o della persona che gli ha fornito il prodotto.
                            </div>
                        </div>
                        <div className="policy-title">DIFFERENZA TRA GARANZIA LEGALE E GARANZIE CONVENZIONALI
                            <div className="policy-terms">
                                Le garanzie convenzionali, gratuite o a pagamento, offerte dal Produttore o dal
                                rivenditore, non sostituiscono né limitano quella legale di conformità, rispetto alla
                                quale possono avere invece diversa ampiezza e/o durata. Chiunque offra garanzie
                                convenzionali deve comunque sempre specificare che si tratta di garanzie diverse e
                                aggiuntive rispetto alla garanzia legale di conformità che tutela i consumatori.
                            </div>
                        </div>
                        <div className="policy-title">
                            MODALITÀ DI ESERCIZIO DELLA GARANZIA LEGALE
                            <div className="policy-terms">
                                Occorre recarsi presso il punto vendita dove è stata ritirata la spesa, esibendo il
                                documento d'acquisto (a titolo esemplificativo e non esaustivo scontrino fiscale,
                                conferma d'ordine etc...) e consegnare il prodotto che presenta un difetto di conformità
                                come sopra riportato a norma di legge. L'addetto del punto vendita accerta che il danno
                                rientri nella garanzia legale e procede, su richiesta, alternativamente alla
                                sostituzione del prodotto con altro equivalente o al rimborso del valore del prezzo
                                pagato in coerenza con le modalità di pagamento previste per il servizio.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Lg;