import React from 'react';
import { ReactComponent as Alert } from "../../images/card-alert.svg"
import { useTranslation } from 'react-i18next';

import "./password-help.styles.scss";

const PasswordHelp = (props) => {
    const { t } = useTranslation();
    return (
        <div className="password-help">
            <Alert />
            <span>{t("passwordChange.passwordHelp")}</span>
        </div>
    );
}

export default PasswordHelp;



