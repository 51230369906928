import React from "react"
import disponibility from "./Service"
import dayOfWeek from "../../../utils/dateUtils"
import Slider from "../../../components/UI/Slider/Slider";
import { Translation } from 'react-i18next';
import { useState,useEffect } from "react";

const Days = ({ selectedDay,selectDay, deliveryMode,cartId}) => {

    const [days, setDays] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        disponibility(cartId, deliveryMode)
            .then(response => {
                if (response.data.length > 0) {
                    setDays(response.data)
                    setIsLoaded(true)
                }
            }).catch((error) => {
                console.log(error);
            })
    }, [deliveryMode]) 

        const data = dayOfWeek(days)
        if (isLoaded) {
            if (days && days.length > 0) {
                return (
                    <Translation>
                        {t =>
                            <div className="disponibility-days">
                                <Slider>
                                    {days && data.map((day) => (
                                        <button key={day.day}
                                            className={`disponibility-button outline ${selectedDay === day.day ? "disponibility-button-selected" : ""}`}
                                            onClick={() => selectDay(day)}>
                                            <div className="text-center">
                                                <span>{t(`daysOfWeek.${day.name}`)}</span>
                                            </div>
                                            <div className="text-center" style={{ fontSize: '30px' }}>
                                                <span className="day">{day.dayNumber}</span>
                                            </div>
                                            <div className="text-center">
                                                <span>{t(`months.${day.month}`)}</span>
                                            </div>
                                        </button>
                                    ))}
                                </Slider>
                            </div>
                        }
                    </Translation>
                );
            } else {
                return (
                    <Translation>
                        {t =>
                            <span className="text-center primary-text mb-4">
                                {t("days.primaryText")}
                            </span>
                        }
                    </Translation>
                );
            }
        } else return null;
}
export default Days