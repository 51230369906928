import React from 'react'
//import "./video.styles.scss";

import "./banner-details-video.styles.scss";

const src = "https://www.youtube.com/embed/D8RrX6HGvrE";

const BannerDetailsAmiciAnimaliVideo = () => {
    return (
          <iframe
             className='banner-details-video'
             src={src}
             frameborder="0"
             allowFullScreen
         /> 
    );
};

export default BannerDetailsAmiciAnimaliVideo;