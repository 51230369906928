import React from "react"
import { useTranslation } from "react-i18next";

const Accepts = ({ user }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="custom-checkbox">
                <input className="custom-control-input" type="checkbox" id="accept1" name="accept1"
                    defaultChecked={true}
                    disabled
                />
                <label className="custom-control-label" htmlFor="accept1">
                    {t("profile.acceptTermsAndConditions")}
                </label>
            </div>
            <div className="custom-checkbox">
                <input className="custom-control-input" type="checkbox" id="accept2" name="accept2"
                    defaultChecked={user && user.accept2}
                    disabled
                />
                <label className="custom-control-label" htmlFor="accept2">
                    {t("profile.acceptPrivacyPolicy")}
                </label>
            </div>
            <div className="custom-checkbox">
                <input className="custom-control-input" type="checkbox" id="accept3" name="accept3"
                    defaultChecked={user && user.accept3}
                    disabled
                />
                <label className="custom-control-label" htmlFor="accept3">
                    {t("signup.terms2")}
                </label>
            </div>
        </>
    );
}

export default Accepts;



