import React from 'react'
import "./video.styles.scss";

const src = "https://www.youtube.com/embed/ur5NIkxkeDM";


const Video = () => {
  return (
    <iframe
      /* width="500"
      height="240" */
      className='video-container'
      src={src}
      frameborder="0"
      allowFullScreen
    />
  );
};

export default Video;
