import React from "react";
import { Route, Switch } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import CustomBreadcrumb from "../../components/UI/Breadcrumb/CustomBreadcrumb";
import MobileProfileMenu from "./ProfileMenu/MobileProfileMenu";
import ProfilePreview from "./ProfilePreview/ProfilePreview";
import ProfileAddresses from "./ProfileAddresses/ProfileAddresses";
import BillingAddresses from "./ProfileAddresses/BillingAddresses";
import Orders from "../Orders/Orders";
import Smartlists from "../Smartlist/Smartlists";
import Payments from "../Payments/Payments";
import Profile from "./ProfileUpdate/ProfileUpdate";
import PasswordChange from "./PasswordChange/PasswordChange";
import * as actionCreators from "../../redux/store/actions/index"
import "./profile-container.styles.scss";
import GoBackButton from "../../components/UI/Buttons/GoBackButton";
import { history } from "../../App";

class ProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jwt: null,
            userId: null
        }
    }

    renderBreadcrumb = () => {
        const { history } = this.props;
        const pathname = history.location.pathname;
        var level2 = null;
        switch (pathname) {
            case "/profile":
                level2 = { path: "/profile", label: "profileContainer.preview" }
                break;
            case "/profile/update":
                level2 = { path: "/profile/update", label: "profileContainer.yourData" }
                break;
            case "/profile/addresses":
                level2 = { path: "/profile/addresses", label: "profileContainer.shippingAddress" }
                break;
            case "/profile/billing":
                level2 = { path: "/profile/billing", label: "profileContainer.billingAddress" }
                break;
            case "/profile/password":
                level2 = { path: "/profile/password", label: "profileContainer.passwordChange" }
                break;
            case "/profile/orders":
                level2 = { path: "/profile/orders", label: "profileContainer.orders" }
                break;
            case "/profile/smartlists":
                level2 = { path: "/profile/smartlists", label: "profileContainer.slists" }
                break;
            case "/profile/payments":
                level2 = { path: "/profile/payments", label: "profileContainer.payments" }
                break;

            default: level2 = { path: "/profile", label: "profileContainer.preview" }
        }
        return level2;
    }

    render() {
        const { user, logout, slists } = this.props;
        window.setData = this.setState.bind(this);
        console.log(this.state.jwt, this.state.userId)
        return (
            <Translation>
                {t =>
                    <div className="profile-container">
                        <div className="profile-breadcrumb">
                            <CustomBreadcrumb
                                level1={{
                                    path: "/profile",
                                    label: "profile"
                                }}
                                level2={{
                                    ...this.renderBreadcrumb(),
                                    active: true
                                }}
                            />
                        </div>
                        <div className="page-grid mobile-padding">
                            <div className="grid-left">
                                <ProfileMenu />
                                <button className="secondary-cta secondary-cta-abort w-100" onClick={logout}>
                                    {/* <button className="secondary-cta secondary-cta-abort" onClick={logout}> */}
                                    {t("profileContainer.logout")}
                                </button>
                            </div>
                            <div className="grid-right">
                                <div className="profile-content">
                                    <MobileProfileMenu />
                                    <Switch>
                                        <Route exact path='/profile' render={() => <ProfilePreview user={user} slists={slists} />} />
                                        <Route exact path='/profile/update' render={() => <Profile />} />
                                        <Route exact path='/profile/addresses' render={() => <ProfileAddresses />} />
                                        <Route exact path='/profile/billing' render={() => <BillingAddresses />} />
                                        <Route exact path='/profile/password' render={() => <PasswordChange />} />
                                        <Route exact path='/profile/orders' render={() => <Orders />} />
                                        <Route exact path='/profile/smartlists' render={() => <Smartlists />} />
                                        <Route exact path='/profile/payments' render={() => <Payments jwt={this.state.jwt} userId={this.state.userId}/>} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        slists: state.slists.slists,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actionCreators.logout()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileContainer));


