/* eslint-disable */

import * as actionTypes from "../actions/actionTypes";
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    user: localStorage.getItem("user") != "undefined" && localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : null,
    jwt: null,
    latitude: null,
    longitude: null,
    showOverlay: false,
    showLogin: false,

    // defaultStore: null,
    defaultStore: {
        id: 686491,
        name: "Superstore Trento"
    },
    defaultDelivery: null,
    defaultZipCode: "",
    defaultCityName: "",
    clickCoop: null
}

const userReducer = createReducer(initialState, {
    [actionTypes.LOGIN]: (state, action) => { state.user = action.user, state.jwt = action.jwt, state.showLogin = action.showLogin },
    [actionTypes.LOGOUT]: (state, action) => { state.user = action.user },
    [actionTypes.USER_UPDATE]: (state, action) => { state.user = action.user },
    [actionTypes.IS_LOGGED]: (state, action) => { state.user = action.user },
    [actionTypes.COORDINATE]: (state, action) => {
        state.latitude = action.latitude,
            state.longitude = action.longitude
    },
    [actionTypes.OVERLAY]: (state, action) => { state.showOverlay = action.showOverlay },
    [actionTypes.SHOW_LOGIN]: (state, action) => { state.showLogin = action.showLogin },
    [actionTypes.DEFAULT_STORE]: (state, action) => {
        state.defaultStore = action.defaultStore,
            state.defaultDelivery = action.defaultDelivery,
            state.defaultZipCode = action.defaultZipCode,
            state.defaultCityName = action.defaultCityName
    },
    [actionTypes.CLICK_COOP]: (state, action) => { state.clickCoop = action.clickCoop }
})

export default userReducer;

