import React, { Component } from 'react';

import "./TermsAndCondi.scss"

class TermsAndCondi extends Component {
    render() {
        return (
            <div className="container d-flex justify-content-center flex-column mt-5 mb-5 cookie-policy">
                <div className="policy-heading text-center">CONDIZIONI GENERALI DI VENDITA<br />
                    DISPOSIZIONI GENERALI/AMBITO DI APPLICAZIONE
                </div>
                <div className="policy-title">ART.1 "PREMESSE"
                    <div className="policy-terms">
                        Click & Coop è il servizio che ti offre la possibilità di fare acquisti online e di ritirare la spesa presso il negozio selezionato, tra quelli a disposizione, nella finestra oraria desiderata. Il Servizio prevede una spesa minima dell’importo di 30 (trenta) Euro.
                        Il servizio ed il presente sito sono di proprietà di SAIT – CONSORZIO DELLE COOPERATIVE DI CONSUMO TRENTINE SOC. COOP., con sede legale a Trento (TRENTO), in Via Innsbruck, n. 2, C.F./P.IVA e numero di iscrizione nel Registro delle Imprese di Trento 00122300221, (d'ora innanzi, per brevità, “Sait”) ed è dedicato alla vendita al dettaglio di generi alimentari e non alimentari.
                        I prodotti offerti in vendita invece sono di - e nel caso di acquisto il contratto viene concluso con - Trento Sviluppo S.r.l., con sede in Trento (TRENTO), via Innsbruck 2, capitale sociale pari a Euro 4.900.000,00 iscritta al Registro delle Imprese di Trento al numero R.E.A. 147375, codice fiscale e P.IVA 01542000227, (d'ora innanzi, per brevità, “TRENTO Sviluppo”).
                        Le presenti Condizioni Generali, pertanto, sono valide tra TRENTO Sviluppo e chiunque, effettui l'acquisto tramite il sito web <a href="https://www.clickcoop.it" target="_blank" rel="noopener noreferrer"> www.clickcoop.it</a>
                        TRENTO Sviluppo osserva la normativa in materia di contratti conclusi a distanza di cui agli art. 45 e seguenti del D. Lgs 206/2005 s.m.i. Il Servizio è usufruibile esclusivamente da utenti che rivestono lo status di "consumatori", intendendosi per tale la persona fisica maggiorenne o la persona giuridica che compie la prenotazione per scopi non riferibili all'attività commerciale o professionale eventualmente svolta (di seguito, per brevità “Consumatore” o “Cliente”). Resta quindi inteso che non saranno vendute a singoli clienti quantità di merce vistosamente eccedenti il bisogno familiare (le quantità limite sono precisate per prodotto in fase di ordine). Ciò al fine di evitare acquisti che per quantità possano snaturare l'attività dei punti vendita, volta essenzialmente alla vendita al consumatore finale. Tale condizione vuole, tra l'altro, salvaguardare la totalità dei consumatori che potrebbero vedere disattese le proprie aspettative per il prematuro esaurimento delle scorte, causato da accaparramenti di merce posti in essere da pochi clienti.
                        Le presenti Condizioni Generali si applicano a tutte le Prenotazioni effettuate dai Clienti tramite il Sito e possono essere modificate in qualsiasi momento da TRENTO Sviluppo. Eventuali modifiche saranno in vigore dal momento della loro pubblicazione sul Sito e verranno applicate alle sole Prenotazioni concluse successivamente alla pubblicazione delle predette modifiche.
                    </div>
                </div>
                <div className="policy-title">ART.2 "REGISTRAZIONE"
                    <ul className="policy-terms">
                        Per navigare sul sito Click & Coop non è obbligatoria la registrazione al sito; per accedere al servizio, invece la registrazione è necessaria e permette di ottenere vantaggi e servizi aggiuntivi tra i quali:
                        <li>
                            L’accesso immediato alla pagina d'acquisto effettuando il login: è possibile memorizzare i propri dati senza doverli inserire ad ogni spesa. I dati possono essere autonomamente modificati in ogni momento;
                        </li>
                        <li>
                            per i Soci: le spese effettuate tramite Click&Coop contribuiscono al caricamento dei punti sulla propria tessera socio. Il saldo punti sarà aggiornato il giorno successivo all'acquisto;
                        </li>
                        <li>
                            La gestione degli ordini: è possibile visualizzare lo storico dei propri ordini e modificare gli ordini pendenti;
                        </li>
                        <li>
                            La possibilità di salvare le liste personalizzate della spesa e poterle richiamare ogni volta in modo pratico e veloce;
                        </li>
                        <li>
                            La possibilità di comporre il carrello della spesa al fine di riprenderlo in un secondo momento.
                        </li>
                    </ul>
                    <div className="policy-terms">
                        Effettuato l'accesso all'area riservata, il Cliente, prima di effettuare l'ordine, visualizzerà la prima data e fascia oraria disponibile per il ritiro della spesa. La stessa andrà selezionata al termine dell'imputazione dell'ordine che andrà successivamente ritirato presso l'area dedicata Click & Coop del negozio selezionato.
                    </div>
                </div>
                <div className="policy-title">ART.3 "ORARI DEL SERVIZIO"
                    <div className="policy-terms">
                        Le fasce orarie per il ritiro della spesa corrispondono all'orario riportato sul sito Click & Coop relativamente al punto vendita selezionato per l'utilizzo del servizio, orario che può essere differente da quello del negozio fisico. La finestra oraria di ritiro della spesa deve essere confermata alla conclusione dell'ordine e variano a seconda del punto di ritiro.
                    </div>
                </div>
                <div className="policy-title">ART.4 "FOTO E PRODOTTI"
                    <div className="policy-terms">
                        Le foto utilizzate sul sito sono meramente rappresentative del prodotto e puramente indicative. La confezione dei prodotti consegnati potrebbe essere diversa da quella riportata sul sito.
                    </div>
                </div>
                <div className="policy-title">ART.5 "PREZZI E PROMOZIONI"
                    <div className="policy-terms">
                        I prezzi e le promozioni applicati alla vendita sono quelli in vigore sul sito al momento dell'ordine. I prezzi sul sito potranno essere differenti da quelli presenti nel negozio fisico prescelto.
                    </div>
                </div>
                <div className="policy-title">ART.6 "PAGAMENTO"
                    <div className="policy-terms">
                        <ul className="policy-terms">
                            L‘acquisto può essere pagato dal cliente a sua scelta con le seguenti modalità:
                            <li>
                                alla consegna con contanti carta di credito, carte prepagate, carta socio INcooperazione.
                            </li>
                            <li>
                                on-line con le modalità indicate nel sito.
                            </li>
                        </ul>
                    </div>
                    Nel caso fossero presenti dei punti di ritiro (di seguito, “Punti di ritiro”) diversi dal punto vendita
                    sarà possibile solo il pagamento in modalità on-line.
                    Per la modalità di pagamento on-line, al momento della conferma dell’ordine d’acquisto viene richiesta all’emittente carta, una pre-autorizzazione (blocco del plafond o disponibilità della carta) di un importo pari al totale dell’ordine maggiorato del 5%. L’esatto importo che sarà addebitato sulla carta del cliente, verrà determinato una volta ultimata la preparazione dell’ordine e verificata la presenza di tutti gli articoli selezionati, oltre al calcolo esatto dei prodotti a peso variabile.
                    Il prezzo, inoltre, potrà variare in ragione degli shoppers che verranno automaticamente addebitati al cliente in numero variabile in conseguenza del tipo di prodotti acquistati (da uno a tre shoppers a seconda delle seguenti categorie: secchi, freschi e surgelati) e, per ciascuna tipologia, in ragione del loro volume.
                </div>
                <div className="policy-title">ART.7 "PRODOTTI A PESO VARIABILE"
                    <div className="policy-terms">
                        I prodotti a peso variabile (ad es. frutta, verdura, carne, pesce, prodotti di gastronomia, formaggi...) vengono proposti nel sito in misure e pesi indicati con un prezzo al kg. Il peso effettivo si determinerà solamente al momento della preparazione dell'ordine e potrà variare fino al 30% in più o in meno rispetto a quanto indicativamente proposto nel sito. Per tale motivo, ove la spesa comprenda uno o più di tali prodotti, il peso e l'importo determinato al momento dell'ordine potranno differire dal peso e dall'importo effettivo calcolato al termine della preparazione della spesa.
                    </div>
                </div>
                <div className="policy-title">ART.8 "INDICAZIONE DEL PAESE DI ORIGINE O DI ALLEVAMENTO - MACELLAZIONE
                    DEI PRODOTTI""
                    <div className="policy-terms">
                        La provenienza della carne indicata al momento dell'ordine può differire da quella del prodotto consegnato. Le informazioni di legge saranno in ogni caso presenti sul prodotto che verrà consegnato.
                    </div>
                </div>
                <div className="policy-title">ART.9 "CONFERMA DELL'ORDINE E DOCUMENTO DI CONSEGNA"
                    <div className="policy-terms">
                        Nella sua casella di posta elettronica il Cliente riceverà conferma dell'ordine effettuato con il riepilogo dei prodotti, dei relativi prezzi, della data ed ora di consegna e delle eventuali condizioni particolari applicabili all'ordine stesso, fatto salvo, in ogni caso, quanto previsto dall'articolo 12 relativamente ai prodotti non disponibili e dell'articolo 7 relativo ai prodotti a peso variabile.
                        Insieme alla conferma il Cliente riceverà altresì indicazioni circa le modalità di esercizio dei suoi diritti (ad es. il diritto di recesso).
                        Il contratto di vendita è considerato concluso con l'invio al Cliente da parte di TRENTO Sviluppo della mail di conferma d'ordine.
                        Successivamente, terminata la preparazione della spesa, TRENTO Sviluppo invierà al Cliente a mezzo mail un documento riepilogativo recante indicazione dei prodotti che verranno effettivamente consegnati al momento del ritiro e l'indicazione del prezzo che verrà effettivamente e conclusivamente addebitato.
                    </div>
                </div>
                <div className="policy-title">ART.10 "ANNULLAMENTO E MODIFICA DELL'ORDINE"
                    <div className="policy-terms">
                        È possibile modificare oppure annullare l'ordine fino a che lo stesso sul sito non sia segnalato in stato di "Lavorazione".
                        In caso di ritardo al ritiro o sopraggiunti imprevisti è possibile selezionare un nuovo slot libero nella medesima giornata. Se non esistono slot disponibili, contattare la preparazione al numero <a href="tel:+0461 362600">0461362600</a> o alla mail info@clickcoop.it per concordare il posticipo della consegna, che di regola non potrà andare oltre la giornata selezionata.
                        Qualora non sia possibile evadere l'ordine per cause di forza maggiore, ovvero per impreviste difficoltà tecnico organizzative, ovvero per la temporanea indisponibilità dei prodotti oggetto
                        dell'ordine medesimo, TRENTO Sviluppo potrà annullare l'Ordine dandone comunicazione al Cliente al numero di telefono indicato nella procedura di Prenotazione, ovvero dandone comunicazione a mezzo mail senza operare alcun addebito a carico del Cliente stesso.
                    </div>
                </div>
                <div className="policy-title">ART.11 "CONSEGNA"
                    <div className="policy-terms">
                        Per ritirare la spesa il Cliente potrà recarsi presso il Punto di Ritiro selezionato mostrando all’addetto la conferma dell’ordine (anche da smartphone/tablet).
                        La spesa contenente bevande alcoliche non potrà essere consegnata ad incaricati minorenni. Al momento della consegna un operatore potrà chiedere un documento di riconoscimento al fine di stabilire l’età del Cliente.
                    </div>
                </div>
                <div className="policy-title">ART.12 "PRODOTTI NON DISPONIBILI - SOSTITUZIONE"
                    <div className="policy-terms">
                        La disponibilità dei prodotti durante la compilazione dell'ordine on line deve essere considerata puramente indicativa, perché, per effetto degli acquisti contemporaneamente operati da più Clienti, i prodotti potrebbero non essere più disponibili al momento della preparazione.
                        <ul className="policy-terms">
                            Nel caso di indisponibilità:
                            <li>
                                Alcuni prodotti non saranno sostituibili e quindi non saranno consegnati.
                            </li>
                            <li>
                                Per i prodotti sostituibili, Click & Coop indicherà immediatamente quale è il prodotto alternativo e relativo prezzo, il Cliente - in fase di ordine - potrà decidere per ogni singolo
                                prodotto se accettare o meno la sostituzione.
                            </li>
                        </ul>
                        Nel sito sono evidenziati i casi in cui valgono limitazioni all'acquisto di singoli prodotti.
                    </div>
                </div>
                <div className="policy-title">
                    ART.13 "RESPONSABILITÀ"
                    <div className="policy-terms">
                        TRENTO Sviluppo garantisce il rispetto della catena del freddo ed in generale delle norme di qualità relative ai prodotti (in particolare alimentari) esclusivamente fino al momento della consegna al Cliente; è esclusa ogni responsabilità relativa al cattivo stato dei prodotti dovuta ad impropria conservazione successiva al momento della consegna. TRENTO Sviluppo declina ogni responsabilità per danni diretti o indiretti di qualunque natura essi siano o sotto qualunque forma si manifestino, conseguenti all'utilizzo del Sito e/o delle notizie, foto ed informazioni ivi contenute. TRENTO Sviluppo non si assume alcuna responsabilità sulle informazioni fornite/acquisite dai singoli produttori o distributori.
                    </div>
                </div>
                <div className="policy-title">
                    ART.14 "ACCETTAZIONE DELLA MERCE"
                    <div className="policy-terms">
                        La presa in consegna della spesa da parte del Cliente o del suo incaricato equivale ad accettazione e riconoscimento del buono stato di conservazione degli alimenti.
                    </div>
                </div>
                <div className="policy-title">
                    ART.15 "MANCATO RITIRO"
                    <div className="policy-terms">
                        Il Cliente o il suo incaricato dovranno essere presenti per il ritiro della spesa, all'indirizzo e nella fascia oraria indicati sull'ordine di acquisto. Per esigenze sopravvenute o impreviste del Cliente che non consentano il ritiro nei termini pattuiti, è possibile contattare il numero <a href="tel:+0461 362600">0461362600</a> o in alternativa la mail info@clickcoop.it.
                        Il mancato ritiro nei termini di cui sopra comporterà l’annullamento dell'ordine ed il contratto si intenderà risolto.
                        In tale caso TRENTO Sviluppo si riserva di applicare quanto previsto dal successivo art. 23.
                    </div>
                </div>
                <div className="policy-title">ART.16 "FATTURA"
                    <div className="policy-terms">
                        L'emissione della fattura non è obbligatoria, e può essere richiesta dal Cliente come indicato dal D.P.R. del 26/10/1972 n. 633 - art. 22. La fattura sarà consegnata all'atto del ritiro dei prodotti, coincidente con la data del pagamento.
                    </div>
                </div>
                <div className="policy-title">
                    ART.17 "CARTA IN COOPERAZIONE (CARTA SOCIO)"
                    <div className="policy-terms">
                        Per i clienti che possiedono la Carta In Cooperazione, le spese effettuate tramite Click&Coop contribuiscono al caricamento dei punti sulla propria Carta In Cooperazione.
                    </div>
                </div>
                <div className="policy-title">ART.18 "PRIVACY"
                    <div className="policy-terms">
                        In ragione della proprietà del sito e della sua gestione la titolarità del trattamento dei dati del Cliente ai fini privacy rimane in capo a Sait che procederà al trattamento nel rispetto della normativa in materia di privacy come precisato in dettaglio nell'informativa nella sezione "PRIVACY" del sito.
                    </div>
                </div>
                <div className="policy-title">
                    ART.19 "FORO COMPETENTE"
                    <div className="policy-terms">
                        Ogni e qualsiasi controversia che dovesse insorgere in relazione all'applicazione, interpretazione ed esecuzione delle presenti Condizioni, sarà inderogabilmente devoluta al giudice del luogo di residenza o di domicilio del consumatore, se ubicati nel territorio dello Stato.
                    </div>
                </div>
                <div className="policy-title">ART.20 "GARANZIA LEGALE DI CONFORMITÀ"
                    <div className="policy-terms">
                        TRENTO Sviluppo riconosce su tutti i prodotti acquistati la garanzia legale di conformità. Per maggiori informazioni consultare la sezione "Garanzia Legale di conformità" del Sito.
                    </div>
                </div>
                <div className="policy-title">ART.21 "COMUNICAZIONI"
                    <div className="policy-terms">
                        <ul className="policy-terms">
                            È possibile chiedere informazioni, inviare comunicazioni o inoltrare reclami contattando TRENTO Sviluppo con le seguenti modalità:
                            <li>
                                form contatti “contattaci” presente a piè di pagina
                            </li>
                            <li>
                                numero di telefono <a href="tel:+0461 362600">0461362600</a>
                            </li>
                            <li>
                                email info@clickcoop.it
                            </li>
                        </ul>
                        Per chiedere informazioni, inviare comunicazioni o inoltrare reclami in tema privacy, invece è possibile contattare Sait ai recapiti presenti sulla sezione “informativa privacy” del presente sito.
                    </div>
                </div>
                <div className="policy-title">ART.22 "DIRITTO DI RECESSO E RESO"
                    <div className="policy-terms">
                        I diritti del Cliente sono tutelati dal D. Lgs 206/2005 s.m.i..
                        Il Cliente ha, pertanto, il diritto di recedere dal contratto, anche parzialmente, senza spiegazioni e senza aggravio di spese, alternativamente:
                        <ul>
                            <li>
                                a) rendendo dichiarazione esplicita della sua decisione di recedere dal contratto presentandosi presso il punto vendita ove ha acquistato i prodotti, munito di documento d'acquisto (documento di consegna e scontrino), entro 14 giorni lavorativi dal giorno del ricevimento e/o ritiro degli stessi presso il punto vendita e contestualmente restituendo a TRENTO Sviluppo i prodotti medesimi;
                            </li>
                            <li>
                                b) mediante l'invio di raccomandata A/R a Trento Sviluppo S.r.l., Trento (TRENTO), via Innsbruck 2, entro 14 giorni lavorativi dal giorno ritiro dei prodotti. La comunicazione può essere anticipata, entro lo stesso termine, tramite e-mail all'indirizzo info@clickcoop.it o fax al numero 0461362600 o telegramma, a condizione che sia confermata mediante raccomandata A/R entro le 48 ore successive. Tale comunicazione dovrà specificare la volontà di recedere dall'acquisto ed il prodotto o i prodotti per i quali si intende esercitare il diritto di recesso, allegando copia del documento d'acquisto;
                            </li>
                            <li>
                                c)  inviando a Trento Sviluppo S.r.l., Trento (TRENTO), via Innsbruck 2, entro 14 giorni lavorativi dal giorno del ritiro dei prodotti presso il punto vendita, il documento d' acquisto (documento di consegna e scontrino) e il modulo di "recesso tipo" ai sensi dell'art.49, comma 1, lettera h, di seguito riportato:
                            </li>
                        </ul>
                    </div>
                    <div className="policy-terms">
                        Con la presente io/noi(*) notifico/notifichiamo(*) il recesso dal mio/nostro(*) contratto di vendita
                        dei seguenti beni /servizi(*)<br /><br />
                        Ordinato il (*)/ricevuto il (*)<br /><br />
                        Nome del/dei consumatore(i)<br /><br />
                        Indirizzo del/dei consumatore (i)<br /><br />
                        Firma del/dei consumatore(i)(solo sei il presente modulo è inviato in versione cartacea)<br /><br />
                        Data<br /><br />
                        (*) Cancellare la dicitura non utilizzata
                    </div>
                    <br />
                    <div className="policy-terms">
                        Posto quanto previsto ai precedenti commi b) e c), il Cliente dovrà quindi restituire il prodotto alternativamente secondo le seguenti modalità:
                        <ul>
                            <li>
                                recandosi presso il punto vendita TRENTO Sviluppo in cui si è ritirato il prodotto da rendere entro 14 giorni lavorativi dal ricevimento/ritiro del prodotto;
                            </li>
                            <li>
                                ovvero inviando il prodotto e copia del documento di acquisto entro 14 giorni lavorativi dal ritiro, a Trento Sviluppo S.r.l., Trento (TRENTO), via Innsbruck 2.
                            </li>
                        </ul>
                        Le sole spese dovute dal consumatore per l'esercizio del diritto di recesso sono le spese dirette di restituzione del bene al mittente.
                        Se il recesso sarà stato esercitato conformemente alle modalità descritte, TRENTO Sviluppo provvederà a rimborsare la somma versata per l'acquisto del prodotto entro 14 giorni dalla data in cui TRENTO Sviluppo è venuta a conoscenza dell'esercizio del diritto di recesso da parte del Cliente. Il Cliente sarà responsabile della diminuzione di valore del bene restituito risultante da una manipolazione diversa da quella necessaria per stabilire la natura, le caratteristiche e il funzionamento del bene stesso.
                        Fermo quanto sopra, il diritto di recesso non è consentito nel caso di:
                        <ol>
                            <li>acquisto di prodotti confezionati sigillati, aperti dal Cliente; </li>
                            <li>di beni confezionati su misura o personalizzati (ad es. prodotti di gastronomia); </li>
                            <li>
                                di beni che per loro natura non possono essere rispediti o rischiano di deteriorarsi o alterarsi rapidamente (ad es. i prodotti alimentari freschi e deperibili come i prodotti di gastronomia);
                            </li>
                            <li>abbigliamento intimo;</li>
                            <li>
                                prodotti audiovisivi (a titolo esemplificativo e non esaustivo: cd, dvd, videogiochi, programmi software....) con confezione aperta e con sigilli e contrassegni SIAE rimossi;
                            </li>
                            <li>quotidiani e periodici;</li>
                            <li>
                                prodotti che devono essere registrati presso il produttore prima di essere utilizzati (es. Software, lettori MP3, navigatori satellitari..);
                            </li>
                            <li>
                                prodotti virtuali (a titolo esemplificativo e non esaustivo: cofanetti regalo, gift card, buoni carburanti, ricariche virtuali...)
                            </li>
                            <li>carte prepagate e/o ricariche telefoniche che siano state attivate dal Cliente;</li>
                        </ol>
                    </div>
                </div>

                <div className="policy-title">
                    ART.23 "SOSPENSIONI DEL SERVIZIO"
                    <div className="policy-terms">
                        TRENTO Sviluppo si riserva il diritto di rifiutare o cancellare Prenotazioni che provengano:
                        <ul>
                            <li>da un Utente con cui essa abbia in corso un contenzioso legale;</li>
                            <li>
                                da un Utente che abbia in precedenza omesso di ritirare i prodotti oggetto dell'ordine ovvero che abbia altrimenti violato le presenti Condizioni Generali;
                            </li>
                            <li>
                                da un Utente che abbia rilasciato dati identificativi che siano risultati falsi, incompleti o comunque inesatti.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="policy-title">ART.24 "RITIRO RAEE"
                    <div className="policy-terms">
                        TRENTO Sviluppo effettua il ritiro gratuito dei RAEE di tipo equivalente, presso lo stesso luogo di consegna delle apparecchiature elettriche ed elettroniche acquistate.
                    </div>
                </div>
            </div>
        );
    }
}

export default TermsAndCondi;