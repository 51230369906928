import * as actionTypes from "./actionTypes"
import toaster from "../../../utils/toaster"
import { ecommerceCartsService } from "../../../services/ecommerce/EcommerceCartsService"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { history } from "../../../App"
import i18n from "../../../i18n"
import swal from "../../../utils/swal"
import { defaultStoreAction } from "./user"
import { storesService } from "../../../services/basis/StoresService"
import config from "../../../config/config"
import { overlayAction } from "./overlay"
import { paymentsService } from "../../../features/Payments/Service"

export const cartAction = (cart) => {
    return {
        type: actionTypes.CART,
        cart: cart
    }
}

export const availabilityAction = (data) => {
    return {
        type: actionTypes.AVAILABILITY,
        availability: data
    }
}

export const current = () => {
    return (dispatch, getState) => {
        ecommerceCartsService.current()
            .then(response => {
                if (response.responseCode === 4004) {
                    dispatch(open("IN_STORE", "686491", ""))
                } else {
                    const cart = response.data;
                    dispatch(cartAction(cart))

                    if (!getState().user.defaultStore) {
                        // Set default store
                        storesService.getStore(cart.storeId)
                            .then(resp => {
                                dispatch(defaultStoreAction(resp.data, cart.deliveryMode,
                                    resp?.data?.address?.zip,
                                    // Use zipCode as cityName
                                    resp?.data?.address?.zip))
                            }).catch(error => {
                                console.log(error)
                            })
                    }

                    // ecommerceService.availability()
                    //     .then(response => {
                    //         dispatch(availabilityAction(response.data))
                    //     }).catch((error) => {
                    //         console.log(error);
                    //     })
                }
            }).catch((error) => {
                console.log(error);
                dispatch(cartAction(null))
            })
    }
};

export const availability = () => {
    return dispatch => {
        ecommerceService.availability()
            .then(response => {
                dispatch(availabilityAction(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const open = (deliveryMode, storeId, addressId) => {
    return (dispatch, getState) => {
        ecommerceCartsService.open(deliveryMode, storeId, addressId)
            .then(response => {
                dispatch(cartAction(response.data.cart))
                // add unauthenticathed product
                if (getState().cart.productToAdd) {
                    const item = getState().cart.productToAdd;
                    ecommerceCartsService.store(response.data.cart.id, item.barcode, item.amount, item.um, item.enableReplacement)
                        .then((response) => {
                            const um = response.data.updatedProduct.um;
                            dispatch(addProductToAddAction(response.data.cart))
                            toaster.standard(`${i18n.t("cart.addedProduct.youHave")} ${item.amount} ${um} ${response.data.updatedProduct.description} ${i18n.t("cart.addedProduct.inCart")}`)
                        }).catch((error) => {
                            console.log(error);
                            toaster.error(error.message);
                        })
                }

                // get first availability
                // ecommerceService.availability()
                //     .then(response => {
                //         dispatch(availabilityAction(response.data))
                //     }).catch((error) => {
                //         console.log(error);
                //     })

            }).catch((error) => {
                toaster.error(error.message);
            })
    }
};

export const store = (cartId, barcode, amount, um, replace, unity) => {
    return dispatch => {
        ecommerceCartsService.store(cartId, barcode, amount, um, replace, unity)
            .then((response) => {
                const um = response.data.updatedProduct.um;
                dispatch(cartAction(response.data.cart))
                toaster.standard(`${i18n.t("cart.addedProduct.youHave")} ${amount} ${um} ${response.data.updatedProduct.description} ${i18n.t("cart.addedProduct.inCart")}`)
            }).catch((error) => {
                console.log(error);
                toaster.error(error.message);
            })
    }
};

export const remove = (cartId, barcode) => {
    return dispatch => {
        ecommerceCartsService.remove(cartId, barcode)
            .then((response) => {
                dispatch(cartAction(response.data.cart))
                toaster.standard(`${response.data.updatedProduct.description} rimosso dal carrello`)
            }).catch((error) => {
                console.log(error);
            })
    }
};



export const freeze = (data, cartId, paymentMode, deliveryMode, setShowModal, setHtmlString) => {
    return dispatch => {
        dispatch(overlayAction(true))
        ecommerceCartsService.freeze(data, cartId, paymentMode, deliveryMode)
            .then(response => {
                setTimeout(() => {
                    dispatch(overlayAction(false))
                    if (response?.data?.cart?.paymentMode === "XPAY") {
                        const orderCode = response.data.cart.code;
                        paymentsService.nonce(orderCode)
                            .then(response => {
                                paymentsService.threeDS(response)
                                    .then(response => {
                                        console.log(response);
                                        if (response.esito && response.esito === "OK") {
                                            // history.push({
                                            //     pathname: `/confirm/${orderCode}`,
                                            //     state: {
                                            //         orders: response.data,
                                            //     }
                                            // })
                                            setHtmlString(response.html.replace(/<script/g, "<script nonce='GTMCOOPSAIT'"));
                                            setShowModal(true);
                                        } else {
                                            history.push({
                                                pathname: `/paymentfailed/${orderCode}`,
                                                state: {
                                                    orders: response.data,
                                                }
                                            })
                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        // history.push("/confirm");
                        history.push({
                            pathname: `/confirm/${response.data.cart.code}`,
                            state: {
                                orders: response.data,
                            }
                        })
                        // ecommerceCartsService.current()
                        //     .then(response => {
                        //         dispatch(cartAction(response.data))
                        //         // ecommerceService.availability()
                        //         //     .then(response => {
                        //         //         dispatch(availabilityAction(response.data))
                        //         //     }).catch((error) => {
                        //         //         console.log(error);
                        //         //     })
                        //     }).catch((error) => {
                        //         console.log(error);
                        //     })
                        dispatch(cartAction(null))
                        // dispatch(open("IN_STORE", "686491", ""))
                    }
                }, 3000);

            }).catch((error) => {
                dispatch(overlayAction(false))
                toaster.error(error.message)
                console.log(error);
            })
    }
};


export const move = (cartId, storeId) => {
    return dispatch => {
        ecommerceCartsService.move(cartId, storeId)
            .then(response => {
                history.push("/cart");
                dispatch(cartAction(response.data.cart))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const abort = (cartId) => {
    return dispatch => {
        ecommerceCartsService.abort(cartId)
            .then((response) => {
                dispatch(cartAction(null))
                dispatch(availabilityAction(null))

                // ecommerceCartsService.current()
                //     .then(response => {
                //         if (response.responseCode === 4004) {
                //             dispatch(open("IN_STORE", "686491", ""))
                //         } else {
                //             dispatch(cartAction(response.data))
                //             // ecommerceService.availability()
                //             //     .then(response => {
                //             //         dispatch(availabilityAction(response.data))
                //             //     }).catch((error) => {
                //             //         console.log(error);
                //             //     })
                //         }
                //     }).catch((error) => {
                //         console.log(error);
                //     })

            }).catch((error) => {
                console.log(error);
            })
    }
};

export const review = (cartId) => {
    return dispatch => {
        ecommerceCartsService.review(cartId)
            .then(response => {
                console.log(response);
                dispatch(cartAction(response.data.cart))

            }).catch((error) => {
                toaster.error(i18n.t("error.cartNotValid"))
            })
    }
};

export const setProductToAddAction = (product) => {
    return {
        type: actionTypes.SET_PRODUCT_TO_ADD,
        productToAdd: product
    }
}

export const setProductToAdd = (product, amount, replace) => {
    return dispatch => {
        product = { ...product, amount: amount, enableReplacement: replace }
        dispatch(setProductToAddAction(product))
    }
};

export const addProductToAddAction = (cart) => {
    return {
        type: actionTypes.ADD_PRODUCT_TO_ADD,
        cart: cart,
        productToAdd: null
    }
}

export const copy = (orderId) => {
    return dispatch => {
        ecommerceCartsService.copyOrder(orderId)
            .then(response => {
                console.log(response);
                if (response && response.responseCode === 4105) {
                    dispatch(freezeCheckAction(response.data, true))
                    history.push("/cart");

                } else if (response && response.responseCode === 0) {
                    dispatch(cartAction(response.data.cart))
                    history.push("/cart");
                }
            })
    }
};

export const modify = (orderId, terminalId) => {
    return dispatch => {
        ecommerceCartsService.modifyOrder(orderId, terminalId)
            .then(response => {
                console.log(response);
                if (response && response.responseCode === 4105) {
                    dispatch(freezeCheckAction(response.data, true))
                    history.push("/cart");

                } else if (response && response.responseCode === 0) {
                    dispatch(cartAction(response.data.cart))
                    history.push("/cart");
                }
            })
    }
};

export const freezeCheck = (data, showPopup) => {
    return dispatch => {
        dispatch(freezeCheckAction(data, showPopup))
    }
};

export const freezeCheckAction = (data, showPopup) => {
    return {
        type: actionTypes.FREEZE_CHECK,
        cart: data.cart,
        updatedItems: data.updatedItems,
        removedItems: data.removedItems,
        showFreezePopup: showPopup
    }
}

export const closeFreezePopup = () => {
    return dispatch => {
        dispatch(closeFreezePopupAction())
    }
};

export const closeFreezePopupAction = () => {
    return {
        type: actionTypes.CLOSE_FREEZE_POPUP,
        updatedItems: null,
        removedItems: null,
        showFreezePopup: false
    }
}

export const getCarts = () => {
    return dispatch => {
        ecommerceCartsService.openCarts()
            .then(response => {
                console.log(response);
                dispatch(getCartsAction(response.data));
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const getCartsAction = (carts) => {
    return {
        type: actionTypes.CARTS,
        carts: carts
    }
}

export const slistToCart = (slistId) => {
    return dispatch => {
        ecommerceCartsService.slistToCart(slistId)
            .then(response => {
                history.push("/cart");
                dispatch(cartAction(response.data))
                swal.success(i18n.t("smartlist.slistToCart.success"));
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const categories = () => {
    return dispatch => {
        ecommerceService.categories()
            .then(response => {
                console.log(response)
                dispatch(categoriesAction(response.data));
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const categoriesAction = (categories) => {
    return {
        type: actionTypes.CATEGORIES,
        categories: categories
    }
}

export const manageCoupon = (cartId, couponCode, action, couponId) => {
    return dispatch => {
        ecommerceCartsService.manageCoupon(cartId, couponCode, action, couponId)
            .then(response => {
                console.log(response);
                dispatch(cartAction(response.data.cart))
            }).catch(error => {
                console.log(error);
            })
    }
};

export const bookTimeslot = (cartId, timeSlotId, timeSlotDay) => {
    return dispatch => {
        ecommerceCartsService.bookTimeslot(cartId, timeSlotId, timeSlotDay)
            .then(response => {
                dispatch(cartAction(response.data.cart))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const changeDeliveryMode = (deliveryMode) => {
    return {
        type: actionTypes.CHANGE_DELIVERYMODE,
        deliveryMode: deliveryMode
    }
}

export const massiveReplacement = (cartId, replacement) => {
    return dispatch => {
        ecommerceCartsService.massiveReplacement(cartId, replacement)
            .then(response => {
                dispatch(cartAction(response.data.cart))
                toaster.standard(replacement ? "Prodotto sostitutivo accettato" : "Prodotto sostitutivo rifiutato")
            }).catch((error) => {
                console.log(error);
            })
    }
};