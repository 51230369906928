import * as actionTypes from "./actionTypes";

export const setEmailPhoneAction = (email, phone) => {
    return {
        type: actionTypes.SET_EMAIL_PHONE,
        email: email,
        phone: phone
    }
}

export const setEmailPhone = (email, phone) => {
    return (dispatch) => {
         dispatch(setEmailPhoneAction(email, phone))
     }
};