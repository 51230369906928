import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Translation } from 'react-i18next';
import toaster from "../../../utils/toaster";
import notes from "./Service";
import "./notes.styles.scss";
import * as actionCreators from '../../../redux/store/actions/index';
import { useDispatch, useSelector } from "react-redux";
import i18n from '../../../i18n';

const Notes = ({ cartId, setNotes, notess, disabled, setDisabled, oldNote, setOldNote }) => {
    const user = useSelector(state => state.user.user)
    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = (values) => {
        notes(cartId, values.notes)
            .then(response => {
                console.log(response);
                toaster.standard(i18n.t("succesfully.request"))
                setDisabled(true);
                setOldNote(notess);
            }).catch((error) => {
                toaster.error(error.message);
            })
    }

    useEffect(() => {
        if(notess === oldNote){
            setDisabled(true);
        }
    }, [notess])

    return (
        <Translation>
            {t =>
                <div className='notes-container'>
                    <div className='notes-container-center'>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete="off"
                        >
                            <div className='notes-container-form'>
                                <textarea
                                    name="notes"
                                    className="notes-textarea"
                                    rows="4"
                                    ref={register}
                                    type="text"
                                    id="notes"
                                    onChange={e => {setNotes(e.target.value); setDisabled(false)}}
                                    value={notess}
                                    maxLength={250}
                                />
                            </div>
                            <div className='notes-container-buttons'>
                                <button className="mt-1 notes-confirm-button" type="submit" disabled={disabled}>
                                    <span>SALVA NOTE</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }

        </Translation>
    )
}

export default Notes;