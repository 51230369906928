import React, { useState, useEffect, useRef } from "react";
import { Route, Switch } from 'react-router-dom';
import CurrentCart from "./CurrentCart/CurrentCart"
import CartMenu from "./CartMenu/CartMenu"
import Checkout from "../Checkout/Checkout";
import FreezeCheckPopup from "./CartMenu/FreezeCheck/FreezeCheckPopup";
import Disponibility from "../Disponibility/Disponibility";
import toaster from "../../utils/toaster";
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router'
import { ecommerceCartsService } from "../../services/ecommerce/EcommerceCartsService";
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next';
import * as actionCreators from "../../redux/store/actions/index"
import dayOfWeek from "../../utils/dateUtils";
import SelectTimeSlots from "../Disponibility/SelectTimeSlots/SelectTimeSlots";
import "./cart.styles.scss";
import DefaultSelectedStore from "../DefaultSelectedStore/DefaultSelectedStore";
import CheckoutHeader from "./CheckoutHeader/CheckoutHeader";
import Summary from "../Summary/Summary";
import { Modal } from 'react-bootstrap'

const Cart = ({
  cart,
  current,
  freezeCheck,
  history,
  updatedItems,
  removedItems,
  showFreezePopup,
  closeFreezePopup,
  abort,
  freeze,
  overlay,
  user
}) => {

  const { t } = useTranslation();
  const ref = useRef();
  const timeSlotBooking = cart ? cart.timeSlotBooking : null;
  const [day, setDay] = useState(timeSlotBooking ? timeSlotBooking.weekDay : null);
  const [timeSlotId, setTimeSlotId] = useState(timeSlotBooking ? timeSlotBooking?.id : null);
  const [showDisponibility, setShowDisponibility] = useState(false);
  const [choosedDay, setChoosedDay] = useState(timeSlotBooking ? dayOfWeek(timeSlotBooking?.weekDay) : null);
  const [choosedTimeslot, setChoosedTimeslot] = useState(timeSlotBooking ?
    { validFrom: timeSlotBooking?.validFrom, validTo: timeSlotBooking?.validTo } : null);
  const [invoice, setInvoice] = useState();
  const [billingAddressId, setBillingAddressId] = useState();
  // const [billingAddress, setBillingAddress] = useState(false);
  const [enableProductsReplacement, setEnableProductsReplacement] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState();
  const [termsAndCondition, setTermsAndCondition] = useState();
  const [deliveryMode, setDeliveryMode] = useState();
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [deliveryZip, setDeliveryZip] = useState();
  const [deliveryCity, setDeliveryCity] = useState();
  const [deliveryCountry, setDeliveryCountry] = useState();
  const [notes, setNotes] = useState();
  const [showModal, setShowModal] = useState(false);
  const [htmlString, setHtmlString] = useState("");

  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    current();
    checkFreeze();
  }, [current])

  useEffect(() => {
    setNotes(cart?.notes)
  }, [cart])

  const selectDay = (day) => {
    setDay(day.day);
    setChoosedDay(day)
    setTimeSlotId(null)
    setChoosedTimeslot(null);
  }

  const selectTimeslot = (timeSlot) => {
    setTimeSlotId(timeSlot.id)
    setChoosedTimeslot(timeSlot)
  }

  const selectInvoice = (e) => {
    if (e.target.checked === false) {
      setInvoice(e.target.checked)
      setBillingAddressId(null)
    } else {
      setInvoice(e.target.checked)
    }
  }

  const enableReplacement = (value) => {
    setEnableProductsReplacement(value)
  }

  const selectAddress = (address) => {
    // setBillingAddress(address)
    setBillingAddressId(address.id)
  }

  const togglePopup = isOpen => setShowDisponibility(isOpen);

  const goToCheckout = () => {
    if (cart && cart.minNetTotal) {
      if (cart.netTotal >= cart.minNetTotal) {
        ecommerceCartsService.freezeCheck(cart.id, false)
          .then(response => {
            if (response && response.responseCode === 4105) {
              freezeCheck(response.data, true);
            } else if (response && response.responseCode === 0) {
              history.push("/checkout")
            }
          })
      } else {
        toaster.standard(`${t("cart.minNetTotal")}: ${cart && cart.minNetTotal}€`);
      }
    } else {
      history.push("/checkout")
    }
  }

  const checkFreeze = (path) => {
    let cartUpdate = window.location.pathname === "/app/cart";
    ecommerceCartsService.freezeCheck(cart.id, false, cartUpdate)
      .then(response => {
        if (response && response.responseCode === 4105) {
          freezeCheck(response.data, true);
        } else if (response && response.responseCode === 0) {
          history.push(path);
        }
      })
  }

  const goToAvailability = () => {
    if (cart) {
      checkFreeze("/availability")
    }
  }
  const submit = (data) => {
    ecommerceCartsService.freezeCheck(cart.id, false)
      .then(response => {
        if (response && response.responseCode === 4105) {
          freezeCheck(response.data, true);
        } else if (response && response.responseCode === 0) {
          data.timeSlotId = timeSlotId;
          data.invoice = invoice;
          data.billingAddressId = billingAddressId;
          data.enableProductsReplacement = enableProductsReplacement
          data.notes = notes;
          freeze(data, cart.id, paymentMethod, deliveryMode, setShowModal, setHtmlString);
        }
      })
    // overlay(true);
    // setTimeout(() => {
    //   overlay(false)
    // }, 3000);
  }

  const onLoad = () => {
    try {
      if (ref.current?.contentWindow?.location?.pathname.includes('confirm')) {
        setShowModal(false);
        history.push(`/confirm/${cart.code}`);
      } else {
        setShowModal(false);
        history.push(`/paymentfailed/${cart.code}`);
      }
    } catch (error) {
      return
    }
  }

  if (cart) {
    return (
      <div className="cart-container">
        <div className="page-grid mobile-cart-padding">
          <div className="grid-left-cart">
            <Modal show={showModal} centered={true} onHide={() => setShowModal(false)} dialogClassName="payment-modal">
              <iframe srcDoc={htmlString} className='modal-iframe' onLoadStart={onLoad} onLoad={onLoad} ref={ref} />
            </Modal>
            <CheckoutHeader />
            <Switch>
              <Route exact path='/cart' render={() =>
                <CurrentCart
                  cart={cart}
                  freezeCheck={freezeCheck}
                  abort={abort}
                  open={showFreezePopup}
                  closePopup={closeFreezePopup}
                  updatedItems={updatedItems}
                  removedItems={removedItems}
                  togglePopup={togglePopup}
                  choosedDay={choosedDay}
                  choosedTimeslot={choosedTimeslot}
                  termsAndCondition={termsAndCondition}
                  goToCheckout={goToCheckout}
                  onSubmit={handleSubmit(submit)}
                  paymentMethod={paymentMethod}
                  goToAvailability={goToAvailability}
                  user={user}
                />} />
              <Route exact path='/availability'
                render={() => <SelectTimeSlots
                  day={day}
                  setPaymentMethod={setPaymentMethod}
                  choosedDay={choosedDay}
                  timeSlotId={timeSlotId}
                  selectDay={selectDay}
                  selectTimeslot={selectTimeslot}
                  cart={cart}
                  freezeCheck={freezeCheck}
                  abort={abort}
                  open={showFreezePopup}
                  closePopup={closeFreezePopup}
                  updatedItems={updatedItems}
                  removedItems={removedItems}
                  togglePopup={togglePopup}
                  choosedTimeslot={choosedTimeslot}
                  termsAndCondition={termsAndCondition}
                  goToCheckout={goToCheckout}
                  onSubmit={handleSubmit(submit)}
                  paymentMethod={paymentMethod}
                  user={user}
                  deliveryMode={deliveryMode}
                  setDeliveryMode={setDeliveryMode}
                  deliveryAddress={deliveryAddress}
                  setDeliveryAddress={setDeliveryAddress}
                  deliveryZip={deliveryZip}
                  setDeliveryZip={setDeliveryZip}
                  deliveryCity={deliveryCity}
                  setDeliveryCity={setDeliveryCity}
                  deliveryCountry={deliveryCountry}
                  setDeliveryCountry={setDeliveryCountry}
                  notes={notes}
                  setNotes={setNotes}
                  checkFreeze={checkFreeze}
                />}
              />
              <Route exact path='/checkout' render={() =>
                <Checkout
                  day={day}
                  timeSlotId={timeSlotId}
                  invoice={invoice}
                  enableProductsReplacement={enableProductsReplacement}
                  billingAddressId={billingAddressId}
                  selectInvoice={selectInvoice}
                  enableReplacement={enableReplacement}
                  selectAddress={selectAddress}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                  setTermsAndCondition={setTermsAndCondition}
                  register={register}
                  errors={errors}
                  watch={watch}
                  cart={cart}
                  freezeCheck={freezeCheck}
                  abort={abort}
                  open={showFreezePopup}
                  closePopup={closeFreezePopup}
                  updatedItems={updatedItems}
                  removedItems={removedItems}
                  togglePopup={togglePopup}
                  choosedDay={choosedDay}
                  choosedTimeslot={choosedTimeslot}
                  termsAndCondition={termsAndCondition}
                  goToCheckout={goToCheckout}
                  onSubmit={handleSubmit(submit)}
                  user={user}
                  checkFreeze={checkFreeze}
                />
              } />

              <Route exact path='/summary' render={() =>
                <Summary
                  day={day}
                  timeSlotId={timeSlotId}
                  invoice={invoice}
                  enableProductsReplacement={enableProductsReplacement}
                  billingAddressId={billingAddressId}
                  selectInvoice={selectInvoice}
                  enableReplacement={enableReplacement}
                  selectAddress={selectAddress}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                  setTermsAndCondition={setTermsAndCondition}
                  register={register}
                  errors={errors}
                  watch={watch}
                  choosedDay={choosedDay}
                  choosedTimeslot={choosedTimeslot}
                  onSubmit={handleSubmit(submit)}
                  cart={cart}
                  freezeCheck={freezeCheck}
                  abort={abort}
                  open={showFreezePopup}
                  closePopup={closeFreezePopup}
                  updatedItems={updatedItems}
                  removedItems={removedItems}
                  togglePopup={togglePopup}
                  termsAndCondition={termsAndCondition}
                  goToCheckout={goToCheckout}
                  user={user}
                  checkFreeze={checkFreeze}
                  deliveryAddress={deliveryAddress}
                  deliveryZip={deliveryZip}
                  deliveryCity={deliveryCity}
                  deliveryCountry={deliveryCountry}
                />
              } />
            </Switch>
          </div>
          <div className="grid-right">
            <CartMenu
              cart={cart}
              freezeCheck={freezeCheck}
              abort={abort}
              open={showFreezePopup}
              closePopup={closeFreezePopup}
              updatedItems={updatedItems}
              removedItems={removedItems}
              togglePopup={togglePopup}
              choosedDay={choosedDay}
              choosedTimeslot={choosedTimeslot}
              termsAndCondition={termsAndCondition}
              goToCheckout={goToCheckout}
              onSubmit={handleSubmit(submit)}
              paymentMethod={paymentMethod}
              user={user}
              deliveryAddress={deliveryAddress}
              deliveryZip={deliveryZip}
              deliveryCity={deliveryCity}
              deliveryCountry={deliveryCountry}
            />
          </div>
        </div>
        <FreezeCheckPopup
          open={showFreezePopup}
          closePopup={closeFreezePopup}
          updatedItems={updatedItems}
          removedItems={removedItems}
        />
        <Disponibility
          open={showDisponibility}
          togglePopup={togglePopup}
          day={day}
          choosedDay={choosedDay}
          timeSlotId={timeSlotId}
          selectDay={selectDay}
          selectTimeslot={selectTimeslot}
          cart={cart}
        />
      </div>
    );
  }
  else {
    return (
      <DefaultSelectedStore />
    );
  }
}


const mapStateToProps = state => {
  return {
    cart: state.cart.cart,
    user: state.user.user,
    updatedItems: state.cart.updatedItems,
    removedItems: state.cart.removedItems,
    showFreezePopup: state.cart.showFreezePopup
  };
}

const mapDispatchToProps = dispatch => {
  return {
    current: () => dispatch(actionCreators.current()),
    freezeCheck: (data, showPopup) => dispatch(actionCreators.freezeCheck(data, showPopup)),
    closeFreezePopup: () => dispatch(actionCreators.closeFreezePopup()),
    abort: (cartId) => dispatch(actionCreators.abort(cartId)),
    freeze: (data, cartId, paymentMode, deliveryMode, setShowModal, setHtmlString) => dispatch(actionCreators.freeze(data, cartId, paymentMode, deliveryMode, setShowModal, setHtmlString)),
    overlay: (isActive) => dispatch(actionCreators.overlay(isActive)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);





