import * as actionTypes from "./actionTypes"

export const overlayAction = (isActive) => {
    return {
        type: actionTypes.OVERLAY,
        showOverlay: isActive
    }
}

export const overlay = (isActive) => {
    return dispatch => {
        dispatch(overlayAction(isActive))
    }
};