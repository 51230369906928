import React from "react"
import Placeholder from "../../../images/product-placeholder.png"
import { ReactComponent as LandingCart } from '../../../images/landing-cart-sticky.svg';
import { ReactComponent as SlistIcon } from "../../../images/slist.svg"
import { ReactComponent as CheckList } from "../../../images/check-list.svg"
import { connect } from "react-redux"
import { history } from "../../../App";
import { tags } from "../../../utils/renderTags";
import { withRouter } from "react-router-dom";
import config from "../../../config/config";
import { Translation } from "react-i18next";
import * as actionCreators from "../../../redux/store/actions/index"
import renderPrice from "../../../utils/renderPrice";
import NewCounter from "../../UI/NewCounter/NewCounter";
import SlistPopup from "../../../features/Smartlist/SlistPopup/SlistPopup";

import "./card-recipie.styles.scss";
class CardRecipie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: "",
            isAlreadyInList: false,
            showPopup: false
        }
    }

    componentDidMount() {
        this.setCartAmount();
        this.setIsInList();
    }

    componentDidUpdate(prevProps, prevState) {
        const { cart, currentSlist } = this.props;
        if (prevProps?.cart?.id !== cart?.id || prevProps.currentSlist?.items?.length !== currentSlist?.items?.length) {
            this.setCartAmount();
        }
        if (prevProps.currentSlist?.items?.length !== currentSlist?.items?.length) {
            this.setIsInList();
        }
    }

    setCartAmount = () => {
        const { cart, item } = this.props;
        var isAlreadyBooked = false;
        var itemAlreadyBooked = null;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyBooked = true;
                    itemAlreadyBooked = element;
                }
            });
        }
        if (isAlreadyBooked) {
            if (itemAlreadyBooked.purchaseUm === "PZ") {
                this.setState({ amount: itemAlreadyBooked.amount })
            } else {
                this.setState({ amount: itemAlreadyBooked.weight })
            }
        } else {
            this.setState({ amount: item?.minWeight ? item.minWeight : item?.umStep })
        }
    }

    setIsInList = () => {
        const { currentSlist, item } = this.props;
        var isAlreadyInList = false;
        if (currentSlist && currentSlist.items) {
            currentSlist.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyInList = true;
                }
            });
        }
        this.setState({ isAlreadyInList: isAlreadyInList })
    }

    incrementAmount = () => {
        const { item } = this.props;
        const { amount } = this.state;
        let newAmount = +amount + item.umStep;
        newAmount = newAmount?.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (item?.maxAmount) {
            if (newAmount <= item.maxAmount) {
                this.setState({ amount: newAmount })
            }
        } else {
            this.setState({ amount: newAmount })
        }
    }

    decrementAmount = () => {
        const { item } = this.props;
        const { amount } = this.state;
        let newAmount = +amount - item?.umStep;
        newAmount = newAmount?.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (item.minWeight) {
            if (amount > item.minWeight) {
                this.setState({ amount: newAmount })
            }
        } else if (amount > item?.umStep) {
            this.setState({ amount: newAmount })
        }
    }

    changeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    renderPrices = (item) => {
        if (item.price !== item.netPrice) {
            return (
                <nobr>
                    <span className=" strike">
                        {`${renderPrice(item.price?.toFixed(2))} \u20AC`}
                    </span>
                </nobr>
            );
        } else {
            return null
        }
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                return (
                    <Translation>
                        {t =>
                            <div className="card-product-promo">
                                <span>{t("cardProduct.offer")}</span>
                            </div>
                        }
                    </Translation>
                );
            } else {
                return null;
            }
        }
    }

    // isAlreadyBooked = (item) => {
    //     var isAlreadyBooked = false;
    //     var itemToAdd = null;
    //     if (this.props.cart.items) {
    //         const items = this.props.cart.items;
    //         for (var i = 0; i < items.length; i++) {
    //             if (items[i].ref === item.ref) {
    //                 isAlreadyBooked = true;
    //                 itemToAdd = items[i];
    //                 break;
    //             }
    //         }
    //     } else {
    //         if (item.purchaseUm === "KG") {
    //             history.push({
    //                 pathname: `/product/${item.barcode}`,
    //                 state: { product: item }
    //             })
    //         } else {
    //             this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
    //         }
    //         return;
    //     }

    //     if (isAlreadyBooked) {
    //         const addItem = { ...itemToAdd, available: true }
    //         history.push({
    //             pathname: `/product/${item.barcode}`,
    //             state: { product: addItem, isDetail: true }
    //         })
    //     } else {
    //         if (item.purchaseUm === "KG") {
    //             history.push({
    //                 pathname: `/product/${item.barcode}`,
    //                 state: { product: item }
    //             })
    //         } else {
    //             this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
    //         }
    //     }
    // }

    goToDetails = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        const { cart, loadedProducts, from, savedScroll, page, totalPages } = this.props;
        if (cart && cart.items) {
            const items = cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: item, loadedProducts, from, savedScroll, page, totalPages }
            })
            return;
        }

        if (isAlreadyBooked) {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: {
                    product: itemToAdd,
                    isDetail: true,
                    loadedProducts,
                    from,
                    savedScroll,
                    page,
                    totalPages
                }
            })
        } else {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: item, loadedProducts, from, savedScroll, page, totalPages }
            })
        }
    }

    closeSlistPopup = () => this.setState({ showPopup: false })

    render() {
        const { item, user, cart, setProductToAdd, showLogin, open } = this.props;
        const { isAlreadyInList, showPopup, amount } = this.state;
        return (
            <Translation>
                {t =>
                    <>
                        <div className="card-recipie" style={{ ...this.props.style }}>
                            <div className={"card-recipie-container"}>
                                <div className={"card-recipie-image"}>
                                    <div className="link" onClick={() => this.goToDetails(item)}>
                                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                            onError={(e) => {
                                                e.target.src = Placeholder
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card-product-promo-container">
                                {this.renderPromo(item)}
                            </div> */}
                            <div className="card-recipie-details">
                                <span className="link" onClick={() => this.goToDetails(item)}>
                                    {item.description}
                                </span>
                            </div>
                            <div className="card-recipie-prices">
                                {item.tags ?
                                    <div className="card-recipie-tags">
                                        {item?.tags && item.tags.split(",").map(tag => (
                                            <span key={tag}>{tags.renderTags(tag)}</span>
                                        ))}
                                    </div>
                                    :
                                    <div className="card-recipie-tags">
                                        &nbsp;
                                    </div>
                                }
                                <div>
                                    {this.renderPrices(item)}
                                    <nobr><span className="netTotal">{`${renderPrice(item.netPrice?.toFixed(2))} \u20AC`}</span></nobr>
                                </div>
                            </div>
                            <div className="card-recipie-um">
                                {item.pricePerUm && item.umPerUm &&
                                    `${renderPrice(item.pricePerUm?.toFixed(2))} \u20AC / ${item.umPerUm} `
                                }
                            </div>
                            <div className="card-recipie-buttons">
                                {/* <NewCounter
                                    value={this.state.amount}
                                    um={item.purchaseUm}
                                    increment={this.incrementAmount}
                                    decrement={this.decrementAmount}
                                    writable={true}
                                    changeAmount={this.changeAmount}
                                    pattern="^\d(\.\d)*$"
                                /> */}
                                {config.ENABLED_SLIST &&
                                    <div className="card-recipie-slist">
                                        {isAlreadyInList ?
                                            <div className="card-recipie-slist is-already-in-list">
                                                {/* <button className="is-already-in-list outline"> */}
                                                <CheckList />
                                            </div>
                                            :
                                            <div className="card-recipie-slist" onClick={() => {
                                                if (user) {
                                                    // currentSlist && this.props.itemCreate(currentSlist.id, item.barcode, 1)
                                                    this.setState({ showPopup: true })
                                                } else {
                                                    showLogin();
                                                }
                                            }}>
                                                <SlistIcon />
                                            </div>
                                        }
                                    </div>
                                }
                                <button className="cart outline"
                                    onClick={() => {
                                        if (user && cart) {
                                            // this.isAlreadyBooked(item)
                                            this.props.store(this.props?.cart?.id, item.barcode, this.state.amount, item.purchaseUm);
                                        }
                                        if (user && !cart) {
                                            setProductToAdd(item, this.state.amount);
                                            open("IN_STORE", "686491", "")
                                            //this.props.history.push("/cart")
                                        }
                                        if (!user) {
                                            setProductToAdd(item, this.state.amount);
                                            showLogin();
                                        }
                                    }}>
                                    {/* <LandingCart /> */}
                                    <span>{t("buy")}</span>
                                </button>
                            </div>
                            <div className="card-recipie-buttons-prodotto">
                                {config.ENABLED_SLIST &&
                                    <div className="card-recipie-slist-prodotto">
                                        {isAlreadyInList ?
                                            <div className="card-recipie-slist is-already-in-list">
                                                {/* <button className="is-already-in-list outline"> */}
                                                <CheckList />
                                            </div>
                                            :
                                            <div className="card-recipie-slist-prodotto" onClick={() => {
                                                if (user) {
                                                    // currentSlist && this.props.itemCreate(currentSlist.id, item.barcode, 1)
                                                    this.setState({ showPopup: true })
                                                } else {
                                                    showLogin();
                                                }
                                            }}>
                                                {/* <SlistIcon /> */}
                                            </div>
                                        }
                                    </div>
                                }
                                <button className="cart outline" onClick={() => history.push("/producter")}>
                                    <span>{t("Scheda Prodotto")}</span>
                                </button>
                            </div>
                        </div>
                        {showPopup &&
                            <SlistPopup
                                show={showPopup}
                                barcode={item.barcode}
                                amount={item.purchaseUm === "PZ" ? amount : 1}
                                onClose={this.closeSlistPopup}
                            />
                        }
                    </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
        showLogin: () => dispatch(actionCreators.showLogin(true)),
        open: (deliveryMode, storeId, addressId) => dispatch(actionCreators.open(deliveryMode, storeId, addressId)),

    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardRecipie));