import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import Days from "../Days/Days";
import disponibility from "../Days/Service";
import timeslots from "../Timeslots/Service";
import Timeslots from "../Timeslots/Timeslots";
import selectDeliveryAddresss from "./Service"
import "../SelectTimeSlots/select-timeslots.styles.scss";
import { useDispatch } from "react-redux";
import * as actionCreators from "../../../redux/store/actions/index"


const SelectStores = ({
    cartId,
    setDeliveryMode,
    deliveryMode,
    day,
    selectDay,
    cart,
    setDeliveryAddress,
    setDeliveryZip,
    setDeliveryCity,
    setDeliveryCountry
}) => {
    const dispatch = useDispatch();
    const [deliveryAddresses, setDeliveryAddresses] = useState([])

    useEffect(() => {
        let deliveryMode = cart?.deliveryMode;
        selectDeliveryAddresss(cartId)
            .then(response => {
                if (response.data.length > 0) {
                    let reversed = response.data.reverse();
                    setDeliveryAddresses(reversed)
                    reversed.map((item) => {
                        if (cart && deliveryMode && deliveryMode !== "") {
                            if (deliveryMode === "IN_STORE") {
                                if (item.deliveryMode === "IN_STORE") {
                                    setDeliveryMode("IN_STORE");
                                    selectDay("");
                                    setDeliveryAddress(item.address);
                                    setDeliveryZip(item.zip);
                                    setDeliveryCity(item.city);
                                    setDeliveryCountry(item.county)
                                }
                            } else {
                                if (item.deliveryMode === "DRIVE") {
                                    setDeliveryMode("DRIVE");
                                    selectDay("");
                                    setDeliveryAddress(item.address);
                                    setDeliveryZip(item.zip);
                                    setDeliveryCity(item.city);
                                    setDeliveryCountry(item.county)
                                }
                            }
                        } else {
                            if (item.deliveryMode === "IN_STORE") {
                                setDeliveryMode("IN_STORE");
                                selectDay("");
                                setDeliveryAddress(item.address);
                                setDeliveryZip(item.zip);
                                setDeliveryCity(item.city);
                                setDeliveryCountry(item.county)
                            }
                        }
                    })
                }
            }).catch((error) => {
                console.log(error);
            })
    }, [cartId])

    const renderStores = (deliveryAddress, deliveryModes) => {
        switch (deliveryModes) {
            case "DRIVE":
                return (
                    <div className="d-flex">
                        <div className="radio-button mr-3">
                            <div className="custom control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="DRIVE"
                                    name="DRIVE"
                                    value="DRIVE"
                                    checked={deliveryMode === "DRIVE"}
                                    onChange={() => { setDeliveryMode("DRIVE"); selectDay(""); setDeliveryAddress(deliveryAddress.address); setDeliveryZip(deliveryAddress.zip); setDeliveryCity(deliveryAddress.city); setDeliveryCountry(deliveryAddress.county); dispatch(actionCreators.changeDeliveryMode("DRIVE")) }}
                                />
                                <label className={`custom-control-label ${deliveryMode && deliveryMode.includes("DRIVE") ? "store-selected" : "store-deselected"}`}
                                    htmlFor="DRIVE">
                                    <h5 style={{ textTransform: "uppercase" }}>{cart.storeDescription}</h5>
                                    <span className="store-address">{deliveryAddress.address}<br />{deliveryAddress.zip} {deliveryAddress.city} {deliveryAddress.county}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                );
            case "IN_STORE":
                return (
                    <div className="d-flex">
                        <div className="radio-button mr-3">
                            <div className="custom control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="IN_STORE"
                                    name="IN_STORE"
                                    value="IN_STORE"
                                    checked={deliveryMode === "IN_STORE"}
                                    onChange={() => { setDeliveryMode("IN_STORE"); selectDay(""); setDeliveryAddress(deliveryAddress.address); setDeliveryZip(deliveryAddress.zip); setDeliveryCity(deliveryAddress.city); setDeliveryCountry(deliveryAddress.county); dispatch(actionCreators.changeDeliveryMode("IN_STORE")) }}
                                />
                                <label className={`custom-control-label ${deliveryMode && deliveryMode.includes("IN_STORE") ? "store-selected" : "store-deselected"}`}
                                    htmlFor="IN_STORE">
                                    <h5 style={{ textTransform: "uppercase" }}>{cart.storeDescription}</h5>
                                    <span className="store-address">{deliveryAddress.address}<br />{deliveryAddress.zip} {deliveryAddress.city} {deliveryAddress.county}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                );

            default: return null;
        }
    }


    return (
        <div>
            <div className="store-heading-div">
                <span className="store-heading">PUNTO DI RITIRO</span>
            </div>
            <div className="store-container">
                {deliveryAddresses && deliveryAddresses.map(deliveryAddress => (
                    <div style={{ marginBottom: "15px" }} key={deliveryAddress}>
                        {renderStores(deliveryAddress, deliveryAddress.deliveryMode)}
                    </div>
                ))}
            </div>
        </div>
    );
}
export default SelectStores