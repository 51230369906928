import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import landingBanners from "./Service"

export default class HomeCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            banners: []
        };
    }

    componentDidMount() {
        landingBanners().then(response => {
            this.setState({
                isLoaded: true,
                banners: response.data
            })
        });
    }

    render() {
        const { isLoaded, banners } = this.state;
        return (isLoaded ?
            <Carousel className="landing-carousel" controls={banners.length > 1} indicators={banners.length > 1}>
                {banners && banners.map((item, index) => (
                    <Carousel.Item key={item.id}>
                        <div style={{ backgroundImage: `url(${item.imageUrl})` }} alt=""></div>
                    </Carousel.Item>
                ))}
            </Carousel>
            : null
        );
    }

}