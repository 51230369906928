import React from "react";
import Categories from "../Categories/Categories";
import StickyBox from "react-sticky-box";
import { history } from "../../App";
//import HomeCarousel from "../Home/HomeCarousel/HomeCarousel"
// import HomeTags from "./HomeTags/HomeTags";
//import FeaturedProducts from "./FeaturedProducts/FeaturedProducts";
import config from "../../config/config";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import * as actionCreators from "../../redux/store/actions/index";
//import LocalProducts from "../Home/LocalProducts/LocalProducts";
import LocalRecipies from "./LocalRecipies/LocalRecipies";
import FeaturedRecipies from "./FeaturedRecipies/FeaturedRecipies";
import Header from "../../components/MainComponent/Header/ResponsiveHeader";

import HeaderTags from "../../components/MainComponent/Header/HeaderTags/HeaderTags";
import SearchRecipie from "./SearchRecipies/SearchRecipie";
import MainTemplate from "../../components/MainComponent/Template/MainTemplate";
import ResponsiveHeader from "../../components/MainComponent/Header/ResponsiveHeader";
import NewHeader from "../../components/MainComponent/Header/NewHeader/NewHeader";
import RecipieTags from "./RecipieTags/RecipieTags";
import RecipieCategories from "../RecipieCategories/RecipieCategories";
import CustomBreadcrumb from "../../components/UI/Breadcrumb/CustomBreadcrumb";
//import HomeBrands from "./HomeBrands/HomeBrands";
//import HomeSuggestions from "./HomeSuggestions/HomeSuggestions";
//import HomeHolidays from "./HomeHolidays/HomeHolidays";

class Recipies extends React.Component {
    componentDidMount() {
        if (this.props.user) {
            this.props.current();
            if (config.ENABLED_SLIST) {
                this.props.getSlists();
            }
        }
    }

    render() {
        return (
            <Translation>
                {t =>
                    <div style={{ padding: "unset" }} className="page-grid mobile-view-search">
                        <StickyBox className="grid-left" offsettop={0} offsetbottom={0}>
                            <div className="bread-crumb">
                                <CustomBreadcrumb
                                    level1={{
                                        path: "/recipie",
                                        label: "Le nostre ricette",
                                        active: true
                                    }}
                                />
                            </div>
                            <RecipieCategories />
                            <button className="back-to-home" onClick={() => history.push('/')}>TORNA ALLA HOME</button>
                        </StickyBox>
                        <div style={{ paddingTop: "35px" }} className="grid-right">
                            <LocalRecipies />
                            {/* <RecipieTags />  */}
                            <div style={{ padding: "unset" }} className="page-grid mobile-view-search">
                                <StickyBox className="grid-left" offsettop={0} offsetbottom={0}>
                                    {/* <Categories /> */}
                                </StickyBox>
                                <div style={{ paddingTop: "20px" }} className="grid-right">
                                    {/* <SearchRecipie />
                                    <FeaturedRecipies /> */}
                                </div>

                            </div>
                            {/*   <LocalRecipies />
                            <FeaturedRecipies /> */}


                            {/* <SearchRecipie/> */}
                            {/* <HeaderTags/> */}

                            {/* <HomeCarousel /> */}
                            {/* <HomeBrands /> */}
                            {/* <HomeSuggestions /> */}
                            {/* <HomeTags /> */}
                            {/* <FeaturedProducts /> */}
                            {/* <HomeHolidays /> */}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        current: () => dispatch(actionCreators.current()),
        getSlists: () => dispatch(actionCreators.getSlists()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Recipies);
