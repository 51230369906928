import config from "../../config/config";
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";
import axios from 'axios'
import { authHeader } from "../../utils/authHeader";

export const productsService = { info, fetchByRef }

function info(barcode) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/products/barcode/${barcode}/info`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchByRef(ref, storeId) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/products/ref/${ref}?store=${storeId}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}