import React from 'react';
import CardProduct from '../CardProduct/CardProduct';
import './insieme-card.styles.scss';
import Bollo from '../../../images/Provali_insieme_freccia.png'
import Header from '../../../images/Provali_insieme_banner+fondo.png'

const InsiemeCard = ({ left, right }) => {

    return (
        <div className='insieme-container'>
            <div className='image-container'>
                <img src={Header} alt='' />
            </div>
            <div className='card-container'>
                <CardProduct item={left} noReplace={true}/>
                <hr />
                <img src={Bollo} alt='' className='circle-image'/>
                <CardProduct item={right} noReplace={true}/>
            </div>
        </div>
    )
}

export default InsiemeCard