import React, { useState } from "react";
import SmartlistItem from "./SmartlistItem/SmartlistItem";
import PopupInput from "../../../components/UI/Popup/PopupInput";
import Popup from "../../../components/UI/Popup/Popup";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux"
import * as actionCreators from "../../../redux/store/actions"

import "./current-smartlist.styles.scss";

const CurrentSmartlist = ({ currentSlist, slist, setActiveKey }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [state, setState] = useState({
        createTitle: "",
        selectTitle: slist ? slist.title : "",
        copyTitle: slist ? `Copia di ${slist.title}` : ""
    });

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }));
    }

    const renderAbort = () => (
        <Popup
            action={() => {
                dispatch(actionCreators.deleteSlist(slist.id))
                setActiveKey(null);
            }}
            title={t("smartlist.delete")}
            button={t("delete")}
            content={t("smartlist.delete.question")}
            trigger={
                <button className="secondary-cta">
                    <div className="text">
                        <span>{t("delete")}</span>
                    </div>
                </button>
            }
        />
    );

    const renderCopy = () => (
        <PopupInput
            action={() => {
                dispatch(actionCreators.copySlist(slist.id, state.copyTitle))
                setActiveKey(null);
            }}
            title={t("smartlist.copy")}
            button={t("copy")}
            onChange={onChange}
            value={state.copyTitle}
            name={"copyTitle"}
            trigger={
                <button className="secondary-cta">
                    <div className="text">
                        <span>{t("copy")}</span>
                    </div>
                </button>
            }
        />
    );

    const renderRename = () => (
        <PopupInput
            action={() => {
                dispatch(actionCreators.renameSlist(slist.id, state.selectTitle))
                setActiveKey(null);
            }}
            title={t("smartlist.rename")}
            button={t("rename")}
            onChange={onChange}
            value={state.selectTitle}
            name={"selectTitle"}
            trigger={
                <button className="secondary-cta">
                    <span className="">{t("rename")}</span>
                </button>
            }
        />
    );


    const renderSlistToCart = () => (
        <Popup
            action={() => {
                dispatch(actionCreators.slistToCart(slist.id))
                setActiveKey(null);
            }}
            title={t("smartlist.slistToCart")}
            button={t("add")}
            content={t("smartlist.slistToCart.question")}
            trigger={
                <button className="cta"
                    disabled={slist?.items?.length <= 0}>
                    <span>{t("smartlist.slistToCart.title")}</span>
                </button>
            }
        />
    );

    if (slist) {
        return (
            <div className="current-smartlist">

                {/* <div className="d-none d-sm-block">
                <Row className="container-fluid cart-heading mb-4">
                    <Col sm={1}>
                        <span>{t("products")}</span>
                    </Col>
                    <Col sm={5}>
                        <span className="cart-product-description">
                            {t("description")}
                        </span>
                    </Col>
                    <Col sm={2}>
                        <span>{t("amount")}</span>
                    </Col>
                    <Col sm={2}>
                        <span>{t("delete")}</span>
                    </Col>
                </Row>
            </div> */}

                {currentSlist.items && currentSlist.items.map(item => (
                    <SmartlistItem
                        key={item.id}
                        item={item}
                        currentSlist={currentSlist}
                        itemDelete={(listId, itemId) => dispatch(actionCreators.itemDelete(listId, itemId))}
                        itemUpdate={(listId, itemId, amount) => dispatch(actionCreators.itemUpdate(listId, itemId, amount))}
                    />
                ))}
                <hr />
                <div className="current-smartlist-actions">
                    {renderRename()}
                    {renderCopy()}
                    {renderAbort()}
                    {renderSlistToCart()}
                </div>
            </div>
        );
    } else return null;
}

export default CurrentSmartlist;


