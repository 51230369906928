import React, { useEffect, useState } from 'react';
import { history } from "../../../../App";
import { useForm } from 'react-hook-form';
import { Translation } from 'react-i18next';
import toaster from "../../../../utils/toaster";
import contactUs from "./ContactService";
import ValidateInput from '../../../../components/UI/Input/ValidateInput';
import "./contactus.styles.scss";
import * as actionCreators from '../../../../redux/store/actions/index';
import { useDispatch, useSelector } from "react-redux";
import i18n from '../../../../i18n';
import { ReactComponent as Alert } from "../../../../images/card-alert.svg"
import { ReactComponent as Faq } from "../../../../images/faq.svg"
import { ReactComponent as HeadPhone } from "../../../../images/headphone.svg"
import { authenticationService } from '../../../../services/basis/AuthenticationService';
import ReCAPTCHA from "react-google-recaptcha";
import { Row, Col } from "react-bootstrap"

const ContactUs = () => {
    const dispatch = useDispatch();
    const [birthDate, setBirthdate] = useState();
    const [password, setPassword] = useState();
    const [privacy, setPrivacy] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [privacyReq, setPrivacyReq] = useState(false);
    const [partnerReq, setPartnerReq] = useState(false);
    const [partner, setPartner] = useState(null);
    const [subjectReq, setSubjectReq] = useState(false);
    const user = useSelector(state => state.user.user)
    const recaptchaRef = React.createRef();
    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = (values) => {
        const recaptcha = recaptchaRef.current.getValue();
        let subject = document.getElementById("subject").value;
        let isEnabledReplacement;
        let isDisableReplacement;
        if (!user) {
            isEnabledReplacement = document.getElementById("isEnabledReplacement").checked;
            isDisableReplacement = document.getElementById("isDisableReplacement").checked;
        }
        if (privacy) {
            setPrivacyReq(false);
        } else {
            setPrivacyReq(true);
        }
        if (subject !== "") {
            setSubjectReq(false);
        } else {
            setSubjectReq(true);
        }
        if (isEnabledReplacement || isDisableReplacement) {
            setPartnerReq(false);
        } else {
            setPartnerReq(true);
        }
        if (!privacyReq, !subjectReq, !partnerReq) {
            if (user) {
                values.name = user.firstName;
                values.surname = user.lastName;
                values.email = user.email;
                contactUs(values, subject, true, recaptcha).then(response => history.push("/"))
            } else {
                contactUs(values, subject, partnerReq, recaptcha).then(response => history.push("/"))
            }
        }
    }

    const handlePrivacy = (choose) => {
        setPrivacy(choose);
        setPrivacyReq(!choose);
    }

    const handlePartner = (choose) => {
        setPartnerReq(choose);
    }

    return (
        <Translation>
            {t =>
                <div className='contactus-container'>
                    <div className='contactus-container-center'>
                        {!user &&
                            <div className='contactus-container-title'>Benvenuto/a nel Servizio Clienti Click&Coop</div>
                        }
                        {user &&
                            <div className='contactus-container-title'>Benvenuto {user.firstName} nel Servizio Clienti Click&Coop</div>
                        }
                        <hr style={{ width: "100%", border: "1px solid #bababa", margin: "5px 0" }} />
                        <div className='contactus-container-label'> Click&Coop mette a disposizione un servizio clienti dedicato nel rispondere alle richieste di assistenza, di seguito potete trovare la risposta alle vostre
                            domande e i nostri dati di contatto.  Compila il modulo sottostante per inviarci direttamente la tua richiesta.</div>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}
                        >
                            <div className='contactus-container-form'>
                                {!user &&
                                    <>
                                        <Row className='row'>
                                            <Col md={6}>
                                                <ValidateInput
                                                    name="name"
                                                    label="firstName"
                                                    type="text"
                                                    placeholder="name"
                                                    watch={watch("name")}
                                                    errors={errors.name}
                                                    register={register({
                                                        required: t("required.field")
                                                    })}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <ValidateInput
                                                    name="surname"
                                                    label="lastName"
                                                    type="text"
                                                    placeholder="signup.lastName"
                                                    watch={watch("surname")}
                                                    errors={errors.surname}
                                                    register={register({
                                                        required: t("required.field")
                                                    })}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col md={6}>
                                                <h5 style={{ marginBottom: "10px" }}>Sei socio</h5>
                                                <div className="d-flex" style={{ minWidth: "350px" }}>
                                                    <div className="radio-button mr-3">
                                                        <div className="custom-radio">
                                                            <input type="radio" className="custom-control-input"
                                                                id="isEnabledReplacement"
                                                                name="isEnabledReplacement"
                                                                value={true}
                                                                checked={partner === true}
                                                                onChange={() => setPartner(true)}
                                                            />
                                                            <label className="custom-control-label" htmlFor="isEnabledReplacement">
                                                                <h5>{t("yes")}</h5>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="radio-button">
                                                        <div className="custom-radio">
                                                            <input type="radio" className="custom-control-input"
                                                                id="isDisableReplacement"
                                                                name="isDisableReplacement"
                                                                value={false}
                                                                checked={partner === false}
                                                                onChange={() => setPartner(false)}
                                                            />
                                                            <label className="custom-control-label" htmlFor="isDisableReplacement">
                                                                <h5>{t("no")}</h5>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {partnerReq &&
                                                    <div className="invalid-message">
                                                        <Alert />
                                                        {t("required.field")}
                                                    </div>
                                                }
                                            </Col>
                                            <Col md={6}>
                                                <ValidateInput
                                                    name="email"
                                                    label="email"
                                                    type="email"
                                                    placeholder="storeEmail"
                                                    watch={watch("email")}
                                                    errors={errors.email}
                                                    register={register({
                                                        required: t("required.field"),
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message: t("error.invalidEmail")
                                                        }
                                                    })}
                                                />
                                            </Col>
                                        </Row>
                                    </>}
                                <div style={{ height: "50px", width: "350px" }}>
                                    <h5 style={{ marginBottom: "10px" }}>Tipologia di richiesta</h5>
                                    <select style={{ height: "100%", width: "100%" }} name="subject" id="subject">
                                        <option value="">
                                            SELEZIONARE UNA VOCE
                                        </option>
                                        <option value="PROFILO e ACCOUNT">
                                            PROFILO e ACCOUNT
                                        </option>
                                        <option value="SPESA e ORDINI">
                                            SPESA e ORDINI
                                        </option>
                                        <option value="PRODOTTI">
                                            PRODOTTI
                                        </option>
                                        <option value="CARTE e PAGAMENTO">
                                            CARTE e PAGAMENTO
                                        </option>
                                    </select>
                                    {subjectReq &&
                                        <div className="invalid-message">
                                            <Alert />
                                            {t("required.field")}
                                        </div>
                                    }
                                </div>
                                <textarea
                                    name="notes"
                                    className="contact-textarea"
                                    rows="4"
                                    placeholder="Scrivi il tuo messaggio..."
                                    ref={register}
                                />
                            </div>
                            <div className="custom-checkbox">
                                <input className="custom-control-input" type="checkbox"
                                    id="privacy" name="privacy"
                                    onChange={(e) => handlePrivacy(e.target.checked)}
                                />
                                <label className="custom-control-label" style={{ fontSize: "12px", fontWeight: "bold", color: "#272f47" }} htmlFor="privacy">
                                    Letta l’informativa privacy, preso il consenso al trattamento dei miei dati personali limitatamente a quanto necessario per dar corso alla presente richiesta.
                                </label>
                                {privacyReq &&
                                    <div className="invalid-message">
                                        <Alert />
                                        {t("required.field")}
                                    </div>
                                }
                            </div>
                            <div className='contact-privacy-link'>Ti ricordiamo che puoi visionare in ogni momento l'informativa sulla Privacy tramite il<button style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#272f47" }} onClick={() => history.push("/privacy")}><u>link</u></button>oppure cliccando Privacy Policy ai piedi della pagina.</div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA}
                                    ref={recaptchaRef}
                                    onChange={() => setDisableButton(false)}
                                    onExpired={() => setDisableButton(true)}
                                />
                            </div>
                            <div className='contactus-container-buttons'>
                                <button style={{ width: "155px", border: "1px solid #fff", backgroundColor: disableButton ? "#272f4785" : "#272f47" }} className="mt-1 contact-confirm-button" type="submit" disabled={disableButton}>
                                    <span>INVIA</span>
                                </button>
                            </div>
                        </form>
                        <div className="contact-bottom-buttons">
                            <button style={{ backgroundColor: " #272f47", borderRadius: "22.4px", color: "#fff", height: "148px", width: "200px", fontSize: "15px", fontFamily: "Montserrat-Bold", display: "grid", justifyContent: "center", alignItems: "center", border: "1px solid rgb(39, 47, 71)" }} onClick={() => history.push("/faq")}>
                                <div style={{ marginTop: "20px" }}>
                                    <Faq />
                                </div>
                                Domande Frequenti
                            </button>
                            <button className='customer-support-website'>
                                <div style={{ marginTop: "15px" }}>
                                    <HeadPhone />
                                </div>
                                <span>0461 362600</span>
                                <span> Lun-sab 8.00-20.30<br />Dom 8.30-20.00</span>
                            </button>
                            <button className='customer-support-mobile'>
                                <div style={{ marginTop: "15px" }}>
                                    <HeadPhone />
                                </div>
                                <a href="tel:+0461 362600"><span style={{ color: "#fff" }}>0461 362600</span></a>
                                <span> Lun-sab 8.00-20.30<br />Dom 8.30-20.00</span>
                            </button>
                        </div>
                    </div>
                </div>
            }

        </Translation>
    )
}

export default ContactUs;