import React, { useState, useRef } from "react";
import OrderItem from "./OrderItem/OrderItem";
import i18n from "../../../i18n";
import Popup from "../../../components/UI/Popup/Popup";
import renderPrice from "../../../utils/renderPrice";
import { Translation } from 'react-i18next';
import config from "../../../config/config";
import "./order.styles.scss";
import { Col, Row } from "react-bootstrap";
import dayOfWeek from "../../../utils/dateUtils";
import { paymentsService } from "../../Payments/Service";
import { Modal } from "react-bootstrap";

const Order = ({ order, items, abort, copy, modify, cart, defaultSlot }) => {
    const ref = useRef();
    const [showModal, setShowModal] = useState(false);
    const [htmlString, setHtmlString] = useState("");

    const renderCartTotal = (item) => {
        let result;
        if (item && item.cart) {
            if (item.cart.netTotal && item.cart.total &&
                item.cart.netTotal !== item.cart.total) {
                result =
                    <>
                        <nobr>
                            <span className="text-strike">
                                {`${renderPrice(item.cart.total?.toFixed(2))} \u20AC`}
                            </span>
                        </nobr>
                        <nobr>
                            <span className="ml-1">
                                {`${renderPrice(item.cart.netTotal?.toFixed(2))} \u20AC`}
                            </span>
                        </nobr>
                    </>
            } else {
                result = `${renderPrice(item.cart.netTotal?.toFixed(2))} \u20AC`
            }
            return result;
        }
    }

    const renderOperatorCartTotal = (item) => {
        let result;
        if (item && item.operatorCart) {
            if (item.operatorCart.netTotal && item.operatorCart.total &&
                item.operatorCart.netTotal !== item.operatorCart.total) {
                result =
                    <>
                        <nobr><span className="text-strike">
                            {`${renderPrice(item.operatorCart.total?.toFixed(2))} \u20AC`}
                        </span></nobr>
                        <nobr><span className="ml-1">
                            {`${renderPrice(item.operatorCart.netTotal?.toFixed(2))} \u20AC`}
                        </span></nobr>
                    </>
            } else {
                result = `${renderPrice(item.operatorCart.netTotal?.toFixed(2))} \u20AC`
            }
            return result;
        }
    }

    const renderTotalToPay = (item) => {
        let result;
        if (item) {
            if (item.operatorCart && item.operatorCart.totalToPay) {
                result = `${renderPrice(item.operatorCart.totalToPay?.toFixed(2))} \u20AC`
            } else {
                result = `${renderPrice(item.cart.totalToPay?.toFixed(2))} \u20AC`
            }
            return result;
        }
    }

    const renderAbortOrder = (order, abort, t) => {
        let result;
        (order.status === "TO_BE_PREPARED" || order.status === "PAYMENT_PENDING") ?
            result = (
                <Translation>
                    {t =>
                        <Popup
                            action={() => abort(order.id)}
                            title={i18n.t("order.abort")}
                            content={i18n.t("order.abort.question")}
                            button={i18n.t("delete")}
                            trigger={
                                <button className="secondary-cta secondary-cta-abort justify-content-center">
                                    <span>{i18n.t("order.abortOrder")}</span>
                                </button>
                            }
                        />
                    }
                </Translation>
            ) : result = null;

        return result;
    }

    const renderCopyOrder = (order, copy, t) => {
        return (
            <Popup
                action={() => copy(order.id)}
                title={i18n.t("order.copy")}
                content={i18n.t("order.copy.question")}
                button={i18n.t("copy")}
                trigger={
                    <button className="cta">
                        <span>{i18n.t("order.copyOrder")}</span>
                    </button>
                }
            />
        );
    }
    const renderModifyOrder = (order, modify, t, terminal) => {
        const availableDay = defaultSlot && dayOfWeek(defaultSlot.weekDay)
        return (
            <Popup
                action={() => modify(order.id, terminal)}
                title="Attenzione"
                content={
                    <>
                        <Row>
                            <Col>
                                <label>La modifica dell'ordine non garantirà di mantenere la fascia oraria e il giorno selezionato</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Dopo aver apportato le modifiche all'ordine verrà quindi richiesto di confermare data e orario di consegna</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Punto di ritiro:&nbsp;
                                    <span style={{ fontFamily: 'Montserrat-Bold' }}>
                                        {cart && cart.storeDescription ?
                                            cart.storeDescription
                                            : ""}
                                    </span>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Prima disponibilità:&nbsp;
                                    {defaultSlot &&
                                        <>
                                            <span className="mr-2" style={{ fontFamily: 'Montserrat-Bold' }}> {t(`daysOfWeek.${availableDay.name}`)}</span>
                                            <span className="mr-1" style={{ fontFamily: 'Montserrat-Bold' }}>{availableDay.dayNumber} {t(`months.${availableDay.month}`)}</span>
                                            <span style={{ fontFamily: 'Montserrat-Bold' }}> ({defaultSlot.validFrom} - {defaultSlot.validTo})</span>
                                        </>
                                    }
                                </label>
                            </Col>
                        </Row>
                    </>
                }
                button="AVANTI"
                trigger={
                    <button className="cta">
                        <span>{i18n.t("update")}</span>
                    </button>
                }
            />
        );
    }

    const payOrder = (id) => {
        // paymentsService.payOrder(id)
        //     .then(response => {
        //         console.log(response);
        //         const url = window.URL.createObjectURL(new Blob([response], {
        //             type: 'text/html'
        //         }));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         document.body.appendChild(link);
        //         link.click();
        //         link.remove();
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })
        paymentsService.nonce(id)
            .then(response => {
                paymentsService.threeDS(response)
                    .then(response => {
                        console.log(response);
                        if (response.esito && response.esito === "OK") {
                            setHtmlString(response.html.replace(/<script/g, "<script nonce='GTMCOOPSAIT'"));
                            setShowModal(true);
                        } else {
                            history.push({
                                pathname: `/paymentfailed/${id}`,
                                state: {
                                    orders: response.data,
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
            .catch(error => {
                console.log(error);
            })
    }

    const renderPayment = (order) => {
        let result;
        order.status === "PAYMENT_PENDING" ?
            result = (
                <button className="cta" onClick={() => payOrder(order.code)}>
                    <span>{i18n.t("order.pay")}</span>
                </button>
            ) : result = null;

        return result;
    }

    const onLoad = () => {
        try {
            if (ref.current?.contentWindow?.location?.pathname.includes('confirm')) {
                setShowModal(false);
                history.push(`/confirm/${cart.code}`);
            } else {
                setShowModal(false);
                history.push(`/paymentfailed/${cart.code}`);
            }
        } catch (error) {
            return
        }
    }

    return (
        <Translation>
            {t =>
                <div className="order">
                    <Modal show={showModal} centered={true} onHide={() => setShowModal(false)} dialogClassName="payment-modal">
                        <iframe srcDoc={htmlString} className='modal-iframe' onLoadStart={onLoad} onLoad={onLoad} ref={ref} />
                    </Modal>
                    <div className="order-header d-none d-md-block">
                        <div className="row no-gutters">
                            <div style={{ width: "70px" }}>{t("products")}</div>
                            <div className="col">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-5 text-left px-0" />
                                        <div className="col-2 text-right">
                                            {t("order.price")}
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-2 text-center">{t("amount")}</div>
                                        <div className="col-2 text-right">
                                            {t("total")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    {items && items.map((item) => (
                        <OrderItem key={item.id} item={item} />
                    ))}

                    <div className="order-totals">
                        <div className="row no-gutters checkout-summary-total pt-3">
                            <div style={{ width: "65px" }}>
                                <nobr>
                                    <span
                                        className="checkout-summary-total-text-info">{t("order.totalProductsOrdered")}</span>
                                </nobr>
                            </div>
                            <div className="col pl-0 ">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-2 col-sm-5 text-left px-0" />
                                        <div className="col-2 col-sm-3 text-center px-0" />
                                        <div className="col-1 col-sm-2 text-center px-0">
                                            <span className="d-none d-sm-block cart-product-total">
                                                {order && order.cart && order.cart.itemsCount}
                                            </span>
                                        </div>
                                        <div className="col-sm-2 col-7 text-right px-0">
                                            <span className="checkout-summary-total-price">
                                                {renderCartTotal(order)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {order && order.operatorCart && order.status &&
                            order.status !== "TO_BE_PREPARED" && order.status !== "IN_PREPARATION" &&
                            <div className="row no-gutters pt-2">
                                <div style={{ width: "65px" }}>
                                    <nobr>
                                        <span
                                            className="checkout-summary-total-text-info">{t("order.totalProductsToDispatch")}</span>
                                    </nobr>
                                </div>
                                <div className="col pl-0">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-2 col-sm-5 text-left px-0" />
                                            <div className="col-2 col-sm-3 text-center px-0" />
                                            <div className="col-1 col-sm-2 text-center px-0">
                                                <span className="d-none d-sm-block cart-product-total">
                                                    {order && order.operatorCart && order.operatorCart.itemsCount}
                                                </span>
                                            </div>
                                            <div className="col-sm-2 col-7 text-right px-0">
                                                <span className="checkout-summary-total-price">
                                                    {renderOperatorCartTotal(order)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                        {order && order.cart && order.cart.fees && order.cart.fees.map(fee => (
                            <div key={fee.description} className="row no-gutters pt-2">
                                <div style={{ width: "65px" }}>
                                    <nobr>
                                        <span className="checkout-summary-total-text-info">
                                            {fee.description}
                                        </span>
                                    </nobr>
                                </div>
                                <div className="col pl-0">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-8 col-sm-10 text-center" />
                                            <div className="col-sm-2 col-4 text-right px-0">
                                                <span
                                                    className="checkout-summary-total-price">{`${fee.value?.toFixed(2)} \u20AC`}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="row no-gutters pt-2">
                            <div style={{ width: "65px" }}>
                                <nobr>
                                    <span className="checkout-summary-total-text-info">
                                        {t("order.purchaseTotal")}
                                    </span>
                                </nobr>
                            </div>
                            <div className="col pl-0">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-8 col-sm-10 text-center" />
                                        <div className="col-sm-2 col-4 text-right px-0">
                                            <span
                                                className="checkout-summary-total-price">{renderTotalToPay(order)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {order && order.operatorCart && order.operatorCart.payed &&
                            <>
                                <div className="row no-gutters pt-2">
                                    <div style={{ width: "65px" }}>
                                        <nobr>
                                            <span className="checkout-summary-total-text-info">
                                                {t("order.billTotal")}
                                            </span>
                                        </nobr>
                                    </div>
                                    <div className="col pl-0">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-8 col-sm-10 text-center" />
                                                <div className="col-sm-2 col-4 text-right px-0">
                                                    <span className="checkout-summary-total-price">
                                                        {`${order.operatorCart.payed} \u20AC`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ fontSize: "12px" }} className="row pt-1 ">
                                    {t("order.billAdvice")}
                                </div>
                            </>
                        }
                    </div>
                    <hr />
                    <div className="order-actions">
                        {order && renderAbortOrder(order, abort)}
                        {order && renderCopyOrder(order, copy, t)}
                        {order && renderPayment(order)}
                        {order && order.status === "TO_BE_PREPARED" && renderModifyOrder(order, modify, t, order.cart.terminalId)}
                    </div>
                </div>

            }
        </Translation>
    );

}

export default Order;

