import React, { useState, useEffect, useCallback } from "react"
import StoresMap from "../../components/UI/Maps/StoresMap/StoresMap";
import CustomBreadcrumb from "../../components/UI/Breadcrumb/CustomBreadcrumb";
import { useTranslation } from 'react-i18next';
import { storesService } from "../../services/basis/StoresService"
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../redux/store/actions/index"

import "./stores.styles.scss";

const Stores = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false)
    const [stores, setStores] = useState([])
    const [firstStore, setFirstStore] = useState()
    const [query, setQuery] = useState("")
    const [withDistance, setWithDistance] = useState(false)

    const latitude = useSelector(state => state.user.latitude);
    const longitude = useSelector(state => state.user.longitude);

    useEffect(() => {
        if (navigator.geolocation) {
            if (!latitude && !longitude) {
                navigator.geolocation.getCurrentPosition(
                    pos => {
                        dispatch(actionCreators.coordinate(
                            pos.coords.latitude,
                            pos.coords.longitude
                        ))
                    }
                )
            }
        }
    }, [dispatch, latitude, longitude])

    const handleInput = useCallback((input) => {
        if (withDistance) {
            readStoresByPosition(latitude, longitude, input)
        } else {
            storesService.stores(0, input)
                .then(response => {
                    setStores(response.data)
                    setIsLoaded(true);
                    if (response.data.length > 0) {
                        setFirstStore(response.data[0])
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    }, [latitude, longitude, withDistance])

    useEffect(() => {
        const timer = setTimeout(() => {
            handleInput(query)
        }, 1000);
        return () => clearTimeout(timer);
    }, [query, handleInput, withDistance]);

    const readStoresByPosition = (latitude, longitude, query) => {
        if (latitude && longitude) {
            storesService.storesCoordinate(0, latitude, longitude, query)
                .then(response => {
                    setStores(response.data)
                    setWithDistance(true);
                    setIsLoaded(true);
                    if (response.data.length > 0) {
                        setFirstStore(response.data[0])
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    if (isLoaded) {
        return (
            <div className="stores">
                <CustomBreadcrumb
                    level1={{
                        path: "/stores",
                        label: "stores",
                        active: true
                    }}/>
                <div className="stores-header">
                    <h2>{t("stores.title")}</h2>
                    <input
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Cerca punto vendita"
                    />
                </div>
                {stores && firstStore && firstStore.address &&
                    <StoresMap
                        lat={firstStore?.address?.latitude}
                        lng={firstStore?.address?.longitude}
                        zoom={9}
                        stores={stores}
                        setWithDistance={setWithDistance}
                        disableDistanceButton={latitude && longitude ? false : true}
                    />
                }
            </div>
        );
    } else return null;
}

export default Stores;

