import React from 'react';
import "./newsignup.styles.scss";
import { Translation } from 'react-i18next';
import { ReactComponent  as Smile} from "../../images/smile.svg";
import { history } from "../../App";

const SignUpSuccess = () => {
    return (
        <Translation>
            {t =>
                <div className='signup-container'>
                    <div className='signup-container-center' style={{ textAlign: 'center', gap: '0' }}>
                        <div className='signup-container-title' style={{ marginBottom: '20px' }}>{t('signup.success')}</div>
                        <div className='singup-success-image'><Smile /></div>
                        <div className='signup-container-success-buttons'>
                            <button style={{ width: "unset" }} type="submit" className="mt-1 confirm-button" onClick={() => history.push("/home")}>
                                <span>{t("confirm.returnBackHome")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </Translation>
    )
}

export default SignUpSuccess;