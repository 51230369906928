import React from "react"
import Placeholder from "../../../images/product-placeholder.png"
import { ReactComponent as LandingCart } from '../../../images/landing-cart-sticky.svg';
import { ReactComponent as SlistIcon } from "../../../images/slist1.svg"
import { ReactComponent as VeganIcon } from "../../../images/vegan.svg"
import { ReactComponent as CartIcon } from "../../../images/new-cart-icon6.svg"
import { ReactComponent as MountainIcon } from "../../../images/mountain.svg"
import { ReactComponent as CheckList } from "../../../images/check-list.svg"
import { ReactComponent as List } from "../../../images/list-icon1.svg"
import { ReactComponent as ListGreen } from "../../../images/list-icon-green.svg"
import { ReactComponent as Info } from "../../../images/i-icon.svg"
import { ReactComponent as Frozen } from "../../../images/Frozen-new-icon.svg"
import { ReactComponent as B } from "../../../images/b.svg"
import { connect } from "react-redux"
import { history } from "../../../App";
import { tags } from "../../../utils/renderTags";
import { withRouter } from "react-router-dom";
import config from "../../../config/config";
import { Translation } from "react-i18next";
import * as actionCreators from "../../../redux/store/actions/index"
import renderPrice from "../../../utils/renderPrice";
import NewCounter from "../../UI/NewCounter/NewCounter";
import SlistPopup from "../../../features/Smartlist/SlistPopup/SlistPopup";
import ReactTooltip from "react-tooltip";
import ModalProductSubstitution from "../ModalProduct/ModalProductSubstitution";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
import toaster from "../../../utils/toaster";
import i18n from "../../../i18n";

import "./banner-card-product.styles.scss";
class BannerCardProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: "",
            isAlreadyInList: false,
            showPopup: false,
            open: false,
            checked: true,
            showButtons: false,
            incremented: false,
            decremented: false,
            unity: null
        }
    }

    componentDidMount() {
        this.setCartAmount();
        this.setIsInList();
    }

    componentDidUpdate(prevProps, prevState) {
        const { cart, currentSlist } = this.props;
        if (prevProps?.cart?.id !== cart?.id || prevProps.currentSlist?.items?.length !== currentSlist?.items?.length) {
            //this.setCartAmount();
        }
        if (prevProps.currentSlist?.items?.length !== currentSlist?.items?.length) {
            this.setIsInList();
        }
    }

    setCartAmount = () => {
        const { cart, item } = this.props;
        var isAlreadyBooked = false;
        var itemAlreadyBooked = null;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyBooked = true;
                    itemAlreadyBooked = element;
                }
            });
        }
        if (isAlreadyBooked) {
            if (itemAlreadyBooked.purchaseUm === "PZ") {
                this.setState({ amount: itemAlreadyBooked.amount })
            } else {
                this.setState({ amount: itemAlreadyBooked.weight })
                if (itemAlreadyBooked.attrib3) {
                    if (itemAlreadyBooked.umStep < itemAlreadyBooked.weight) {
                        let steps = Math.ceil(itemAlreadyBooked.weight / itemAlreadyBooked.umStep);
                        let newUnity = parseInt(itemAlreadyBooked.attrib3) * steps;
                        this.setState({ unity: newUnity });
                    } else {
                        this.setState({ unity: parseInt(itemAlreadyBooked.attrib3) });
                    }
                }
            }
        } else {
            this.setState({ amount: item?.minWeight ? parseFloat(item.minWeight?.toFixed(2)) : parseFloat(item?.umStep?.toFixed(2)) })
            if (item.attrib3) {
                this.setState({ unity: parseInt(item.attrib3) })
            }
        }
    }

    setIsInList = () => {
        const { currentSlist, item } = this.props;
        var isAlreadyInList = false;
        if (currentSlist && currentSlist.items) {
            currentSlist.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyInList = true;
                }
            });
        }
        return isAlreadyInList
    }

    incrementAmount = () => {
        const { item, cart } = this.props;
        const { amount } = this.state;
        let roundStep = parseFloat(item.umStep?.toFixed(2));
        let newAmount = +amount + roundStep;
        newAmount = newAmount?.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (item?.maxAmount) {
            if (newAmount <= item.maxAmount) {
                this.setState({ amount: newAmount })
            }
        } else {
            this.setState({ amount: newAmount })
        }
        if (item.attrib3) {
            let newUnity = this.state.unity + parseInt(item.attrib3);
            this.setState({ unity: newUnity });
        }
        if (this.checkIsBooked()) {
            const cartItem = cart.items.find(element => element.ref === item.ref);
            if (newAmount !== cartItem.amount && !this.state.incremented) {
                this.setState({ incremented: true });
                toaster.standard("Dopo aver modificato la quantità aggiornare il carrello");
            }
        }
    }

    decrementAmount = () => {
        const { item, cart } = this.props;
        const { amount } = this.state;
        let roundStep = parseFloat(item.umStep?.toFixed(2));
        let newAmount = +amount - roundStep;
        newAmount = newAmount?.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (item.minWeight) {
            if (amount > item.minWeight) {
                this.setState({ amount: newAmount })
            }
        } else if (amount > parseFloat(item.umStep?.toFixed(2))) {
            this.setState({ amount: newAmount })
        }
        if (item.attrib3 && this.state.unity > item.attrib3) {
            let newUnity = this.state.unity - item.attrib3;
            this.setState({ unity: newUnity });
        }
        if (this.checkIsBooked()) {
            const cartItem = cart.items.find(element => element.ref === item.ref);
            if (newAmount !== cartItem.amount && newAmount > 1 && !this.state.decremented) {
                toaster.standard("Dopo aver modificato la quantità aggiornare il carrello");
                this.setState({ decremented: true });
            }
        }
    }

    changeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    checkIsBooked = () => {
        const { cart, item } = this.props;
        var isAlreadyBooked = false;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyBooked = true;
                }
            });
        }
        return isAlreadyBooked;
    }

    renderPrices = (item) => {
        if (item.price !== item.netPrice) {
            return (
                <nobr>
                    <span className=" strike">
                        {`${renderPrice(item.price?.toFixed(2))} \u20AC`}
                    </span>
                </nobr>
            );
        } else {
            return null
        }
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                return (
                    <Translation>
                        {t =>
                            <div className="card-product-promo">
                                <span>-{item.discountLabel}</span>
                            </div>
                        }
                    </Translation>
                );
            } else {
                return null;
            }
        }
    }

    // isAlreadyBooked = (item) => {
    //     var isAlreadyBooked = false;
    //     var itemToAdd = null;
    //     if (this.props.cart.items) {
    //         const items = this.props.cart.items;
    //         for (var i = 0; i < items.length; i++) {
    //             if (items[i].ref === item.ref) {
    //                 isAlreadyBooked = true;
    //                 itemToAdd = items[i];
    //                 break;
    //             }
    //         }
    //     } else {
    //         if (item.purchaseUm === "KG") {
    //             history.push({
    //                 pathname: `/product/${item.barcode}`,
    //                 state: { product: item }
    //             })
    //         } else {
    //             this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
    //         }
    //         return;
    //     }

    //     if (isAlreadyBooked) {
    //         const addItem = { ...itemToAdd, available: true }
    //         history.push({
    //             pathname: `/product/${item.barcode}`,
    //             state: { product: addItem, isDetail: true }
    //         })
    //     } else {
    //         if (item.purchaseUm === "KG") {
    //             history.push({
    //                 pathname: `/product/${item.barcode}`,
    //                 state: { product: item }
    //             })
    //         } else {
    //             this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
    //         }
    //     }
    // }

    handleSubstituteProduct = (barcode, show) => {
        if (this.state.checked) {
            this.setState({ checked: false }, () => {
                if (this.checkIsBooked()) {
                    this.props.store(this.props?.cart?.id, this.props.item.barcode, this.state.amount, this.props.item.purchaseUm, this.props.item.replacementBarcode ? this.state.checked : false);
                } else {
                    toaster.standard(i18n.t('substitution.cancelled'));
                }
            });
        } else {
            this.setState({ checked: true });
            toaster.standard(i18n.t('substitution.confirm'))
        }
    }

    showSubstituteProduct = (barcode, show) => {
        ecommerceService.getProductFromBarcode(barcode)
            .then(response => {
                console.log(response.data);
                this.setState({ open: true, product: response.data, showButtons: show });
            }).catch((error) => {
                console.log(error);
                toaster.standard(i18n.t('error.substitution'));
            })
    }

    goToDetails = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        const { cart, loadedProducts, from, savedScroll, page, totalPages } = this.props;
        if (cart && cart.items) {
            const items = cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            history.push({
                pathname: `/product/${item.ref.toString()}`,
                state: { product: item, loadedProducts, from, savedScroll, page, totalPages }
            })
            return;
        }

        if (isAlreadyBooked) {
            history.push({
                pathname: `/product/${item.ref.toString()}`,
                state: {
                    product: itemToAdd,
                    isDetail: true,
                    loadedProducts,
                    from,
                    savedScroll,
                    page,
                    totalPages
                }
            })
        } else {
            history.push({
                pathname: `/product/${item.ref.toString()}`,
                state: { product: item, loadedProducts, from, savedScroll, page, totalPages }
            })
        }
    }

    closeSlistPopup = () => this.setState({ showPopup: false })

    render() {
        const { item, user, cart, setProductToAdd, showLogin, open } = this.props;
        const { isAlreadyInList, showPopup, amount } = this.state;
        const isPromo = (item.promotions && item.promotions[0] !== null && item.promotions[0] !== undefined) ? true : false
        return (
            <Translation>
                {t =>
                    <>
                        <div className="banner-card-product" style={{ ...this.props.style }}>
                            <div className={"banner-card-product-container"}>
                                <div className="banner-card-product-top">
                                    <div className="banner-card-product-left-column">
                                        <div className="info" onClick={() => this.goToDetails(item)}>
                                            {/* <Frozen /> */}
                                        </div>
                                        {/* <ReactTooltip id="registerTip" place="right" effect="solid" backgroundColor="grey"  textColor="white" border="10px solid">
                                            Surgelato
                                        </ReactTooltip> */}
                                        {/*  <div className="info">
                                            <B />
                                        </div>
                                        <div className="info">
                                            <B />
                                        </div> */}
                                    </div>
                                    <div className="card-product-promo-container">
                                        {this.renderPromo(item)}
                                    </div>
                                    <div className={"banner-card-product-image"}>
                                        <div className="link" onClick={() => this.goToDetails(item)}>
                                            <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                                onError={(e) => {
                                                    e.target.src = Placeholder
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {config.ENABLED_SLIST &&
                                            <div className="list">
                                                {isAlreadyInList ?
                                                    <div className="list is-already-in-list">
                                                        {/* <button className="is-already-in-list outline"> */}
                                                        <List />
                                                    </div>
                                                    :
                                                    <div className="list" onClick={() => {
                                                        if (user) {
                                                            // currentSlist && this.props.itemCreate(currentSlist.id, item.barcode, 1)
                                                            this.setState({ showPopup: true })
                                                        } else {
                                                            showLogin();
                                                        }
                                                    }}>
                                                        {this.setIsInList() ? <ListGreen /> : <List />}
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {item?.categoryId?.startsWith("100") &&
                                            <>
                                                <div className="info" onClick={() => this.goToDetails(item)} data-tip data-for="registerTip">
                                                    <Frozen />
                                                </div>
                                                <ReactTooltip id="registerTip" place="top" effect="solid" backgroundColor="grey" textColor="white" border="10px solid">
                                                    Surgelato
                                                </ReactTooltip>
                                            </>
                                        }
                                    </div>
                                </div>

                                {/*  <div className={"banner-card-product-image"}>
                                    <div className="link" onClick={() => this.goToDetails(item)}>
                                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                            onError={(e) => {
                                                e.target.src = Placeholder
                                            }}
                                        />
                                    </div>
                                </div> */}
                            </div>
                            {
                                item.description2 &&
                                <div className="banner-card-product-details">
                                    <span className="link" style={{ color: "black" }} onClick={() => this.goToDetails(item)}>
                                        {item.description2}
                                    </span>
                                </div>
                            }
                            <div style={{ height: "15px" }}>
                                {
                                    item.brand &&
                                    <div style={{ fontFamily: "Montserrat-Regular", fontSize: "12px" }}>{item.brand}</div>
                                }
                            </div>
                            <div style={isPromo ? { justifyContent: 'space-between', height: "13px", display: 'flex' } : { justifyContent: 'flex-start', height: "13px", display: 'flex' }}>
                                {
                                    item.weightString &&
                                    <div style={{ fontFamily: "Montserrat-Regular", fontSize: "10px", textAlign: "left" }}>{item.weightString}</div>
                                }
                                {
                                    item.price && isPromo &&
                                    <div style={{ fontFamily: "Montserrat-Regular", fontSize: "14px", textAlign: "right", textDecorationLine: 'line-through' }}>{` ${renderPrice((item.price * amount)?.toFixed(2))} \u20AC `}</div>
                                }
                            </div>
                            <div className="banner-card-product-prices" style={item.replacementBarcode ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}>
                                {item.replacementBarcode && <label className="substitute-label">{t('substitution.label')}</label>}
                                {/* <span style={{ height: "13px", fontFamily: "Montserrat-Regular", fontSize: "10px", color: "#010201", fontWeight: "600" }}>Prodotto alternativo</span> */}
                                {item.netPrice &&
                                    <nobr><span className={"netTotal"} style={isPromo ? { color: '#d80014' } : {}}>{` ${renderPrice((item.netPrice * amount)?.toFixed(2))} \u20AC `}</span></nobr>
                                }

                            </div>
                            {/*  <div className="banner-card-product-prices">
                                {item.tags ?
                                    <div className="banner-card-product-tags">
                                        {item.tags.split(",").map(tag => (
                                            <span key={tag}>{tags.renderTags(tag)}</span>
                                        ))}
                                    </div>
                                    :
                                    <div className="banner-card-product-tags">
                                        &nbsp;
                                    </div>
                                }

                                <div>
                                    {this.renderPrices(item)}
                                    {item.netPrice &&
                                        <nobr><span className="netTotal">{` ${renderPrice(item.netPrice?.toFixed(2))} \u20AC `}</span></nobr>
                                    }
                                </div>
                            </div> */}
                            <div className="banner-card-product-switch-button">
                                <div className="banner-card-product-um" style={item.replacementBarcode ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}>
                                    {item.replacementBarcode &&
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" onClick={() => this.handleSubstituteProduct(item.replacementBarcode, true)} checked={this.state.checked} />
                                                <span className="slider round"></span>
                                            </label>
                                            <Info height={20} width={30} onClick={() => this.showSubstituteProduct(item.replacementBarcode, false)} />
                                        </div>}
                                    {item.pricePerUM && item.umPerUM &&
                                        `${renderPrice(item.pricePerUM?.toFixed(2))} \u20AC / ${item.umPerUM} `
                                    }
                                </div>
                            </div>
                            {/* <div className="banner-card-product-vegan">
                                <div>
                                    <VeganIcon />
                                </div>
                                <div>
                                    <VeganIcon />
                                </div>
                                <div>
                                    <VeganIcon />
                                </div>
                                <div>
                                    <VeganIcon />
                                </div>
                                <div>
                                    <VeganIcon />
                                </div>
                            </div> */}
                            {/*  <div className="card-product-schedaProduct">
                                <div>
                                    <MountainIcon />
                                </div>
                                <div className="card-product-schedaProduct-link">
                                    <span className="link" style={{ fontSize: "14px", fontWeight: "bold" }} onClick={() => history.push("/producter")}>
                                        <u>{t("Scheda Prodotto")}</u>
                                    </span>
                                </div>
                            </div> */}
                            <div className="banner-card-product-buttons">

                                <NewCounter
                                    value={this.state.amount}
                                    um={item.purchaseUm}
                                    increment={this.incrementAmount}
                                    decrement={this.decrementAmount}
                                    // writable={true}
                                    attrib3={this.state.unity}
                                    changeAmount={this.changeAmount}
                                    showUm={true}
                                    pattern="^\d(\.\d)*$"
                                    disabled={!item.available}
                                />

                                <button className="cart outline"
                                    style={{ backgroundColor: this.checkIsBooked() ? "#4fc269" : "#e22018" }}
                                    onClick={() => {
                                        if (user && cart) {
                                            // this.isAlreadyBooked(item)
                                            this.props.store(this.props?.cart?.id, item.barcode, this.state.amount, item.purchaseUm, this.props.item.replacementBarcode ? this.state.checked : false);
                                            this.setState({ incremented: false, decremented: false });
                                        }
                                        if (user && !cart) {
                                            setProductToAdd(item, this.state.amount, this.props.item.replacementBarcode ? this.state.checked : false);
                                            this.props.history.push("/cart")
                                            // open("IN_STORE", "686491", "")
                                        }
                                        if (!user) {
                                            setProductToAdd(item, this.state.amount, this.props.item.replacementBarcode ? this.state.checked : false);
                                            showLogin();
                                        }
                                    }}>
                                    {/* <LandingCart /> */}
                                    <CartIcon />
                                    {/* <span className="d-none d-md-block">{t("cardProduct.buy")}</span> */}
                                </button>
                            </div>
                        </div>
                        {showPopup &&
                            <SlistPopup
                                show={showPopup}
                                barcode={item.barcode}
                                amount={item.purchaseUm === "PZ" ? amount : 1}
                                onClose={this.closeSlistPopup}
                            />
                        }
                        <ModalProductSubstitution
                            open={this.state.open}
                            product={this.state.product}
                            showButtons={this.state.showButtons}
                            onHide={() => this.setState({ open: false })}
                            onConfirm={() => this.setState({ open: false, checked: true })}
                            renderPrice={renderPrice}
                        />
                    </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setProductToAdd: (item, amount, replace) => dispatch(actionCreators.setProductToAdd(item, amount, replace)),
        store: (cartId, barcode, amount, um, enableReplacement) => dispatch(actionCreators.store(cartId, barcode, amount, um, enableReplacement)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
        showLogin: () => dispatch(actionCreators.showLogin(true)),
        open: (deliveryMode, storeId, addressId) => dispatch(actionCreators.open(deliveryMode, storeId, addressId)),

    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BannerCardProduct));