import React from "react"
import { ReactComponent as CouponIcon } from "../../../../images/coupon.svg"
// import { ReactComponent as RemoveCoupon } from "../../../../images/remove_coupon.svg"
import { ReactComponent as Delete } from "../../../../images/delete-icon.svg"
import renderPrice from "../../../../utils/renderPrice";

import "./coupon.styles.scss";

const Coupon = ({ coupon, handleCoupon, cart}) => {
    return (
        <div className="checkout-coupons-item">
             <Delete className="remove" onClick={() => handleCoupon(coupon.code, "DELETE", coupon.allocatedCouponId)} />
            <div className="description-coupon">
                <span> BUONO SCONTO</span>
            </div>
            <div className="description-coupon">
            <span>{cart && `- \u20AC ${renderPrice(coupon.value?.toFixed(2))}`}</span>
            </div>
        </div>
    );
}

export default Coupon;