import React from "react"
import parse from 'html-react-parser';
import { Translation } from "react-i18next";
import { ReactComponent as AccordionDown } from "../../../images/accordion-down.svg";
import { ReactComponent as AccordionUp } from "../../../images/accordion-up.svg";

import "./product-info.styles.scss";

export default class ProductInfo extends React.Component {

    state = {
        isOpen: true
    }

    render() {
        const { info } = this.props;
        const { isOpen } = this.state;
        if (info) {
            let nutrition = null;
            if (info.nutrition) {
                try {
                    nutrition = JSON.parse(info.nutrition);
                } catch (e) {
                    nutrition = info.nutrition
                }
            }
            let ingredients = null
            if (info.ingredients) {
                try {
                    ingredients = JSON.parse(info.ingredients);
                } catch (e) {
                    ingredients = info.ingredients
                }
            }
            let allergyAdvice = null;
            let containAllergies = [];
            let canContainAllergies = [];
            let notContainAllergies = [];
            if (info.allergyAdvice) {
                try {
                    allergyAdvice = JSON.parse(info.allergyAdvice);
                    allergyAdvice.forEach(item => {
                        if (item.value.value === "Contiene") {
                            containAllergies.push(item);
                        }
                        if (item.value.value === "Non contiene") {
                            notContainAllergies.push(item);
                        }
                        if (item.value.value === "Potrebbe contenere") {
                            canContainAllergies.push(item);
                        }
                    });
                } catch (e) {
                    allergyAdvice = info.allergyAdvice;
                }
            }
            return (
                <Translation>
                    {t =>
                        <div className="product-info">
                            <div className="product-info-collapse">
                                <h2>Informazioni sul prodotto</h2>
                                <span onClick={() => this.setState({ isOpen: !isOpen })}>
                                    {isOpen ? <AccordionUp /> : <AccordionDown />}
                                </span>
                            </div>
                            {isOpen &&
                                <>
                                    <hr />
                                    <div className="row">
                                        {(info.description || info.country || info.storage) &&
                                            // <div className="col-lg-4 col-md-6">
                                            <div className="col-12">
                                                {info.description &&
                                                    <div className="mb-4">
                                                        <div className="product-info-title">
                                                            {t("description")}
                                                        </div>
                                                        {info.description}
                                                    </div>
                                                }
                                                {info.country &&
                                                    <div className="mb-4">
                                                        <div className="product-info-title">
                                                            {t("origin")}
                                                        </div>
                                                        {info.country}
                                                    </div>
                                                }
                                                {info.storage &&
                                                    <div className="mb-4">
                                                        <div className="product-info-title">
                                                            {t("storage")}
                                                        </div>
                                                        {info.storage}
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {nutrition &&
                                            <div className="col-lg-4 col-md-6">
                                                <div className="mb-4">
                                                    <div className="product-info-title">
                                                        {t("nutrition")}
                                                    </div>
                                                    {/*  <div className="product-info-nutrition">
                                                        {info.nutrition}
                                                    </div> */}
                                                    {/*  <table>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th width="106px" style={{ textAlign: "right" }}>{nutrition.per100Heading}{nutrition.per100Unit && nutrition.per100Unit.toLowerCase()}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {nutrition.nutrientValues && Array.isArray(nutrition.nutrientValues) &&
                                                                nutrition.nutrientValues.map(item => (
                                                                    <tr key={item.name}>
                                                                        <td>{item.name}</td>
                                                                        <td style={{ textAlign: "right" }}>{item.per100 && item.per100.value}</td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table> */}
                                                    <table>
                                                        <thead>
                                                            {/*  <tr>
                                                               <th></th>
                                                               <th width="106px" style={{ textAlign: "right" }}>{nutrition.per100Heading}{nutrition.per100Unit}</th>
                                                             </tr>*/}
                                                        </thead>
                                                        <tbody>
                                                            {nutrition &&
                                                                nutrition.split(';').map((item, index) => {
                                                                    const y = item.substr(0, item.lastIndexOf(': '));
                                                                    const value = item.substring(item.lastIndexOf(': ') + 1);
                                                                    if (index % 2 == 0) {
                                                                        return (
                                                                            <tr key={item} style={{ backgroundColor: "white" }}>
                                                                                <td>{y}</td>
                                                                                <td style={{ textAlign: "right" }}>{value}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <tr key={item} style={{ backgroundColor: "#f8f8f8" }}>
                                                                                <td>{y}</td>
                                                                                <td style={{ textAlign: "right" }}>{value}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {info.ingredients &&
                                            <div className="col-lg-4 col-md-6">
                                                <div className="mb-4">
                                                    <div className="product-info-title">
                                                        {t("ingredients")}
                                                    </div>
                                                    {ingredients && Array.isArray(ingredients) && ingredients.map((item, index) => (
                                                        <>
                                                            <span key={index}>{parse(item.value)}</span>
                                                            {ingredients.length !== index + 1 &&
                                                                <span>, </span>
                                                            }
                                                        </>
                                                    ))}
                                                    {ingredients && !Array.isArray(ingredients) &&
                                                        <span>{ingredients}</span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {allergyAdvice && Array.isArray(allergyAdvice) &&
                                            <div className="col-lg-4 col-md-6">
                                                <div className="mb-4">
                                                    <div className="product-info-title">
                                                        {t("allergyAdvice")}
                                                    </div>
                                                    {containAllergies.length > 0 &&
                                                        <div className="product-info-allergy">
                                                            <div className="allergy-title">
                                                                Contiene:
                                                            </div>
                                                            {containAllergies.map((item, index) => (
                                                                <>
                                                                    <span key={item.name.id}>{item.name.value}</span>
                                                                    {containAllergies.length !== index + 1 &&
                                                                        <span>, </span>
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>
                                                    }
                                                    {containAllergies.length > 0 &&
                                                        <div className="product-info-allergy">
                                                            <div className="allergy-title">
                                                                Potrebbe contenere:
                                                            </div>
                                                            {canContainAllergies.map((item, index) => (
                                                                <>
                                                                    <span key={item.name.id}>{item.name.value}</span>
                                                                    {canContainAllergies.length !== index + 1 &&
                                                                        <span>, </span>
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>
                                                    }
                                                    {notContainAllergies.length > 0 &&
                                                        <div className="product-info-allergy">
                                                            <div className="allergy-title">
                                                                Non contiene:
                                                            </div>
                                                            {notContainAllergies.map((item, index) => (
                                                                <>
                                                                    <span key={item.name.id}>{item.name.value}</span>
                                                                    {notContainAllergies.length !== index + 1 &&
                                                                        <span>, </span>
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {allergyAdvice && !Array.isArray(allergyAdvice) &&
                                            <div className="col-lg-4 col-md-6">
                                                <div className="mb-4">
                                                    <div className="product-info-title">
                                                        {t("allergyAdvice")}
                                                    </div>
                                                    <div className="product-info-allergy">
                                                        <span>{allergyAdvice}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {(!!info.origin && info.origin !== "" || !!info.provenance && info.provenance !== "") ?
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6">
                                                <div className="mb-4">
                                                    <div className="product-info-title">
                                                        TRACCIABILITÁ
                                                    </div>
                                                    {info.origin !== "" &&
                                                        <div className="product-info-allergy">
                                                            <span style={{ whiteSpace: 'pre-line' }}>{info.origin}</span>
                                                        </div>
                                                    }
                                                    {info.provenance !== "" &&
                                                        <div className="product-info-allergy">
                                                            <span style={{ whiteSpace: 'pre-line' }}>{info.provenance}</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        null
                                    }
                                </>
                            }
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}