import React from "react";
import Days from "../Days/Days"
import Timeslots from "../Timeslots/Timeslots"
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import * as actionCreators from "../../../redux/store/actions/index"
import { useHistory } from "react-router-dom"
import "./select-timeslots.styles.scss";
import { ReactComponent as Cal } from "../../../images/cal-icon.svg";
import { ReactComponent as AccordionDown } from "../../../images/accordion-down.svg";
import { ReactComponent as AccordionUp } from "../../../images/accordion-up.svg";
import CartMenu from "../../Cart/CartMenu/CartMenu";
import { useState, useEffect } from "react";
import SelectStores from "../SelectStores/SelectStores";
import disponibility from "../Days/Service";
import Notes from "../Notes/Notes";
import NotePopup from "../NotePopup/NotePopup";

const SelectTimeSlots = ({
  cart,
  day,
  selectTimeslot,
  timeSlotId,
  open,
  togglePopup,
  selectDay,
  choosedDay,
  freezeCheck,
  abort,
  showFreezePopup,
  closeFreezePopup,
  updatedItems,
  removedItems,
  choosedTimeslot,
  termsAndCondition,
  goToCheckout,
  checkFreeze,
  // handleSubmit(submit),
  // enableProductsReplacement={enableProductsReplacement}
  paymentMethod,
  user,
  setDeliveryMode,
  deliveryMode,
  deliveryAddress,
  setDeliveryAddress,
  deliveryZip,
  setDeliveryZip,
  deliveryCity,
  setDeliveryCity,
  deliveryCountry,
  setDeliveryCountry,
  notes,
  setNotes,
  setPaymentMethod
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false)
  const [notePopup, setNotePopup] = useState(false)
  const [disabled, setDisabled] = useState(true);
  const [oldNote, setOldNote] = useState(cart?.notes);
  const submit = () => {
    // dispatch(actionCreators.bookTimeslot(cart.id, timeSlotId, choosedDay.date))
    // togglePopup(false);
  }

  useEffect(() => {
    setOldNote(cart?.notes)
  }, [cart])

  useEffect(() => {
    setPaymentMethod();
  }, [])

  const closeNotePopup = () => {
    setNotePopup(false);
  }

  return (
    <div className="select-timeslots">
      <div className="select-timeslots-content">
        {/* <h2>{t("availability.title")}</h2> */}
        <div className="select-timeslots-content-header desktop">
          <div className="col-lg-6 col-md-6 col-sm-5 select-timeslots-content-header-text">
            <span className="select-timeslots-content-header-text-digit-text">2</span>
            <span className="select-timeslots-content-header-text-alpha-text">RITIRO</span>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-7 select-timeslots-content-header-buttons">
            <button style={{ width: "160px", height: "36px", borderRadius: "18px", backgroundColor: "white", color: "#d80014", fontWeight: "bold", border: "solid 1px rgba(255, 255, 255, 0.5)" }}
              onClick={() => history.push("/cart")}
            >
              INDIETRO
            </button>
            <button className="avanti-button"
              // onClick={() => history.push("/checkout")}
              onClick={() => {
                if (!disabled) {
                  setNotePopup(true)
                }
                else {
                  checkFreeze("/checkout")
                }
              }}
              disabled={
                !choosedDay || !choosedTimeslot
              }
            >
              AVANTI
            </button>
          </div>
        </div>
        <div className="select-timeslot-cart-menu-dropdown">
          <div className="col-6" style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
            <Cal className="mr-4" />
            <h5>RIEPILOGO</h5>
          </div>
          <div className="col-6" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <AccordionUp /> : <AccordionDown />}
          </div>
        </div>
        {
          isOpen &&
          <CartMenu
            cart={cart}
            freezeCheck={freezeCheck}
            abort={abort}
            open={showFreezePopup}
            closePopup={closeFreezePopup}
            updatedItems={updatedItems}
            removedItems={removedItems}
            togglePopup={togglePopup}
            choosedDay={choosedDay}
            choosedTimeslot={choosedTimeslot}
            termsAndCondition={termsAndCondition}
            goToCheckout={goToCheckout}
            // onSubmit={handleSubmit(submit)}
            // enableProductsReplacement={enableProductsReplacement}
            paymentMethod={paymentMethod}
            user={user}
          />
        }
        <SelectStores
          cartId={cart.id}
          setDeliveryMode={setDeliveryMode}
          deliveryMode={deliveryMode}
          selectDay={selectDay}
          selectedDay={day}
          day={day}
          selectTimeslot={selectTimeslot}
          selectedTimeslot={timeSlotId}
          cart={cart}
          deliveryAddress={deliveryAddress}
          setDeliveryAddress={setDeliveryAddress}
          deliveryZip={deliveryZip}
          setDeliveryZip={setDeliveryZip}
          deliveryCity={deliveryCity}
          setDeliveryCity={setDeliveryCity}
          deliveryCountry={deliveryCountry}
          setDeliveryCountry={setDeliveryCountry}
        />
        {
          deliveryMode &&
          <div>
            <hr className="horizonal-line" />
            <div style={{ margin: "30px 20px" }}>
              <span className="store-heading ">GIORNO e FASCIA ORARIA DI RITIRO</span>
            </div>
            <Days selectDay={selectDay} cartId={cart.id} selectedDay={day} deliveryMode={deliveryMode} />
          </div>
        }
        {day &&
          <div>
            <Timeslots day={day} selectTimeslot={selectTimeslot} cartId={cart.id} selectedTimeslot={timeSlotId} deliveryMode={deliveryMode} />
            <hr className="horizonal-line" />
          </div>
        }
        <div style={{ margin: "20px" }}>
          <span className="store-heading ">NOTE</span>
        </div>
        <Notes cartId={cart.id} notess={notes} setNotes={setNotes} disabled={disabled} setDisabled={setDisabled} oldNote={oldNote} setOldNote={setOldNote} />
      </div>
      {notePopup &&
        <NotePopup
          show={notePopup}
          cartId={cart.id}
          notess={notes}
          setNotes={setNotes}
          onClose={closeNotePopup}
          setDisabled={setDisabled}
          setOldNote={setOldNote}
        />
      }
      <div className="select-timeslots-content-header">
        <div className="col-lg-6 col-md-6 col-sm-5 select-timeslots-content-header-text">
          <span className="select-timeslots-content-header-text-digit-text">2</span>
          <span className="select-timeslots-content-header-text-alpha-text">RITIRO</span>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-7 select-timeslots-content-header-buttons">
          <button style={{ width: "160px", height: "36px", borderRadius: "18px", backgroundColor: "white", color: "#d80014", fontWeight: "bold", border: "solid 1px rgba(255, 255, 255, 0.5)" }}
            onClick={() => history.push("/cart")}
          >
            INDIETRO
          </button>
          <button className="avanti-button"
            // onClick={() => history.push("/checkout")}
            onClick={() => {
              if (!disabled) {
                setNotePopup(true)
              }
              else {
                checkFreeze("/checkout")
              }
            }}
            disabled={
              !choosedDay || !choosedTimeslot
            }
          >
            AVANTI
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelectTimeSlots;