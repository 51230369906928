import React, { useState } from "react";
import Categories from "../../../../../features/Categories/Categories";
import CategoryTree from "../../../../../features/SearchCategory/CategoryTree/CategoryTree";
import Filters from "../../../../Filters/Filters";
import { ReactComponent as BurgerIcon } from '../../../../../images/burger-icon.svg';
import { useHistory } from "react-router";
import { slide as Menu } from 'react-burger-menu'

import "./burger-menu.styles.scss";

const BurgerMenu = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const history = useHistory();

    const stateChangeHandler = (newState) => {
        setIsOpen(newState.isOpen)
    }

    // useEffect(() => {
    //     return history.listen(location => {
    //         setIsOpen(false)
    //     })
    // }, [history])

    const handleClose = () => {
        setIsOpen(false);
    }

    return (
        <div className="burger-menu">
            <Menu
                isOpen={isOpen}
                onStateChange={(state) => stateChangeHandler(state)}
                customBurgerIcon={<BurgerIcon />}
                bodyClassName={"no-scroll"}
            >
                <div className="burger-menu-header" onClick={() => setIsOpen(false)} >
                    <button className="secondary-cta" onClick={handleClose}>Conferma</button>
                </div>
                <div className="burger-menu-content">
                    {history.location.pathname === "/search" &&
                        <Filters showDefaultFilters={true} />
                    }
                    <div className="burger-menu-title">Categorie</div>
                    {history.location.pathname.includes("/category")
                        ?
                        <CategoryTree handleClose={handleClose}/>
                        :
                        <Categories/>
                    }
                </div>
            </Menu>
        </div>
    );
}

export default BurgerMenu;
