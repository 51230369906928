
import React from "react";
import { ReactComponent as Localization } from "../../../../images/localization.svg"

import "./map-localization.styles.scss";

const MapLocalization = ({ setWithDistance, disableDistanceButton }) => {
    return (
        <div className="map-localization leaflet-top leaflet-right">
            <div className="leaflet-control leaflet-bar">
                <button onClick={() => setWithDistance(true)} disabled={disableDistanceButton}>
                    <Localization />
                </button>
            </div>
        </div>
    )
}

export default MapLocalization;