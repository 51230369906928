import React from "react"
// import { ReactComponent as GlutenFree } from '../images/tags/icona-GLUTEN-FREE.svg';
// import { ReactComponent as Bio } from '../images/tags/icona-BIO.svg';
import { ReactComponent as Placeholder } from '../images/tags/bio.svg';

export const tags = { renderTags, renderDetailTags }

function renderTags(tag) {
    // var icon = null;
    var icon = <Placeholder />
    switch (tag) {
        // case "SENZA GLUTINE": icon = <GlutenFree title={"Senza glutine"} />;
        //     break;
        // case "BIOLOGICO": icon = <Bio title={"Biologico"} />
        //     break;

        // default: return icon;
    }

    return icon;
}

function renderDetailTags(tag) {
    var icon = null;
    var style = null;
    var label = null;
    switch (tag) {
        case "SENZA GLUTINE":
            // icon = <GlutenFree title={"Senza glutine"} />;
            icon = <Placeholder />
            label = "SENZA GLUTINE"
            break;
        case "BIOLOGICO":
            // icon = <Bio title={"Biologico"} />
            icon = <Placeholder />
            label = "BIOLOGICO"
            break;

        default: return null;
    }
    return (
        <div>
            {icon}
            <h5 style={style}>{label}</h5>
        </div>
    );
}