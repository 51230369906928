import React from "react"
import { storesService } from "../../../services/basis/StoresService"
import Dropdown from "../../../components/UI/Dropdown/Dropdown";

export default class CartCreateSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
        }
    }

    componentDidMount() {
        const { deliveryMode, zipCode } = this.props;
        if (deliveryMode && zipCode) {
            storesService.storesByDelivery(deliveryMode, zipCode)
                .then(data => {
                    if (data && data.length > 0) {
                        this.setState({ stores: data, });
                    } else {
                        this.setState({ stores: data });
                        this.props.select(null)
                    }
                }).catch(error => {
                    console.log(error);
                });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.zipCode !== nextProps.zipCode ||
            this.props.deliveryMode !== nextProps.deliveryMode) {
            if (nextProps.deliveryMode && nextProps.zipCode) {
                storesService.storesByDelivery(nextProps.deliveryMode, nextProps.zipCode)
                    .then(data => {
                        this.setState({ stores: data });
                        this.props.select(null)
                    }).catch(error => {
                        console.log(error);
                    });;
            }
        }
    }

    render() {
        const { select, label, deliveryMode, zipCode, defaultStore } = this.props;
        const { stores } = this.state;
        return (
            <>
                <Dropdown
                    items={stores}
                    select={select}
                    label={label}
                    defaultStore={defaultStore}
                />
                {deliveryMode && zipCode && stores && stores.length <= 0 && 
                    <span className="no-stores">Nessun punto vendita rispetta i parametri indicati</span>
                }
            </>
        );
    }
}

