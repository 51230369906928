import React from "react";
//import Logo from "../../../../images/coop-sait-logo.png";
//import HeaderItem from "../HeaderItems/HeaderItem";
//import HeaderCartItem from "../HeaderItems/HeaderCartItem";
//import HeaderProfileItem from "../HeaderItems/HeaderProfileItem";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
// import CategorySelect from "../../../CategorySelect/CategorySelect";
//import { ReactComponent as UserIcon } from '../../../../images/landing-user-sticky.svg';
//import { ReactComponent as CartIcon } from '../../../../images/landing-cart-sticky.svg';
import { ReactComponent as Pin } from '../../../images/pin.svg';
import { ReactComponent as Search } from '../../../images/search.svg';

import "./search-recipie.styles.scss";
//import HeaderTags from "../HeaderTags/HeaderTags";

class SearchRecipie extends React.Component {
    render() {
        const { user, cart, searchInput, onChange, showModalLogin,
            submitSearch, setCategory, defaultDelivery, defaultStore } = this.props;
        return (
            <Translation>
                {t =>
                    // <div className="container-fluid">
                        <div className="search-header">
                            <div className="search-header-middle">
                                <div className="col-4 search">
                                    <form onSubmit={e => submitSearch(e)}>
                                        <input
                                            type="search"
                                            onChange={onChange}
                                            value={searchInput}
                                            placeholder={t("header.mobileMobilePlaceHolder")}
                                        />
                                        {/* <CategorySelect setCategory={setCategory} /> */}
                                        <button type="submit">
                                            {/* <span>{t("header.search")}</span> */}
                                            <Search />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    // </div>
                }
            </Translation>
        );
    }
}

export default withRouter(SearchRecipie);
