import React from 'react';
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
import Placeholder from "../../../images/product-placeholder.png"
import "./modal-product-substitution.scss";

const ModalProductSubstitution = ({
    open,
    product,
    onHide,
    renderPrice,
    onConfirm,
    showButtons
}) => {
    const { t } = useTranslation();


    return (
        <Modal show={open} dialogClassName="substitution" onHide={onHide}>
            <Modal.Header className='substitution-header' closeButton={!showButtons}>Articolo alternativo</Modal.Header>
            <div className='substution-content'>
                <div className='substitution-text'>
                    {/* Se l’articolo scelto non è disponibile alla consegna della spesa, ti porteremo uno dei seguenti prodotti sostitutivi senza costi aggiuntivi: pagherai sempre il prezzo più basso scegliendo la qualità Coop! */}
                    In caso di prodotto non disponibile, accetti la consegna di questo come eventuale sostituzione dell’articolo scelto.
                </div>
                {product &&
                    <div className='substitution-product'>
                        <div className='image'>
                            <img src={product.imageUrl ? product.imageUrl : Placeholder} alt="" />
                        </div>
                        <div className='description'>
                            <span>{product.description2}</span>
                            <div style={{ height: "15px" }}>
                                {
                                    product.brand &&
                                    <div style={{ fontFamily: "Montserrat-Regular", fontSize: "10px", color: "#a1a1a1", textAlign: "left" }}>{product.brand}</div>
                                }
                            </div>
                            <div style={{ height: "13px" }}>
                                {
                                    product.weightString &&
                                    <div style={{ fontFamily: "Montserrat-Regular", fontSize: "10px", color: "#a1a1a1", textAlign: "left" }}>{product.weightString}</div>
                                }
                            </div>
                            <div className="card-product-prices" style={{ justifyContent: 'flex-end' }}>
                                {/* <span style={{ height: "13px", fontFamily: "Montserrat-Regular", fontSize: "10px", color: "#010201", fontWeight: "600" }}>Prodotto alternativo</span> */}
                                {product.netPrice &&
                                    <nobr><span className="netTotal">{` ${renderPrice(product.netPrice?.toFixed(2))} \u20AC `}</span></nobr>
                                }
                            </div>
                            <div className="card-product-switch-button">
                                <div className="card-product-um" style={{ justifyContent: 'flex-end' }}>
                                    {product.pricePerUM && product.umPerUM &&
                                        `${renderPrice(product.pricePerUM?.toFixed(2))} \u20AC / ${product.umPerUM} `
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {showButtons &&
                    <div className="substitution-button" >
                        <div>
                            <button className="header-tags-modify-button-annula" onClick={onHide}>
                                ANNULLA
                            </button>
                        </div>
                        <div>
                            <button className="header-tags-modify-button-conferma" onClick={onConfirm}>
                                CONFERMA
                            </button>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    )
}

export default ModalProductSubstitution