import React, { useState, useEffect } from "react";


import "./banner.styles.scss";
const Banner = () => {
    return (
        <div className="banners">
            <div className="banners-products-content">
                <div className="banners-products-text">
                  {/*  <div className="title">I prodotti del territorio</div>
                    <div className="subtitle">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</div> */}
                   {/*  <button className="cta" onClick={() => history.push("/discoverproducts")}>
                        <span>Scopri tutti i prodotti</span>
                    </button>  */}
                </div>
                {/* <div>Prodotti</div> */}
            </div>
        </div>
    );
}

export default Banner; 