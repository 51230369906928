import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import axios from 'axios'
import { authHeader } from "../../../utils/authHeader";

export default function paymentsMode(deliveryMode, storeId) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/configuration/deliveryMode/${deliveryMode}?storeId=${storeId}`,
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}
