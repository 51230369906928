import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import * as actionCreators from "../../redux/store/actions/index"
import ProductInfo from "../Product/ProductInfo/ProductInfo";
import { productsService } from "../Product/Service";
import ProductDetails from "./ProductDetails/ProductDetails";
import { withRouter } from "react-router";

import "./productdetailspopup.styles.scss";
import Product from "../Product/Product";
import ProductDetailsInfo from "./ProductDetailsInfo/ProductDetailsInfo";

const ProductDetailsPopup = ({
  cart,
  show,
  itemRef,
  barcode,
  onClose,
  location,
  defaultStore,
  match
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [amount, setAmount] = useState();
  const [umStep, setUmStep] = useState();
  const [info, setInfo] = useState();
  const [product, setProduct] = useState([])
  const [productImages, setProductImages] = useState()

  //const productBarcode = barcode;
  // const barcode = match.params.barcode;
  const isDetail = location.state ? location.state.isDetail : null;
  const detailProduct = location.state ? location.state.product : null;
  const storeId = cart ? cart.storeId : (defaultStore ? defaultStore.id : "");
  /* const submit = () => {
    dispatch(actionCreators.bookTimeslot(cart.id, timeSlotId, choosedDay.date))
    togglePopup(false);
  } */

  useEffect(() => {
    productsService.fetchByRef(itemRef, storeId)
      .then(response => {
        const product = response.data;
        /*  setProduct1(response.data);
         console.log(product1); */
        let initialAmount = null;
        // check if is a cart item 
         if (detailProduct) {
             if (isDetail) {
                 if (detailProduct.um === "PZ") {
                     initialAmount = detailProduct.amount;
                 } else {
                     initialAmount = detailProduct.weight;
                 }
             } else {
                 initialAmount = detailProduct.umStep;
             }
         } else {
             initialAmount = product.umStep;
         }   

        setProduct(product);
        setAmount(initialAmount);
        setUmStep(product.umStep)
        // setAvailable(isDetail ? true : product.available)
        setProductImages(
          product && product.images
            ? product.images.map(item => { return { original: item } }) : null
        );
      }).catch((error) => {
        console.log(error);
      })
  }, [itemRef, storeId])

  useEffect(() => {
    productsService.info(barcode).then(response => {
      if (response.data) {
        setInfo(response.data);
      }
    }).catch((error) => {
      console.log(error);
    })
  }, [barcode])
  return (
    <Modal show={show} centered={true} onHide={onClose}  dialogClassName="productdetailspopup">
      <Modal.Header className="productdetailspopup-header" closeButton> Dettaglio Prodotto</Modal.Header>
      <div className="productdetailspopup-content">
        <ProductDetails
          product={product}
          productImages={productImages}
          amount={amount}
        />
        {info &&
          <ProductDetailsInfo info={info} />
        }
      </div>
      {/* <div className="disponibility-content">
        <h2>{t("availability.title")}</h2>
        <Days selectDay={selectDay} cartId={cart.id} selectedDay={day} />
        {day &&
          <Timeslots day={day} selectTimeslot={selectTimeslot} cartId={cart.id} selectedTimeslot={timeSlotId} />
        }
        <button
          className="cta"
          onClick={submit}
          disabled={!day || !timeSlotId}
        >
          <span>{t("select")}</span>
        </button>
      </div> */}
    </Modal>
  );
}


export default withRouter(ProductDetailsPopup);