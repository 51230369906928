/* eslint-disable */

import * as actionTypes from "../actions/actionTypes";
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    email: "",
    phone: ""
}

const signupReducer = createReducer(initialState, {
    [actionTypes.SET_EMAIL_PHONE]: (state, action) => {
        state.email = action.email,
        state.phone = action.phone
    }
});

export default signupReducer;