import React, { useState, useEffect } from "react"
import SmartlistHeader from "./SmartlistHeader/SmartlistHeader";
import PopupInput from "../../components/UI/Popup/PopupInput";
import CurrentSmartlist from "./CurrentSmartlist/CurrentSmartlist";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import { connect, useSelector } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"

import "./smartlists.styles.scss";

const Smartlists = ({
    getSlists,
    createSlist,
    fetchSlist
}) => {
    const { t } = useTranslation();

    const slists = useSelector(state => state.slists.slists)
    const currentSlist = useSelector(state => state.slists.currentSlist)
    const [createTitle, setCreateTitle] = useState("")
    const [activeKey, setActiveKey] = useState()

    useEffect(() => {
        getSlists();
    }, [getSlists])

    const selectSlist = (slistId, index) => {
        if (index !== activeKey) {
            fetchSlist(slistId);
            setActiveKey(index);
        } else {
            setActiveKey(null);
        }
    }

    const renderCreate = () => (
        <PopupInput
            action={() => createSlist(createTitle)}
            title={t("smartlist.create")}
            button={t("create")}
            onChange={(e) => setCreateTitle(e.target.value)}
            name="createTitle"
            trigger={
                <button className="cta">
                    <span>{t("smartList.createNew")}</span>
                </button>
            }
        />
    );

    return (
        <div className="smartlists">
            <div className="smartlists-title">
                <h2>{t("smartlist.title")}</h2>
                {renderCreate()}
            </div>
            <Accordion activeKey={activeKey}>
                {slists && slists.map((item, index) => (
                    <div className="smartlists-card" key={item.id}>
                        <SmartlistHeader
                            slist={item}
                            activeKey={activeKey}
                            index={item.id}
                            selectSlist={selectSlist}
                        />
                        <Accordion.Collapse eventKey={item.id}>
                            <CurrentSmartlist
                                slist={item}
                                currentSlist={currentSlist}
                                setActiveKey={setActiveKey}
                            />
                        </Accordion.Collapse>
                    </div>
                ))}
            </Accordion>
            {slists && slists.length === 0 &&
                <span className="title">
                    {t("orders.empty")}
                </span>
            }
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        getSlists: () => dispatch(actionCreators.getSlists()),
        fetchSlist: (id) => dispatch(actionCreators.fetchSlist(id)),
        createSlist: (title) => dispatch(actionCreators.createSlist(title)),
    };
}

export default connect(null, mapDispatchToProps)(Smartlists);