import React from "react"
import { Link, withRouter } from "react-router-dom";

import "./header-item.styles.scss";

const HeaderProfileItem = (props) => {
    const actived = props.history.location.pathname === "/profile";
    return (
        <Link className="link" to={props.path}>
            <div className={`header-item ${actived ? "header-item-actived" : ""}`}
                onClick={props.onClick}
            >
                <div className="header-item-content">
                    <div className="header-item-content-logged">
                        {props.children}
                    </div>
                    {props.label &&
                        <div className="header-item-content-label">{props.label}</div>
                    }
                </div>
            </div>
        </Link>
    );
}

export default withRouter(HeaderProfileItem);