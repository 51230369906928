import React from "react"
import { useTranslation } from "react-i18next";


const UpdateTags = ({ filters, register, handleCheckboxValues }) => {
    const { t } = useTranslation();
    return (
        <>
            {
                filters && filters?.map((item, index) => {
                    if (!item.specialTag) {
                        return (
                            <div className="custom-checkbox" key={item.id}>
                                <input className="custom-control-input" type="checkbox" id={item.id} name={item.description}
                                    defaultChecked={item && item.checked}
                                    disabled={item && item.specialTag}
                                    ref={register}
                                    onChange={() => handleCheckboxValues(item)}
                                />
                                <label className="custom-control-label" htmlFor={item.id}>
                                    {item.description}
                                </label>
                            </div>
                        )
                    }
                })
            }
        </>
    );
}

export default UpdateTags;



