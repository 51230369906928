import React, { useState, useEffect } from "react";
import StoreInfo from "./StoreInfo/StoreInfo"
import StoreDetailMap from "../../../components/UI/Maps/StoreDetailMap/StoreDetailMap";
import CustomBreadcrumb from "../../../components/UI/Breadcrumb/CustomBreadcrumb";
import { storesService } from "../../../services/basis/StoresService";
import { useTranslation } from 'react-i18next';

import "./store.styles.scss";

const Store = ({ match }) => {
    const { t } = useTranslation();
    const storeId = match.params.storeId;
    const [store, setStore] = useState();
    useEffect(() => {
        storesService.getStore(storeId)
            .then(response => {
                setStore(response.data)
            }).catch(error => {
                console.log(error);
            })
    }, [storeId])

    if (store) {
        return (
            <div className="store">
                <CustomBreadcrumb
                    level1={{
                        path: "/stores",
                        label: "stores",
                    }}
                    level2={{
                        path: "/stores",
                        label: store.name,
                        active: true
                    }}
                />
                <h2>{t("stores.title")}</h2>
                <div className="store-content">
                    {store.address && store.address.latitude && store.address.longitude &&
                        <StoreDetailMap lat={store.address.latitude} lng={store.address.longitude} zoom={18} />
                    }
                    <StoreInfo store={store} />
                </div>
            </div>
        );
    } else return null
}

export default Store;





