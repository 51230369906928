import React, { useEffect, useState } from 'react'
import CustomBreadcrumb from '../../components/UI/Breadcrumb/CustomBreadcrumb';
import { leafletsService } from "./Service"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"
import { format, parseISO } from "date-fns";

import "./leaflets.styles.scss";

const Leaflets = () => {

    const [leaflets, setLeaflets] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        leafletsService.leaflets().then(response => {
            setLeaflets(response.data)
        });
    }, [])

    return (
        <div className="leaflets">
            <CustomBreadcrumb
                level1={{
                    path: "/leaflets",
                    label: "leaflets.title",
                    active: true
                }} />
            <div className="leaflets-title">{t("leaflets.title")}</div>
            <div className="leaflets-content">
                <div className="grid">
                    {leaflets && leaflets.map(item => (
                        <div key={item.id} className="">
                            <Link to={`/leaflets/${item.id}`} style={{ textDecoration: "none" }}>
                                <div key={item.id}>
                                    <img src={item.coverImage} alt="" />
                                    <div className="leaflet-title">{item.title}</div>
                                    <div className="leaflet-subtitle">
                                        {t("validFrom")} {format(parseISO(item.validFrom), "dd/MM/yyyy")} {t("validTo")} {format(parseISO(item.validTo), "dd/MM/yyyy")}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            {leaflets && leaflets.length <= 0 &&
                <div className="text-center">
                    <h4 className="primary-text">{t("leaflets.empty")}</h4>
                </div>
            }
        </div>
    );
}

export default Leaflets;

