import * as actionTypes from "./actionTypes"
import { smartlistService } from "../../../services/slists/SmartlistService"
import toaster from "../../../utils/toaster";
import i18n from "../../../i18n"

export const favorites = (favorites) => {
    return {
        type: actionTypes.FAVORITES,
        favorites: favorites
    }
}

export const getFavorites = () => {
    return (dispatch) => {
        smartlistService.getFavorites()
            .then(response => {
                dispatch(favorites(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const add = (favorites) => {
    return {
        type: actionTypes.ADD_FAVORITE,
        favorites: favorites
    }
}

export const addFavorite = (barcode) => {
    return (dispatch) => {
        smartlistService.addFavorite(barcode)
            .then(response => {
                dispatch(add(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const remove = (favorites) => {
    return {
        type: actionTypes.DELETE_FAVORITE,
        favorites: favorites
    }
}

export const deleteFavorite = (barcode) => {
    return (dispatch) => {
        smartlistService.deleteFavorite(barcode)
            .then(response => {
                dispatch(remove(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const slists = (slists) => {
    return {
        type: actionTypes.SLISTS,
        slists: slists
    }
}

export const getSlists = () => {
    return (dispatch) => {
        smartlistService.getSlists()
            .then(response => {
                dispatch(slists(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const slistsItemCreate = (currentSlist) => {
    return {
        type: actionTypes.SLISTS_ITEM_CREATE,
        currentSlist: currentSlist
    }
}

export const itemCreate = (listId, barcode, amount) => {
    return (dispatch) => {
        smartlistService.itemsCreate(listId, barcode, amount)
            .then(response => {
                toaster.standard(i18n.t("smartlist.product.added"))
                dispatch(slistsItemCreate(response.data))
                
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const slistsItemCreateCustom = (currentSlist) => {
    return {
        type: actionTypes.SLISTS_ITEM_CREATE_CUSTOM,
        currentSlist: currentSlist
    }
}

export const itemCreateCustom = (listId, amount, description) => {
    return (dispatch) => {
        smartlistService.itemsCreateCustom(listId, amount, description)
            .then(response => {
                dispatch(slistsItemCreateCustom(response.data))
                toaster.standard(i18n.t("smartlist.product.added"))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const slistsItemDelete = (currentSlist) => {
    return {
        type: actionTypes.SLISTS_ITEM_DELETE,
        currentSlist: currentSlist
    }
}

export const itemDelete = (listId, itemId) => {
    return (dispatch) => {
        smartlistService.itemsDelete(listId, itemId)
            .then(response => {
                dispatch(slistsItemDelete(response.data))
                toaster.standard(i18n.t("smartlist.product.deleted"))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const slistsItemUpdate = (currentSlist) => {
    return {
        type: actionTypes.SLISTS_ITEM_UPDATE,
        currentSlist: currentSlist
    }
}

export const itemUpdate = (listId, itemId, amount) => {
    return (dispatch) => {
        smartlistService.itemsUpdate(listId, itemId, amount)
            .then(response => {
                dispatch(slistsItemUpdate(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const fetchSmartlist = (slist) => {
    return {
        type: actionTypes.FETCH_SLIST,
        currentSlist: slist
    }
}

export const fetchSlist = (id) => {
    return (dispatch) => {
        smartlistService.fetchSlist(id)
            .then(response => {
                dispatch(fetchSmartlist(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const createSmartlist = (currentSlist, slists) => {
    return {
        type: actionTypes.CREATE_SLIST,
        currentSlist: currentSlist,
        slists: slists
    }
}

export const createSlist = (title) => {
    return (dispatch) => {
        smartlistService.createSlist(title)
            .then(response => {
                smartlistService.getSlists()
                    .then(resp => {
                        dispatch(createSmartlist(response.data, resp.data))
                    }).catch((error) => {
                        console.log(error);
                    })

            }).catch((error) => {
                console.log(error);
            })
    }
};

export const deleteSmartlist = (slists) => {
    return {
        type: actionTypes.DELETE_SLIST,
        slists: slists
    }
}

export const deleteSlist = (id) => {
    return (dispatch) => {
        smartlistService.deleteSlist(id)
            .then(response => {
                smartlistService.getSlists()
                    .then(resp => {
                        dispatch(deleteSmartlist(resp.data))
                    }).catch((error) => {
                        console.log(error);
                    })
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const renameSmartlist = (currentSlist, slists) => {
    return {
        type: actionTypes.CREATE_SLIST,
        currentSlist: currentSlist,
        slists: slists
    }
}

export const renameSlist = (id, title) => {
    return (dispatch) => {
        smartlistService.renameSlist(id, title)
            .then(response => {
                smartlistService.getSlists()
                    .then(resp => {
                        dispatch(renameSmartlist(response.data, resp.data))
                    }).catch((error) => {
                        console.log(error);
                    })

            }).catch((error) => {
                console.log(error);
            })
    }
};

export const copySmartlist = (currentSlist, slists) => {
    return {
        type: actionTypes.CREATE_SLIST,
        currentSlist: currentSlist,
        slists: slists
    }
}

export const copySlist = (id, title) => {
    return (dispatch) => {
        smartlistService.copySlist(id, title)
            .then(response => {
                smartlistService.getSlists()
                    .then(resp => {
                        dispatch(copySmartlist(response.data, resp.data))
                    }).catch((error) => {
                        console.log(error);
                    })

            }).catch((error) => {
                console.log(error);
            })
    }
};



