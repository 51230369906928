import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import axios from 'axios'
import { authHeader } from "../../../utils/authHeader";

export default function notes(cartId, notes) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/notes`,
            {
                cartId: cartId,
                notes: notes
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}