import React from "react"
import { Translation } from "react-i18next";
import zipCodesCities from "./Service";
import { debounce } from "lodash"

export default class DropdownInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            input: "",
            zipCities: null
        }
    }

    componentDidMount() {
        const { defaultZipCode, defaultCityName } = this.props
        if (
            (defaultZipCode && defaultZipCode !== "") ||
            (defaultCityName && defaultCityName !== "") 
            ) {
            zipCodesCities(defaultCityName, defaultZipCode)
                .then(response => {
                    if (response.data && response.data.length > 0) {
                        this.setState({ zipCities: response.data })
                        this.handleChange(response.data[0])
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.input !== this.state.input) {
            this.getItems(this.state.input);
        }
    }

    handleInput = (e) => {
        if (e.target.value !== "") {
            this.setState({ [e.target.name]: e.target.value, isOpen: true });
        } else {
            this.setState({ [e.target.name]: e.target.value, isOpen: false });
            const item = {}
            item.zipCode = ""
            this.props.setZipCode(item)
        }
    }

    getZipCities = (input) => {
        if (this.state.input !== "") {
            zipCodesCities(this.state.input)
                .then(response => {
                    this.setState({ zipCities: response.data })
                }).catch(error => {
                    console.log(error)
                })
        } else {
            this.setState({ zipCities: null, isOpen: false })
        }
    }

    getItems = debounce((input) => {
        this.getZipCities(input)
    }, 1000)


    componentWillUnmount() {
        document.removeEventListener('click', this.globalClickListener)
    }

    globalClickListener = () => {
        this.setState({ isOpen: false }, () => {
            document.removeEventListener('click', this.globalClickListener)
        })
    }

    toggleOpen = () => {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => {
            if (this.state.isOpen) {
                document.addEventListener('click', this.globalClickListener)
            }
        })
    }

    handleChange = (item) => {
        this.setState({
            isOpen: false,
            input: `${item.cityName} - ${item.zipCode}`
        })
        this.props.setZipCode(item)
    }

    render() {
        const { zipCities, input } = this.state;
        const menuClass = `dropdown-items ${this.state.isOpen ? "dropdown--isOpen" : ""} 
        dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return (
            <Translation>
                {t =>
                    <div className="dropdown mb-3">
                        <input
                            name="input"
                            placeholder={this.props.placeholder}
                            onChange={this.handleInput}
                            value={input}
                            type="search"
                        />
                        <div className={menuClass} aria-labelledby="dropdownMenuButton">
                            {zipCities && zipCities.map((item, index) => (
                                <span key={index} className="dropdown-item"
                                    onClick={() => this.handleChange(item)}>
                                    {item.cityName} - {item.zipCode}
                                </span>
                            ))}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

