import React from "react"
import { Translation } from "react-i18next";
import { ReactComponent as Alert } from "../../../images/card-alert.svg"

import "./validate-input.styles.scss";

const ValidateInput = ({ value, watch, label, register, errors, placeholder, ...otherProps }) => {
    const filterInput = (event) => {
        if (otherProps.pattern && event.key !== 'Backspace') {
            const regex = RegExp(otherProps.pattern);
            if (!regex.test(event.key)) {
                event.preventDefault();
            }
        }
    }
    const transformInput = (event) => {
        if (otherProps.uppercase) {
            event.target.value = event.target.value.toUpperCase();
        }
    }
    return (
        <Translation>
            {t =>
                <div className="validate-input">
                    {label &&
                        <label>{t(label)}</label>
                    }
                    <input className={`form-input ${errors ? "input-required" : ""}`}
                        onKeyDown={filterInput}
                        onChange={transformInput}
                        defaultValue={value}
                        placeholder={t(placeholder)}
                        ref={register}
                        {...otherProps}
                    />
                    {errors && errors.message &&
                        <div className="invalid-message">
                            <Alert />
                            {errors.message}
                        </div>
                    }
                    {errors && errors.type === "passwordMatch" && (
                        <div className="invalid-message">
                            <Alert />
                            {t("passwordChange.invalidPassword")}
                        </div>
                    )}
                </div>
            }
        </Translation>
    );
}

export default ValidateInput;