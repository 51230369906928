import React from "react";
import NewHeader from "./NewHeader/NewHeader";
import MobileHeader from "./MobileHeader/MobileHeader"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions"
import { Service } from "./NewHeader/Service";
import { debounce } from "lodash"
import dayOfWeek from "../../../utils/dateUtils";
import Disponibility from "../../../features/Disponibility/Disponibility";
import { isMobile } from "react-device-detect";
class ResponsiveHeader extends React.Component {
    constructor(props) {
        super(props);
        this.inputFocus = this.utilizeFocus()
        const { cart } = this.props
        this.state = {
            isOpen: false,
            searchInput: "",
            category: "",
            searchData: null,
            tips: null,
            categories: null,
        };
    }

    utilizeFocus = () => {
        const ref = React.createRef()
        const setFocus = () => {
            ref.current && ref.current.focus()
        }
        return { setFocus, ref }
    }
    componentDidMount() {
        if (this.state.searchInput !== "") {
            Service.autoSuggestion(this.state.searchInput)
                .then(response => {
                    if (response.data && response.data.length > 0) {
                        this.setState({ tips: response.data.tips })
                        this.setState({ categories: response.data.categories })
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.searchInput !== this.state.searchInput) {
            this.getItems(this.state.searchInput);
        }

        if (this.props.location.key !== prevProps.location.key
            && this.props.location.pathname !== "/search") {
            this.setState({ searchInput: "" })
        }
    }

    handleInput = (e) => this.setState({ searchInput: e.target.value, isOpen: true });

    getSearchData = (searchInput) => {
        if (this.state.searchInput !== "") {
            Service.autoSuggestion(this.state.searchInput)
                .then(response => {
                    this.setState({ tips: response.data.tips })
                    this.setState({ categories: response.data.categories })
                }).catch(error => {
                    console.log(error)
                })
        } else {
            this.setState({ tips: null, isOpen: false })
        }
    }

    getItems = debounce((searchInput) => {
        this.getSearchData(searchInput)
    }, 1000)


    componentWillUnmount() {
        document.removeEventListener('click', this.globalClickListener)
    }

    globalClickListener = () => {
        this.setState({ isOpen: false }, () => {
            document.removeEventListener('click', this.globalClickListener)
        })
    }

    cancelSearchItem = () => {
        this.setState({
            searchInput: ""
        });
        this.inputFocus.setFocus()
    }

    handleChangeTips = (item) => {
        this.setState({
            isOpen: false,
            searchInput: `${item}`
        })

        this.props.history.push({
            pathname: "/search",
            state: {
                searchInput: `${item}`,
            }
        })
    }
    handleChangeCategories = (item) => {
        this.setState({
            isOpen: false,
        })
        this.props.history.push({
            pathname: "/search",
            state: {
                searchInput: this.state.searchInput,
                //searchInput: this.state.tips[0],
                categoryId: `${item.categoryId}`
            }
        })
    }

    setCategory = (item) => this.setState({ category: item })


    submitSearch = (e) => {
        e.preventDefault();
        this.setState({
            isOpen: false
        })
        this.props.selectSearchInput(this.state.searchInput)
        this.props.history.push({
            pathname: "/search",
            state: {
                searchInput: this.state.searchInput,
            }
        })
    }

    renderTotal = (cart) => {
        if (cart) {
            if (cart.netTotal) {
                return `${cart.netTotal?.toFixed(2)} \u20AC`;
            } else {
                return `0.00 \u20AC`;
            }
        } else {
            return `0.00 \u20AC`;
        }
    }

    render() {
        const { showModalLogin, user } = this.props;
        return (
            <>
                <NewHeader
                    {...this.props}
                    renderTotal={this.renderTotal}
                    searchInput={this.state.searchInput}
                    onChange={this.handleInput}
                    tips={this.state.tips}
                    categories={this.state.categories}
                    availability={this.props.availability}
                    isOpen={this.state.isOpen}
                    showModalLogin={showModalLogin}
                    handleChangeTips={this.handleChangeTips}
                    handleChangeCategories={this.handleChangeCategories}
                    submitSearch={this.submitSearch}
                    setCategory={this.setCategory}
                    cancelSearchItem={this.cancelSearchItem}
                    inputFocus={this.inputFocus}
                />
                <MobileHeader
                    {...this.props}
                    renderTotal={this.renderTotal}
                    searchInput={this.state.searchInput}
                    onChange={this.handleInput}
                    tips={this.state.tips}
                    categories={this.state.categories}
                    availability={this.props.availability}
                    isOpen={this.state.isOpen}
                    showModalLogin={showModalLogin}
                    handleChangeTips={this.handleChangeTips}
                    handleChangeCategories={this.handleChangeCategories}
                    submitSearch={this.submitSearch}
                    setCategory={this.setCategory}
                    cancelSearchItem={this.cancelSearchItem}
                    inputFocus={this.inputFocus}
                />
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        availability: state.cart.availability,
        defaultStore: state.user.defaultStore,
        defaultDelivery: state.user.defaultDelivery
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showModalLogin: () => dispatch(actionCreators.showLogin(true)),
        selectSearchInput: (input) => dispatch(actionCreators.search(input)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponsiveHeader));
