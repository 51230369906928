import CustomError from "./customError";
import i18n from "../i18n"
import toaster from "./toaster";

export function checkResponse(response) {
    if (response.responseCode && response.responseCode !== 0) {
        if (response.responseCode === 24) {
            //for check clickcoop flag
            return response;
        } else if (response.responseCode === 42 || response.responseCode === 43 || response.responseCode === 44 || response.responseCode === 4004) {
            //for registration
            return response
        } else {
            // throw new CustomError(response.responseCode, errorMessage(response));
            errorMessage(response);
        }
    }
    return response;
}

function errorMessage(response) {
    console.log(response);
    switch (response.responseCode) {
        case 1:
            if (response.errorMessage.includes('Unauthorized')) {
                toaster.standard(i18n.t("error.wrongCredentials"))
            } else {
                toaster.standard(i18n.t("error.generic"))
            }
            return response.errorMessage;
        case 2:
            toaster.standard(i18n.t("error.userNotFound"))
            return i18n.t("error.userNotFound")

        case 4:
            toaster.standard(i18n.t("error.generic"))
            return i18n.t("error.generic")

        case 30:
            toaster.standard(i18n.t("error.invalidPhone"))
            return i18n.t("error.invalidPhone")

        case 42:
            toaster.standard(i18n.t("error.deliveryNotEnabled"))
            return i18n.t("error.deliveryNotEnabled")

        case 2035:
            toaster.standard(i18n.t("error.lastSlist"))
            return i18n.t("error.lastSlist")

        case 2036:
            toaster.standard(i18n.t("error.listEmpty"))
            return i18n.t("error.listEmpty")

        case 2222:
            toaster.standard(i18n.t("error.homeServiceNotEnabled"))
            return i18n.t("error.homeServiceNotEnabled")

        case 4000:
            toaster.standard(i18n.t("error.genericError"))
            return i18n.t("error.genericError")

        case 4004:
            toaster.standard(i18n.t("error.cartNotFound"))
            return i18n.t("error.cartNotFound")

        case 4028:
            toaster.standard(i18n.t("error.timeslotNotAvailable"))
            return i18n.t("error.timeslotNotAvailable")

        case 4029:
            toaster.standard(i18n.t("error.timeExceeded"))
            return i18n.t("error.timeExceeded")

        case 4030:
            toaster.standard(i18n.t("error.productNotFound"))
            return i18n.t("error.productNotFound")

        case 4031:
            toaster.standard(i18n.t("error.productNotValid"))
            return i18n.t("error.productNotValid")

        case 4033:
            toaster.standard(i18n.t("error.minimunNet") + response.errorMessage)
            return i18n.t("error.minimunNet") + response.errorMessage

        case 4034:
            toaster.standard(i18n.t("error.maximumReached"))
            return i18n.t("error.maximumReached")

        case 4037:
            toaster.standard(i18n.t("error.missingBillingAddress"))
            return i18n.t("error.missingBillingAddress")

        case 4038:
            toaster.standard(i18n.t("error.bundlesExceeded"))
            return i18n.t("error.bundlesExceeded")

        case 4205:
            toaster.standard(i18n.t("error.orderWrongStatus"))
            return i18n.t("error.orderWrongStatus")
        
        case 4209:
            toaster.standard(i18n.t("error.cartAlreadyOpen"))
            return i18n.t("error.cartAlreadyOpen")

        case 6002:
            toaster.standard(i18n.t("error.expiredCoupon"))
            return i18n.t("error.expiredCoupon")

        case 6003:
            toaster.standard(i18n.t("error.alreadyUsed"))
            return i18n.t("error.alreadyUsed")

        case 6004:
            toaster.standard(i18n.t("error.invalidCoupon"))
            return i18n.t("error.invalidCoupon")

        case 6005:
            toaster.standard(i18n.t("error.couponGreater"))
            return i18n.t("error.couponGreater")

        case 6008:
            toaster.standard(i18n.t("error.totalMinCoupon"))
            return i18n.t("error.totalMinCoupon")

        case 6009:
            toaster.standard(i18n.t("error.couponNotFound"))
            return i18n.t("error.couponNotFound")

        default:
            toaster.standard(i18n.t("error.generic"))
            return response.errorMessage;
    }
}