/* import React, { useState, useEffect } from "react";


import "./local-products.styles.scss";*/
import { history } from "../../../App";

import React from "react";
import CardProduct from "../../../components/Products/CardProduct/CardProduct";
import Slider from "react-slick";
import { Translation } from "react-i18next";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { connect } from "react-redux"
import BannerCardProduct from "../../../components/Products/BannerCardProduct/BannerCardProduct";

import "./home-holidays.styles.scss";
import { ReactComponent as ArrowRight } from "../../../images/arrow_right1.svg";
import { ReactComponent as ArrowLeft } from "../../../images/arrow_left.svg";


class HomeHolidays extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            products: []
        };
    }

    componentDidMount() {
        const storeId = this.props.cart ? this.props.cart.storeId :
            (this.props.defaultStore ? this.props.defaultStore.id : null);
        ecommerceService.featuredProducts(storeId).then(response => {
            this.setState({
                isLoaded: true,
                products: response.data
            })
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const storeId = this.props.cart ? this.props.cart.storeId :
            (this.props.defaultStore ? this.props.defaultStore.id : null);
        if (nextProps && nextProps.cart) {
            if (storeId !== nextProps.cart.storeId) {
                ecommerceService.featuredProducts(nextProps.cart.storeId).then(response => {
                    this.setState({
                        isLoaded: true,
                        products: response.data
                    })
                });
            }
        } else {
            ecommerceService.featuredProducts(this.props.defaultStore.id).then(response => {
                this.setState({
                    isLoaded: true,
                    products: response.data
                })
            });
        }
    }

    render() {
        const { products, isLoaded } = this.state;
        if (isLoaded) {
            return (
                <Translation>
                    {t =>
                        <div className="local-home-holidays">
                            <div className="local-home-holidays-content">
                                <div className="local-home-holidays-text">
                                    <div className="title">Le nostre<br />ricette</div>
                                    <div className="subtitle">Le migliori ricette selezionate<br />per mangiare in modo sano e gustoso</div>
                                    <div onClick={() => history.push("/recipies")}>
                                        <span className="link" style={{ color: "white", fontWeight: "bold", fontSize: "24px" }}><u>Scopri di piu</u><ArrowRight /></span>
                                    </div>
                                    {/*  <button className="cta" onClick={() => history.push("/recipies")}>
                                        <span>Scopri tutti i prodotti</span>
                                    </button> */}
                                </div>
                                <div className="local-home-holidays-content-card">
                                   {/*  <BannerCardProduct item={data[0]} />
                                    <BannerCardProduct item={data[0]} /> */}
                                    <BannerCardProduct item={products[0]}/>
                                    <BannerCardProduct item={products[1]}/>  
                                </div>
                                {/*  <div className="local-home-holidays-content-card">
                                    <CardProduct item={products[0]} />
                                    <CardProduct item={products[0]} />
                                </div> */}
                                {/* <div>
                                    <CardProduct item={products[0]} />
                                </div> */}
                            </div>
                            {/*  <h2 className="text-center">
                                {t("home.featuredProducts")}
                            </h2> */}
                            {/* <Slider {...settings}> */}
                            {/* {products && products.map((item) => ( */}
                            {/* <CardProduct item={products[0]} /> */}
                            {/* ))} */}
                            {/* </Slider> */}
                            {/* <div className="carousel-dots"></div> */}
                            {/* <div className="products-grid">
                                {products && products.map((item) => (
                                    <CardProduct key={item.id} item={item} />
                                ))}
                            </div> */}
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}

/* function appendDots(dots) {
    return (<div>
        <div className="carousel-dots">
            <ul>{dots}</ul>
        </div>
    </div>);
} */

/* const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <SampleArrow><ArrowRight /></SampleArrow>,
    prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};
*/

function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
}

/* const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    appendDots: appendDots,
    arrows: false,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }
    ]
}; */

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        defaultStore: state.user.defaultStore,
    };
}

export default connect(mapStateToProps)(HomeHolidays);


/* const LocalProducts = () => {
    return (
        <div className="local-products">
            <div className="local-products-content">
                <div className="local-products-text">
                    <div className="title">I prodotti del territorio</div>
                    <div className="subtitle">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</div>
                    <button className="cta" onClick={()=>history.push("/discoverproducts")}>
                        <span>Scopri tutti i prodotti</span>
                    </button>
                </div>
                <div>Prodotti</div>
            </div>
        </div>
    );
}

export default LocalProducts; */









/* import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { history } from "../../../App";
import { ReactComponent as ArrowRight } from "../../../images/arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../../images/arrow_left.svg";

import "./home-holidays.styles.scss";

const HomeHolidays = () => {
    const { t } = useTranslation();
    return (
        <div className="home-holidays">
            <div className="content">
                <h5 className="subtitle">Pane e Pasticceria</h5>
                <h2 className="title">Fatti conquistare dalla magia delle feste</h2>
                <button className="cta" onClick={() => history.push("/recipies")}>
                    <span>SCOPRI TUTTI I PRODOTTI</span>
                </button>
                <Slider {...settings}>
                    <div className="home-holidays-item"></div>
                    <div className="home-holidays-item"></div>
                    <div className="home-holidays-item"></div>
                    <div className="home-holidays-item"></div>
                </Slider>
                <div className="carousel-dots"></div>
            </div>
        </div>
    );
}

function appendDots(dots) {
    return (<div>
        <div className="carousel-dots">
            <ul>{dots}</ul>
        </div>
    </div>);
}

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    appendDots: appendDots,
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};

export default HomeHolidays; */