import React, { useEffect } from "react";
import { Translation } from 'react-i18next';
import { withRouter } from "react-router-dom"
import { useDispatch } from "react-redux"
import { history } from "../../App";
import { ReactComponent as SmileyIcon } from '../../images/sad-smiley-icon.svg';
import * as actionCreators from "../../redux/store/actions/index"
import "./payment-failed.styles.scss"
import CheckoutHeader from "../Cart/CheckoutHeader/CheckoutHeader";

const PaymentFailed = ({ location, match }) => {
    const order = location?.state?.orders ? location?.state?.orders : "";
    const orderCode = match.params.orderCode;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionCreators.current())
    }, []);

    return (
        <Translation>
            {t =>
                <div className="checkout-payment-failed">
                    <CheckoutHeader orderCode={orderCode} />
                    <div className="checkout-payment-failed-title text-center" style={{ marginTop: "50px", color: "#d80014" }}>
                        PAGAMENTO FALLITO
                    </div>
                    {order && order.cart && order.cart.payment && order.cart.payment.mode === "XPAY" &&
                        <div className="checkout-payment-failed-subtitle">
                            <div>
                                {t("confirm.subtitle")}
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                                {t("confirm.subtitleBottom")}
                            </div>
                        </div>
                    }
                    <div className="checkout-payment-failed-title text-center">
                        {t("confirm.orderNumber")} <span style={{ fontFamily: "Montserrat-Bold" }}>{orderCode}</span>
                    </div>
                    <div className="checkout-payment-failed-title text-center">
                        <SmileyIcon />
                    </div>
                    {/*  <div className="checkout-payment-failed-email text-center">
                        <span>Riceverai una mail a </span>
                        <span><b>{order && order.cart && order.cart.profileEmail}</b> </span>
                        <span>con le informazioni e il ritiro della tua spesa</span>
                    </div> */}
                    <div className="text-center">
                        <button style={{ width: "198px" }} className="cta"
                            onClick={() => {
                                history.push("/profile/orders");
                            }}>
                            <span>RIPROVA</span>
                        </button>
                    </div>
                </div>
            }
        </Translation>
    );
}

export default withRouter(PaymentFailed);

