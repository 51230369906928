import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import { ReactComponent as AccordionDown } from "../../images/accordion-down.svg";
import { ReactComponent as AccordionUp } from "../../images/accordion-up.svg";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions"

import "./filters.styles.scss";

const Filters = ({
    onlyPromotions,
    onlyBranded,
    tag,
    tags,
    setOnlyBranded,
    setOnlyPromotions,
    selectTag,
    showDefaultFilters
}) => {
    const { t } = useTranslation();
    const [showFilters, setShowFilters] = useState(showDefaultFilters ? showDefaultFilters : false);

     useEffect(() => {
        // reset filters on component unmount 
        return () => {
            setOnlyPromotions(false)
            setOnlyBranded(false)
           // selectTag("");
        }
    }, [setOnlyPromotions, setOnlyBranded]) 

    return (
        <div className="category-filters">
            {/*  <div className="category-filters-button">
                <div style={{display:"flex"}} onClick={() => setShowFilters(!showFilters)}>
                    <h5>{t("filters")}</h5>
                    {showFilters ?
                        <AccordionUp /> : <AccordionDown />
                    }
                </div>

            </div> */}
            <div className="view_all" onClick={() => setShowFilters(!showFilters)}>
                <h5>{t("filters")}</h5>
                {showFilters ?
                    <AccordionUp /> : <AccordionDown />
                }
            </div>
            {/* <hr /> */}
            {showFilters &&
                <div className="category-filters-container">
                   {/*  <div>
                        <div className="tags">categorie</div>
                        <div className="custom-checkbox">
                            <input type="checkbox" className="custom-control-input"
                                value={onlyPromotions}
                                checked={onlyPromotions}
                                id="onlyPromotions" name="onlyPromotions"
                                onChange={(e) => setOnlyPromotions(e.target.checked)}
                            />
                            <label className="custom-control-label mobile-checkbox" htmlFor="onlyPromotions">
                                {t("onlyPromotions")}
                            </label>
                        </div>
                        <div className="custom-checkbox">
                            <input type="checkbox" className="custom-control-input"
                                value={onlyBranded}
                                checked={onlyBranded}
                                id="onlyBranded" name="onlyBranded"
                                onChange={(e) => setOnlyBranded(e.target.checked)}
                            />
                            <label className="custom-control-label mobile-checkbox" htmlFor="onlyBranded">
                                {t("onlyBranded")}
                            </label>
                        </div>
                    </div> */}
                    <hr />
                    <div className="tags">{t("tags")}</div>
                    {/* <div className="tags-container"> */}
                    <div className="custom-checkbox">
                        {tags && tags.map(category => (
                            <div key={category.id} className="custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                    id={category.id}
                                    name={category.tag}
                                    value={category.id}
                                    checked={tag === category.tag}
                                    onChange={(e) => selectTag(e.target.name)}
                                />
                                <label
                                    className="custom-control-label mobile-checkbox" htmlFor={category.id}>
                                    {category.description}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        onlyPromotions: state.categories.onlyPromotions,
        onlyBranded: state.categories.onlyBranded,
        tag: state.categories.tag,
        tags: state.categories.tags,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setOnlyPromotions: (onlyPromotions) => dispatch(actionCreators.onlyPromotions(onlyPromotions)),
        setOnlyBranded: (onlyBranded) => dispatch(actionCreators.onlyBranded(onlyBranded)),
        selectTag: (tag) => dispatch(actionCreators.tag(tag)),
    };
}

Filters.propTypes = {
    onlyPromotions: PropTypes.bool.isRequired,
    onlyBranded: PropTypes.bool.isRequired,
    tag: PropTypes.string.isRequired,
    setOnlyPromotions: PropTypes.func.isRequired,
    setOnlyBranded: PropTypes.func.isRequired,
    selectTag: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);