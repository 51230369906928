import React from "react";
import Counter from "../../../components/UI/NewCounter/NewCounter"
import NewCounter from "../../../components/UI/NewCounter/NewCounter";
import Placeholder from "../../../images/product-placeholder.png"
import { ReactComponent as Delete } from "../../../images/delete-icon.svg"
import translation from "../../../i18n"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import renderPrice from "../../../utils/renderPrice";
import ModalProductSubstitution from "../../../components/Products/ModalProduct/ModalProductSubstitution";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
import { ReactComponent as InformtaionIcon } from '../../../images/i-icon.svg';
import ProductDetailsPopup from "../../ProductDetailsPopup/ProductDetailsPopup";
import "./cart-item.styles.scss";
import toaster from "../../../utils/toaster";
import i18n from "../../../i18n";

class CartItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            checked: props.item.enableReplacement,
            product: null,
            showPopup: false,
            showButtons: false,
            unity: null
        }
    }

    componentDidMount() {
        if (this.props.item.attrib3) {
            this.setUnity(this.props.item);
        }
    }

    setUnity = (item) => {
        let steps = Math.ceil(item.weight / parseFloat(item.umStep?.toFixed(2)));
        let newUnity = parseInt(item.attrib3) * steps;
        this.setState({ unity: newUnity });
    }

    handleSubstituteProduct = (item, check, show) => {
        this.props.store(this.props.cart.id, item.barcode, item.amount, item.purchaseUm, item.replacementBarcode ? check : false);
    }

    showSubstituteProduct = (item, show) => {
        ecommerceService.getProductFromBarcode(item.replacementBarcode)
            .then(response => {
                console.log(response.data);
                this.setState({ open: true, product: response.data, showButtons: show });
            }).catch((error) => {
                console.log(error);
                toaster.standard(i18n.t('error.substitution'));
            })
    }

    incrementAmount = () => {
        const { item, cart, store, alternative } = this.props;
        let roundStep = parseFloat(item.umStep?.toFixed(2));
        let amount = item.purchaseUm === "PZ" ? item.amount : item.weight;
        let newAmount = +amount + roundStep;
        newAmount = newAmount?.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (item?.maxAmount) {
            if (newAmount <= item.maxAmount) {
                this.setState({ amount: newAmount })
                store(cart.id, item.barcode, newAmount, item.purchaseUm, alternative)
            }
        } else {
            this.setState({ amount: newAmount })
            store(cart.id, item.barcode, newAmount, item.purchaseUm, alternative)
        }
        if (item.attrib3) {
            let newUnity = this.state.unity + parseInt(item.attrib3);
            this.setState({ unity: newUnity });
        }
    }

    decrementAmount = () => {
        const { item, cart, store, alternative } = this.props;
        let roundStep = parseFloat(item.umStep?.toFixed(2));
        let amount = item.purchaseUm === "PZ" ? item.amount : item.weight;
        let newAmount = +amount - roundStep;
        newAmount = newAmount?.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (item.minWeight) {
            if (amount > item.minWeight) {
                this.setState({ amount: newAmount })
                store(cart.id, item.barcode, newAmount, item.purchaseUm, alternative)
            }
        } else if (amount > parseFloat(item.umStep?.toFixed(2))) {
            this.setState({ amount: newAmount })
            store(cart.id, item.barcode, newAmount, item.purchaseUm, alternative)
        }
        if (item.attrib3 && this.state.unity > item.attrib3) {
            let newUnity = this.state.unity - item.attrib3;
            this.setState({ unity: newUnity });
        }
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                if (item.promotions[0]?.labelCode) {
                    switch (item.promotions[0]?.labelCode) {
                        case "SOTTOCOSTO":
                            return <div className="isundercostcart">SOTTOCOSTO</div>
                        case "2x1":
                            return <div className="is2x1cart">2 al prezzo di 1</div>
                        case "2x1V":
                            return <div className="is2x1cart">-{item.promotions[0].valueGuest}€ con 2pz</div>
                        case "3x2":
                            return <div className="is3x2cart">3 al prezzo di 2</div>
                        case "2x1P":
                            return <div className="is3x2cart">-{item.promotions[0].valueGuest}% sul 2˚ pz</div>
                        default:
                            break;
                    }
                } else {
                    const label = item.promotions[0]?.valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                    return (
                        <p className="cart-product-description__promo">
                            {translation.t(label)}
                        </p>
                    );
                }
            } else {
                return null;
            }
        }
    }

    // renderPrices = (item, amount) => {
    //     let result;
    //     let is3x2 = item.promotions[0]?.labelCode === "3x2";
    //     let is2x1 = item.promotions[0]?.labelCode === "2x1";
    //     if (is2x1 || is3x2) {
    //         if (is2x1) {
    //             if (amount <= 1) {
    //                 return (
    //                     <div>
    //                         <p>
    //                             <span>{`${renderPrice(item.netPrice?.toFixed(2))} \u20AC / ${item?.um?.toLowerCase()}`}</span>
    //                         </p>
    //                     </div>
    //                 )
    //             } else {
    //                 let reduce = Math.floor(amount / 2)
    //                 let newPricePerUM = parseFloat(item.netPrice * (amount - reduce)?.toFixed(2) / amount).toFixed(2)
    //                 return (
    //                     <p>
    //                         <nobr>
    //                             <span className="mr-1 text-strike">
    //                                 {`${renderPrice(item.price?.toFixed(2))} \u20AC`}
    //                             </span>
    //                         </nobr>
    //                         <span>
    //                             {`${renderPrice(newPricePerUM)} \u20AC / ${item?.um?.toLowerCase()}`}
    //                         </span>
    //                     </p>
    //                 )
    //             }
    //         } else {
    //             if (amount <= 2) {
    //                 return (
    //                     <div>
    //                         <p>
    //                             <span>{`${renderPrice(item.netPrice?.toFixed(2))} \u20AC / ${item?.um?.toLowerCase()}`}</span>
    //                         </p>
    //                     </div>
    //                 )
    //             } else {
    //                 let reduce = Math.floor(amount / 3)
    //                 let newPricePerUM = parseFloat(item.netPrice * (amount - reduce)?.toFixed(2) / amount).toFixed(2)
    //                 return (
    //                     <p>
    //                         <nobr>
    //                             <span className="mr-1 text-strike">
    //                                 {`${renderPrice(item.price?.toFixed(2))} \u20AC`}
    //                             </span>
    //                         </nobr>
    //                         <span>
    //                             {`${renderPrice(newPricePerUM)} \u20AC / ${item?.um?.toLowerCase()}`}
    //                         </span>
    //                     </p>
    //                 )
    //             }
    //         }
    //     }
    //     if (item.price !== item.netPrice) {
    //         result =
    //             <p>
    //                 <nobr>
    //                     <span className="mr-1 text-strike">
    //                         {`${renderPrice(item.price?.toFixed(2))} \u20AC`}
    //                     </span>
    //                 </nobr>
    //                 <span>
    //                     {`${renderPrice(item.netPrice?.toFixed(2))} \u20AC / ${item?.um?.toLowerCase()}`}
    //                 </span>
    //             </p>
    //     } else {
    //         result = <div>
    //             <p>
    //                 <span>{`${renderPrice(item.netPrice?.toFixed(2))} \u20AC / ${item?.um?.toLowerCase()}`}</span>
    //             </p>
    //         </div>
    //     }
    //     return result
    // }
    renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <>
                    <p>
                        <nobr><span className="mr-1 text-strike">
                            {`${renderPrice(item.price?.toFixed(2))} \u20AC`}
                        </span>
                        </nobr>
                        <span>
                            {`${renderPrice(item.netPrice?.toFixed(2))} \u20AC / ${item?.um?.toLowerCase()}`}
                        </span>
                    </p>
                    {/* {item.vatConfigurationId && item.attrib3 &&
                        <p>
                            <span className="product-detail-netPrice d-none d-sm-inline">{`${item.attrib3} \u20AC / kg`}</span>
                        </p>
                    } */}
                </>
        } else {
            result = <div><p>
                <span>{`${renderPrice(item.netPrice?.toFixed(2))} \u20AC / ${item?.um?.toLowerCase()}`}</span>
            </p>
                {/* {item.vatConfigurationId && item.attrib3 &&
                    <p>
                        <span className="product-detail-netPrice d-none d-sm-inline">{`${item.attrib3} \u20AC / kg`}</span>
                    </p>
                } */}
            </div>
        }
        return result
    }

    render() {
        const { item, cart, store, remove, alternative } = this.props;
        let amount = item.purchaseUm === "PZ" ? item.amount : item.weight;
        const link = `/product/${item.ref.toString()}`;
        return (
            <div className="article">
                <div className="row no-gutters">
                    {/* Only for desktop device */}
                    <div className="article__image d-none d-sm-block">
                        {/*  <Link className="link"
                            to={{
                                pathname: link,
                                state: { product: item, isDetail: true }
                            }}>
                            <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                onError={(e) => { e.target.src = Placeholder }}
                            />
                        </Link> */}
                        <div className="link" onClick={() => this.setState({ showPopup: true })}>
                            <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                onError={(e) => { e.target.src = Placeholder }}
                            />
                        </div>
                    </div>

                    <div className="col pl-0 px-sm-0 pr-sm-4">
                        <div className="container-fluid article__centerer">
                            <div className="row" style={{ gap: "0" }}>

                                {/* Only for mobile device */}

                                <div className="col-3 d-block d-sm-none article__image">
                                    <Link
                                        to={{
                                            pathname: link,
                                            state: { product: item, isDetail: true }
                                        }}>
                                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                            onError={(e) => { e.target.src = Placeholder }}
                                        />
                                    </Link>
                                </div>

                                <div className="col-6 d-none d-lg-block d-sm-block col-sm-4 pr-0" style={{ borderRight: "1px solid #bababa", height: "55px" }}>
                                    <div className="article__info">
                                        <div>
                                            <div className="link" onClick={() => this.setState({ showPopup: true })}>
                                                <p className="article__name">{item.description2 ? item.description2 : item.description}</p>
                                            </div>
                                        </div>
                                        {/* <div className="col-2">
                                            <div className="link" onClick={() => this.setState({ showPopup: true })}>
                                                <span><InformtaionIcon /></span>
                                            </div>
                                        </div> */}
                                        {/* <span className="article__promo">{this.renderPromo(item)}</span> */}
                                    </div>
                                    {item.replacementBarcode &&
                                        <div className="substitute">
                                            <label className="switch">
                                                <input type="checkbox" onClick={(e) => this.handleSubstituteProduct(item, e.target.checked, true)} checked={alternative} />
                                                <span className="slider round mobile-slider"></span>
                                            </label>
                                            <label className="substitute-cart-label">Prodotto alternativo</label>
                                            <InformtaionIcon height={15} width={20} onClick={() => this.showSubstituteProduct(item, false)} />
                                        </div>}
                                </div>
                                <div className="col-9 d-block d-lg-none d-sm-none col-sm-4 pr-0">
                                    <div className="article__info">
                                        <div>
                                            <div className="link" onClick={() => this.setState({ showPopup: true })}>
                                                <p className="article__name">{item.description2 ? item.description2 : item.description}</p>
                                            </div>
                                        </div>
                                        {/* <div className="col-2">
                                            <div className="link" onClick={() => this.setState({ showPopup: true })}>
                                                <span><InformtaionIcon /></span>
                                            </div>
                                        </div> */}
                                        {/* <span className="col-3 article__promo">{this.renderPromo(item)}</span> */}
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className="substitutediv col-10">
                                            {item.replacementBarcode &&
                                                <div className="substitute">
                                                    <label className="switch">
                                                        <input type="checkbox" onClick={(e) => this.handleSubstituteProduct(item, e.target.checked, true)} checked={alternative} />
                                                        <span className="slider round mobile-slider"></span>
                                                    </label>
                                                    <label className="substitute-cart-label">Prodotto alternativo</label>
                                                    <InformtaionIcon height={20} width={30} onClick={() => this.showSubstituteProduct(item, false)} />
                                                </div>
                                            }
                                        </div>
                                        <div className="arcticle__remove col-2">
                                            <button style={{ color: "#ed1c1c" }} className="button-clean outline"
                                                onClick={() => remove(cart.id, item.barcode)}>
                                                <Delete />
                                                {/* <FontAwesomeIcon icon="times-circle" size="1x" /> */}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* <span className="vertical-line"></span> */}
                                <div className="col-2 d-none d-lg-flex d-sm-flex col-sm-2 text-center px-0 pl-sm-0" style={{ borderRight: "1px solid #bababa", height: "55px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div className="article__price">
                                        <span className="article__promo">{this.renderPromo(item)}</span>
                                        {this.renderPrices(item, amount)}
                                    </div>
                                </div>

                                {/*  <div className="col-1 col-sm-1 px-0 text-right text-sm-center d-block d-sm-none">
                                    <div className="article__remove">
                                        <button className="button-clean outline primary-text" onClick={() => remove(cart.id, item.barcode)}>
                                            <FontAwesomeIcon icon="times-circle" size="1x" />
                                        </button>
                                    </div>
                                </div> */}

                                {/* <div className="col-3 col-sm-1 px-0 pr-sm-auto" /> */}
                                {/* <span className="vertical-line"></span> */}
                                <div className="col-4 d-none d-lg-flex d-sm-flex col-sm-3 px-0 pr-sm-auto text-center article-amount-counter">
                                    <NewCounter
                                        value={item.purchaseUm === "PZ" ? item.amount : item.weight}
                                        um={item.purchaseUm}
                                        increment={this.incrementAmount}
                                        decrement={this.decrementAmount}
                                        // writable={true}
                                        attrib3={this.state.unity}
                                        // changeAmount={this.changeAmount}
                                        showUm={true}
                                        pattern="^\d(\.\d)*$"
                                        disabled={!item.available}
                                    />
                                </div>
                                <div className="col-6  d-flex d-lg-none d-sm-none " style={{ justifyContent: "left", alignItems: "center" }}>
                                    <span className="article-label">QUANTITA'</span>
                                </div>
                                <div className="col-6 d-flex d-lg-none d-sm-none col-sm-3 pr-sm-auto text-center" style={{ height: "55px", display: "flex", justifyContent: "right", alignItems: "center", marginTop: "10px" }}>
                                    <NewCounter
                                        value={item.purchaseUm === "PZ" ? item.amount : item.weight}
                                        um={item.purchaseUm}
                                        increment={this.incrementAmount}
                                        decrement={this.decrementAmount}
                                        // writable={true}
                                        attrib3={this.state.unity}
                                        // changeAmount={this.changeAmount}
                                        showUm={true}
                                        pattern="^\d(\.\d)*$"
                                        disabled={!item.available}
                                    />
                                </div>
                                {/* <span className="vertical-line"></span> */}
                                <div className="col-6 d-flex d-lg-none d-sm-none" style={{ justifyContent: "left", alignItems: "center" }}>
                                    <span className="article-label">PREZZO</span>
                                </div>
                                <div className="col-6 d-flex d-lg-none d-sm-none col-sm-2 text-center pl-sm-0" style={{ height: "55px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                    <div className="article__price">
                                        <span className="article__promo">{this.renderPromo(item)}</span>
                                        {this.renderPrices(item, amount)}
                                    </div>
                                </div>
                                <div className="col-4 d-none d-lg-flex d-sm-flex col-sm-2 px-0 text-center text-sm-center" style={{ borderRight: "1px solid #bababa", height: "55px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div className="article__total">
                                        <span>{`${renderPrice(item.rowNetPrice?.toFixed(2))} \u20AC`}</span>
                                    </div>
                                </div>
                                <div className="col-6 d-flex d-lg-none d-sm-none" style={{ justifyContent: "left", alignItems: "center" }}>
                                    <span className="article-label">TOTALE</span>
                                </div>
                                <div className="col-6 d-flex d-lg-none d-sm-none col-sm-2 text-center text-sm-center" style={{ height: "55px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                    <div className="article__total">
                                        <span>{`${renderPrice(item.rowNetPrice?.toFixed(2))} \u20AC`}</span>
                                    </div>
                                </div>
                                {/* <span className="vertical-line"></span> */}
                                <div className="col-1 d-none d-lg-flex d-sm-flex col-sm-1 px-0 text-right text-sm-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div className="arcticle__remove">
                                        <button style={{ color: "#ed1c1c" }} className="button-clean outline"
                                            onClick={() => remove(cart.id, item.barcode)}>
                                            <Delete />
                                            {/* <FontAwesomeIcon icon="times-circle" size="1x" /> */}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalProductSubstitution
                    open={this.state.open}
                    product={this.state.product}
                    showButtons={this.state.showButtons}
                    onHide={() => this.setState({ open: false })}
                    onConfirm={() => this.setState({ open: false, checked: true })}
                    renderPrice={renderPrice}
                />
                <ProductDetailsPopup
                    show={this.state.showPopup}
                    barcode={item.barcode}
                    itemRef={item.ref.toString()}
                    onClose={() => this.setState({ showPopup: false })}
                    cart={cart}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart
    };
}

const mapDispatchToProps = dispatch => {
    return {
        store: (cartId, barcode, amount, um, enableReplacement) => dispatch(actionCreators.store(cartId, barcode, amount, um, enableReplacement)),
        remove: (cartId, barcode) => dispatch(actionCreators.remove(cartId, barcode)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);