import React, { useState } from "react"
import ValidateInput from "../../../components/UI/Input/ValidateInput";
import Accepts from "../../../components/Accepts/Accepts";
import { Row, Col, Modal } from 'react-bootstrap'
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import DatePicker from "react-datepicker";
import moment from "moment";

import "./profile-update.styles.scss"

function ProfileUpdate(props) {

    const [showModal, setShowModal] = useState(false)

    return (
        <Translation>
            {t =>
                <div className="profile-update">
                    <Modal show={showModal} centered={true} onHide={() => setShowModal(false)} dialogClassName="profile-delete-modal">
                        <div style={{ padding: '30px' }}>
                            <div style={{ marginBottom: '1rem' }}>
                                <span style={{ fontFamily: 'Montserrat-Bold' }}>Sei sicuro di voler eliminare il profilo?</span><br /><br />
                                Il processo di eliminazione del profilo creato sul sito clickcoop.it non è immediato e prevede l'invio della richiesta a SAIT soc. coop. - Consorzio delle Cooperative di Consumo Trentine che la prenderà in carico e ne darà riscontro entro 48 ore previa verifica di tutte le condizioni necessarie.<br /><br />
                                Non è ad esempio possibile eliminare automaticamente un profilo che ha una spesa in corso.<br /><br />
                                In ogni caso puoi sempre recarti in un Punto Vendita per gestire la tua richiesta.<br /><br />
                                <span style={{ fontFamily: 'Montserrat-Bold' }}>Confermi di voler procedere con la richiesta di eliminazione?</span><br />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <button
                                    className="secondary-cta"
                                    onClick={() => { setShowModal(false) }}
                                >
                                    <span>{t("cancel")}</span>
                                </button>
                                <button
                                    className="cta"
                                    onClick={() => { props.deleteUser(); setShowModal(false) }}
                                >
                                    <span>{t("confirm")}</span>
                                </button>
                            </div>
                        </div>
                    </Modal>
                    <h2>{t("profileContainer.yourData")}</h2>

                    <Row className="signup-row">
                        <Col md={6}>
                            <ValidateInput
                                name="firstName"
                                label="signup.firstName"
                                type="text"
                                pattern="[A-Za-z\s]"
                                value={props.user && props.user.firstName}
                                disabled
                            />
                        </Col>
                        <Col md={6}>
                            <ValidateInput
                                name="lastName"
                                label="signup.lastName"
                                type="text"
                                pattern="[A-Za-z\s]"
                                value={props.user && props.user.lastName}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row className="signup-row">
                        <Col md={3}>
                            <ValidateInput
                                name="Codice fiscale"
                                label="Codice fiscale"
                                type="text"
                                pattern="[A-Za-z\s]"
                                value={props.user && props.user.code}
                                disabled
                            />
                        </Col>
                        <Col md={3} className="validate-input">
                            <label>{t('profile.birthDate')}</label>
                            <DatePicker
                                value={props.user && props.user.birthDate ? moment(props.user.birthDate).format('DD/MM/YYYY') : null}
                                locale="it"
                                placeholderText={t('profile.birthDate')}
                                disabled
                                customInput={<input disabled style={{ width: '100%' }} />}
                            />
                        </Col>
                        <Col md={6}>
                            <ValidateInput
                                name="email"
                                label="E mail"
                                type="text"
                                pattern="[A-Za-z\s]"
                                value={props.user && props.user.email}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row className="signup-row">
                        <Col md={6}>
                            <ValidateInput
                                name="Carta incooperazione"
                                label="Carta incooperazione"
                                type="text"
                                pattern="[0-9]"
                                value={props.user && props.user.cards && props.user.cards[0] && props.user.cards[0].code}
                                disabled
                            />
                        </Col>
                        <Col md={6}>
                            <ValidateInput
                                name="mobilePhone"
                                label="phoneNumber"
                                type="tel"
                                pattern="[0-9]"
                                value={props.user && props.user.mobilePhone}
                                disabled
                            />
                        </Col>
                    </Row>

                    <h3>{t('signup.privacy')}</h3>
                    <hr />

                    <Accepts user={props.user} />
                    <div className="buttons-container">
                        <div className="text-right" style={{ marginTop: "10px" }}>
                            <button className="cta" style={{ backgroundColor: '#272f47' }} onClick={() => setShowModal(true)}>
                                <span>{t("deleteProfile")}</span>
                            </button>
                        </div>
                    </div>

                </div>
            }
        </Translation >
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        deleteUser: () => dispatch(actionCreators.deleteUser()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate);