import React from "react";
// import Logo from "../../../../images/coop-sait-logo.png";
import Logo from "../../../../images/Logo_Click&Coop.png";
import HeaderItem from "../HeaderItems/HeaderItem";
import HeaderCartItem from "../HeaderItems/HeaderCartItem";
import HeaderProfileItem from "../HeaderItems/HeaderProfileItem";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import * as actionCreators from "../../../../redux/store/actions"
import CategorySelect from "../../../CategorySelect/CategorySelect";
import { ReactComponent as UserIcon } from '../../../../images/landing-user-sticky.svg';
import { ReactComponent as CartIcon } from '../../../../images/new-cart-icon5.svg';
import { ReactComponent as Pin } from '../../../../images/pin.svg';
import { ReactComponent as Calender } from '../../../../images/new-calender-icon.svg';
import { ReactComponent as Search } from '../../../../images/search.svg';
//import { ReactComponent as Logo } from '../../../../images/Logo_coop.svg';
import { ReactComponent as Cancel } from '../../../../images/close.svg';
import reactStringReplace from 'react-string-replace';
import "./new-header.styles.scss";
import HeaderTags from "../HeaderTags/HeaderTags";
import RecipieHeaderTags from "../RecipieHeaderTags/RecipieHeaderTags";
import { connect } from "react-redux"
import { Service } from "./Service";
import dayOfWeek from "../../../../utils/dateUtils";
import HomeTags from "../../../../features/Home/HomeTags/HomeTags";
import GoBackButton from "../../../UI/Buttons/GoBackButton";
import { history } from "../../../../App";


class NewHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultSlot: null
        }

    }

    componentDidMount() {
        this.getFirstSlot();
        //this.props.changeCategory();
    }

    /* componentDidUpdate(prevProps, prevState) {
         if (prevState.defaultSlot !== this.state.defaultSlot) {
                this.getFirstSlot();
            } else {
                this.setState({ defaultSlot: null })
            }
        
    }  */

    getFirstSlot = () => {

        Service.defaultCalender()
            .then(response => {
                this.setState({ defaultSlot: response.data })
            }).catch(error => {
                console.log(error);
            })
    }

    collapseTree = () => {
        this.props.changeCategory("", 0);
    }



    /*  refreshPage = () =>{
        window.location.reload(false);
    }   */

    /*   componentDidMount() {
        
             Service.defaultCalender()
                 .then(response => {
                     console.log(response.data)
                     if (response.data && response.data.length > 0) {
                         this.setState({ defaultSlot: response.data })
                     }
                 }).catch(error => {
                     console.log(error)
                 })
        
     }  */

    /*  componentDidUpdate(prevProps, prevState) {
         if (prevState.defaultSlot !== this.state.defaultSlot) {
             Service.defaultCalender()
             .then(response => {
                 console.log(response.data)
                 if (response.data && response.data.length > 0) {
                     this.setState({ defaultSlot: response.data })
                 }
             }).catch(error => {
                 console.log(error)
             })
              
         }
 
        
     } */
    render() {
        const { user, cart, searchInput, onChange, showModalLogin,
            submitSearch, setCategory, defaultStore, handleChangeTips, handleChangeCategories, tips, categories, isOpen, cancelSearchItem, inputFocus, togglePopup, choosedDay, choosedTimeslot, showAvailability } = this.props;
        const inputClass = `${isOpen ? "form-with-two-round-borders" : "form-with-all-round-borders"} `
        const menuClass = `dropdown-items ${isOpen ? "dropdown--isOpen" : ""}
        dropdown-menu${isOpen ? " show" : ""} `;
        const { defaultSlot } = this.state
        const availableDay = defaultSlot && dayOfWeek(defaultSlot.weekDay)
        return (
            <Translation>
                {t =>
                    <div className="header">
                        <div className="header-wrapper">
                            <div className="header-top">
                                <div className="col-6">
                                    <Pin />
                                    <span style={{ fontFamily: "Montserrat-Regular", fontSize: "12px", fontWeight: "normal", margin: "0 10px", color: "#343e59", fontStyle: "normal" }}>Ritiro in store:</span>
                                    <nobr>
                                        {cart && cart.storeDescription ?
                                            cart.storeDescription
                                            : (defaultStore ? defaultStore.name : "")}
                                    </nobr>
                                </div>
                                {
                                    defaultSlot &&
                                    <div className="col-6 calender" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                                        <Calender />
                                        <span style={{ fontFamily: "Montserrat-Regular", fontSize: "12px", fontWeight: "normal", margin: "0 10px", color: "#343e59" }}>Prima Disponibilità:</span>
                                        {/* {defaultSlot &&
                                            `${defaultSlot.weekDay} ${t(`months.${defaultSlot.monthName}`)} | ${defaultSlot.validFrom} - ${defaultSlot.validTo}`
                                        } */}
                                        <span className="mr-2"> {t(`daysOfWeek.${availableDay.name}`)}</span>
                                        <span className="mr-1">{availableDay.dayNumber} {t(`months.${availableDay.month}`)}</span>
                                        <span> ({defaultSlot.validFrom} - {defaultSlot.validTo})</span>
                                    </div>
                                    /*  cart ?
                                         <div className="col-4" onClick={() => togglePopup(true)}>
                                             <Calender />
                                             <span style={{ fontFamily: "Montserrat-Regular", fontSize: "12px", fontWeight: "normal", margin: "0 10px", color: "#343e59" }}>Disponibilità:</span>
                                             {(choosedDay && choosedTimeslot) ?
                                                 `${choosedDay.dayNumber} ${t(`months.${choosedDay.month}`)} | ${choosedTimeslot.validFrom} - ${choosedTimeslot.validTo}`
                                                 :
                                                 t("cart-menu.timeslot")
                                             }
                                         </div>
                                         :
                                         <div className="col-4" onClick={showModalLogin}>
                                             <Calender />
                                             <span style={{ fontFamily: "Montserrat-Regular", fontSize: "12px", fontWeight: "normal", margin: "0 10px", color: "#343e59" }}>Disponibilità:</span>
                                             {defaultSlot &&
                                                 `${defaultSlot.weekDay} ${t(`months.${defaultSlot.monthName}`)} | ${defaultSlot.validFrom} - ${defaultSlot.validTo}`
                                             }
                                         </div> */
                                }
                                {/*  <div className="col-3" onClick={() => togglePopup(true)}>
                                    <Calender />
                                    <span style={{ fontFamily: "Montserrat-Regular", fontSize: "12px", fontWeight: "normal", margin: "0 10px", color: "#343e59" }}>Disponibilità:</span>
                                </div> */}
                            </div>
                            <div className="container-fluid">
                                <div className="row header-middle">
                                    <div className="col-3">
                                        <div className="align-items-center logo" onClick={() => { this.props.selectTag(""); this.collapseTree() }}>
                                            <Link to={defaultStore ? "/home" : "/"} >
                                                {/* <Logo />   */}
                                                <img src={Logo} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                    {/* {this.props.location.pathname !== "/" && (cart || defaultStore) &&  */}
                                    {/* {(this.props.location.pathname == "/home" || this.props.location.pathname == "/discoverproducts" || this.props.location.pathname == "/producter" || this.props.location.pathname == "/search" || this.props.location.pathname == "/cart" || this.props.location.pathname == "/checkout" || this.props.location.pathname == "/signup") && (cart || defaultStore) && */}
                                    {this.props.location.pathname !== "/recipies" &&
                                        <div className="col-6 search">
                                            <form className={inputClass} onSubmit={e => submitSearch(e)} autoComplete="off">
                                                <input
                                                    name="input"
                                                    placeholder={t("header.mobileMobilePlaceHolder")}
                                                    onChange={onChange}
                                                    value={searchInput}
                                                    type="search"
                                                    ref={inputFocus.ref}
                                                    onKeyPress={event => {
                                                        if (event.key === 'Enter') {
                                                            submitSearch(event)
                                                        }
                                                    }}
                                                />
                                                {
                                                    tips &&
                                                    <div className={menuClass} aria-labelledby="dropdownMenuButton">
                                                        <div className="tips-items">
                                                            <h5 style={{ color: "grey", paddingLeft: "25px" }}>Suggerimenti</h5>
                                                            {tips && tips.map((item, index) => (
                                                                <span key={index} className="dropdown-item"
                                                                    onClick={() => handleChangeTips(item)}>
                                                                    {reactStringReplace(item, `${searchInput}`, (match, i) => (
                                                                        <span key={i} style={{ fontWeight: "bold" }}>{match}</span>
                                                                    ))}
                                                                </span>
                                                            ))}

                                                        </div>
                                                        <div className="category-items">
                                                            <h5 style={{ color: "grey", paddingLeft: "25px" }}>Categorie</h5>
                                                            {categories && categories.map((item, index) => (
                                                                <span key={index} className="dropdown-item"
                                                                    onClick={() => handleChangeCategories(item)}>
                                                                    <div style={{ display: "flex" }}>
                                                                        <div>
                                                                            {reactStringReplace(item.label, `${searchInput}`, (match, i) => (
                                                                                <span key={i} style={{ fontWeight: "bold" }}>{match}</span>
                                                                            ))}
                                                                        </div>
                                                                        <div className="count">{item.count}</div>
                                                                    </div>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    searchInput &&
                                                    <button onClick={(e) => cancelSearchItem(e)} className="button-cancel" >
                                                        <Cancel />
                                                    </button>
                                                }
                                                {/* <CategorySelect setCategory={setCategory} />   */}
                                                <button type="submit" className="button-submit">
                                                    {/* <span>{t("header.search")}</span> */}
                                                    <Search />
                                                </button>
                                            </form>
                                        </div>
                                    }
                                    {/* {this.props.location.pathname == "/recipies" &&
                                        <div className="col-4 search">
                                            <form className={inputClass} onSubmit={e => submitSearch(e)}>
                                                <input
                                                    name="input"
                                                    placeholder={t("header.mobileMobilePlaceHolder")}
                                                    onChange={onChange}
                                                    value={searchInput}
                                                    type="search"
                                                />
                                                {
                                                    tips &&
                                                    <div className={menuClass} aria-labelledby="dropdownMenuButton">
                                                        <div className="tips-items">
                                                            <h5 style={{ color: "grey", paddingLeft: "25px" }}>Suggerimenti</h5>
                                                            {tips && tips.map((item, index) => (
                                                                <span key={index} className="dropdown-item"
                                                                    onClick={() => handleChangeTips(item)}>
                                                                    {reactStringReplace(item, `${searchInput}`, (match, i) => (
                                                                        <span key={i} style={{ fontWeight: "bold" }}>{match}</span>
                                                                    ))}
                                                                </span>
                                                            ))}

                                                        </div>
                                                        <div className="category-items">
                                                            <h5 style={{ color: "grey", paddingLeft: "25px" }}>Categorie</h5>
                                                            {categories && categories.map((item, index) => (
                                                                <span key={index} className="dropdown-item"
                                                                    onClick={() => handleChangeCategories(item)}>
                                                                    <div style={{ display: "flex" }}>
                                                                        <div>
                                                                            {reactStringReplace(item.label, `${searchInput}`, (match, i) => (
                                                                                <span key={i} style={{ fontWeight: "bold" }}>{match}</span>
                                                                            ))}
                                                                        </div>
                                                                        <div className="count">{item.count}</div>
                                                                    </div>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                                <button type="submit" className="button-submit">
                                                    <Search />
                                                </button>
                                            </form>
                                        </div>
                                    } */}
                                    {this.props.location.pathname !== "/" &&
                                        <div className="col-3 actions">
                                            {user ?
                                                <HeaderProfileItem
                                                    path="/profile"
                                                    label={user?.firstName}
                                                >
                                                    <div className="user-logged">
                                                        {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                    </div>
                                                </HeaderProfileItem>
                                                :
                                                <HeaderItem
                                                    label={t("login_or_signup")}
                                                    onClick={showModalLogin}>
                                                    <UserIcon />
                                                </HeaderItem>
                                            }
                                            <HeaderCartItem
                                                path="/cart"
                                                total={this.props.renderTotal(cart)}
                                                items={cart ? cart.itemsCount : "0"}
                                                label={t("cart")}
                                                cart={cart}
                                                user={user}
                                                onClick={showModalLogin}
                                            >
                                                <CartIcon />
                                            </HeaderCartItem>
                                        </div>
                                    }
                                    {this.props.location.pathname === "/" &&
                                        <div className="col actions">
                                            {!user &&
                                                <>
                                                    <Link to="/signup">
                                                        <button className="secondary-cta" style={{ marginRight: "20px" }}>
                                                            {t("sign_in")}
                                                        </button>
                                                    </Link>
                                                    <button className="cta" onClick={showModalLogin}>
                                                        <span>{t("access")}</span>
                                                    </button>
                                                </>
                                            }
                                            {user &&
                                                <HeaderProfileItem
                                                    path="/profile"
                                                    label={user.firstName}
                                                >
                                                    <div className="user-logged">
                                                        {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                    </div>
                                                </HeaderProfileItem>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* {this.props.location.pathname !== "/" &&  */}
                            {/* {(this.props.location.pathname == "/home" || this.props.location.pathname == "/discoverproducts" || this.props.location.pathname == "/producter" || this.props.location.pathname == "/search" || this.props.location.pathname == "/cart" || this.props.location.pathname == "/checkout" || this.props.location.pathname == "/signup") && */}
                            {this.props.location.pathname !== "/recipies" &&
                                <div className="header-bottom">
                                    <HeaderTags searchInput={searchInput} />
                                    {/* <HomeTags/> */}
                                </div>
                            }
                            {/* {this.props.location.pathname == "/recipies" &&
                                <div className="header-bottom">
                                    <RecipieHeaderTags />
                                </div>
                            } */}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeKey: state.categories.activeKey,
        mainCategory: state.categories.mainCategory,
        subCategory: state.categories.subCategory,
        isSubCategoryOpen: state.categories.isSubCategoryOpen,
        thirdLevelCategory: state.categories.thirdLevelCategory,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        selectTag: (tag) => dispatch(actionCreators.tag(tag)),
        changeCategory: (categoryId, index) => dispatch(actionCreators.changeCategory(categoryId, index)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewHeader));
