import React from "react";
import CardProduct from "../../../components/Products/CardProduct/CardProduct";
import Slider from "react-slick";
import { Translation } from "react-i18next";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { connect } from "react-redux"
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner'
import "./featured-products.styles.scss";
import { ReactComponent as ArrowRight } from "../../../images/arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../../images/arrow_left.svg";
import GoBackButton from "../../../components/UI/Buttons/GoBackButton";
import BannerCardProduct from "../../../components/Products/BannerCardProduct/BannerCardProduct";
import { Service } from "./Service";
import { Accordion } from "react-bootstrap";

class FeaturedProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            products: [],
            hasMore: true,
            categories: [],
            lastCategoryIndex: 0,
            bars: [],
            firstBars: [],
            selectedCategory: null,
            selectedItem: null
        };
    }

    componentDidMount() {
        const storeId = this.props.cart ? this.props.cart.storeId :
            (this.props.defaultStore ? this.props.defaultStore.id : null);
        const bannerId = this.props.bannerId
        /*  ecommerceService.featuredProducts(storeId).then(response => {
             this.setState({
                 isLoaded: true,
                 products: response.data
             })
         }); */

        Service.bannersProductList(bannerId).then(response => {
            this.setState({
                isLoaded: true,
                products: response.data,
            }, (() => {
                if (this.state.products.length > 2) {
                    this.setState({ categories: this.state.products.slice(0, 2), lastCategoryIndex: 2 }, () => { this.state.categories.map((item, i) => this.loadItems(item.barcodes, i)) });
                } else {
                    this.setState({ categories: this.state.products, lastCategoryIndex: this.state.products.length }, () => { this.state.categories.map((item, i) => this.loadItems(item.barcodes, i)) });
                }
            }))
        }).catch((error) => {
            console.log(error)
        })
    }

    /*  UNSAFE_componentWillReceiveProps(nextProps) {
         const storeId = this.props.cart ? this.props.cart.storeId :
             (this.props.defaultStore ? this.props.defaultStore.id : null);
         if (nextProps && nextProps.cart) {
             if (storeId !== nextProps.cart.storeId) {
                 ecommerceService.featuredProducts(nextProps.cart.storeId).then(response => {
                     this.setState({
                         isLoaded: true,
                         products: response.data
                     })
                 });
             }
         } else {
             ecommerceService.featuredProducts(this.props.defaultStore.id).then(response => {
                 this.setState({
                     isLoaded: true,
                     products: response.data
                 })
             });
         }
     } */

    loadItems = (barcodes, i) => {
        let tmpBars = this.state.bars;
        let bar = [];
        if (barcodes.length > 6) {
            let firstBarcodes = barcodes.slice(0, 6);
            Service.bannersBarcodesProductList(firstBarcodes).then((response) => {
                let newBars = response.data;
                bar.item = newBars;
                tmpBars[i] = bar;
                this.setState(prev => ({ bars: tmpBars, firstBars: [6, ...prev.firstBars] }), () => {
                    if (newBars.length < 6) {
                        let missingTosix = 6 - newBars.length;
                        if (missingTosix + 6 < barcodes.length) {
                            this.loadMoreItems(barcodes, i);
                        }
                    }
                });
            })
        } else {
            Service.bannersBarcodesProductList(barcodes).then((response) => {
                let newBars = response.data;
                bar.item = newBars;
                tmpBars[i] = bar;
                this.setState(prev => ({ bars: tmpBars, firstBars: [...prev.firstBars, barcodes.length] }));
            })
        }
    }
    loadMoreItems = (barcodes, i) => {
        if (this.state.firstBars[i] < barcodes.length) {
            let difference = barcodes.length - this.state.firstBars[i];
            let missing = difference + this.state.firstBars[i]
            let otherBarcodes = null;
            if (difference > 90) {
                otherBarcodes = barcodes.slice(this.state.firstBars[i], this.state.firstBars[i] + 6);
                let tmpBars = this.state.bars;
                let bar = this.state.bars[i]
                let idBars = this.state.firstBars;
                let id = idBars[i];
                let newId = id + 6;
                id = newId;
                idBars[i] = id;
                Service.bannersBarcodesProductList(otherBarcodes).then((response) => {
                    let tmpNewBar = bar.item.concat(response.data);
                    bar.item = tmpNewBar;
                    tmpBars[i] = bar;
                    this.setState({ bars: tmpBars, firstBars: idBars });
                })
            } else {
                otherBarcodes = barcodes.slice(this.state.firstBars[i], missing);
                let tmpBars = this.state.bars;
                let bar = this.state.bars[i]
                let idBars = this.state.firstBars;
                let id = idBars[i];
                let newId = missing;
                id = newId;
                idBars[i] = id;
                Service.bannersBarcodesProductList(otherBarcodes).then((response) => {
                    let tmpNewBar = bar.item.concat(response.data);
                    bar.item = tmpNewBar;
                    tmpBars[i] = bar;
                    this.setState({ bars: tmpBars, firstBars: idBars });
                })
            }
        }
    }

    loadMoreCategories = () => {
        if (this.state.lastCategoryIndex < this.state.products.length) {
            let difference = this.state.products.length - this.state.lastCategoryIndex;
            let missing = difference + this.state.lastCategoryIndex;
            if (difference > 2) {
                let index = this.state.lastCategoryIndex;
                let categories = this.state.categories;
                let newCategories = categories.concat(this.state.products.slice(index, this.state.lastCategoryIndex + 2));
                categories = newCategories;
                this.setState({ categories: categories, lastCategoryIndex: this.state.lastCategoryIndex + 2 }, () => this.state.products.slice(index, index + 2).map((item) => { this.loadItems(item.barcodes, index); index++ }))
            } else {
                let index = this.state.lastCategoryIndex;
                let categories = this.state.categories;
                let newCategories = categories.concat(this.state.products.slice(index, missing));
                categories = newCategories;
                this.setState({ categories: categories, lastCategoryIndex: missing }, () => this.state.products.slice(index, missing).map((item) => { this.loadItems(item.barcodes, index); index++ }))
            }
        }
    }

    showCategory = (item) => {
        window.scrollTo(0, 0);
        Service.bannersBarcodesProductList(item.barcodes).then(response => {
            this.setState({ selectedItem: response.data, selectedCategory: item.category })
        }).catch(error => { console.log(error); })
    }

    render() {
        const { products, isLoaded, categories, bars, hasMore, selectedCategory, selectedItem } = this.state;
        const { bannerId } = this.props;
        if (isLoaded) {
            return (
                <Translation>
                    {t =>
                        <div className="featured-products">
                            {selectedCategory &&
                                <div>
                                    <GoBackButton style={{ position: "relative" }}
                                        onClick={() => {
                                            this.setState({ selectedCategory: null, selectedItem: null })
                                        }}
                                    />
                                </div>
                            }
                            <h2 className="text-center">
                                {t("home.featuredProducts")}
                            </h2>
                            {selectedCategory === null &&
                                <InfiniteScroll
                                    style={{ overflow: "unset" }}
                                    dataLength={categories.length}
                                    next={this.loadMoreCategories}
                                    hasMore={hasMore}
                                    loader={<Loader
                                        type="ThreeDots"
                                        color="#333"
                                        height={70}
                                        width={70}
                                        timeout={3000} //3 secs
                                    />}
                                >
                                    {
                                        categories && categories.map((item, i) => (
                                            <div className="featured-products-category" key={i}>
                                                <div className="featured-products-category-description" onClick={() => this.showCategory(item)}>{item.category}</div>
                                                <Slider {...settings} style={{ maxWidth: '1600px' }} nextArrow={<SampleArrow><ArrowRight onClick={() => { this.loadMoreItems(item.barcodes, i) }} /></SampleArrow>}>
                                                    {bars && bars[i]?.item && bars[i].item.map((el) =>
                                                        <CardProduct key={el.id} item={el} />
                                                    )}
                                                </Slider>
                                            </div>
                                        ))
                                    }
                                </InfiniteScroll>
                            }
                            {selectedCategory &&
                                <div>
                                    <h3 className="text-center">
                                        {selectedCategory}
                                    </h3>
                                    <div className="selectedItem">
                                        {selectedItem && selectedItem.map((el) =>
                                            <CardProduct key={el.id} item={el} />
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}

function appendDots(dots) {
    return (<div>
        <div className="carousel-dots">
            <ul>{dots}</ul>
        </div>
    </div>);
}

const settings = {
    dots: false,
    infinite: false,
    speed: 100,
    slidesToShow: 6,
    slidesToScroll: 2,
    lazyLoad: true,
    prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>,
    responsive: [
        {
            breakpoint: 3000,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1599,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1439,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1279,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        /*  {
             breakpoint: 799,
             settings: {
                 slidesToShow: 3,
                 slidesToScroll: 2,
             }
         }, */
        {
            breakpoint: 799,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 543,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};

function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
}

/* const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    appendDots: appendDots,
    arrows: false,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }
    ]
}; */
const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        defaultStore: state.user.defaultStore,
    };
}

export default connect(mapStateToProps)(FeaturedProducts);
