import config from "../../../../config/config"
import { handleResponse } from "../../../../utils/handleResponse";
import { checkResponse } from "../../../../utils/checkResponse";
import axios from 'axios'
import { recaptchaHeader } from "../../../../utils/authHeader";

export default function contactUs(data, subjcet, partner, recaptcha) {
    return (
        axios.post(`${config.baseUrl}/cli/contact/us`,
            {
                name: data.name,
                surname: data.surname,
                email: data.email,
                message: data.notes,
                subject: subjcet,
                registered: partner === "true"
            },
            {
                headers: recaptchaHeader(recaptcha),
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    );
}