import config from "../../config/config"
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import axios from 'axios';
import { authHeader } from '../../utils/authHeader';

export const ecommerceCartsService = {
    current, open, openCarts, store, review, remove, freeze, abort, move, copyOrder, freezeCheck, slistToCart, manageCoupon, bookTimeslot, modifyOrder, massiveReplacement
}

function current() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/cart/current`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function open(deliveryMode, storeId, addressId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/open`,
            {
                storeId: storeId,
                deliveryMode: deliveryMode,
                addressId: addressId ? addressId : "",
                terminalType: "WEB",
                terminalId: "9095bfc3-2dad-44dc-89e0-b9f232542f32",
            },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    )
}

function openCarts() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/carts/open`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function store(cartId, barcode, amount, um, replace, unity) {
    let body = null;
    if (um === "PZ") {
        body = {
            cartId: cartId,
            barcode: barcode,
            amount: amount,
            preview: false,
            enableReplacement: replace
        }
    } else {
        body = {
            cartId: cartId,
            barcode: barcode,
            weight: amount,
            preview: false,
            enableReplacement: replace
        }
    }
    if (unity) body.attrib3 = unity.toString();
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/store`,
            body,
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function remove(cartId, barcode) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/delete`,
            {
                cartId: cartId,
                barcode: barcode,
                preview: false
            },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    )
}

function review(cartId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/review`,
            {
                cartId: cartId,
                refresh: false
            },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    )
}

function freeze(data, cartId, paymentMode, deliveryMode) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/freeze`, {
            cartId: cartId,
            profileEmail: data.profileEmail,
            profileMobilePhone: data.profileMobilePhone,
            firstName: data.profileFirstName,
            lastName: data.profileLastName,
            timeSlotId: data.timeSlotId,
            paymentMode: paymentMode,
            deliveryMode: deliveryMode,
            notes: data.notes,
            invoice: data.invoice,
            billingAddressId: data.billingAddressId,
            // enableProductsReplacement: data.enableProductsReplacement,
            clientWeb: true,
            // timeSlotDay: data.day
        },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    )
}

function abort(cartId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/abort`,
            {
                cartId: cartId,
            },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    )
}

function move(cartId, storeId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/move`,
            {
                cartId: cartId,
                storeId: storeId,
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function copyOrder(orderId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/copy`,
            {
                orderId: orderId,
                addressId: "",
                terminalType: "WEB",
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
        // .then(checkResponse)
    )
}

function modifyOrder(orderId, terminalId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/modify`,
            {
                orderId: orderId,
                terminalId: terminalId,
                terminalType: "WEB",
                deliveryMode: "IN_STORE",
                title: null,
                storeId: "686491"
            },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
        // .then(checkResponse)
    )
}

function freezeCheck(cartId, preview, cartUpdate) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/freeze/check`,
            {
                cartId: cartId,
                preview: preview,
                cartUpdate: cartUpdate
            },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
        // .then(checkResponse)
    )
}

function slistToCart(slistId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/list`,
            {
                smartListId: slistId,
            },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    )
}

function manageCoupon(cartId, couponCode, action, couponId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/coupon`,
            {
                cartId: cartId,
                couponCode: couponCode,
                action: action,
                couponId: couponId
            },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    )
}

function bookTimeslot(cartId, timeSlotId, timeSlotDay) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/book`,
            {
                cartId: cartId,
                timeSlotId: timeSlotId,
                timeSlotDay: timeSlotDay
            },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    );
}

function massiveReplacement(cartId, replacement) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/massiveReplacementProducts`,
            {
                cartId: cartId,
                enableReplacement: replacement,
            },
            {
                headers: authHeader()
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    );
}