import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Popup from "reactjs-popup";
import { Translation } from "react-i18next";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./image-popup.styles.scss";

const ImagePopup = ({ show, imageSource, onClose }) => {

    const transformOptions = {
        initialScale: 0.1,
        minScale: 0.1,
        maxScale: 2,
        initialPositionX: 0,
        initialPositionY: 0
    }

    return (
        <Translation>
            {t =>
                <Popup
                    lockScroll={true}
                    contentStyle={popupStyle}
                    closeOnDocumentClick={false}
                    open={show}
                    onClose={onClose}
                    modal
                >
                    {close => (
                        <div className="image-popup">
                            <div id="close-popup" className="close" onClick={close}>
                                &times;
                            </div>
                            <div className="image-popup-header">
                                {/* {t("loginPopup.login")} */}
                            </div>
                            <div className="image-popup-image">
                                <TransformWrapper
                                    // initialScale={0.5}
                                    options={transformOptions}
                                    // initialPositionX={70}
                                    // initialPositionY={-200}
                                >
                                    <TransformComponent>
                                        <img src={imageSource} style={{height: '500px'}}/>
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>

                        </div>
                    )}
                </Popup>
            }
        </Translation >
    );
}

const popupStyle = {
    borderRadius: "2px",
    border: "none",
    boxShadow: `0 0 23px 10px rgba(0, 0, 0, 0.24)`,
    cursor: "unset"

}

export default ImagePopup;