import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { history } from "../../../App";
// import { ReactComponent as FacebookIcon } from "../../../images/facebook-cta.svg"
import { ReactComponent as EmailIcon } from "../../../images/Email.svg"
import { ReactComponent as PasswordIcon } from "../../../images/Password.svg"
import { useForm } from 'react-hook-form'
import { Translation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { authenticationService } from "../../../services/basis/AuthenticationService";
import * as actionCreators from "../../../redux/store/actions/index"
import { Link } from "react-router-dom";
import "./login-popup.styles.scss";
import { ReactComponent as Eye } from "../../../images/eye.svg";
import { ReactComponent as CrossedEye } from "../../../images/eye-crossed.svg";

function LoginPopup(props) {
    const flagCoop = useSelector((state) => state.user.clickCoop);
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(false);
    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        if (flagCoop === false) {
            authenticationService.updateClickCoop(data).then((response) => {
                props.setFlagClickCoop(true);
            });
        } else {
            authenticationService.checkClickCoop(data).then((response) => {
                if (response.responseCode === 24) {
                    props.setFlagClickCoop(false);
                } else {
                    props.setFlagClickCoop(true);
                    props.login(data);
                }
            })
        }
    }

    return (
        <Translation>
            {t =>
                <Popup
                    lockScroll={true}
                    contentStyle={popupStyle}
                    closeOnDocumentClick={false}
                    open={props.open}
                    onClose={props.closePopup}
                    modal
                >
                    {close => (
                        <div className="login-popup">
                            <div id="close-popup" className="close" onClick={close}>
                                &times;
                            </div>
                            <div className="login-popup-header">
                                {t("loginPopup.login")}
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                                className={`login-popup-form needs-validation ${errors ? "had-validation" : ""}`}>
                                <div className="login-popup-content">
                                    <div className="row no-gutters">
                                        <div className="col-12"
                                        // style={{ borderRight: "1px solid #d8d8d8" }}
                                        >
                                            {/* <div className="login-popup-title">Usa le tue credenziali</div> */}
                                            <div className="input-container">
                                                <EmailIcon className="icon" />
                                                <input
                                                    name="username"
                                                    placeholder={t("email")}
                                                    type="email"
                                                    watch={watch("username")}
                                                    ref={register({
                                                        required: t("required.field")
                                                        // pattern: {
                                                        //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        //     message: t("error.invalidEmail")
                                                        // }
                                                    })}
                                                />
                                            </div>
                                            <div className="input-container">
                                                <PasswordIcon className="icon" />
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    placeholder="Password"
                                                    watch={watch("password")}
                                                    ref={register({
                                                        required: t("required.field")
                                                    })}
                                                >
                                                </input>
                                                <div className='password-eyes' onClick={() => setShowPassword(!showPassword)}>
                                                    {!showPassword ?
                                                        <Eye/>
                                                        :
                                                        <CrossedEye/>
                                                    }
                                                </div>
                                            </div>
                                            {flagCoop !== false &&
                                                <div className="login-popup-subtitle">
                                                    {t("loginPopup.forgotPassword")} <span className="bold" style={{cursor: 'pointer'}} onClick={() => window.open("https://www.laspesainfamiglia.coop/user/forgotpassword?type=click-coop", "_blank")}>{t("loginPopup.clickHere")}</span>
                                                </div>
                                            }
                                            {flagCoop === false &&
                                                <>
                                                    <div style={{ color: "red" }}>
                                                        <h5>Per poter proseguire accettare il seguente flag</h5>
                                                    </div>
                                                    <div className="custom-checkbox" style={{ margin: '10px' }}>
                                                        <input className="custom-control-input" type="checkbox" id="clickCoop" name="clickCoop"
                                                            register={register({
                                                                required: t("required.field"),
                                                            })}
                                                            onClick={() => setChecked(!checked)}
                                                        />
                                                        <label className="custom-control-label" style={{ fontFamily: 'Montserrat-Bold' }} htmlFor="clickCoop">
                                                            {t("flag.clickandcoop")}
                                                        </label>
                                                    </div>
                                                </>
                                            }
                                            <button className="cta" disabled={flagCoop !== false ? false : !checked}><span>{flagCoop === false ? t("login.save") : t("loginPopup.login")}</span></button>
                                            <div className="login-popup-subtitle" onClick={close}>
                                                {t("loginPopup.notHavingAnAccount")}&nbsp;
                                                <Link to="/registration">
                                                    <span className="bold">{t("loginPopup.register")}</span>
                                                </Link>
                                                {/*  <Link to="/signup">
                                                    <span className="bold">
                                                        {t("loginPopup.register")}
                                                    </span>
                                                </Link>  */}
                                            </div>
                                        </div>
                                        {/* <div className="col-6">
                                            <div className="login-popup-title">Usa i social</div>
                                            <button className="facebook-cta">
                                                <FacebookIcon />
                                                <span>Accedi con Facebook</span>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </Popup>
            }
        </Translation>
    );
}

const popupStyle = {
    borderRadius: "2px",
    border: "none",
    boxShadow: `0 0 23px 10px rgba(0, 0, 0, 0.24)`,
    cursor: "unset"

}

const mapDispatchToProps = dispatch => {
    return {
        setFlagClickCoop: (flag) => dispatch(actionCreators.setFlagClickAndCoop(flag)),
        login: (data) => dispatch(actionCreators.login(data)),
    };
}

export default connect(null, mapDispatchToProps)(LoginPopup);