import React from "react"
import { Link, withRouter } from "react-router-dom";
import renderPrice from "../../../../utils/renderPrice";

import "./header-item.styles.scss";

const HeaderCartItem = ({ user, path, history, onClick, children, label, cart, total }) => {
    if (user) {
        return (
            <Link className="link" to={path}>
                <div style={{  display:"flex", justifyContent:"center",alignItems:"center" }} className={`header-item 
                    ${(history.location.pathname === "/cart" ||
                        history.location.pathname === "/cart/create" ||
                        history.location.pathname === "/carts") ? "header-item-actived" : ""}`}
                >
                    {/* {props.items > 0 &&
                    <div className="header-item-counts">
                        <span>{props.items}</span>
                    </div>
                } */}
                    <div className="header-item-content header-item-cart">
                        <div className="cart">{children}</div>
                        <div>
                            <div className="header-item-label">{label}</div>
                            {total &&
                                <div className="header-item-total">
                                    <span>{renderPrice(total)}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Link>
        )
    } else return (
        <div style={{ display:"flex", justifyContent:"center",alignItems:"center"}} className={`header-item 
                ${(history.location.pathname === "/cart" ||
                history.location.pathname === "/cart/create" ||
                history.location.pathname === "/carts") ? "header-item-actived" : ""}`}
            onClick={onClick}
        >
            {/* {props.items > 0 &&
                    <div className="header-item-counts">
                        <span>{props.items}</span>
                    </div>
                } */}
            <div className="header-item-content header-item-cart">
                <div className="cart">{children}</div>
                <div>
                    <div className="header-item-label">{label}</div>
                    {total &&
                        <div className="header-item-total">
                            <span>{renderPrice(total)}</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default withRouter(HeaderCartItem);