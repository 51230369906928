import React, { useState, useEffect, useCallback } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import StickyBox from "react-sticky-box";
import Loader from 'react-loader-spinner'
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux"
import Categories from "../../Categories/Categories";
import Filters from "../../../components/Filters/Filters";
import BannerCardProduct from "../../../components/Products/BannerCardProduct/BannerCardProduct";
import { Service } from "./Service"
import CardProduct from "../../../components/Products/CardProduct/CardProduct";
import CategoryTree from "../../SearchCategory/CategoryTree/CategoryTree";
import GoBackButton from "../../../components/UI/Buttons/GoBackButton";
import { history } from "../../../App";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";
import "./bannerProductList.styles.scss"
import ParagonaliCard from "../../../components/Products/ParagonaliCard/ParagonaliCard";
import InsiemeCard from "../../../components/Products/InsiemeCard/InsiemeCard";

const BannerProductList = ({ location, match }) => {
    const { t } = useTranslation();
    const [isLoaded, setIsLoaded] = useState(false);
    const [page, setPage] = useState(0);
    const size = 10;
    const [totalPages, setTotalPages] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [bannersWithProducts, setBannersWithProducts] = useState([]);
    const [bannerProducts, setBannerProducts] = useState();
    const [bannerProductsApp, setBannerProductsApp] = useState();

    const bannerId = location?.state?.bannerId ? location?.state?.bannerId : match.params.bannerId ? match.params.bannerId : "";
    const type = location?.state?.type ? location?.state?.type : "";

    const bannersProducts = useCallback(
        (input, page, size) => {
            Service.bannersProductList(input, page, size).then(response => {
                setBannersWithProducts(response.data);
                setTotalPages(response.totalPages);
                setPage(response.number);
            }).catch((error) => {
                console.log(error)
            })
        },
        [],
    );

    const bannersTwiceProducts = useCallback(
        (input, page) => {
            Service.bannersTwiceProductList(input).then(response => {
                setBannersWithProducts(response.data)
            }).catch((error) => {
                console.log(error)
            })
        },
        [],
    );

    const bannersHeader = useCallback(
        (input, page) => {
            Service.bannersHeader(input).then(response => {
                console.log(response)
                if (response.data.bannerProducts) {
                    setBannerProducts(response.data.bannerProducts)
                }
                if (response.data.bannerProductsApp) {
                    setBannerProductsApp(response.data.bannerProductsApp)
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        [],
    );

    useEffect(() => {
        if (type === "PARAGONALI" || type === "INSIEME") {
            bannersTwiceProducts(bannerId, 0);
        } else {
            bannersProducts(bannerId, 0, size);
        }
        bannersHeader(bannerId)
    }, [bannersProducts, bannersHeader, bannerId])

    const loadMore = () => {
        if (totalPages !== undefined) {
            const newPage = page + 1;
            if (type === "PARAGONALI" || type === "INSIEME") {
                if (newPage < totalPages) {
                    Service.bannersTwiceProductList(bannerId).then(response => {
                        const newResponse = bannersWithProducts;
                        newResponse.push(...response.data);
                        setBannersWithProducts(newResponse)
                    }).catch((error) => {
                        console.log(error)
                    })
                } else {
                    setHasMore(false);
                }
            } else {
                if (newPage < totalPages) {
                    Service.bannersProductList(bannerId, newPage, size).then(response => {
                        const newResponse = bannersWithProducts;
                        newResponse.push(...response.data);
                        setTotalPages(response.totalPages);
                        setPage(response.number);
                        setBannersWithProducts(newResponse)
                    }).catch((error) => {
                        console.log(error)
                    })
                } else {
                    setHasMore(false);
                }
            }
        } else {
            const newPage = page + 1;
            if (type === "PARAGONALI" || type === "INSIEME") {
                if (newPage < totalPages) {
                    Service.bannersTwiceProductList(bannerId).then(response => {
                        const newResponse = bannersWithProducts;
                        newResponse.push(...response.data);
                        setBannersWithProducts(newResponse)
                    }).catch((error) => {
                        console.log(error)
                    })
                } else {
                    setHasMore(false);
                }
            }
        }
    }

    return (
        <div className="search-results">
            <div className="page-grid mobile-view-search">
                <div className="grid-left" offsettop={0} offsetbottom={0}>
                    {/* <Filters showDefaultFilters={true} /> */}
                    <CategoryTree />
                </div>
                <div className="grid-right">
                    {bannerProducts &&
                        <img className="bannerProduct desktop" src={bannerProducts} alt="" />
                    }
                    {bannerProductsApp &&
                        <img className="bannerProduct mobile" src={bannerProductsApp} alt="" />
                    }
                    <InfiniteScroll
                        style={{ overflowX: "hidden" }}
                        dataLength={bannersWithProducts.length}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={<Loader
                            type="ThreeDots"
                            color="#333"
                            height={70}
                            width={70}
                            timeout={3000} //3 secs
                        />}
                    >
                        {(type !== "PARAGONALI" && type !== "INSIEME") &&
                            <div className="products-grid mobile-products-search">
                                {bannersWithProducts && bannersWithProducts.length > 0 && bannersWithProducts.map((item) => (
                                    <CardProduct key={item.id} item={item} />
                                ))}
                                {isLoaded && bannersWithProducts && bannersWithProducts.length === 0 &&
                                    <span className="title">
                                        {t("newSearch.noProductsFound")}
                                    </span>
                                }
                            </div>
                        }
                        {type === "PARAGONALI" &&
                            <div className="products-grid mobile-products-search">
                                {bannersWithProducts && bannersWithProducts.length > 0 && bannersWithProducts.map((item) => (
                                    <ParagonaliCard key={item.leftCartItem.id} left={item.leftCartItem} right={item.rightCartItem} />
                                ))}
                                {isLoaded && bannersWithProducts && bannersWithProducts.length === 0 &&
                                    <span className="title">
                                        {t("newSearch.noProductsFound")}
                                    </span>
                                }
                            </div>
                        }
                        {type === "INSIEME" &&
                            <div className="products-grid mobile-products-search">
                                {bannersWithProducts && bannersWithProducts.length > 0 && bannersWithProducts.map((item) => (
                                    <InsiemeCard key={item.leftCartItem.id} left={item.leftCartItem} right={item.rightCartItem} />
                                ))}
                                {isLoaded && bannersWithProducts && bannersWithProducts.length === 0 &&
                                    <span className="title">
                                        {t("newSearch.noProductsFound")}
                                    </span>
                                }
                            </div>
                        }
                    </InfiniteScroll>
                    <ScrollToTopButton />
                </div>
            </div>
        </div>
    );
}

export default BannerProductList;



