import React, { useState } from "react"
import Popup from "../../../components/UI/Popup/Popup"
import Coupon from "./Coupon/Coupon";
import renderPrice from "../../../utils/renderPrice";
import { ReactComponent as Alert } from "../../../images/card-alert.svg"
import { ReactComponent as Calendar } from "../../../images/cart-calendar.svg";
import { ReactComponent as Cal } from "../../../images/cal-icon.svg";
import { ReactComponent as Update } from "../../../images/cart-update.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import * as actionCreators from "../../../redux/store/actions/index";
import { ReactComponent as AccordionDown } from "../../../images/accordion-down.svg";
import { ReactComponent as AccordionUp } from "../../../images/accordion-up.svg";

import "./cart-menu.styles.scss";

const CartMenu = ({
    cart,
    abort,
    togglePopup,
    choosedDay,
    choosedTimeslot,
    history,
    goToCheckout,
    onSubmit,
    enableProductsReplacement,
    paymentMethod,
    user,
    deliveryAddress,
    deliveryZip,
    deliveryCity,
    deliveryCountry,
    mobile
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [couponCode, setCouponCode] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    const renderMinNetTotal = () => {
        if (cart && cart.minNetTotal && cart.minNetTotal > 0 && cart?.netTotal < cart.minNetTotal) {
            return (
                <div className="cart-menu-minNetTotal">
                    <Alert />
                    <div>
                        {t("cartMenu.minimumItemsToOrder")} : <b>{`${cart && cart.minNetTotal && cart.minNetTotal > 0 && cart.minNetTotal?.toFixed(2)} \u20AC`}</b>
                    </div>
                </div>
            );
        } else return null;
    }

    const handleCoupon = (coupon, action, couponId) => {
        dispatch(actionCreators.manageCoupon(cart.id, coupon, action, couponId));
    }

    const selectedPaymentMethod = (paymentMethod) => {
        switch (paymentMethod) {
            case "XPAY":
                return (
                    <div>{t("online")}</div>
                );
            case "BANCOMAT":
                return (
                    <div>{t("atDelivery")}</div>
                );
            case "CASH":
                return (
                    <div>{t("atDelivery")}</div>
                );
            default: return null;
        }
    }

    return (
        <div className="cart-menu">
            {renderMinNetTotal()}
            <div className="cart-menu-title">
                <div className="title">
                    <Cal className="mr-4" />
                    <h5>RIEPILOGO</h5>
                </div>
            </div>
            <div className="cart-menu-fees">
                <div className="title">
                    <span className="title-first">1</span>
                    <span className="title-second">Dettaglio Spesa</span>
                </div>
                <div className="points">
                    <div className="points-label">I miei punti</div>
                    <div className="points-info">{user && user.cards && user.cards[0].points}</div>
                </div>
                <div className="points">
                    <span className="points-label">Carta Incooperazione</span>
                    <span className="points-info">{user && user.cards && user.cards[0].code}</span>
                </div>
            </div>
            {(!mobile || history.location.pathname !== "/cart") &&
                <div className="cart-menu-collapse">
                    <div className="cart-menu-collapse-label">Elenco prodotti</div>
                    <div
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {isOpen ? <AccordionUp /> : <AccordionDown />}
                    </div>
                </div>
            }
            {(!mobile || history.location.pathname !== "/cart") &&
                <div className="cart-menu-fees">
                    {
                        isOpen &&
                        <div>
                            <div className="fee">
                                {/*  <span>{t("cart.totalAmount")} ({cart?.itemsCount})</span>
                        <span>{cart && `\u20AC ${renderPrice(cart.netTotal?.toFixed(2))}`}</span> */}
                                <span>TOTALE PRODOTTI</span>
                                <span>{cart?.itemsCount}</span>
                            </div>
                            <div>
                                {cart && cart.items && cart.items.map(item => (
                                    <div className="fee" key={item.id}>
                                        <span className="description">{item.description}</span>
                                        <span className="amount">{item.amount} {item.purchaseUm}</span>
                                        {/* <span>{cart && `\u20AC ${renderPrice(fee.value?.toFixed(2))}`}</span>  */}
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    {cart && cart.fees && cart.fees.map(fee => (
                        <div className="fee" key={fee.id}>
                            <span>{fee.description}</span>
                            <span>{cart && `\u20AC ${renderPrice(fee.value?.toFixed(2))}`}</span>
                        </div>
                    ))}
                    {cart && cart.coupons &&
                        <div className="lists">
                            {cart.coupons.map(item => (
                                <Coupon
                                    key={item.id}
                                    coupon={item}
                                    handleCoupon={handleCoupon}
                                    cart={cart}
                                />
                            ))}
                        </div>
                    }
                    <div className="fee total">
                        <span>{t("total")}</span>
                        <span>{cart && cart.totalToPay ? `\u20AC ${renderPrice(cart.totalToPay?.toFixed(2))}` : `\u20AC ${renderPrice(cart.netTotal?.toFixed(2))}`}</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Popup
                            action={() => abort(cart.id)}
                            title={t("cart.delete")}
                            content={t("cart.delete.question")}
                            button={t("delete")}
                            trigger={
                                <div className="tiny-cta">
                                    {t("cart.delete")}
                                </div>
                            }
                        />
                        {
                            (history.location.pathname === "/availability" ||
                                history.location.pathname === "/checkout" ||
                                history.location.pathname === "/summary") &&
                            <div className="tiny-cta"
                                onClick={() => history.push("/cart")}>
                                Modifica Carrello
                            </div>
                        }
                    </div>
                </div>}
            <div className="cart-menu-timeslot">
                <div className="title">
                    <span className="title-first">2</span>
                    <span className="title-second">Ritiro</span>
                </div>
                <div className="name-text">NOME</div>
                <div className="name">
                    <span>{cart.profileDescription}</span>
                </div>
                <div className="address">INDIRIZZO DI CONSEGNA</div>
                {
                    deliveryAddress &&
                    <div className="name">
                        <span>{cart.storeDescription}</span><br />
                        <span className="">{deliveryAddress}<br />{deliveryZip} {deliveryCity} {deliveryCountry}</span>
                    </div>
                }
                <div className="time-slot">FASCIA ORARIA</div>
                {
                    history.location.pathname === "/cart" &&
                    <div className="name">
                        <span>Scegli fascia oraria</span>
                    </div>
                }
                {
                    (history.location.pathname === "/availability" ||
                        history.location.pathname === "/checkout" ||
                        history.location.pathname === "/summary") &&
                    <div className="name">
                        <span className="tiny-cta">
                            {(choosedDay && choosedTimeslot) &&
                                `${choosedDay.dayNumber} ${t(`months.${choosedDay.month}`)} | ${choosedTimeslot.validFrom} - ${choosedTimeslot.validTo}`
                            }
                        </span>
                    </div>
                }
            </div>
            <div className="cart-menu-payment">
                <div className="title">
                    <span className="title-first">3</span>
                    <span className="title-second">Metodo di pagamento</span>
                </div>
                {
                    (history.location.pathname === "/availability" ||
                        history.location.pathname === "/cart") &&
                    <div className="card-title">Aggiungi metodo di pagamento</div>
                }
                {
                    (history.location.pathname === "/summary" ||
                        history.location.pathname === "/checkout") &&
                    <div className="card-title">{paymentMethod && selectedPaymentMethod(paymentMethod)}</div>
                }
            </div>
        </div>
    );
}

export default withRouter(CartMenu);
