import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import axios from 'axios'

export const Service = {
    banners, bannersProductList, bannersHeader, bannersTwiceProductList
};

function banners(category) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners/search?type=PRODUCT`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function bannersProductList(bannerId, page, size) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners/${bannerId}/products?p=${page}&s=${size}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}
function bannersTwiceProductList(bannerId) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners/${bannerId}/twiceProducts`)
            .then(handleResponse)
            .then(checkResponse)
    );
}
function bannersHeader(bannerId) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners/${bannerId}/headerBanner`)
            .then(handleResponse)
            .then(checkResponse)
    );
}