import React from "react";
import Slider from "react-slick";
import HomeTag from "./HomeTag/HomeTag";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { ReactComponent as Offerta } from "../../../images/tags/offers.svg";
import { useSelector, useDispatch } from 'react-redux'
import * as actionCreators from "../../../redux/store/actions"

import "./home-tags.styles.scss";

const HomeTags = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const tags = useSelector(state => state.categories.tags)

    // renderIcon = (id) => {
    //     switch (id) {
    //         case "Bio":
    //             return <Biologico />
    //         case "Senza_glutine":
    //             return <SenzaGlutine />
    //         case "Senza_lattosio":
    //             return <SenzaLattosio />
    //         case "Benessere":
    //             return <Benessere />
    //         case "Vegano":
    //             return <Vegano />

    //         default:
    //             return null;
    //     }
    // }

    return (
        <div className="home-tags">
            <Slider {...settings}>
                <div className="home-tags-item unset-width">
                    <div className="text-center">
                        <div className="home-tags-link" onClick={() => {
                            dispatch(actionCreators.onlyPromotions(true))
                            props.history.push({ pathname: "/search", })
                        }}>
                            <Offerta />
                            <div className="text">{t("promo")}</div>
                        </div>
                    </div>
                </div>
                {tags && tags.map(item => (
                    <HomeTag key={item.id} tag={item.id} description={item.description}>
                        <Offerta />
                        {/* {this.renderIcon(item.id)} */}
                    </HomeTag>
                ))}
            </Slider>
        </div>
    );
}

export default withRouter(HomeTags);

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
        {
            breakpoint: 1301,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 1150,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                // dots: true,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                // dots: true,
            }
        }
    ]
};





