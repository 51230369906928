import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';
import axios from 'axios'
import { authHeader } from '../../utils/authHeader';

export const leafletsService = { leaflets, fetchLeaflet };

function leaflets() {
    return (
        axios.get(`${config.baseUrl}/cli/cms/leaflets`,
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchLeaflet(leafletId) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/leaflets/${leafletId}`,
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}