import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';
import axios from 'axios'
import { authHeader } from '../../utils/authHeader';

export const storesService = {
    stores, states, storesByDelivery, storesCoordinate, storesByZipcode, getStore, firstSlot
};

function stores(page, query) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce?s=999&p=${page}&q=${query}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function storesCoordinate(page, lat, lon, query) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce?s=999&p=${page}&lat=${lat}&lon=${lon}&q=${query}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function states() {
    return (
        axios.get(`${config.baseUrl}/cli/stores/states`)
            .then(handleResponse)
        //.then(checkResponse)
    );
}

function storesByDelivery(deliveryMode) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce/delivery?delivery=${deliveryMode}`)
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function storesByZipcode(zipcode) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce?zipcode=${zipcode}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function getStore(storeId) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/${storeId}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function firstSlot(storeId, deliveryMode) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/stores/${storeId}/delivery/${deliveryMode}/slots/first/available?tid=${config.DEFAULT_TID}`,
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}