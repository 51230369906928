import * as actionTypes from "./actionTypes"
import { history } from "../../../App"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"

export const changeCategoryAction = (categoryId, index) => {
    return {
        type: actionTypes.CHANGE_CATEGORY,
        categoryId: categoryId,
        mainCategory: categoryId,
        activeKey: categoryId
    }
}

export const changeCategory = (categoryId, index) => {
    return (dispatch) => {
        dispatch(changeCategoryAction(categoryId, index))
        dispatch(resetScrolledProduct());
        // history.push({ pathname: `/category/${categoryId}` })
        //history.push({ pathname: `/` })
    }
};


export const changeSubCategoryAction = (id, isSubCategoryOpen) => {
    return {
        type: actionTypes.CHANGE_SUB_CATEGORY,
        categoryId: id,
        subCategory: id,
        isSubCategoryOpen: isSubCategoryOpen
    }
}

export const changeSubCategory = (id, firstLevelCategory, secondLevelCategory) => {
    return (dispatch, getState) => {
        let isSubCategoryOpen = true;
        if (id === getState().categories.subCategory) {
            isSubCategoryOpen = !getState().categories.isSubCategoryOpen;
        }
        dispatch(changeSubCategoryAction(id, isSubCategoryOpen))
        dispatch(resetScrolledProduct());
        history.push({ pathname: `/category/${firstLevelCategory}/${secondLevelCategory}` })
    }
};

export const changeThirdLevelCategoryAction = (id) => {
    return {
        type: actionTypes.CHANGE_THIRD_LEVEL_CATEGORY,
        categoryId: id,
        thirdLevelCategory: id,
    }
}

export const changeThirdLevelCategory = (id, firstLevelCategory, secondLevelCategory, thirdLevelCategory) => {
    return (dispatch) => {
        dispatch(changeThirdLevelCategoryAction(id))
        dispatch(resetScrolledProduct());
        // history.push({ pathname: `/category/${id}` })
        history.push({ pathname: `/category/${firstLevelCategory}/${secondLevelCategory}/${thirdLevelCategory}` })
    }
};

export const onlyPromotionsAction = (onlyPromotions) => {
    return {
        type: actionTypes.ONLY_PROMOTIONS,
        onlyPromotions: onlyPromotions
    }
}

export const onlyPromotions = (onlyPromotions) => {
    return (dispatch) => {
        dispatch(onlyPromotionsAction(onlyPromotions))
    }
};

export const onlyBrandedAction = (onlyBranded) => {
    return {
        type: actionTypes.ONLY_BRANDED,
        onlyBranded: onlyBranded
    }
}

export const onlyBranded = (onlyBranded) => {
    return (dispatch) => {
        dispatch(onlyBrandedAction(onlyBranded))
    }
};

export const tagAction = (tag) => {
    return {
        type: actionTypes.TAG,
        tag: tag
    }
}

export const tag = (tag) => {
    return (dispatch, getState) => {
        let newTag = "";
        if (tag !== getState().categories.tag) {
            newTag = tag;
        }
        dispatch(tagAction(newTag))
    }
};

export const searchAction = (searchInput) => {
    return {
        type: actionTypes.SEARCHINPUT,
        searchInput: searchInput
    }
}

export const search = (searchInput) => {
    return (dispatch, getState) => {
        let newSearchInput = "";
        if (searchInput !== getState().categories.searchInput) {
            newSearchInput = searchInput;
        }
        dispatch(searchAction(newSearchInput))
    }
};

export const getTags = () => {
    return dispatch => {
        ecommerceService.productsTags()
            .then(response => {
                dispatch(getTagsAction(response.data));
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const updateFilters = (data) => {
    return dispatch => {
        ecommerceService.filtersUpdate(data)
            .then(response => {
                dispatch(getTags());
            }).catch((error) => {
                console.log(error)
            })
    }
}

export const getFiltersAction = (filters) => {
    return {
        type: actionTypes.FILTERS,
        filters: filters
    }
}

export const getTagsAction = (tags) => {
    return {
        type: actionTypes.TAGS,
        tags: tags
    }
}

export const changeAllCategoriesAction = (id, mainCategory, subCategory, thirdLevelCategory, isSubCategoryOpen, index) => {
    return {
        type: actionTypes.CHANGE_ALL_CATEGORIES,
        categoryId: id,
        mainCategory: mainCategory,
        subCategory: subCategory,
        thirdLevelCategory: thirdLevelCategory,
        isSubCategoryOpen: isSubCategoryOpen,
        activeKey: index
    }
}

export const changeAllCategories = (id, mainCategory, subCategory, thirdLevelCategory, isSubCategoryOpen, index) => {
    return (dispatch) => {
        dispatch(changeAllCategoriesAction(id, mainCategory, subCategory, thirdLevelCategory, isSubCategoryOpen, index))
        dispatch(resetScrolledProduct());
        // history.push({ pathname: `/category/${id}` })
        history.push({ pathname: `/category/${mainCategory}/${subCategory}/${thirdLevelCategory}` })
    }
}

export const saveScrolledProduct = (page, item, scroll, loadedProducts, activeKey, totalPages) => {
    return {
        type: actionTypes.SAVE_SCROLLEDPRODUCT,
        page: page,
        scroll: scroll,
        product: item,
        loadedProducts: loadedProducts,
        oldActiveKey: activeKey,
        totalPages: totalPages
    }
}
export const resetScrolledProduct = () => {
    return {
        type: actionTypes.RESET_SCROLLEDPRODUCT,
        page: null,
        scroll: null,
        product: null,
        loadedProducts: null,
        oldActiveKey: null
    }
}

