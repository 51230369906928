import React from 'react'
import "./error.style.scss";
import { ReactComponent as Error } from "../../images/registerError.svg"
import { useTranslation } from 'react-i18next';

const RegistrationError = () => {
    const { t } = useTranslation();

    return (
        <div className='error-container'>
            <Error />
            <div className='message'>
                {t('error.registration')}
            </div>
        </div>
    )
}

export default RegistrationError