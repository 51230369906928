import * as actionTypes from "./actionTypes"
import { authenticationService } from "../../../services/basis/AuthenticationService"
import { history } from "../../../App"
import toaster from "../../../utils/toaster"
import { cartAction, addProductToAddAction, open } from "./cart"
import { ecommerceCartsService } from "../../../services/ecommerce/EcommerceCartsService"
import { slists } from "./slists"
import { smartlistService } from "../../../services/slists/SmartlistService"
import config from "../../../config/config"
import swal from "../../../utils/swal"
import i18n from "../../../i18n"
import { getTags } from "./categories"

export const loginAction = (user, showModal) => {
    return {
        type: actionTypes.LOGIN,
        user: user?.profile,
        jwt: user?.jwt,
        showLogin: showModal
    }
}

export const login = (data) => {
    return (dispatch, getState) => {
        authenticationService.login(data)
            .then(response => {
                dispatch(loginAction(response.data, false))
                dispatch(setFlagClickAndCoop(response.data.click_coop));

                // get current cart 
                ecommerceCartsService.current()
                    .then(response => {
                        if (response.responseCode === 4004) {
                            dispatch(open("IN_STORE", "686491", ""))
                        } else {
                            dispatch(cartAction(response.data))
                            // get first availability
                            // ecommerceService.availability()
                            //     .then(response => {
                            //         dispatch(availabilityAction(response.data))
                            //     }).catch((error) => {
                            //         console.log(error);
                            //     })

                            // add unauthenticathed product
                            if (getState().cart.productToAdd) {
                                const item = getState().cart.productToAdd;
                                ecommerceCartsService.store(response.data.id, item.barcode, item.amount, item.um, item.enableReplacement)
                                    .then((response) => {
                                        const um = response.data.updatedProduct.um;
                                        dispatch(addProductToAddAction(response.data.cart))
                                        toaster.standard(`${i18n.t("cart.addedProduct.youHave")} ${item.amount} ${um} ${response.data.updatedProduct.description} ${i18n.t("cart.addedProduct.inCart")}`)
                                    }).catch((error) => {
                                        console.log(error);
                                        toaster.error(error.message);
                                    })
                            }

                            if (config.ENABLED_SLIST) {
                                // get favorites
                                // smartlistService.getFavorites()
                                //     .then(response => {
                                //         dispatch(favorites(response.data))
                                //     }).catch((error) => {
                                //         console.log(error);
                                //     })
                                // get slists
                                smartlistService.getSlists()
                                    .then(response => {
                                        dispatch(slists(response.data))
                                    }).catch((error) => {
                                        console.log(error);
                                    })
                            }
                            if (getState().cart.cart || getState().user.defaultStore) {
                                // history.push("/home")
                            } else {
                                history.push("/")
                            }
                        }
                    }).catch(error => {
                        console.log(error);
                        if (getState().cart.productToAdd) {
                            history.push("/cart")
                        }
                    })
            }).catch((error) => {
                dispatch(loginAction(null, true));
            })
    }
};

export const logoutAction = () => {
    return {
        type: actionTypes.LOGOUT,
        user: null
    }
}

export const logout = () => {
    return dispatch => {
        history.push("/")
        dispatch(logoutAction())
        authenticationService.logout()
            .then(response => {
                dispatch(cartAction(null))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const isLoggedAction = (user) => {
    return {
        type: actionTypes.IS_LOGGED,
        user: user
    }
}

export const isLogged = () => {
    return dispatch => {
        authenticationService.isLogged()
            .then(response => {
                dispatch(isLoggedAction(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const coordinateAction = (latitude, longitude) => {
    return {
        type: actionTypes.COORDINATE,
        latitude: latitude,
        longitude: longitude
    }
}

export const coordinate = (latitude, longitude) => {
    return dispatch => {
        dispatch(coordinateAction(latitude, longitude))
    }
};

export const updateUserAction = (user) => {
    return {
        type: actionTypes.USER_UPDATE,
        user: user
    }
}

export const updateUser = (data) => {
    return dispatch => {
        authenticationService.updateUser(data)
            .then(response => {
                dispatch(updateUserAction(response.data))
                swal.success('Profilo aggiornato con successo');
            }).catch((error) => {
                swal.error('Errore durante l\'aggiornamento del profilo');
                console.log(error);
            })
    }
};

export const deleteUser = () => {
    return dispatch => {
        authenticationService.deleteUser()
            .then(response => {
                swal.deleteSuccess('Richiesta eliminazione profilo inviata, verrà eseguita il prima possibile.');
            }).catch((error) => {
                swal.error('Errore nell\'invio della richiesta');
                console.log(error);
            })
    }
};



export const showLogin = (show) => {
    return dispatch => {
        dispatch(showLoginAction(show))
        dispatch(getTags())
    }
};

export const showLoginAction = (show) => {
    return {
        type: actionTypes.SHOW_LOGIN,
        showLogin: show
    }
}

export const defaultStoreAction = (store, deliveryMode, zipCode, cityName) => {
    return {
        type: actionTypes.DEFAULT_STORE,
        defaultStore: store,
        defaultDelivery: deliveryMode,
        defaultZipCode: zipCode,
        defaultCityName: cityName
    }
}

export const setFlagClickAndCoop = (click) => {
    return {
        type: actionTypes.CLICK_COOP,
        clickCoop: click
    }
}
