import React, { useEffect, useState } from "react"
import NewCounter from "../../../components/UI/NewCounter/NewCounter";
import Col from 'react-bootstrap/Col'
import Placeholder from "../../../images/product-placeholder.png";
import ImageGallery from 'react-image-gallery';
import renderPrice from "../../../utils/renderPrice";
import config from "../../../config/config";
import { tags } from "../../../utils/renderTags";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from 'react-router-dom';
import * as actionCreators from "../../../redux/store/actions/index";
import { ReactComponent as Frozen } from "../../../images/Frozen-new-icon.svg"
import { ReactComponent as List } from "../../../images/list-icon1.svg"
import { ReactComponent as ListGreen } from "../../../images/list-icon-green.svg"
import { ReactComponent as Delete } from "../../../images/delete-white-icon.svg"
import { ReactComponent as Info } from "../../../images/i-icon.svg"
import ImagePopup from "../ImagePopup/ImagePopup";
import LoginPopup from "../../Login/LoginPopup/LoginPopup";
import getStoredState from "redux-persist/es/getStoredState";
import SlistPopup from "../../Smartlist/SlistPopup/SlistPopup";
import { ReactComponent as CartIcon } from "../../../images/cart.svg"
import "../react-slick.styles.scss";
import ReactImageMagnify from 'react-image-magnify';
import ReactSlick from 'react-slick';
import SpacedSpan from "./SpacedSpan";
import ReactImageMagnified from "./ReactImageMagnified";
import ModalProductSubstitution from "../../../components/Products/ModalProduct/ModalProductSubstitution";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
import toaster from "../../../utils/toaster";
import i18n from "../../../i18n";



const ProductDetails = ({
    product,
    productImages,
    amount,
    incrementAmount,
    decrementAmount,
    history,
    setShowPopup,
    unity,
    replacement,
    incremented,
    decremented,
    setIncremented,
    setDecremented
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user)
    const cart = useSelector(state => state.cart.cart)
    const currentSlist = useSelector(state => state.slists.currentSlist)
    const [isOpen, setIsOpen] = useState(false)
    const [imageSource, setImageSource] = useState()
    const [checked, setChecked] = useState();
    const [showButtons, setShowButtons] = useState(false);
    const [open, setOpen] = useState(false)
    const [barcodeProduct, setBarcodeProduct] = useState();
    window.scrollTo(0, 0);

    useEffect(() => {
        var isAlreadyBooked = false;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === product.ref) {
                    isAlreadyBooked = true;
                    setChecked(element.enableReplacement)
                }
            });
        } else {
            setChecked(true)
        }
    }, [])

    const renderPrices = (item) => {
        let result;
        let is3x2 = item.promotions[0]?.labelCode === "3x2";
        let is2x1 = item.promotions[0]?.labelCode === "2x1";
        let is2x1V = item.promotions[0]?.labelCode === "2x1V";
        let is2x1P = item.promotions[0]?.labelCode === "2x1P";
        if (is2x1 || is3x2 || is2x1P || is2x1V) {
            if (is2x1) {
                if (amount <= 1) {
                    return (
                        <div className="product-price">
                            <span>
                                {`${renderPrice((item.netPrice * amount)?.toFixed(2))} \u20AC`}
                            </span>
                        </div>
                    )
                } else {
                    let reduce = Math.floor(amount / 2)
                    return (
                        <div className="product-price">
                            <span className="mr-1 text-strike">
                                {`${renderPrice((item.price * amount)?.toFixed(2))} \u20AC`}
                            </span>
                            <span className="primary-color">
                                {` ${renderPrice((item.netPrice * (amount - reduce))?.toFixed(2))} \u20AC `}
                            </span>
                        </div>
                    )
                }
            } else if (is3x2) {
                if (amount <= 2) {
                    return (
                        <div className="product-price">
                            <span>
                                {`${renderPrice((item.netPrice * amount)?.toFixed(2))} \u20AC`}
                            </span>
                        </div>
                    )
                } else {
                    let reduce = Math.floor(amount / 3)
                    return (
                        <div className="product-price">
                            <span className="mr-1 text-strike">
                                {`${renderPrice((item.price * amount)?.toFixed(2))} \u20AC`}
                            </span>
                            <span className="primary-color">
                                {` ${renderPrice((item.netPrice * (amount - reduce))?.toFixed(2))} \u20AC `}
                            </span>
                        </div>
                    )
                }
            } else if (is2x1V) {
                if (amount <= 1) {
                    return (
                        <div className="product-price">
                            <span>
                                {` ${renderPrice((item.netPrice * amount)?.toFixed(2))} \u20AC `}
                            </span>
                        </div>
                    )
                } else {
                    let reduce = Math.floor(amount / 2)
                    return (
                        <div className="product-price">
                            <span className="mr-1 text-strike">
                                {`${renderPrice((item.price * amount)?.toFixed(2))} \u20AC`}
                            </span>
                            <span className="primary-color">
                                {` ${renderPrice(((item.netPrice * amount) - (item.promotions[0].valueGuest * reduce))?.toFixed(2))} \u20AC `}
                            </span>
                        </div>
                    )
                }
            } else if (is2x1P) {
                if (amount <= 1) {
                    return (
                        <div className="product-price">
                            <span className={"netTotal"}>
                                {` ${renderPrice((item.netPrice * amount)?.toFixed(2))} \u20AC `}
                            </span>
                        </div>
                    )
                } else {
                    let reducePrice = item.netPrice * item.promotions[0].valueGuest / 100
                    let reduce = Math.floor(amount / 2)
                    let price = ((item.netPrice * amount) - (reducePrice * reduce))?.toFixed(3)
                    return (
                        <div className="product-price">
                            <span className="mr-1 text-strike">
                                {`${renderPrice((item.price * amount)?.toFixed(2))} \u20AC`}
                            </span>
                            <span className="primary-color">
                                {` ${renderPrice(price.substring(0, price.length -1))} \u20AC `}
                            </span>
                        </div>
                    )
                }
            }
        }
        if (item.price !== item.netPrice) {
            result =
                <div className="product-price">
                    <span className="mr-1 text-strike">
                        {`${renderPrice((item.price * amount)?.toFixed(2))} \u20AC`}
                    </span>
                    <span className="primary-color">
                        {`${renderPrice((item.netPrice * amount)?.toFixed(2))} \u20AC`}
                    </span>
                </div>
        } else {
            result =
                <div className="product-price">
                    <span>{`${renderPrice((item.netPrice * amount)?.toFixed(2))} \u20AC`}</span>
                </div>
        }
        return result
    }

    const renderPromo = (item) => {
        let result;
        if (item.labelCode === "SOTTOCOSTO") {
            return <div className="isundercost">sottocosto</div>
        }
        if (item.labelCode === "3x2") {
            return <div className="is3x2detail">3 al prezzo di 2</div>
        }
        if (item.labelCode === "2x1") {
            return <div className="is2x1detail">2 al prezzo di 1</div>
        }
        if (item.labelCode === "2x1P") {
            return <div className="is3x2detail">Acquistando due prodotti ottieni uno sconto del {item?.valueGuest}% sul secondo prodotto</div>
        }
        if (item.labelCode === "2x1V") {
            return <div className="is2x1detail">Risparmia {item?.valueGuest}€ acquistando due prodotti</div>
        }
        return result;
    }

    const renderPromoTag = (item) => {
        if (item.promotions[0]?.labelCode === "SOTTOCOSTO") {
            return (
                <div className="product-detail-promotion-undercost">
                    <span>SOTTO</span>
                    <span>COSTO</span>
                </div>
            )
        }
        if (item.promotions[0]?.labelCode === "3x2") {
            return (
                <div className="product-detail-promotion-3x2">
                    <span className="threextwodetail">{item.promotions[0]?.labelCode}</span>
                </div>
            )
        }
        if (item.promotions[0]?.labelCode === "2x1") {
            return (
                <div className="product-detail-promotion">
                    <span className="twoxonedetail">{item.promotions[0]?.labelCode}</span>
                </div>
            )
        }
        if (item.promotions[0]?.labelCode === "2x1V") {
            return (
                <div className="product-detail-promotion">
                    <span className="twoxoneVdetail"><span className="saveDetail">Risparmia</span>{item.promotions[0]?.valueGuest}€</span>
                </div>
            )
        }
        if (item.promotions[0]?.labelCode === "2x1P") {
            return (
                <div className="product-detail-promotion-3x2">
                    <span className="threextwodetail">{item.promotions[0]?.valueGuest}%</span>
                </div>
            )
        }
        return (
            <div className="product-detail-promotion">
                -{item.discountLabel}
            </div>
        )
    }

    const renderUnity = (item) => {
        let result;
        let steps;
        let newUnity;
        if (item.umStep < amount) {
            // steps = Math.ceil(amount / item.umStep);
            // newUnity = item.attrib3 * steps;
            let roundStep = parseFloat(item.umStep?.toFixed(2));
            let steps = Math.ceil(amount / roundStep);
            let newUnity = parseInt(item.attrib3) * steps;
            return newUnity
        } else {
            return item.attrib3
        }
    }

    const handleSubstituteProduct = (barcode, show) => {
        if (checked) {
            setChecked(false);
            if (checkIsBooked()) {
                dispatch(actionCreators.store(cart.id, product.barcode, amount, product.um, false, unity));
            } else {
                toaster.standard(i18n.t('substitution.cancelled'));
            }
        } else {
            setChecked(true);
            toaster.standard(i18n.t('substitution.confirm'))
        }
    }

    const showSubstituteProduct = (barcode, show) => {
        ecommerceService.getProductFromBarcode(barcode)
            .then(response => {
                console.log(response.data);
                setOpen(true);
                setShowButtons(show)
                setBarcodeProduct(response.data)
            }).catch((error) => {
                console.log(error);
                toaster.standard(i18n.t('error.substitution'));
            })
    }

    const openPopUp = (e) => {
        setImageSource(e)
        setIsOpen(true)
    }

    const checkIsBooked = () => {
        // const { cart, item } = this.props;
        var isAlreadyBooked = false;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === product.ref) {
                    isAlreadyBooked = true;
                }
            });
        }
        return isAlreadyBooked;
    }

    const setIsInList = () => {
        // const { currentSlist, item } = this.props;
        var isAlreadyInList = false;
        if (currentSlist && currentSlist.items) {
            currentSlist.items.forEach(element => {
                if (element.ref === product.ref) {
                    isAlreadyInList = true;
                }
            });
        }
        return isAlreadyInList
        // this.setState({ isAlreadyInList: isAlreadyInList })
    }

    const renderPricePerUm = (item) => {
        let is3x2 = item.promotions[0]?.labelCode === "3x2";
        let is2x1 = item.promotions[0]?.labelCode === "2x1";
        let is2x1P = item.promotions[0]?.labelCode === "2x1P";
        let is2x1V = item.promotions[0]?.labelCode === "2x1V";
        if (is2x1 || is3x2) {
            const n = is2x1 ? 2 : 3;
            const m = is2x1 ? 1 : 2;
            if (amount <= m) {
                return `${renderPrice(item.pricePerUM?.toFixed(2))} \u20AC / ${item.umPerUM}`
            } else {
                let reduce = Math.floor(amount / n)
                let newPricePerUM = parseFloat(item.pricePerUM * (amount - reduce)?.toFixed(2) / amount).toFixed(2)
                return `${renderPrice(newPricePerUM)} \u20AC / ${item.umPerUM}`
            }
        }
        if (is2x1V){
            if (amount <= 1) {
                return `${renderPrice(item.pricePerUM?.toFixed(2))} \u20AC / ${item.umPerUM}`
            } else {
                let reduce = Math.floor(amount / 2)
                let price = ((item.netPrice * amount) - (item.promotions[0].valueGuest * reduce))
                let newPricePerUM = parseFloat(price / (item?.weight * amount) * 1000).toFixed(2)
                return `${renderPrice(newPricePerUM)} \u20AC / ${item.umPerUM}`
            }
        }
        if (is2x1P){
            if (amount <= 1) {
                return `${renderPrice(item.pricePerUM?.toFixed(2))} \u20AC / ${item.umPerUM}`
            } else {
                let reduce = Math.floor(amount / 2)
                let reducePrice = item.netPrice * item.promotions[0].valueGuest / 100
                let price = ((item.netPrice * amount) - (reducePrice * reduce))?.toFixed(2)
                let newPricePerUM = parseFloat(price / (item?.weight * amount) * 1000);
                return `${renderPrice(newPricePerUM?.toFixed(2))} \u20AC / ${item.umPerUM}`
            }
        }
        return `${renderPrice(item.pricePerUM?.toFixed(2))} \u20AC / ${item.umPerUM}`
    }

    const renderPercentage = () => {
        let reduce = Math.floor(amount / 2)
        let discountPrice = (product.netPrice * amount) - (product.promotions[0].valueGuest * reduce);
        let percentage = discountPrice * 100 / (product.netPrice * amount)
        return Math.floor(100 - percentage);
    }

    return (
        <div className="product">
            <div className="product-image">
                {product.promotions && product.promotions[0] &&
                    renderPromoTag(product)
                }
                {product.promotions && product.promotions[0] &&
                    product.promotions[0].labelCode && product.promotions[0].labelCode === "SOTTOCOSTO" &&
                    <div className="product-detail-promo-container-undercost">
                        <span>-{product.discountLabel}</span>
                    </div>
                }
                {product.promotions && product.promotions[0] &&
                    product.promotions[0].labelCode && product.promotions[0].labelCode === "2x1V" &&
                    <div className="product-detail-promo-container-2x1V">
                        <>
                            <span>con</span>
                            <span>2 pz</span>
                        </>
                    </div>
                }
                {product.promotions && product.promotions[0] &&
                    product.promotions[0].labelCode && product.promotions[0].labelCode === "2x1P" &&
                    <div className="product-detail-promo-container-2x1P">
                        <>
                            <span>sul 2˚</span>
                            <span>pezzo</span>
                        </>
                    </div>
                }
                <ReactImageMagnified
                    {...{
                        productImages: productImages,
                        product: product,
                        rimProps: {
                            // isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false,
                            enlargedImagePosition: 'over'
                        },
                        openPopUp: openPopUp
                    }}
                />
                {
                    isOpen &&
                    <ImagePopup
                        show={isOpen}
                        imageSource={imageSource}
                        onClose={() => setIsOpen(false)}
                    />
                }
            </div>
            {/*  <div className="product-image">
                {!productImages &&
                    <img src={product.imageUrl ? product.imageUrl : Placeholder} alt=""
                        onError={(e) => e.target.src = Placeholder}
                    />
                }
                {productImages &&
                    <ImageGallery
                        items={productImages}
                        showFullscreenButton={false}
                        autoPlay={false}
                        // showBullets={productImages.length > 1 ? true : false}
                        showBullets={true}
                        showPlayButton={false}
                        showThumbnails={false}
                        onErrorImageURL={Placeholder}
                        onClick={(e) => openPopUp(e)}
                    />
                }
                {
                    isOpen &&
                    <ImagePopup
                        show={isOpen}
                        imageSource={imageSource}
                        onClose={() => setIsOpen(false)}
                    />
                }
            </div>  */}
            <div className="product-detail">
                <div className="product-detail-ref">{t("product.ref")}: {product.ref}</div>
                {
                    product.description2 ? <h2>{product.description2}</h2> : <h2>{product.description}</h2>
                }
                {
                    product.brand &&
                    <div style={{ fontSize: "18px", fontFamily: "Montserrat-Bold" }}>
                        {product.brand}
                    </div>
                }
                {
                    product.weightString &&
                    <div style={{ fontSize: "15px", fontFamily: "Montserrat-Bold" }}>
                        {product.weightString}
                    </div>
                }
                {product.categoryId.startsWith("100") &&
                    <div className="info" style={{ marginTop: "8px" }} data-tip data-for="registerTip">
                        <Frozen />
                    </div>
                }
                {/* <h2>{product.description2}</h2> */}
                {product.pricePerUm && product.umPerUm &&
                    <div className="product-detail-pricePerUm" product-detail-ref>
                        {renderPricePerUm(product)}
                    </div>
                }
                <div className="product-detail-tag">
                    {product.tags && product.tags.split(",").map(tag => (
                        <span key={tag}>{tags.renderDetailTags(tag)}</span>
                    ))}
                </div>
                {product.replacementBarcode &&
                    <div className="product-detail-sub">
                        <div style={{ marginRight: '20px' }}>
                            {product.replacementBarcode &&
                                <div>
                                    <label className="switch">
                                        <input type="checkbox" onClick={() => handleSubstituteProduct(product.replacementBarcode, true)} checked={checked} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            }
                        </div>
                        <label className="sub-label">Prodotto alternativo</label>
                        <Info height={20} width={30} onClick={() => showSubstituteProduct(product.replacementBarcode, false)} />
                    </div>
                }
                {product.promotions && product.promotions[0] && product.promotions[0].labelCode === "2x1V" && amount > 1 &&
                    <div className="is2x1detail">-{renderPercentage()}%</div>
                }
                {product.price &&
                    renderPrices(product)
                }
                {product.pricePerUM && product.umPerUM &&
                    <div className="product-detail-netPrice">
                        {renderPricePerUm(product)}
                    </div>
                }
                {product.promotions && product.promotions[0] &&
                    renderPromo(product.promotions[0])
                }
                <NewCounter
                    value={amount}
                    um={product.purchaseUm}
                    increment={incrementAmount}
                    decrement={decrementAmount}
                    showUm={true}
                    attrib3={unity}
                />
                <div>
                    {user && cart &&
                        <button className="cta"
                            style={{ backgroundColor: checkIsBooked() ? incremented || decremented ? "#e22018" : "#4fc269" : "#e22018" }}
                            onClick={() => {
                                dispatch(actionCreators.store(cart.id, product.barcode, amount, product.um, product.replacementBarcode ? checked : false, unity));
                                setIncremented(false);
                                setDecremented(false);
                            }}
                        >
                            <span>{checkIsBooked() ? incremented || decremented ? "AGGIORNA CARRELLO" : "NEL CARRELLO" : t("addToCart")}</span>
                        </button>
                    }
                    {user && !cart &&
                        <>
                            <button className="cta"
                                onClick={() => {
                                    dispatch(actionCreators.setProductToAdd(product, amount, product.replacementBarcode ? checked : false))
                                    history.push("/cart")
                                }}>
                                <span>{t("addToCart")}</span>
                            </button>
                        </>
                    }
                    {!user &&
                        <button className="cta"
                            onClick={() => {
                                dispatch(actionCreators.setProductToAdd(product, amount, product.replacementBarcode ? checked : false))
                                dispatch(actionCreators.showLogin(true))
                            }}>
                            {/* <span>{t("access")}</span> */}
                            <CartIcon />
                            <span>ACQUISTA</span>
                        </button>
                    }
                    {checkIsBooked() &&
                        <button className="cta"
                            style={{ backgroundColor: "#e22018", marginLeft: '5px' }}
                            onClick={() => {
                                dispatch(actionCreators.remove(cart.id, product.barcode));
                            }}
                        >
                            <Delete style={{ marginRight: '0', fill: '#fff' }} />
                        </button>
                    }
                </div>
                {config.ENABLED_SLIST &&
                    <button className="list-button" onClick={() => {
                        if (user) {
                            setShowPopup(true);
                        } else {
                            dispatch(actionCreators.showLogin(true))
                        }
                    }}>
                        {/* {user ? <List/> : t("access")}  */}
                        {setIsInList() ? <ListGreen /> : <List />} <span className="add-to-list">{setIsInList() ? t("added-to-list") : t("add-to-list")}</span>
                    </button>
                }
            </div>
            <ModalProductSubstitution
                open={open}
                product={barcodeProduct}
                showButtons={showButtons}
                onHide={() => { setOpen(false); }}
                onConfirm={() => { setOpen(false); setChecked(true); }}
                renderPrice={renderPrice}
            />
        </div>
    );
}

export default withRouter(ProductDetails);