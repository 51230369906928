import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import axios from 'axios'

export default function landingBanners() {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners?category=WEB&position=LANDING_PAGE`)
            .then(handleResponse)
            .then(checkResponse)
    );
}