import React from 'react'
import { Translation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import "./aboutus.styles.scss"
import ColouredCard1M from "../../../../images/departments/Convenienza_quotidiana.png";
import ColouredCard2M from "../../../../images/departments/Rispetto natura.png";
import ColouredCard3M from "../../../../images/departments/Territorio.png";
import ColouredCard4M from "../../../../images/departments/benessere.png";
import ColouredCard5M from "../../../../images/departments/sicurezza-alimentare.png";

const AboutUs = () => {
  const history = useHistory();
  return (
    <Translation>
      {t =>
        <div className='aboutus'>
          <div className='banner'>
          </div>
          <div className='content'>
            <div className='title'>
              {t("footer.aboutUs")}
            </div>
            <hr />
            <div className='subtitle'>
              {t("aboutus.subtitle")}
              <div>
                {t("aboutus.subtitle1")}<b style={{ fontFamily: 'Montserrat-Bold' }}>{t("aboutus.subtitle2")}</b>
              </div>
            </div>
            <div className='cards'>
              <img src={ColouredCard1M} alt="" onClick={() => history.push("/ourvalues/convenienza_quotidiana")}/>
              <img src={ColouredCard2M} alt="" onClick={() => history.push("/ourvalues/rispetto_della_natura")}/>
              <img src={ColouredCard3M} alt="" onClick={() => history.push("/ourvalues/il_nostro_territorio")}/>
              <img src={ColouredCard4M} alt="" onClick={() => history.push("/ourvalues/benessere_a_tua_misura")}/>
              <img src={ColouredCard5M} alt="" onClick={() => history.push("/ourvalues/sicurezza_alimentare")}/>
            </div>
            <div className='disclaimer'>
              <div style={{ textAlign: 'center'}}>
                <h1>{t("aboutus.disclaimer")}</h1>
              </div>
              <div className='steps'>
                <div className='outCircle'>
                  <div className='innerCircle'>
                    1
                  </div>
                  <div className='textCircle'>
                    {t("aboutus.card1")}
                  </div>
                </div>
                <hr />
                <div className='outCircle'>
                  <div className='innerCircle'>
                    2
                  </div>
                  <div className='textCircle'>
                    {t("aboutus.card2")}
                  </div>
                </div>
                <hr />
                <div className='outCircle'>
                  <div className='innerCircle'>
                    3
                  </div>
                  <div className='textCircle'>
                    {t("aboutus.card3")}
                  </div>
                </div>
              </div>
            </div>
            <div className='imageMobile' />
            <div className='description'>
              <div className='textStep'>
                <div className='textStep-text'>
                  {t("aboutus.description")}
                  <div style={{ marginTop: '10px' }}>
                    {t("aboutus.description2")}
                  </div>
                </div>
                <div className='textStep-steps'>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <div className='numbers'>1</div>
                    <div className='step'>{t("sign_in")}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <div className='numbers'>2</div>
                    <div className='step'>{t("aboutus.step2")}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <div className='numbers'>3</div>
                    <div className='step'>{t("aboutus.step3")}</div>
                  </div>
                </div>
              </div>
              <div className='description-image' />
            </div>
          </div>
        </div>
      }
    </Translation>
  )
}

export default AboutUs