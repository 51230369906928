import React from 'react'
import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet';
import { withRouter } from 'react-router-dom';

import "./store-detail-map.styles.scss";

const StoresMap = ({ lat, lng, zoom, history }) => {
    const position = [lat, lng]
    return (
        <div className="store-detail-map">
            <LeafletMap center={position} zoom={zoom}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={position} />
            </LeafletMap>
        </div>
    )
}

export default withRouter(StoresMap);



