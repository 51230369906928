import React from "react"
import { useTranslation } from "react-i18next";

import "./order-filters.styles.scss";

const OrderFilters = ({ filter, setFilter, totalElements }) => {
    const { t } = useTranslation();
    return (
        <div className="order-filters">
            <div className="filters">

                {/* <div className={`order-filters-item ${filter === "" ? "filter-selected" : ""}`}
                    onClick={() => setFilter("")}>
                    <span>{t("orders.all")}</span>
                </div> */}

                <div className={`order-filters-item ${filter === "GREEN" ? "filter-selected" : ""}`}
                    onClick={() => setFilter("GREEN")}>
                    <div className="icon-container">
                        <div className="icon order-filter-COLLECTED">
                            &nbsp;
                        </div>
                    </div>
                    <span>{t("order.status.COLLECTED")}</span>
                </div>

                <div className={`order-filters-item ${filter === "YELLOW" ? "filter-selected" : ""}`}
                    onClick={() => setFilter("YELLOW")}>
                    <div className="icon-container">
                        <div className="icon order-filter-IN_PREPARATION">
                            &nbsp;
                        </div>
                    </div>
                    <span>{t("order.status.ISSUING_RECEIPT")}</span>
                </div>
                
                <div className={`order-filters-item ${filter === "ORANGE" ? "filter-selected" : ""}`}
                    onClick={() => setFilter("ORANGE")}>
                    <div className="icon-container">
                        <div className="icon order-filter-TO_BE_COLLECTED">
                            &nbsp;
                        </div>
                    </div>
                    <span>{t("order.status.TO_BE_COLLECTED")}</span>
                </div>

                <div className={`order-filters-item ${filter === "RED" ? "filter-selected" : ""}`}
                    onClick={() => setFilter("RED")}>
                    <div className="icon-container">
                        <div className="icon order-filter-CANCELLED">
                            &nbsp;
                        </div>
                    </div>
                    <span>{t("order.status.ABORTED")}</span>
                </div>

            </div>
            {/* <div className="order-filters-total">
                {t("orders.total")}: <b>{totalElements}</b>
            </div> */}
        </div>
    );
}

export default OrderFilters;