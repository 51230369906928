import React from "react";
import NewCounter from "../../../../components/UI/NewCounter/NewCounter";
import Placeholder from "../../../../images/product-placeholder.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom"

import "./smartlist-item.styles.scss"

class SmartlistItem extends React.Component {
    render() {
        const { item, currentSlist, itemDelete, itemUpdate } = this.props;
        return (
            <div className="smartlist-item">
                <Link className="link"
                    to={{ pathname: `/product/${item.ref?.toString()}`, state: { product: item } }}>
                    <div className="smartlist-item-link">
                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                            onError={(e) => { e.target.src = Placeholder }}
                        />
                        <h5>{item.description}</h5>
                    </div>
                </Link>
                <NewCounter
                    value={item.amount}
                    increment={() => itemUpdate(currentSlist.id, item.id, item.amount + 1)}
                    decrement={() => item.amount !== 1 && itemUpdate(currentSlist.id, item.id, item.amount - 1)}
                />
                <button className="button-clean outline primary-text"
                    onClick={() => itemDelete(currentSlist.id, item.id)}>
                    <FontAwesomeIcon icon="times-circle" size="1x" />
                </button>
            </div>
        );
    }
}

export default SmartlistItem;
