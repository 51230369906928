import React, { Component } from 'react';
import { Table } from "react-bootstrap";

import "./CookiePolicy.scss"

class CookiePolicy extends Component {
    render() {
        return (
            <div className="container d-flex justify-content-center flex-column mt-5 mb-5 cookie-policy">
                <div className="policy-heading text-center">COOKIE POLICY</div>
                <div className="policy-title">Dati di navigazione
                    <div className="policy-terms">Questo sito web acquisisce in modo implicito, utilizzando i protocolli di comunicazione di internet,
                        per e nel corso del suo normale funzionamento, alcuni dati personali degli utenti che accedono al
                        sito stesso, come l’indirizzo IP, i nomi di dominio dei computer da cui l’utente accede, gli indirizzi
                        MAC, assegnati dai produttori delle schede di rete, wireless etc.
                        Queste informazioni non vengono raccolte per identificare gli utenti, ma potrebbero esserlo
                        attraverso associazioni ed elaborazioni anche incrociate con dati di terzi; da tali dati si ricavano
                        informazioni statistiche sull’utilizzo del sito e sul suo funzionamento e ulteriori informazioni in caso
                        di accertamento di responsabilità relativamente a reati informatici.
                    </div>
                </div>
                <div className="policy-title">Cosa sono i cookie?
                    <div className="policy-terms">I cookie sono delle piccole stringhe di testo che i siti visitati dall’utente installano sul suo terminale;
                        tali stringhe vengono successivamente ritrasmesse al sito che le ha installate in caso di successiva
                        visita da parte dell’utente. Laddove l’utente riceva, durante la navigazione sul sito, anche cookie
                        inviati da siti o web server diversi, si parla di cookie di terze parti.
                        I cookie vengono installati per diverse finalità, tra cui possono rientrare la possibilità di effettuare le
                        autenticazioni informatiche, il monitoraggio delle sessioni di navigazione, scegliere la lingua.
                    </div>
                </div>
                <div className="policy-title">Cookie prima parte e cookie di terze parti
                    <div className="policy-terms">I cookie che sono installati direttamente da SAIT sono detti “Cookie prima parte”, mentre i cookie
                        che sono installati e acquisiti da un sito diverso rispetto a quello su cui sta navigando l’utente sono
                        definiti “Cookie di terze parti”.
                        Tra i cookie di terze parti rientrano i social buttons (o social plugin), che permettono al sito di
                        interagire con i più famosi social media, come Facebook, Instagram, LinkedIn, Twitter etc oppure i
                        cookie di Google Analytics, quelli necessari per implementare gli iframe di Youtube etc. Per quanto
                        concerne i cookie di terze parti, è responsabilità delle terze parti cui il cookie fa capo fornire le
                        informative e le informazioni circa la gestione dei dati raccolti.
                    </div>
                </div>
                <div className="policy-title">Tipologie di cookie

                    <div className="policy-terms">
                        <div className="policy-subtitle">Cookie tecnici
                            <div className="policy-terms">
                                Sono normalmente installati direttamente dal gestore del sito e sono cookie che permettono la
                                comunicazione tra sito ed utente. Possono essere di navigazione o di sessione, atti a garantire la
                                normale navigazione e fruizione del sito e solitamente hanno durata pari a quella della navigazione
                                sul sito, oppure cookie di funzionalità, che permettono una migliore fruizione del sito, come quelli
                                relativi alla scelta della lingua del sito, all’immissione nel carrello degli acquisti scelti etc.
                                Essendo tali cookie necessari per il funzionamento del sito non è necessario, per la loro installazione
                                sul terminale dell’utente, il suo preventivo consenso.
                            </div>
                        </div>
                    </div>

                    <div className="policy-subtitle">Cookie analitici
                        <div className="policy-terms">Sono cookie che permettono di raccogliere informazioni sugli utenti, ad esempio sul numero di
                            utenti che visitano il sito e sulle modalità di visita del sito, in forma più o meno aggregata. Uno degli
                            strumenti più famosi per ottenere tali report statistici è Google Analytics, servizio fornito da Google
                            Inc.
                        </div>
                    </div>
                </div>
                <div className="policy-terms">
                    I cookie analitici sono assimilabili ai cookie tecnici laddove le informazioni raccolte siano in forma
                    aggregata e non sia possibile identificare le abitudini del singolo utente; per quanto concerne nello
                    specifico i cookie di Google Analytics sarà necessario anonimizzare, almeno parzialmente, l’indirizzo
                    IP dell’utente che visita il sito ed eliminare le condivisioni dei dati con Google. Laddove tale
                    anonimizzazione e mancanza di condivisione non fosse possibile tali cookie rientreranno in quelli di
                    profilazione.
                </div>

                <div className="policy-title">Cookie di profilazione
                    <div className="policy-terms">Sono utilizzati per tracciare una preferenza dell’utente e offrirgli dei messaggi pubblicitari sulla base
                        delle preferenze ricavate. Tali cookie sono particolarmente invasivi ed è necessario, prima di
                        installarli sul terminale dell’utente, ottenere il suo consenso.
                    </div>
                </div>
                <div className="policy-title">Il banner al primo accesso
                    <div className="policy-terms">Il Provvedimento generale del Garante Privacy in materia di cookie dell’8 maggio 2014, n. 229, il
                        “Working Document 02/2013 providing guidance on obtaining consent for cookies”, l’Opinion del
                        WP 29 n. 4/2012 On Cookie Consent Exemptione le Linee guida del Garante di data 10 giugno 2021,
                        n. 231 richiedono, in caso di utilizzo di cookie diversi dai cookie tecnici, la predisposizione di un
                        banner al primo accesso dell’utente sul sito (cd. informativa breve) che indichi in modo essenziale
                        quali sono le modalità di gestione dei cookie da parte del sito, rimandando alla privacy policy estesa.
                    </div>
                </div>
                <div className="policy-terms">SAIT ha predisposto il suddetto banner: cliccando sul tasto “OK, Accetta tutto” l’utente accetta
                    l’installazione di tutti i cookie e decide di continuare la fruizione del sito. Cliccando invece il tasto
                    “Accetta solo i necessari” verranno installati solo i cookie tecnici. Infine, cliccando il tasto
                    personalizza l’utente potrà scegliere quali cookie autorizzare o meno. È inoltre stato previsto un
                    cookie specifico che memorizza la scelta dell’utente in materia d’installazione dei cookie per 365
                    giorni; ciò significa che l’utente visualizzerà il cookie una sola volta e se volesse successivamente
                    modificare le scelte effettuate potrà farlo seguendo le istruzioni al paragrafo “Procedura per la
                    disabilitazione dei cookie”.
                </div>

                <div className="policy-title">Il nostro sito installa i seguenti cookie:</div>
                <div className="table-responsive-sm-lg-md-xl">
                    <Table className="table table-bordered">
                        <thead className="thead">
                            <tr>
                                <th>Nome cookie</th>
                                <th>Durata</th>
                                <th>Funzione</th>
                                <th>Tipo cookie</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>JSESSIONID</td>
                                <td>Sessione</td>
                                <td>mantenere la sessione sicura per tutta la durata della visita</td>
                                <td>Tecnico</td>
                            </tr>
                            <tr>
                                <td>tarteaucitron</td>
                                <td>12 mesi</td>
                                <td>Protegge le scelte relative al consenso dei cookie</td>
                                <td>Tecnico</td>
                            </tr>
                            <tr>
                                <td>_ga</td>
                                <td>2 anni</td>
                                <td>Cookie di Google Analytics - Distinguere i visitatori del sito tramite un identificatore casuale</td>
                                <td>Analitico</td>
                            </tr>
                            <tr>
                                <td>_ga_RR92D6GOPP</td>
                                <td>2 anni</td>
                                <td>Cookie di Google Analytics - Raccogliere le statistiche utili al miglioramento del sito </td>
                                <td>Analitico</td>
                            </tr>
                        </tbody>
                    </Table>

                </div>
                <div className="policy-title">Procedura per la disabilitazione dei cookie</div>
                <div className="policy-subtitle">L’erogazione di tutti i cookie è disattivabile intervenendo sulle impostazioni del Suo browser. E’
                    opportuno evidenziare, tuttavia, come intervenire su queste impostazioni potrebbe rendere
                    inutilizzabile il sito nel caso in cui si bloccassero i cookie indispensabili per l’erogazione dei nostri
                    Servizi. Ogni browser dispone di impostazioni diverse per la disattivazione dei cookie. Di seguito
                    trova i collegamenti alle istruzioni per i browser più comuni:
                </div>
                <div>
                    <ul>
                        <li>Google Chrome: <a
                            href="https://support.google.com/chrome/answer/95647?hl=it"> https://support.google.com/chrome/answer/95647?hl=it
                        </a>
                        </li>
                        <li>Internet Explorer:
                            <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies"> https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies
                            </a>
                        </li>
                        <li>Mozilla Firefox:
                            <a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"> https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie
                            </a>
                        </li>
                        <li>Apple Safari:
                            <a href="https://support.apple.com/it-it/HT201265">  https://support.apple.com/it-it/HT201265</a></li>
                    </ul>
                </div>
                <div className='policy-subtitle'>
                    Link alle privacy policy delle terze parti che installano cookie <br />
                    Google: <a href="https://www.google.com/intl/it_it/policies/technologies/cookies/">  https://www.google.com/intl/it_it/policies/technologies/cookies/</a>
                </div>
            </div>
        );
    }
}

export default CookiePolicy;