import React, { useEffect, useState } from "react";
import ValidateInput from "../../components/UI/Input/ValidateInput";
//import BillingAddressSelect from "./BillingAddressSelect/BillingAddressSelect";
//import PaymentsMode from "./PaymentsMode/PaymentsMode";
import CustomBreadcrumb from "../../components/UI/Breadcrumb/CustomBreadcrumb";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux"
import { addressesService } from "../ProfileContainer/ProfileAddresses/Service";
import { useHistory } from "react-router-dom"
import renderPrice from "../../utils/renderPrice";
import { ReactComponent as Cal } from "../../images/cal-icon.svg";
import { ReactComponent as AccordionDown } from "../../images/accordion-down.svg";
import { ReactComponent as AccordionUp } from "../../images/accordion-up.svg";
import "./summary.styles.scss";
import CartMenu from "../Cart/CartMenu/CartMenu";

const Summary = ({
    paymentMethod,
    choosedTimeslot,
    choosedDay,
    onSubmit,
    removedItems,
    togglePopup,
    termsAndCondition,
    goToCheckout,
    freezeCheck,
    abort,
    showFreezePopup,
    closeFreezePopup,
    updatedItems,
    deliveryAddress,
    deliveryZip,
    deliveryCity,
    deliveryCountry
}) => {
    const { t } = useTranslation();
    const user = useSelector(state => state.user.user);
    const cart = useSelector(state => state.cart.cart);
    const [addresses, setAddresses] = useState([]);
    let history = useHistory();
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        addressesService.getBillingAddresses()
            .then(response => {
                if (response.data.length > 0) {
                    setAddresses(response.data)
                }
            }).catch((error) => {
                console.log(error);
            })
    }, [])

    const selectedPaymentMethod = (paymentMethod) => {
        switch (paymentMethod) {
            case "XPAY":
                return (
                    <span style={{ fontFamily: "Montserrat-Bold" }}>{t("online")}</span>
                );
            case "BANCOMAT":
                return (
                    <span style={{ fontFamily: "Montserrat-Bold" }}>{t("atDelivery")}</span>
                );
            default: return null;
        }
    }

    return (
        <div className="">
            <div className="summary-heading-bar desktop" style={{ marginBottom: "30px" }}>
                <div className="col-lg-6 col-md-4 col-sm-5 summary-heading-bar-text">
                    <span className="summary-heading-bar-text-digit-text">4</span>
                    <span className="summary-heading-bar-text-alpha-text">RIEPILOGO</span>
                </div>
                <div className="col-lg-6 col-md-8 col-sm-7 summary-heading-bar-buttons">
                    <button style={{ width: "160px", height: "36px", borderRadius: "18px", backgroundColor: "white", color: "#d80014", fontWeight: "bold", border: "solid 1px rgba(255, 255, 255, 0.5)" }}
                        onClick={() => history.push("/checkout")}>
                        INDIETRO
                    </button>
                    <button className="avanti-button"
                        onClick={onSubmit}
                    >
                        {paymentMethod !== 'XPAY' ? 'CONFERMA' : 'CONFERMA E PAGA'}
                    </button>
                </div>
            </div>
            <div className="summary-cart-menu-dropdown">
                <div className="col-6" style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <Cal className="mr-4" />
                    <h5>RIEPILOGO</h5>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? <AccordionUp /> : <AccordionDown />}
                </div>
            </div>

            {
                isOpen &&
                <CartMenu
                    cart={cart}
                    freezeCheck={freezeCheck}
                    abort={abort}
                    open={showFreezePopup}
                    closePopup={closeFreezePopup}
                    updatedItems={updatedItems}
                    removedItems={removedItems}
                    togglePopup={togglePopup}
                    choosedDay={choosedDay}
                    choosedTimeslot={choosedTimeslot}
                    termsAndCondition={termsAndCondition}
                    goToCheckout={goToCheckout}
                    // onSubmit={handleSubmit(submit)}
                    // enableProductsReplacement={enableProductsReplacement}
                    deliveryAddress={deliveryAddress}
                    paymentMethod={paymentMethod}
                    user={user}
                />
            }
            <div style={{ marginTop: "20px" }}>
                <div style={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
                    <div style={{ display: "flex" }}>
                        <div className="col-6" style={{ display: "flex", alignItems: "center", fontFamily: "Montserrat-Regular", fontSize: "20px", color: "#272f47", fontWeight: "bold" }}>
                            CONFERMA ORDINE
                        </div>
                        <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                            <button className="modifica-button"
                                onClick={() => history.push("/cart")}
                            >MODIFICA
                            </button>
                        </div>
                    </div>
                    {paymentMethod === "XPAY" &&
                        <div style={{ padding: '0 15px' }}>
                            <span style={{ fontFamily: 'Montserrat-Bold' }}>N.B.</span><br />
                            <span style={{ fontFamily: 'Montserrat-Bold' }}>NON PAGHI ORA</span>, l’importo ti verrà addebitato solo al momento dell’emissione dello scontrino.<br />
                            Si tratta di una preautorizzazione che impegna un importo pari al totale spesa maggiorato del 5% per la gestione dei prodotti a peso variabile.<br />
                            Al momento dell’emissione dello scontrino ti verrà addebitato l’esatto importo in base ai prodotti effettivamente consegnati e al valore di quelli a peso variabile.<br />
                        </div>
                    }
                    <hr style={{ width: '100%' }} />
                    <div className="col-12" style={{ fontSize: "17px", color: "#272f47" }}>
                        <span style={{ marginRight: "10px" }}>{cart?.itemsCount}</span>
                        <span>Articoli</span>
                    </div>
                    <div className="col-12" style={{ fontSize: "17px", color: "#272f47" }}>
                        <span style={{ marginRight: "10px" }}>{t("total")}</span>
                        <span style={{ fontFamily: "Montserrat-Bold" }}>{cart && cart.totalToPay ? `${renderPrice(cart.totalToPay?.toFixed(2))} \u20AC` : `${renderPrice(cart.netTotal?.toFixed(2))} \u20AC`}</span>
                    </div>
                </div>
                <hr />
                <div style={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
                    <div style={{ display: "flex" }}>
                        <div className="col-6" style={{ display: "flex", alignItems: "center", color: "#272f47" }}>
                            <span>La tua spesa sarà pronta per il <b>ritiro</b> :</span>
                        </div>
                        <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                            <button className="modifica-button"
                                onClick={() => history.push("/availability")}
                            >MODIFICA
                            </button>
                        </div>
                    </div>
                    <div className="col-12" style={{ color: "#272f47", fontFamily: "Montserrat-Bold" }}>
                        <span className="">
                            {(choosedDay && choosedTimeslot) &&
                                `${choosedDay.dayNumber} ${t(`months.${choosedDay.month}`)} | ${choosedTimeslot.validFrom} - ${choosedTimeslot.validTo}`
                            }
                        </span>
                    </div>
                    <div className="col-12" style={{ textTransform: "uppercase", color: "#272f47", fontFamily: "Montserrat-Bold" }}>
                        <span>{cart.storeDescription}</span>
                    </div>
                    {deliveryAddress &&
                        <div className="col-12" style={{ marginTop: '-10px' }}>
                            <span className="">{deliveryAddress}<br />{deliveryZip} {deliveryCity} {deliveryCountry}</span>
                        </div>
                    }
                </div>
                <hr />
                <div style={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
                    <div style={{ display: "flex" }}>
                        <div className="col-6" style={{ display: "flex", alignItems: "center", color: "#272f47" }}>
                            <span>A nome :</span>
                        </div>
                        <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                            <button className="modifica-button"
                                onClick={() => history.push("/checkout")}
                            >MODIFICA
                            </button>
                        </div>
                    </div>
                    <div className="col-12" style={{ textTransform: "uppercase", color: "#272f47", fontFamily: "Montserrat-Bold" }}>
                        <span>{cart.profileDescription}</span>
                    </div>
                    <div className="col-12" style={{ fontSize: "17px", color: "#272f47" }}>
                        {/* <span>Il metodo di pagamento scelto è : <b>{paymentMethod && paymentMethod.includes("XPAY")? "ONLINE":"AT DELIVERY"}</b></span> */}
                        <span>Il metodo di pagamento scelto è : {paymentMethod && selectedPaymentMethod(paymentMethod)}</span>
                    </div>
                    <div className="col-12" style={{ fontStyle: "italic", color: "#272f47", fontFamily: "Montserrat-Regular", fontSize: "20px" }}>
                        <span> Puoi richiedere la fattura al momento del ritiro della spesa</span>
                    </div>
                </div>
            </div>
            <div className="summary-heading-bar" style={{ marginBottom: "30px" }}>
                <div className="col-lg-6 col-md-4 col-sm-5 summary-heading-bar-text">
                    <span className="summary-heading-bar-text-digit-text">4</span>
                    <span className="summary-heading-bar-text-alpha-text">RIEPILOGO</span>
                </div>
                <div className="col-lg-6 col-md-8 col-sm-7 summary-heading-bar-buttons">
                    <button style={{ width: "160px", height: "36px", borderRadius: "18px", backgroundColor: "white", color: "#d80014", fontWeight: "bold", border: "solid 1px rgba(255, 255, 255, 0.5)" }}
                        onClick={() => history.push("/checkout")}>
                        INDIETRO
                    </button>
                    <button className="avanti-button"
                        onClick={onSubmit}
                    >
                        {paymentMethod !== 'XPAY' ? 'CONFERMA' : 'CONFERMA E PAGA'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Summary;