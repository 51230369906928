import React, { useState, useEffect } from "react";
import ProfileCard from "../../../components/ProfileCard/ProfileCard";
import AddressForm from "./AddressForm/AddressForm";
import toaster from "../../../utils/toaster"
import { useTranslation } from 'react-i18next';
import { addressesService } from "./Service"

import "./profile-addresses.styles.scss"

const ProfileAddresses = () => {
    const { t } = useTranslation();
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState();

    useEffect(() => {
        addressesService.getAddresses()
            .then(response => {
                if (response.data.length > 0) {
                    setAddresses(response.data)
                }
            }).catch((error) => {
                console.log(error);
            })
    }, [])

    const newAddress = (address) => {
        addressesService.newAddress(address)
            .then(response => {
                console.log(response);
                setAddresses(response.data)
                setAddress(null);
                if (address.id) {
                    toaster.standard(t("addresses.update-message"))
                } else {
                    toaster.standard(t("addresses.new-message"))
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    const deleteAddress = (addressId) => {
        addressesService.deleteAddress(addressId)
            .then(response => {
                console.log(response);
                setAddresses(response.data)
                setAddress(null)
                toaster.standard(t("addresses.delete-message"))
            }).catch((error) => {
                console.log(error);
            })
    }

    return (
        <div className="profile-addresses">
            <h2>{t("addresses.pageTitle")}</h2>
            <div className="profile-addresses-grid">
                {addresses && addresses.map((address, index) => (
                    <ProfileCard key={address.id}>
                        <h5>{t("addressCard.address")} {index + 1}</h5>
                        <span className="tiny-cta" onClick={() => setAddress(address)}>{t("update")}</span>
                        <div className="info">
                            {address?.address}, {address?.streetNumber}  <br />
                            {address?.zip} {address?.city} {address?.county}
                        </div>
                    </ProfileCard>
                ))}
            </div>
            {(!addresses || addresses?.length <= 0) &&
                <div className="no-addresses">{t("addresses.empty")}</div>
            }
            <h3>{address ? t("addresses.update") : t("addresses.new")}</h3>
            <hr />
            <AddressForm
                address={address}
                setAddress={setAddress}
                newAddress={newAddress}
                deleteAddress={deleteAddress}
            />
        </div>
    );
}

export default ProfileAddresses;





