import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import i18n from "../../i18n";

import "./category-select.styles.scss";

class CategorySelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            text: i18n.t("category"),
            selected: false
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.globalClickListener)
    }

    globalClickListener = () => {
        this.setState({ isOpen: false }, () => {
            document.removeEventListener('click', this.globalClickListener)
        })
    }

    toggleOpen = () => {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => {
            if (this.state.isOpen) {
                document.addEventListener('click', this.globalClickListener)
            }
        })
    }

    handleChange = (category) => {
        this.setState({
            isOpen: false,
            text: category.description,
            selected: true
        })
        this.props.setCategory(category)
    }

    clearCategory = () => {
        this.setState({
            isOpen: false,
            selected: false,
            text: i18n.t("category")
        })
        this.props.setCategory(null)
    }

    render() {
        const { isOpen, text, selected } = this.state;
        const { categories } = this.props;
        return (
            <div className="category-select">
                <div className="category-button" onClick={this.toggleOpen}>
                    <span>{text}</span>
                    {selected ?
                        <FontAwesomeIcon icon={"times-circle"} size="1x" onClick={this.clearCategory} />
                        :
                        <FontAwesomeIcon icon={isOpen ? "caret-up" : "caret-down"} size="1x" />
                    }
                </div>
                {isOpen &&
                    <div className="category-dropdown">
                        {categories && categories.map((item, index) => (
                            <div className="category-dropdown-item"
                                key={item.id}
                                onClick={() => this.handleChange(item)}>
                                {item.description}
                                {categories.length !== index + 1 &&
                                    <hr />
                                }
                            </div>
                        ))}
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        categories: state.cart.categories,
    };
}

export default connect(mapStateToProps)(CategorySelect);