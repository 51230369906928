import React from "react";
import CookieConsent from "react-cookie-consent";
import FacebookIcon from "../../../images/facebook-square-brands.svg";
// import PlayStore from "../../../images/PlayStore.png";
// import AppStore from "../../../images/AppStore.png";
import { ReactComponent as PlayStore } from "../../../images/PlayStoreNew.svg";
import { ReactComponent as AppStore } from "../../../images/AppStoreNew.svg";
import InstagramIcon from "../../../images/instagram-square-brands.svg";
import { ReactComponent as Visa } from "../../../images/Visa.svg";
import { ReactComponent as MasterCard } from "../../../images/MasterCard.svg";
import { ReactComponent as Cash } from "../../../images/Cash.svg";
// import { ReactComponent as Logo } from "../../../images/logo-footer.svg";
import Logo from "../../../images/coop-sait-logo_bianco.png";
import { Translation } from "react-i18next";
import { isIOS, isAndroid, isMobile } from "react-device-detect";
import "./footer.styles.scss";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {
    render() {
        return (
            <Translation>
                {t =>
                    <div className="footer">
                        {/* <CookieConsent
                            location="bottom"
                            buttonText="OK"
                            enableDeclineButton
                            declineButtonText="Non consentire i cookies"
                            style={{ background: "#2B373B" }}
                            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                            expires={150}>
                            {t("footer.cookieConsent")}
                        </CookieConsent> */}
                        <div className="footer-content">
                            <div className="row container-fluid">
                                <div className="col-lg-3 col-md-12 col-12 text-center text-lg-left mobile-col logo d-none d-lg-block">
                                    <div style={{ width: "100%" }}>
                                        <img src={Logo} alt="smartbip" />
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <span style={{ fontSize: "10px" }}>
                                            {t("footer.copyRight")}
                                        </span>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <span style={{ fontSize: "10px" }}>
                                            {new Date().getFullYear()}&nbsp;{t("footer.copyRight2")}
                                        </span>
                                    </div>
                                </div>
                                <div className="logo-mobile d-lg-none">
                                    <div style={{ width: "100%" }}>
                                        <img src={Logo} alt="smartbip" />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-12 text-center text-md-left mb-3 mb-lg-0 mobile-col home">
                                    <div className="footer-link">
                                        <Link to="/">
                                            <span className="footer-anchor">{t("footer.home")}</span>
                                        </Link>
                                    </div>
                                    <div className="footer-link">
                                        <Link to="/aboutus">
                                            <span className="footer-anchor"> {t("footer.aboutUs")}</span>
                                        </Link>
                                    </div>
                                    {/*  <div className="footer-link">
                                        <Link to="/faq">
                                            <span className="footer-anchor"> {t("footer.Faq")}</span>
                                        </Link>
                                    </div> */}
                                    {/*  <div className="footer-link">
                                        <Link to="/lg">
                                            <span className="footer-anchor"> {t("footer.graranziaLegale")}</span>
                                        </Link>
                                    </div> */}
                                    <div className="footer-link">
                                        <a href="https://www.e-coop.it/richiamo-prodotti" target="blank"><span className="footer-anchor">AVVISO RICHIAMO PRODOTTO</span></a>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-12 text-center text-md-left mobile-col contact">
                                    <div className="footer-link">
                                        <Link to="/contactus">
                                            <span className="footer-anchor">CONTATTACI</span>
                                        </Link>
                                    </div>
                                    <div className="footer-link">
                                        <Link to="/faq">
                                            <span className="footer-anchor"> {t("footer.Faq")}</span>
                                        </Link>
                                    </div>
                                    <div className="footer-link">
                                        <Link to="/tutorial">
                                            <span className="footer-anchor">TUTORIAL</span>
                                        </Link>
                                    </div>
                                    {/* <div className="footer-text">
                                        <span className="bold">
                                            {t("footer.followUs")}
                                        </span>
                                        <div className="mt-2">
                                            <a href=""
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="footer-social-icon"
                                            >
                                                <img src={FacebookIcon} alt="facebook" />
                                            </a>
                                            <a href=""
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="footer-social-icon"
                                            >
                                                <img src={InstagramIcon} alt="facebook" />
                                            </a>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-lg-2 col-md-4 col-12 text-center text-md-left mb-3 mb-lg-0 mobile-col information">
                                    <div className="footer-text">
                                        <span className="bold">
                                            <nobr>
                                                INFORMAZIONI
                                            </nobr>
                                        </span>
                                    </div>
                                    <div className="footer-link" style={{ fontSize: "10px" }}>
                                        <Link to="/lg">
                                            <span className="footer-anchor"> {t("footer.graranziaLegale")}</span>
                                        </Link>
                                    </div>
                                    <div className="footer-link" style={{ fontSize: "10px" }}>
                                        <Link to="/cookie">
                                            <span className="footer-anchor">  {t("footer.cookiePolicy")}</span>
                                        </Link>
                                    </div>
                                    <div className="footer-link" style={{ fontSize: "10px" }}>
                                        <Link to="/privacy">
                                            <span className="footer-anchor">
                                                {t("footer.privacyPolicy")}
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="footer-link" style={{ fontSize: "10px" }}>
                                        <Link to="/termsandcondition">
                                            <span className="footer-anchor"> {t("footer.termsAndConditions")}</span>
                                        </Link>
                                    </div>
                                </div>
                                {/*  <div className="col-lg-2 col-md-4 col-12 text-center text-md-left mobile-col d-none d-lg-block">
                                    <div className="footer-text">
                                        <span className="bold">
                                            {t("footer.followUs")}
                                        </span>
                                        <div className="mt-2">
                                            <a href=""
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="footer-social-icon"
                                            >
                                                <img src={FacebookIcon} alt="facebook" />
                                            </a>
                                            <a href=""
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="footer-social-icon"
                                            >
                                                <img src={InstagramIcon} alt="facebook" />
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-12 text-center text-md-right"> */}
                                <div className="col-lg-3 col-md-12 col-12 payment">
                                    <div className="footer-text">
                                        <span className="bold">
                                            <nobr>
                                                PAGAMENTI
                                            </nobr>
                                        </span>
                                    </div>
                                    <div className="footer-text">
                                        <div className="footer-cards">
                                            <Visa />
                                            <MasterCard />
                                            <Cash />
                                        </div>
                                        {/* <span className="bold">
                                            <nobr>
                                                PAGAMENTI
                                            </nobr>
                                        </span> */}
                                    </div>
                                    {isMobile &&
                                        <>
                                            <div className="footer-text">
                                                <span className="bold">
                                                    <nobr>
                                                        {t("footer.downloadApp")}
                                                    </nobr>
                                                </span>
                                            </div>
                                            <div className="footer-apps">
                                                {isIOS &&
                                                    <div className="mb-3">
                                                        <a href="https://apps.apple.com/it/app/clickcoop/id1662825516"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <button className="cta" style={{ padding: "0 27px", borderRadius: "12px" }}>
                                                                {/* <img src={AppStore} alt="" /> */}
                                                                <AppStore />
                                                                <span className="bold">
                                                                    {t("footer.appStore")}</span>
                                                            </button>
                                                        </a>
                                                    </div>
                                                }
                                                {isAndroid &&
                                                    <div>
                                                        <a href="https://play.google.com/store/apps/details?id=com.retapps.clickcoop.app"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <button className="cta" style={{ padding: "0 24px", borderRadius: "12px" }}>
                                                                {/* <img src={PlayStore} alt="" /> */}
                                                                <PlayStore />
                                                                <span className="bold">
                                                                    {t("footer.playStore")}</span>
                                                            </button>
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                    <div className="powered text-center">
                                        <span>
                                            {t("footer.poweredBy")} <span className="bold">SmartBip</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/*   <div className="footer-follow d-lg-none">
                                <div className="footer-text">
                                    <span className="bold">{t("footer.followUs")}</span>
                                </div>
                                <div>
                                    <a href=""
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-social-icon"
                                    >
                                        <img src={FacebookIcon} alt="facebook" />
                                    </a>
                                    <a href=""
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-social-icon"
                                    >
                                        <img src={InstagramIcon} alt="facebook" />
                                    </a>
                                </div>
                            </div> */}
                        </div>
                        <div className="footer-bottom row no-gutters d-lg-none">
                            <div className="col-12 col-sm-12 text-center" >
                                <span>
                                    {t("footer.copyRight")}
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}