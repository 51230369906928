import React, { Component } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import ReactSlick from 'react-slick';

import "../react-slick.styles.scss";
import { ReactComponent as ArrowRight } from "../../../images/arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../../images/arrow_left.svg";
import Placeholder from "../../../images/product-placeholder.png";
import { isMobile } from 'react-device-detect';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
/* const frontSrcSet = [
    { src: front_500, setting: '500w' },
    { src: front_779, setting: '779w' },
    { src: front_1020, setting: '1020w' },
    { src: front_1200, setting: '1200w' },
    { src: front_1426, setting: '1426w' }
]
    .map(item => `${item.src} ${item.setting}`)
    .join(', ');
 */
/* const backSrcSet = [
    { src: back_500, setting: '500w' },
    { src: back_779, setting: '779w' },
    { src: back_1020, setting: '1020w' },
    { src: back_1200, setting: '1200w' },
    { src: back_1426, setting: '1426w' }
]
    .map(item => `${item.src} ${item.setting}`)
    .join(', '); */

/* const dataSource = [
    {
        srcSet: frontSrcSet,
        small: front_500,
        large: front_1426
    },
    {
        srcSet: backSrcSet,
        small: back_500,
        large: back_1426
    }
]; */

class ReactImageMagnified extends Component {
    render() {
        const {
            rimProps,
            rsProps,
            productImages,
            product,
            openPopUp
        } = this.props;
        const transformOptions = {
            initialScale: 0.1,
            minScale: 0.1,
            maxScale: 2,
            initialPositionX: 0,
            initialPositionY: 0
        }

        return (
            <ReactSlick
                {...{
                    dots: true,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: <SampleArrow><ArrowRight /></SampleArrow>,
                    prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>,
                }}
                {...rsProps}
            >
                {!productImages &&
                    <ReactImageMagnify
                        {...{
                            smallImage: {
                                alt: '',
                                //  isFluidWidth: true,
                                width: 400,
                                height: 400,
                                src: `${product.imageUrl}`,
                                onError: (e) => e.target.src = Placeholder
                                //sizes: '(max-width: 100px) 300px'
                                //  srcSet: src.srcSet,
                                // sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                            },
                            largeImage: {
                                src: `${product.imageUrl}`,
                                width: 1426,
                                height: 2000,
                                onError: (e) => e.target.src = Placeholder
                            },
                            lensStyle: { backgroundColor: 'rgba(0,0,0,.6)', cursor: 'pointer' },
                            className: "product-cursor"
                        }}
                        {...rimProps}
                    />
                }
                {productImages && productImages.map((src, index) => (
                    // <div key={index}>
                    <>
                        {!isMobile &&
                            <ReactImageMagnify
                                {...{
                                    smallImage: {
                                        alt: '',
                                        //  isFluidWidth: true,
                                        width: 400,
                                        height: 400,
                                        src: `${src.original}`,
                                        onError: (e) => e.target.src = Placeholder
                                        //  srcSet: src.srcSet,
                                        // sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                                    },
                                    largeImage: {
                                        src: `${src.original}`,
                                        width: 1426,
                                        height: 2000,
                                        onError: (e) => e.target.src = Placeholder
                                    },
                                    lensStyle: { backgroundColor: 'rgba(0,0,0,.6)', cursor: 'pointer' },
                                    className: "product-cursor",
                                    // imageClassName:"image-size"
                                    // imageStyle:{width:'100px'}
                                }}
                                {...rimProps}
                            />
                        }
                        {isMobile &&
                            <div className='mobile-image-slick'>
                                <img src={src.original} width={'100vw'} onClick={() => openPopUp(src.original)}/>
                            </div>
                            // <div className='mobile-image-slick'>
                            //     <TransformWrapper
                            //         // initialScale={0.3}
                            //         options={transformOptions}
                            //         // initialPositionX={70}
                            //         // initialPositionY={-200}
                            //     >
                            //         <TransformComponent>
                            //             <img src={src.original} style={{ width: "100%", height: '400px' }} />
                            //         </TransformComponent>
                            //     </TransformWrapper>
                            // </div>
                        }
                    </>
                    // </div>
                ))}
            </ReactSlick>
        );
    }
};

function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
}
export default ReactImageMagnified