import React, { useState, useEffect } from "react"
import ProfileCard from "../../../components/ProfileCard/ProfileCard";
import { ReactComponent as ArrowIcon } from "../../../images/order-arrow1.svg"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from 'moment'
import { history } from "../../../App";

import "./profile-preview.styles.scss";


const ProfilePreview = ({ user, slists }) => {
    const { t } = useTranslation();
    const [order, setOrder] = useState();

    useEffect(() => {
        ecommerceService.orders(0, "")
            .then(response => {
                if (response.data.length > 0) {
                    setOrder(response.data[0]);
                }
            }).catch((error) => {
                console.log(error);
            })
    }, [])

    if (user) {
        return (
            <div className="profile-preview">
                <h2>{t("profile.preview.title")}</h2>
                <hr />
                <h5>{t("profile.preview.hello")} {user?.firstName} {user?.lastName},</h5>
                <div className="description">
                    {t("profile.preview.description")}
                </div>
                <h3>{t("profile.preview.accountInfo")}</h3>
                <hr />
                <div className="profile-preview-grid">
                    {/* <div className="profile-in-profile"> */}
                    <ProfileCard>
                        <h5>{t("registry")}</h5>
                        <Link to="/profile/update" className="link">
                            <span className="tiny-cta">{t("update")}</span>
                        </Link>
                        <div className="info">{user?.firstName} {user?.lastName}</div>
                        <div className="info">{user?.email}</div>
                        {user.principalCard &&
                            <div className="info">{t("signup.card")}: {user.principalCard}</div>
                        }
                    </ProfileCard>
                    {/* <div className="profile-modifica-button" onClick={() => history.push("/profile/update")}>MODIFICA</div> */}
                    {/* <div className="profile-modifica-button" >
                           
                            <Link to="/profile/update" style={{color:"white"}}>
                                  MODIFICA
                            </Link>
                        </div> */}
                    {/* </div> */}
                    {/*  <div className="list-in-profile">
                        <ProfileCard>
                            <h5>Le liste della spesa</h5>
                              <Link to="/profile/billing" className="link">
                                <div className="tiny-cta">{t("update")}</div>
                            </Link> 
                        </ProfileCard>
                        <div className="list-modifica-button" onClick={() => history.push("/profile/smartlists")}>MODIFICA</div> 
                    </div> */}
                  {/*   <ProfileCard>
                        <h5>{t("addresses.favorite")}</h5>
                        {user.address ?
                            <>
                                <Link to="/profile/addresses" className="link">
                                    <span className="tiny-cta">{t("update")}</span>
                                </Link>
                                <div className="info">
                                    {user.address?.address} <br />
                                    {user.address?.zip} {user.address?.city} {user.address?.county}
                                </div>
                            </>
                            :
                            <div className="tiny-cta">{t("add")}</div>
                        }
                    </ProfileCard> */}
                   {/*  <ProfileCard>
                        <h5>{t("billing.addresses.favorite")}</h5>
                        <Link to="/profile/billing" className="link">
                            <div className="tiny-cta">{t("update")}</div>
                        </Link>
                    </ProfileCard> */}
                </div>
                {order &&
                    <div className="d-none d-md-block">
                        <h3>{t("profile.preview.lastOrder")}</h3>
                        <hr />
                        <div className="first-order">
                            <div >
                                <div className="first-order-head">{t("order.deliveryDate")}</div>
                                {/* <h5>{moment(order.deliveryDate).format("L")}</h5> */}
                            </div>
                            <div >
                                <div className="first-order-head">ID</div>
                                {/* <h5>{order.code}</h5> */}
                            </div>
                            <div>
                                <div className="first-order-head">{t("order.status")}</div>
                                {/* <h5>{t(`order.status.${order.status}`)}</h5> */}
                            </div>
                            <div>
                                <div className="first-order-head">{t("order.store")}</div>
                                {/* <h5>{order?.cart?.storeDescription}</h5> */}
                            </div>
                            <div>
                                <div className="first-order-head">{t("order.type")}</div>
                                {/* <h5>{t(`cart.deliveryMode.${order?.cart?.deliveryMode}`)}</h5> */}
                            </div>
                            <div>
                                <div className="first-order-head">{t("total")}</div>
                                {/* <h5>{order?.cart?.totalToPay} €</h5> */}
                            </div>
                            {/*  <Link to="/profile/orders" className="link">
                                <ArrowIcon />
                            </Link> */}
                        </div>
                        <div className="first-order-data">
                            <div className="first-order-head">
                                <span className="inside-title">{t("order.deliveryDate")}:</span>
                                <h5>{moment(order.deliveryDate).format("DD/MM/YYYY")}</h5>
                            </div>
                            <div className="first-order-head">
                                <span className="inside-title">ID:</span>
                                <span className="vertical-line"></span>
                                <h5>{order.code}</h5>
                            </div>
                            <div className="first-order-head">
                                <span className="inside-title">{t("order.status")}:</span>
                                <span className="vertical-line"></span>
                                <h5>{t(`order.status.${order.status}`)}</h5>
                            </div>
                            <div className="first-order-head">
                                <span className="inside-title">{t("order.store")}:</span>
                                <span className="vertical-line"></span>
                                <h5>{order?.cart?.storeDescription}</h5>
                            </div>
                            <div className="first-order-head">
                                <span className="inside-title">{t("order.type")}:</span>
                                <span className="vertical-line"></span>
                                <h5>{t(`cart.deliveryMode.${order?.cart?.deliveryMode}`)}</h5>
                            </div>
                            <div className="first-order-head">
                                <span className="inside-title">{t("total")}:</span>
                                <span className="vertical-line"></span>
                                <h5>{order?.cart?.totalToPay} €</h5>
                            </div>
                            <div className="arrow">
                                <Link to="/profile/orders" className="link">
                                    <ArrowIcon />
                                </Link>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    } else return null;
}

export default ProfilePreview;