import React, { useState, useEffect, useCallback } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import CardProduct from "../../components/Products/CardProduct/CardProduct";
import CategoryBannerImg from "../../images/category-banner.jpg"
import StickyBox from "react-sticky-box";
import Categories from "../Categories/Categories";
import Filters from "../../components/Filters/Filters";
import Loader from 'react-loader-spinner'
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux"
import { ecommerceService } from "../../services/ecommerce/EcommerceService"
import "./search-results.styles.scss";
import BannerCardProduct from "../../components/Products/BannerCardProduct/BannerCardProduct";
import CategoryTree from "../SearchCategory/CategoryTree/CategoryTree";
import CustomBreadcrumb from "../../components/UI/Breadcrumb/CustomBreadcrumb";
import GoBackButton from "../../components/UI/Buttons/GoBackButton";
import { history } from "../../App";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions"
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";

const SearchResults = ({ location,tag}) => {
    const { t } = useTranslation();
    const [isLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [tagDescription, setTagDescription] = useState();
    const cart = useSelector(state => state.cart.cart)
    const defaultStore = useSelector(state => state.user.defaultStore)
    const onlyPromotions = useSelector(state => state.categories.onlyPromotions)
    const onlyBranded = useSelector(state => state.categories.onlyBranded)
    //const tag = useSelector(state => state.categories.tag)
    const storeId = cart ? cart.storeId : (defaultStore ? defaultStore.id : "");
    const searchInput = location?.state?.searchInput ? location?.state?.searchInput : "";
    const categoryId = location?.state?.categoryId ? location?.state?.categoryId : "";
    //const tag = location?.state?.tag ? location?.state?.tag : "";
    const tags = useSelector(state => state.categories.tags);

    useEffect(() => {
        let tagSelected = tags ? tags.find(el => el.tag === tag) : "";
        if (tagSelected){
          setTagDescription(tagSelected.description);
        } else {
          setTagDescription(tag);
        }
      }, [tag])

    const searchProducts = useCallback(
        (input, categoryId, page, tag) => {
            ecommerceService.searchProducts(input, storeId, page, categoryId, onlyPromotions, onlyBranded, tag)
                .then(response => {
                    setProducts(response.data);
                    setIsLoaded(true);
                    setTotalPages(response.totalPages)
                    setPage(response.number)
                    setHasMore(!response.last)
                }).catch((error) => {
                    console.log(error);
                })
        },
        [onlyPromotions, onlyBranded, tag, storeId],
    );

    

    useEffect(() => {
        searchProducts(searchInput, categoryId, 0, tag);
    }, [searchProducts, searchInput, categoryId, tag])

    const loadMore = () => {
        const newPage = page + 1;
        if (newPage < totalPages) {
            ecommerceService.searchProducts(searchInput, storeId, newPage, categoryId, onlyPromotions, onlyBranded, tag)
                .then(response => {
                    const newResponse = products;
                    newResponse.push(...response.data);
                    setProducts(newResponse);
                    setIsLoaded(true);
                    setTotalPages(response.totalPages)
                    setPage(response.number)
                    setHasMore(!response.last)
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    return (
        <div className="search-results">
            <div className="page-grid mobile-view-search">
                <div className="grid-left" offsettop={0} offsetbottom={0}>
                    {/* <Filters showDefaultFilters={true} />  */}
                    <CategoryTree />
                </div>
                {/* <div style={{ paddingTop: "20px" }} className="grid-right"> */}
                <div className="grid-right">
                    <InfiniteScroll
                        style={{ overflowX: "hidden" }}
                        dataLength={products.length}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={<Loader
                            type="ThreeDots"
                            color="#333"
                            height={70}
                            width={70}
                            timeout={3000} //3 secs
                        />}
                    >
                        {/* <div className="category-banner" style={{ backgroundImage: `url(${CategoryBannerImg})` }} /> */}
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {
                                searchInput && !tag &&
                                <div className="search-breadcrumb-container">
                                    <CustomBreadcrumb
                                        level0={{
                                            path: "/home",
                                            label: searchInput,
                                            active: true
                                        }}
                                    />
                                </div>
                            }
                            {
                                tag && !searchInput &&
                                <div className="search-breadcrumb-container">
                                    <CustomBreadcrumb
                                        level0={{
                                            path: "/home",
                                            label: tagDescription,
                                            active: true
                                        }}
                                    />
                                </div>
                            }
                            {/* <Filters /> */}
                        </div>
                        <div className="products-grid">
                            {products && products.length > 0 && products.map((item) => (
                                <CardProduct key={item.id} item={item} />
                            ))}
                            {isLoaded && products && products.length === 0 &&
                                <span className="title">
                                    {t("newSearch.noProductsFound")}
                                </span>
                            }
                        </div>
                    </InfiniteScroll>
                    <ScrollToTopButton />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        tag: state.categories.tag,
        searchInput:state.categories.searchInput,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        selectTag: (tag) => dispatch(actionCreators.tag(tag)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);



