import React from "react";
import { Redirect } from 'react-router'
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"

import "../DefaultStore/default-store.styles.scss";

class DefaultSelectedStore extends React.Component {
  
    componentDidMount() {
        const { open } = this.props;
        // open("IN_STORE", "686491", "")
    }

    render() {
        const { open, cart } = this.props;
        // if (cart) return <Redirect to="/home" />
        return(
            <Redirect to="/home" />
        )
    }
}

 const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
    };
} 

const mapDispatchToProps = dispatch => {
    return {
        open: (deliveryMode, storeId, addressId) => dispatch(actionCreators.open(deliveryMode, storeId, addressId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultSelectedStore);
