import React from "react";
import Categories from "../Categories/Categories";
import StickyBox from "react-sticky-box";
import HomeCarousel from "../Home/HomeCarousel/HomeCarousel"
// import HomeTags from "./HomeTags/HomeTags";
import FeaturedProducts from "./FeaturedProducts/FeaturedProducts";
import config from "../../config/config";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import * as actionCreators from "../../redux/store/actions/index";
import HomeBrands from "./HomeBrands/HomeBrands";
import HomeSuggestions from "./HomeSuggestions/HomeSuggestions";
import HomeHolidays from "./HomeHolidays/HomeHolidays";
import LocalProducts from "./LocalProducts/LocalProducts";
import HomeTags from "./HomeTags/HomeTags";
import HomeValues from "./HomeValues/HomeValues";
import CategoryTree from "../SearchCategory/CategoryTree/CategoryTree";

class Home extends React.Component {
    componentDidMount() {
        if (this.props.user) {
            this.props.current();
            if (config.ENABLED_SLIST) {
                this.props.getSlists();
            }
        } else {
            this.props.getTags();
        }
    }

    render() {
        return (
            <Translation>
                {t =>
                    <div style={{ padding: "unset" }} className="page-grid mobile-view-search">
                        <div className="grid-left" offsettop={0} offsetbottom={0}>
                            {/* <Categories />      */}
                            <CategoryTree/> 
                        </div>
                        {/* <div style={{ paddingTop: "20px" }} className="grid-right"> */}
                        <div className="grid-right">
                            <HomeCarousel />
                            <HomeBrands />
                            <HomeSuggestions />
                            <HomeValues />
                            {/* <HomeTags />  */}
                            {/* <FeaturedProducts />   */}
                            {/* <HomeHolidays /> */}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        current: () => dispatch(actionCreators.current()),
        getSlists: () => dispatch(actionCreators.getSlists()),
        getTags: () => dispatch(actionCreators.getTags())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
