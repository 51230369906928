import React from 'react'
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import resetPassword from "./Service"
import toaster from "../../utils/toaster"
import { Container } from 'react-bootstrap';
import ValidateInput from '../../components/UI/Input/ValidateInput';
import i18n from "../../i18n"

export default function PasswordReset(props) {

    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        resetPassword(data.email)
            .then(response => {
                console.log(response);
                toaster.standard(i18n.t("succesfully.request"))
            }).catch((error) => {
                toaster.error(error.message);
            })
    }

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                    <Container className="reset-password-wrapper">
                        <div className="reset-password-form">
                            <h3 className="mb-3 text-center">{t("passwordReset.title")}</h3>
                            <div>
                                <ValidateInput
                                    name="email"
                                    label="email"
                                    type="email"
                                    watch={watch("email")}
                                    errors={errors.email}
                                    register={register({
                                        required: t("required.field"),
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: t("error.invalidEmail")
                                        }
                                    })}
                                />
                                <div className="text-center mt-2">
                                    <small id="emailHelp" className="form-text text-muted mb-2">
                                        {t('passwordReset.help')}
                                    </small>
                                    <button style={{ width: "unset" }} type="submit" className="mt-1 cta">
                                        <span>{t("passwordReset.send")}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </form>
            }
        </Translation>
    );

}
