import store from "../redux/store/index";

export function authHeader(jwt) {
    //jwt from the webview
    if (jwt){
        return { 'Authorization': 'Bearer ' + jwt };
    } else {
        // return authorization header with jwt token
        let user = store.reduxStore.getState().user;
        if (user && user.jwt) {
            return { 'Authorization': 'Bearer ' + user.jwt };
        } else {
            return {};
        }
    }
}

export function recaptchaHeader(recaptcha) {
    let user = store.reduxStore.getState().user;
    if (user && user.jwt) {
        return { 'Authorization': 'Bearer ' + user.jwt, "g-recaptcha-response": recaptcha };
    } else {
        return {};
    }
}