import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next";

import "./go-back-button.styles.scss";

const GoBackButton = ({ style, onClick }) => {
    const { t } = useTranslation();
    return (
        <button style={{ ...style }} className="go-back-button outline" onClick={onClick}>
            <FontAwesomeIcon className="mr-1" icon="angle-left" size="1x" />
            {t("goBack")}
        </button>
    );
}

export default GoBackButton;