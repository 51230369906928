import React, { useState, useEffect } from 'react'
import GoBackButton from "../../../components/UI/Buttons/GoBackButton"
import CustomBreadcrumb from '../../../components/UI/Breadcrumb/CustomBreadcrumb';
import { Carousel } from "react-responsive-carousel";
import { withRouter } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { format, parseISO } from "date-fns";
import { leafletsService } from '../Service';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./leaflet.styles.scss";

const Leaflet = (props) => {
    const { t } = useTranslation();
    const [leaflet, setLeaflet] = useState();
    const leafletId = props.match.params.leafletId;

    useEffect(() => {
        leafletsService.fetchLeaflet(leafletId).then(response => {
            console.log(response);
            setLeaflet(response.data)
        });
    }, [leafletId])

    if (leaflet) {
        return (
            <div className="leaflet">
                <div className="breadcrumb-container">

                    <GoBackButton onClick={() => props.history.goBack()}>
                        {t("goBack")}
                    </GoBackButton>
                    <CustomBreadcrumb
                        level1={{
                            path: "/leaflets",
                            label: "leaflets.title",
                        }}
                        level2={{
                            path: "/leaflets",
                            label: leaflet.title,
                            active: true
                        }}
                    />
                </div>
                <div className="leaflet-content">
                    <div className="text-center mb-3">
                        <h4>{leaflet.title}</h4>
                        <h6 className="text-muted">
                            {t("validFrom")} {format(parseISO(leaflet.validFrom), "dd/MM/yyyy")} {t("validTo")} {format(parseISO(leaflet.validTo), "dd/MM/yyyy")}
                        </h6>
                    </div>
                    <Carousel
                        showIndicators={false}
                        emulateTouch={true}
                        useKeyboardArrows={true}
                    >
                        {leaflet.images && leaflet.images.map((item) => (
                            <div key={item}>
                                <img src={item} alt="" className="img-fluid" />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        );
    } else return null;
}

export default withRouter(Leaflet);