import React from 'react';
import CardProduct from '../CardProduct/CardProduct';
import './paragonali-card.styles.scss';
import Bollo from '../../../images/Confrontali_bollo.png'
import Header from '../../../images/Confrontali_frecce+fondo_banner.png'

const ParagonaliCard = ({ left, right }) => {

    const calculateSaving = () => {
        let diff = left.netPrice - right.netPrice;
        let firstResult = (Math.abs(diff) * 100) / left.netPrice;
        let result = Math.round(firstResult);
        return result
    }

    return (
        <div className='paragonali-container'>
            <div className='image-container'>
                <img src={Header} alt='' />
            </div>
            <div className='card-container'>
                <CardProduct item={left} noReplace={true}/>
                <hr />
                <div className='saving'>Con COOP risparmi il {calculateSaving()}%</div>
                <img src={Bollo} alt='' className='circle-image'/>
                <CardProduct item={right} noReplace={true}/>
            </div>
        </div>
    )
}

export default ParagonaliCard