import React from "react";
import ValidateInput from "../../../../components/UI/Input/ValidateInput";
import { useForm } from 'react-hook-form'
import { useTranslation } from "react-i18next";

const AddressForm = ({ address, setAddress, newAddress, deleteAddress }) => {
    const form = document.getElementById("form-address");
    const { t } = useTranslation();
    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        newAddress(data);
        form.reset();
    }

    return (
        <div className="address-form">
            <form onSubmit={handleSubmit(onSubmit)} id="form-address" noValidate autoComplete="off"
                className={`needs-validation ${errors ? "had-validation" : ""}`}>
                <div className="address-form-content">
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                    <div className="row signup-row">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                            <ValidateInput
                                name="address"
                                label="Indirizzo*"
                                type="text"
                                value={address && address.address}
                                watch={watch("address")}
                                errors={errors.address}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-2 mb-3 mb-sm-0">
                            <ValidateInput
                                name="streetNumber"
                                label="Civico*"
                                type="text"
                                pattern="[0-9a-zA-Z]"
                                uppercase={true}
                                value={address && address.streetNumber}
                                watch={watch("streetNumber")}
                                errors={errors.streetNumber}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                            <ValidateInput
                                name="state"
                                label="Regione*"
                                pattern="[A-Za-z\s]"
                                type="text"
                                value={address && address.state}
                                watch={watch("state")}
                                errors={errors.state}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                        </div>
                    </div>
                    <div className="row signup-row">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                            <ValidateInput
                                name="city"
                                label="Città*"
                                pattern="[A-Za-z\s]"
                                type="text"
                                value={address && address.city}
                                watch={watch("city")}
                                errors={errors.city}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-2 mb-3 mb-sm-0">
                            <ValidateInput
                                name="county"
                                label="Provincia*"
                                pattern="[A-Za-z\s]"
                                uppercase={true}
                                type="text"
                                value={address && address.county}
                                maxLength={2}
                                watch={watch("county")}
                                errors={errors.county}
                                register={register({
                                    required: t("required.field"),
                                    maxLength: 2, minLength: 2
                                })}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                            <ValidateInput
                                name="zip"
                                label="Cap*"
                                type="text"
                                pattern="[0-9]"
                                value={address && address.zip}
                                maxLength={5}
                                watch={watch("zip")}
                                errors={errors.zip}
                                register={register({
                                    required: t("required.field"),
                                    maxLength: 5, minLength: 5
                                })}
                            />
                        </div>
                        {address &&
                            <div className="d-none">
                                <ValidateInput
                                    name="id"
                                    type="text"
                                    value={address && address.id}
                                    watch={watch("id")}
                                    errors={errors.id}
                                    register={register({
                                        required: t("required.field"),
                                    })}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="profile-addresses-buttons">
                    <button type="button"
                        disabled={!address}
                        className="secondary-cta"
                        onClick={() => {
                            setAddress(null)
                            form.reset();
                        }}
                    >
                        <span>{t("cancel")}</span>
                    </button>
                    <div className="actions">
                        {address &&
                            <button type="button"
                                className="cta cta-abort"
                                onClick={() => {
                                    deleteAddress(address.id);
                                    form.reset();
                                }}
                            >
                                <span>{t("delete")}</span>
                            </button>
                        }
                        <button
                            type="submit"
                            className="cta" >
                            <span>{t("save")}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}



export default AddressForm;