//import config from "../config/config"
import { handleResponse } from "../../../../utils/handleResponse";
import { checkResponse } from "../../../../utils/checkResponse";
import config from "../../../../config/config";
import axios from 'axios'

export const Service = { autoSuggestion, defaultCalender}

function autoSuggestion(input) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/products/search?q=${input}`)
            .then(handleResponse)
            .then(checkResponse)

            
    );
}

function defaultCalender(input) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/stores/686491/delivery/IN_STORE/slots/first/available?tid=01`)
            .then(handleResponse)
            .then(checkResponse)

            
    );
}