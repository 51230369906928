import React from "react"
import { Accordion } from "react-bootstrap";
import { ReactComponent as AccordionDown } from "../../../images/accordion-down.svg";
import { ReactComponent as AccordionUp } from "../../../images/accordion-up.svg";
import { useTranslation } from "react-i18next";

import "./smartlist-header.styles.scss"

const SmartlistHeader = ({
    slist,
    activeKey,
    index,
    selectSlist
}) => {
    const { t } = useTranslation();
    return (
        <div className="smartlist-header">
            <h5>{slist?.title}</h5>
            <Accordion.Toggle as="div" eventKey={index} className="tiny-cta"
                onClick={() => selectSlist(slist.id, index)}>
                {activeKey === index ?
                    <>
                        <span className="d-none d-sm-inline">{t("closeDetail")}</span>
                        <AccordionUp />
                    </>
                    :
                    <>
                        <span className="d-none d-sm-inline">{t("showDetail")}</span>
                        <AccordionDown />
                    </>
                }
            </Accordion.Toggle>
        </div>
    );
}

export default SmartlistHeader;