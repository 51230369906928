import React from 'react'
import MapLocalization from "../MapLocalization/MapLocalization";
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import "./stores-map.styles.scss";

const StoresMap = ({ lat, lng, zoom, stores, setWithDistance, disableDistanceButton, history }) => {
    const { t } = useTranslation();
    const position = [lat, lng]
    return (
        <div className="stores-map">
            {lat && lng &&
                <LeafletMap center={position} zoom={zoom}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {stores && stores.map((store, index) => {
                        if (store.address && store.address?.latitude && store.address?.longitude) {
                            return (
                                <Marker
                                    position={[store.address.latitude, store.address.longitude]}
                                    key={index}
                                    marker_index={index}
                                >
                                    <Popup className="stores-map-popup">
                                        <h5>{store.name}</h5>
                                        {store.address &&
                                            <div className="address">
                                                {store.address?.address}, {store.address?.streetNumber}
                                                {store.address?.zip} {store.address?.city} {store.address?.county}
                                            </div>
                                        }
                                        <div className="tiny-cta" onClick={() => history.push(`/stores/${store.id}`)}>
                                            {t("landingPage.goToStore")}
                                        </div>
                                    </Popup>
                                </Marker>
                            )
                        } else return null;
                    })}
                    <MapLocalization
                        setWithDistance={setWithDistance}
                        disableDistanceButton={disableDistanceButton}
                    />
                </LeafletMap>
            }
        </div>
    )
}

export default withRouter(StoresMap);



