import React from 'react';
import "./newsignup.styles.scss";
import { Translation } from 'react-i18next';
import { useSelector } from "react-redux";
import {ReactComponent as Email} from "../../images/sendemail.svg";

const SignUpEmail = () => {
    const email = useSelector(state => state.signup.email);
    const phone = useSelector(state => state.signup.phone);
    return (
        <Translation>
            {t =>
                <div className='signup-container' style={{flexDirection: 'column'}}>
                    <div className='signup-container-center' style={{textAlign: 'center', gap: '0'}}>
                        <div className='signup-container-title' style={{marginBottom: '20px'}}>{t('signup.thankyou')}</div>
                        <div className='signup-container-label'>{t('signup.thankyou.label1')}&nbsp;<span style={{fontFamily: 'Montserrat-Bold'}}>{email}</span></div>
                        <div className='signup-container-label'>{t('signup.thankyou.label2')}&nbsp;<span style={{fontFamily: 'Montserrat-Bold'}}>{phone}</span>;</div>
                        <div className='signup-container-label'>{t('signup.thankyou.label3')}</div>
                    </div>
                    <div className='email-img'>
                        <Email />
                    </div>
                </div>
            }
        </Translation>
    )
}

export default SignUpEmail