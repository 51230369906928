import React, { useEffect, useState } from 'react';
import "./newsignup.styles.scss";
import { Translation } from 'react-i18next';
import "./signupphone.styles.scss";
import ValidateInput from '../../components/UI/Input/ValidateInput';
import { useForm } from 'react-hook-form';
import { authenticationService } from '../../services/basis/AuthenticationService';
import { useHistory } from 'react-router-dom';

const SignUpPhone = ({match}) => {
    const phone = match.params.mobilePhoneLastDigits;
    const profileId = match.params.profileId;
    const emailOtp = match.params.otp;
    const history = useHistory();

    const { register, handleSubmit, errors, watch, reset } = useForm({
        mode: 'onBlur',
    });

    const handleChange = e => {
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");

        // Check if they hit the max character length
        if (value.length >= maxLength) {
            // Check if it's not the last input field
            if (parseInt(fieldIndex, 10) < 5) {
                // Get the next input field
                const nextSibling = document.querySelector(
                    `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
                );

                // If found, focus the next field
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
    }
    const resendOtp = () => {
        //chiamata per reinviare otp
    }

    const onSubmit = (values) => {
        let phoneOtp = "";
        Object.values(values).map(item => {phoneOtp += item;});
        authenticationService.otpVerify(emailOtp, phoneOtp, profileId).then((response) => {
            if (response.data === "OK"){
                history.push("/signupsuccess");
            } else {
                history.push("/signupError");
            }
        });
        reset();
    }

    return (
        <Translation>
            {t =>
                <div className='phone-container'>
                    <div className='phone-container-center' style={{ textAlign: 'center', gap: '0' }}>
                        <div className='phone-container-title' style={{ marginBottom: '20px' }}>{t('signup.phone.title')}</div>
                        <div className='phone-container-label'>{t('signup.phone.label1')}&nbsp;<span style={{ fontFamily: 'Montserrat-Bold' }}>*******{phone}</span></div>
                        <div className='phone-container-label' style={{ marginBottom: '20px' }}>{t('signup.phone.label2')}</div>
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                            <div className='otp-container'>
                                <ValidateInput
                                    name="ssn-1"
                                    type="text"
                                    maxLength="1"
                                    onChange={handleChange}
                                    watch={watch("ssn-1")}
                                    errors={errors.ssn1}
                                    register={register({
                                        required: t("required.field")
                                    })}
                                />
                                <ValidateInput
                                    name="ssn-2"
                                    type="text"
                                    maxLength="1"
                                    onChange={handleChange}
                                    watch={watch("ssn-2")}
                                    errors={errors.ssn2}
                                    register={register({
                                        required: t("required.field")
                                    })}
                                />
                                <ValidateInput
                                    name="ssn-3"
                                    type="text"
                                    maxLength="1"
                                    onChange={handleChange}
                                    watch={watch("ssn-3")}
                                    errors={errors.ssn3}
                                    register={register({
                                        required: t("required.field")
                                    })}
                                />
                                <ValidateInput
                                    name="ssn-4"
                                    type="text"
                                    maxLength="1"
                                    onChange={handleChange}
                                    watch={watch("ssn-4")}
                                    errors={errors.ssn4}
                                    register={register({
                                        required: t("required.field")
                                    })}
                                />
                                <ValidateInput
                                    name="ssn-5"
                                    type="text"
                                    maxLength="1"
                                    onChange={handleChange}
                                    watch={watch("ssn-5")}
                                    errors={errors.ssn5}
                                    register={register({
                                        required: t("required.field")
                                    })}
                                />

                            </div>
                            <div className='phone-container-label-small'>
                                {t('signup.phone.label3')}&nbsp;
                                <span className="otp-link-button" onClick={() => resendOtp()}>
                                    {t("loginPopup.clickHere")}
                                </span>
                            </div>
                            <div className='phone-container-buttons'>
                                <button style={{ width: "unset" }} type="submit" className="mt-1 confirm-button">
                                    <span>{t("signup.verify")}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </Translation>
    )
}

export default SignUpPhone;