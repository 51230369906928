import React from "react";
import LandingCarousel from "./LandingCarousel/LandingCarousel";
import StoreSelect from "../CartCreate/StoreSelect/StoreSelect"
import DropdownInput from "./DropdownInput/DropdownInput";
import { Translation } from "react-i18next";
import { ecommerceService } from "../../services/ecommerce/EcommerceService";
import * as actionCreators from "../../redux/store/actions/index"
import { storesService } from "../../services/basis/StoresService";
import { connect } from "react-redux"

import "./default-store.styles.scss";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryModes: [],
            selectedDeliveryMode: this.props.defaultDelivery ? this.props.defaultDelivery : null,
            zipCode: this.props.defaultZipCode ? this.props.defaultZipCode : "",
            cityName: this.props.defaultCityName ? this.props.defaultCityName : "",
            selectedStore: this.props.defaultStore ? this.props.defaultStore : null,
            firstSlot: null
        }
    }

    componentDidMount() {
        ecommerceService.deliveryModes()
            .then(data => {
                this.setState({
                    deliveryModes: data
                })
            });
        if (this.state.selectedStore) {
            this.getFirstSlot();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedStore !== this.state.selectedStore) {
            if (this.state.selectedStore && this.state.selectedStore.id) {
                this.getFirstSlot();
            } else {
                this.setState({ firstSlot: null })
            }
        }
    }

    getFirstSlot = () => {
        const { selectedStore, selectedDeliveryMode } = this.state;
        storesService.firstSlot(selectedStore?.id, selectedDeliveryMode)
            .then(response => {
                this.setState({ firstSlot: response.data })
            }).catch(error => {
                console.log(error);
            })
    }

    onClick = (e) => this.setState({ [e.target.name]: e.target.value });

    selectStore = (item) => this.setState({ selectedStore: item });

    setZipCode = (item) => {
        this.setState({ zipCode: item.zipCode, cityName: item.cityName });
    }

    submit = () => {
        const { selectedStore, selectedDeliveryMode, zipCode, cityName } = this.state;
        this.props.setStore(selectedStore, selectedDeliveryMode, zipCode, cityName);
        this.props.history.push("/home")
    }

    render() {
        const { deliveryModes, selectedDeliveryMode, zipCode, selectedStore, firstSlot } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="landing">
                        <div className="landing-content">
                            <h1>{t("landingPage.title")}</h1>

                            {/* Delivery mode */}
                            <div className="landing-step">
                                <h3>{t("landingPage.service")}</h3>
                                <div className="deliveryMode">
                                    {deliveryModes && deliveryModes.map(item => (
                                        <div key={item.id}>
                                            <button
                                                className={`outline secondary-cta 
                                                    ${item.name === selectedDeliveryMode ? "selected" : ""} `}
                                                onClick={this.onClick}
                                                name="selectedDeliveryMode"
                                                value={item.name}>
                                                {t(`cart.deliveryMode.${item.name}`)}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Zip code */}
                            <div className={`landing-step ${selectedDeliveryMode ? "" : "step-disabled"}`}>
                                <h3>{t("landingPage.cap")}</h3>
                                <div className="zipCode">
                                    <DropdownInput
                                        setZipCode={this.setZipCode}
                                        placeholder="Comune o cap"
                                        defaultZipCode={this.props.defaultZipCode}
                                        defaultCityName={this.props.defaultCityName}
                                    />
                                </div>
                            </div>

                            {/* Store select */}
                            <div className={`landing-step ${(selectedDeliveryMode && zipCode && zipCode !== "") ? "" : "step-disabled"}`}>
                                <h3>{t("landingPage.store")}</h3>
                                <div className="storeSelect">
                                    <StoreSelect
                                        zipCode={zipCode}
                                        deliveryMode={selectedDeliveryMode}
                                        select={this.selectStore}
                                        label={t("create.cart.store")}
                                        defaultStore={selectedStore ? selectedStore.name : t("create.cart.store")}
                                    />
                                </div>
                                {firstSlot &&
                                    <div className="first-slot">
                                         {selectedDeliveryMode === "AT_HOME" ? t("landingPage.firstSlot-delivery") : t("landingPage.firstSlot-pickup")}      
                                        <b> {firstSlot.description}</b> {t("landingPage.atTime")}
                                        <b> {firstSlot.validFrom}-{firstSlot.validTo} </b>
                                    </div>
                                }
                                {!firstSlot && selectedStore && selectedDeliveryMode && zipCode &&
                                    <div className="no-stores">
                                        {t("landingPage.noSlot")} {selectedDeliveryMode === "AT_HOME" ? t("landingPage.delivery") : t("landingPage.pickup")} {t("landingPage.ofShopping")}
                                    </div>
                                }
                            </div>

                            <div className="landing-step">
                                <button className="cta" onClick={this.submit}
                                    disabled={!selectedStore}>
                                    <span>{t("landingPage.goToStore")}</span>
                                </button>
                            </div>
                        </div>
                        <LandingCarousel />
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        defaultStore: state.user.defaultStore,
        defaultDelivery: state.user.defaultDelivery,
        defaultZipCode: state.user.defaultZipCode,
        defaultCityName: state.user.defaultCityName
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setStore: (store, deliveryMode, zipCode, cityName) =>
            dispatch(actionCreators.defaultStoreAction(store, deliveryMode, zipCode, cityName)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
