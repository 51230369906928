import React, { useState, useEffect } from "react";
import BillingAddressForm from "./AddressForm/BillingAddressForm";
import ProfileCard from "../../../components/ProfileCard/ProfileCard";
import toaster from "../../../utils/toaster"
import { useTranslation } from 'react-i18next';
import { addressesService } from "./Service"

import "./profile-addresses.styles.scss"

const BillingAddresses = () => {
    const { t } = useTranslation();
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState();

    useEffect(() => {
        addressesService.getBillingAddresses()
            .then(response => {
                setAddresses(response.data)
            }).catch((error) => {
                console.log(error);
            })
    }, [])

    const newAddress = (address) => {
        addressesService.newBillingAddress(address)
            .then(response => {
                setAddresses(response.data)
                if (address.id) {
                    toaster.standard(t("billing.addresses.updated-message"))
                } else {
                    toaster.standard(t("billing.addresses.new-message"))
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    const deleteAddress = (addressId) => {
        addressesService.deleteBillingAddress(addressId)
            .then(response => {
                setAddresses(response.data)
                setAddress(null)
                toaster.standard(t("billing.addresses.deleted-message"))
            }).catch((error) => {
                console.log(error);
            })
    }


    return (
        <div className="profile-addresses">
            <h2>{t("billing.addresses.pageTitle")}</h2>
            <div className="profile-addresses-grid billing-grid">
                {addresses && addresses.map((address, index) => (
                    <ProfileCard key={address.id}>
                        <h5>{t("addressCard.address")} {index + 1}</h5>
                        <div className="tiny-cta"
                            onClick={() => setAddress(address)}>{t("update")}
                        </div>
                        <div className="billing-info">
                            <span>{t("billing.addresses.companyName")}:</span> <b>{address.companyName}</b>
                        </div>
                        <div className="billing-info">
                            <span>{t("billing.addresses.vatCode")}:</span> <b>{address.vatCode}</b>
                        </div>
                        <div className="billing-info">
                            <span>{t("billing.addresses.ssnCode")}:</span> <b>{address.ssnCode}</b>
                        </div>
                        <div className="billing-info">
                            <span>{t("billing.addresses.address")}:</span> <b>
                                {address?.address}, {address?.streetNumber} {address?.zip} {address?.city} {address?.county}
                            </b>
                        </div>
                        <div className="billing-info">
                            <span>{t("billing.addresses.phone")}:</span> <b>{address.phone}</b>
                        </div>
                        <div className="billing-info">
                            <span>{t("billing.addresses.fax")}:</span> <b>{address.fax}</b>
                        </div>
                        <div className="billing-info">
                            <span>{t("billing.addresses.pec")}:</span> <b>{address.pec}</b>
                        </div>
                        <div className="billing-info">
                            <span>{t("billing.addresses.recipientCode")}:</span> <b>{address.recipientCode}</b>
                        </div>
                    </ProfileCard>
                ))}
            </div>
            {(!addresses || addresses?.length <= 0) &&
                <div className="no-addresses">{t("billing.addresses.empty")}</div>
            }
            <h3>{address ? t("addresses.update") : t("addresses.new")}</h3>
            <hr />
            <BillingAddressForm
                address={address}
                setAddress={setAddress}
                newAddress={newAddress}
                deleteAddress={deleteAddress}
            />
        </div>
    );
}

export default BillingAddresses;

