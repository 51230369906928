import React from "react"

import "./profile-card.styles.scss";

const ProfileCard = ({ children }) => (
    <div className="profile-card">
        {children}
    </div>
)

export default ProfileCard;