import React from "react";
import { Link } from "react-router-dom"
import { useDispatch } from 'react-redux'
import * as actionCreators from "../../../../../redux/store/actions"
import { history } from "../../../../../App";


const HeaderTag = ({ children, tag, description }) => {
    const dispatch = useDispatch()
    const goToDetails = (tag) => {
        history.push({
            pathname: `/search`,
            state: {
                tag: `${tag}`,
            }
        })
    }
    return (
        <div className="header-tags-item">
            <div className="text-center">
                {/*  <Link className="header-tags-link" to="/search"
                    onClick={() => dispatch(actionCreators.tag(tag))} >
                    {children}
                    <div className="text">{description}</div>
                </Link>  */}
                <div className="header-tags-link"
                    onClick={() => goToDetails(tag)} >
                    {children}
                    <div className="text">{description}</div>
                </div>
            </div>
        </div>
    );
}

export default HeaderTag;
