import React from "react"
import Form from 'react-bootstrap/Form'
import { ReactComponent as Alert } from "../../images/card-alert.svg"
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import it from "date-fns/esm/locale/it";
registerLocale("it", it)
setDefaultLocale(it);

const SignupDatepicker = ({ label, value, onChange, errors }) => {
    return (
        <Form.Group className="validate-input" style={{minWidth: '350px'}}>
            <Form.Label>{label}</Form.Label>
            <DatePicker
                isClearable
                autoComplete="off"
                selected={value}
                onChange={onChange}
                value={value}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                locale="it"
                placeholderText="gg/mm/aaaa"
                showMonthDropdown
                showYearDropdown
                fixedHeight
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                popperPlacement="bottom-start"
                popperClassName="smartbip-datepicker"
                customInput={<input style={{minWidth: '350px', width: '350px'}}/>}
            />
            {errors && errors.message &&
                <div className="invalid-message">
                    <Alert />
                    {errors.message}
                </div>
            }
        </Form.Group>      
    );
}

export default SignupDatepicker;