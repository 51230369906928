import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRight } from "../../../images/arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../../images/arrow_left.svg";
import image51 from "../../../images/departments/Image51.jpg"
import image52 from "../../../images/newBrands/coopcasa/casacoop@3x-100.jpg"
import image53 from "../../../images/departments/Image53.jpg"
import image54 from "../../../images/departments/Image54.jpg"
import image55 from "../../../images/departments/Image55.jpg"
import image56 from "../../../images/newBrands/iocoop/iocoop@3x-100.jpg"
import image57 from "../../../images/departments/Image57.jpg"
import image58 from "../../../images/newBrands/dosa/dosa@3x-100.jpg"
import image59 from "../../../images/newBrands/crescendo/crescendo@3x-100.jpg"
import image60 from "../../../images/departments/Image60.jpg"
import image61 from "../../../images/departments/Image61.jpg"
import image51M from "../../../images/departments/Benesi@2x-100.jpg"
import image52M from "../../../images/newBrands/coopcasa/Casa_200@3x-100.jpg"
import image53M from "../../../images/departments/viviverdi@2x-100.jpg"
import image54M from "../../../images/departments/solidal@2x-100.jpg"
import image55M from "../../../images/departments/d'origine@2x-100.jpg"
import image56M from "../../../images/newBrands/iocoop/iocoop_200@3x-100.jpg"
import image57M from "../../../images/departments/FiorFiore@2x-100.jpg"
import image58M from "../../../images/newBrands/dosa/dosa_200@3x-100.jpg"
import image59M from "../../../images/newBrands/crescendo/Crescendo_200@3x-100.jpg"
import image60M from "../../../images/departments/coop@2x-100.jpg"
import image61M from "../../../images/departments/amicianimali@2x-100.jpg"
import { history } from "../../../App";


import "./home-brands.styles.scss";

const HomeBrands = () => {
    const { t } = useTranslation();

    const goToDetails = (tag) => {
        history.push({
            pathname: `/brand/${tag}`,
            state: {
                tag: `${tag}`,
            }
        })
    }
    return (
        <div className="home-brands">
            <h2 className="text-center">I nostri marchi</h2>
            <Slider {...settings} style={{maxWidth: '1600px'}}>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("COOP_BENE_SI")}>
                        <img className="desktop" src={image51} alt="" />
                        <img className="mobile" src={image51M} alt="" />
                    </div>
                </div>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("CASA")}>
                        <img className="desktop" src={image52} alt="" />
                        <img className="mobile" src={image52M} alt="" />
                    </div>
                </div>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("COOP_VIVI_VERDE")}>
                        <img className="desktop" src={image53} alt="" />
                        <img className="mobile" src={image53M} alt="" />
                    </div>
                </div>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("COOP_SOLIDAL")}>
                        <img className="desktop" src={image54} alt="" />
                        <img className="mobile" src={image54M} alt="" />
                    </div>
                </div>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("COOP_ORIGINE")}>
                        <img className="desktop" src={image55} alt="" />
                        <img className="mobile" src={image55M} alt="" />
                    </div>
                </div>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("COOP_IO")}>
                        <img className="desktop" src={image56} alt="" />
                        <img className="mobile" src={image56M} alt="" />
                    </div>
                </div>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("COOP_FIOR_FIORE")}>
                        <img className="desktop" src={image57} alt="" />
                        <img className="mobile" src={image57M} alt="" />
                    </div>
                </div>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("D'OSA")}>
                        <img className="desktop" src={image58} alt="" />
                        <img className="mobile" src={image58M} alt="" />
                    </div>
                </div>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("CRESCENDO")}>
                        <img className="desktop" src={image59} alt="" />
                        <img className="mobile" src={image59M} alt="" />
                    </div>
                </div>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("COOP")}>
                        <img className="desktop" src={image60} alt="" />
                        <img className="mobile" src={image60M} alt="" />
                    </div>
                </div>
                <div className="home-items">
                    <div className="link" onClick={() => goToDetails("PET")}>
                        <img className="desktop" src={image61} alt="" />
                        <img className="mobile" src={image61M} alt="" />
                    </div>
                </div>
            </Slider>
            <div className="carousel-dots"></div>
        </div>
    );
}

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    touchMove: true,
    nextArrow: <SampleArrow><ArrowRight /></SampleArrow>,
    prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>,
    responsive: [
        {
            breakpoint: 1919,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1599,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1439,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1279,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        /* {
            breakpoint: 1100,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }, */
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 799,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        }
    ]
};

function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
}

export default HomeBrands;