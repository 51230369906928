/* import React, { useState, useEffect } from "react";


import "./local-products.styles.scss";*/
import { history } from "../../../App";

import React, { Fragment } from "react";
import CardProduct from "../../../components/Products/CardProduct/CardProduct";
import Slider from "react-slick";
import { Translation } from "react-i18next";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import getBanners from "./Service"
import { connect } from "react-redux"
import Carousel from 'react-bootstrap/Carousel'

import "./local-products.styles1.scss";
import { ReactComponent as ArrowRight } from "../../../images/arrow_right1.svg";
import { ReactComponent as ArrowLeft } from "../../../images/arrow_left.svg";
import { ReactComponent as Map } from "../../../images/map-icon2.svg";
import BannerCardProduct from "../../../components/Products/BannerCardProduct/BannerCardProduct";



class LocalProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            products: [],
            cards: []
        };
    }

    componentDidMount() {
        const storeId = this.props.cart ? this.props.cart.storeId :
            (this.props.defaultStore ? this.props.defaultStore.id : null);

        ecommerceService.banners().then(response => {
            console.log(response.data)
            this.setState({
                isLoaded: true,
                products: response.data,
                cards: response.data.prices
            })
        });

        /* ecommerceService.featuredProducts(storeId).then(response => {
            this.setState({
                isLoaded: true,
                products: response.data
            })
        }); */
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const storeId = this.props.cart ? this.props.cart.storeId :
            (this.props.defaultStore ? this.props.defaultStore.id : null);
        if (nextProps && nextProps.cart) {
            if (storeId !== nextProps.cart.storeId) {
                ecommerceService.banners().then(response => {
                    console.log(response.data)
                    this.setState({
                        isLoaded: true,
                        products: response.data
                    })
                });
                /*  ecommerceService.featuredProducts(nextProps.cart.storeId).then(response => {
                     this.setState({
                         isLoaded: true,
                         products: response.data
                     })
                 }); */
            }
        } else {
            ecommerceService.banners().then(response => {
                console.log(response.data)
                this.setState({
                    isLoaded: true,
                    products: response.data
                })
            });
            /*  ecommerceService.featuredProducts(this.props.defaultStore.id).then(response => {
                 this.setState({
                     isLoaded: true,
                     products: response.data
                 })
             }); */
        }
    }

    goToDetails = (bannerId) => {
        history.push({
            pathname: `/discoverproducts`,
            state: {
                bannerId: `${bannerId}`,
            }
        })
    }

    render() {
        const { products, isLoaded, cards } = this.state;
        if (isLoaded) {
            return (
                <Translation>
                    {t =>
                        <div className="local-products">
                            {products.map((item, index) => (
                                <Fragment key={item.id +  "" + index}>
                                    <img src={item.banner.imageUrl} className="desktop" />
                                    <img src={item.banner.imageUrlApp} className="mobile"/>
                                </Fragment>
                            ))}

                            {/* <div className="content">  */}



                            <div className="local-products-text">
                                <div className="circle">
                                    <Map />
                                </div>
                                <div className="title">IL NOSTRO TERRITORIO</div>
                                <div className="description">
                                    <div className="subtitle">TANTI PRODOTTI <br />DEL TRENTINO<br />ALTO ADIGE</div>
                                </div>
                                {/* <div onClick={() => history.push("/discoverproducts")}> */}
                                <div onClick={() => this.goToDetails(products[0].banner.id)} className="seeMore">
                                    <span className="link" ><u>Scopri di piu</u><ArrowRight /></span>
                                </div>



                            </div>
                            <div className="local-products-cards">

                                {
                                    products.length > 0 &&
                                    products[0].prices
                                    && products[0].prices.map((item, index) => (
                                        <CardProduct key={item.id +  "" + index} item={item} />
                                    ))}

                                {/* <BannerCardProduct item={products[0]} />
                                    <BannerCardProduct item={products[1]} />  */}
                            </div>
                        </div>

                        // </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}

/* function appendDots(dots) {
    return (<div>
        <div className="carousel-dots">
            <ul>{dots}</ul>
        </div>
    </div>);
} */

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    /*  nextArrow: <SampleArrow><ArrowRight /></SampleArrow>,
     prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>, */
    responsive: [
        /*   {
              breakpoint: 1300,
              settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
              }
          }, */
        /*   {
              breakpoint: 1024,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
              }
          }, */
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        /*  {
             breakpoint: 576,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1,
             }
         } */
    ]
};
/* 
function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
} */

/* const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    appendDots: appendDots,
    arrows: false,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }
    ]
}; */

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        defaultStore: state.user.defaultStore,
    };
}

export default connect(mapStateToProps)(LocalProducts);


/* const LocalProducts = () => {
    return (
        <div className="local-products">
            <div className="local-products-content">
                <div className="local-products-text">
                    <div className="title">I prodotti del territorio</div>
                    <div className="subtitle">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</div>
                    <button className="cta" onClick={()=>history.push("/discoverproducts")}>
                        <span>Scopri tutti i prodotti</span>
                    </button>
                </div>
                <div>Prodotti</div>
            </div>
        </div>
    );
}

export default LocalProducts; */
