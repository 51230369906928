import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Service } from "./Service"
import Carousel from 'react-bootstrap/Carousel'
import LocalProducts from "../LocalProducts/LocalProducts";
import image10 from "../../../images/departments/Image10.png"
import { history } from "../../../App";


import "./home-carousel.styles.scss";
import BannerCardProduct from "../../../components/Products/BannerCardProduct/BannerCardProduct";
import { Redirect } from "react-router-dom";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";


const HomeCarousel = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
    const category = isMobile ? "MOBILE" : "WEB";
    const [isLoaded, setIsLoaded] = useState(false);
    const [banners, setBanners] = useState([]);
    const [filteredBannersDesk, setFilteredBannersDesk] = useState([]);
    const [filteredBannersMob, setFilteredBannersMob] = useState([]);

    useEffect(() => {
        Service.banners(category).then(response => {
            if (response.responseCode === 0) {
                console.log(response.data)
                setIsLoaded(true);
                setBanners(response.data)
            }
        });
    }, [category])

    useEffect(() => {
        if (banners.length > 0) {
            setFilteredBannersDesk(banners.filter((item) => item.banner.imageUrl !== undefined && item.banner.imageUrl !== ""))
            setFilteredBannersMob(banners.filter((item) => item.banner.imageUrlApp !== undefined && item.banner.imageUrlApp !== "" ))
        }
    }, [banners])

    const goToDetails = (item) => {
        if (item.banner.url && item.banner.url !== "") {
            // window.open(item.banner.url, "_self");
            if (item.banner.url.startsWith("https")) {
                window.open(item.banner.url, "_blank")
            } else {
                window.location.href = item.banner.url;
            }
        } else {
            history.push({
                pathname: `/banners/${item.banner.id}/products`,
                state: {
                    bannerId: `${item.banner.id}`,
                    type: `${item.banner.type}`
                }
            })
        }
    }


    return (
        isLoaded ?
            <div id="carousel-container">
                <div id="carousel-content">
                    <Carousel indicators={false} className="Carousel desktop">
                        {filteredBannersDesk && filteredBannersDesk.map((item, index) => (
                            <Carousel.Item key={item.id + "" + index} style={{ maxHeight: "480px" }}>
                                <div className="link desktop" onClick={() => goToDetails(item)} >
                                    <img src={item.banner.imageUrl} alt="" style={{ width: '100%' }} />
                                    {/* <div style={{ backgroundImage: `url(${item.banner.imageUrl})`, width: '100%' }} alt=""></div> */}
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    <Carousel indicators={false} className="Carousel mobile">
                        {filteredBannersMob && filteredBannersMob.map((item, index) => (
                            <Carousel.Item key={item.id + "" + index} style={{ maxHeight: "480px" }}>
                                <div className="link mobile" onClick={() => goToDetails(item)}>
                                    {/* <div style={{ backgroundImage: `url(${item.banner.imageUrlApp})`, height: '400px', backgroundSize: 'cover' }} alt=""></div> */}
                                    <img src={item.banner.imageUrlApp} alt="" style={{ width: '100%' }} />
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
                <div id="carousel-overlay">
                    <LocalProducts />
                </div>
            </div>
            :
            null
    )
}

export default HomeCarousel;
