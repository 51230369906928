import React from "react";
import Row from "react-bootstrap/Row"
import { Translation } from 'react-i18next';
import { withRouter } from "react-router-dom"
import GoBackButton from "../../../components/UI/Buttons/GoBackButton";

class CheckoutHeader extends React.Component {
    changePage = () => {
        const currentPath = this.props.history.location.pathname;
        let path = "";
        switch (currentPath) {
            case "/cart":
                path = "/availability"; break
            case "/availability":
                path = "/checkout"; break
            case "/checkout":
                path = "/summary"; break
            case "/summary":
                path = `/confirm/${this.props.orderCode}`; break
            default: path = "/cart";
        }
        this.props.history.push(path);
    }

    render() {
        // define css classes depending on current path
        const currentPath = this.props.history.location.pathname;
        let cartCss = "checkout-controls-step-current"
        let availabilityCss = ""
        let checkoutCss = ""
        let summaryCss = ""
        let confirmCss = ""
        switch (currentPath) {
            case "/cart":
                cartCss = "checkout-controls-step-current";
                break;
            case "/availability":
                cartCss = "checkout-controls-step-completed";
                availabilityCss = "checkout-controls-step-current";
                break;
            case "/checkout":
                cartCss = "checkout-controls-step-completed";
                availabilityCss = "checkout-controls-step-completed";
                checkoutCss = "checkout-controls-step-current";
                break;
            case "/summary":
                cartCss = "checkout-controls-step-completed";
                availabilityCss = "checkout-controls-step-completed";
                checkoutCss = "checkout-controls-step-completed";
                summaryCss = "checkout-controls-step-current";
                break;
            case  `/confirm/${this.props.orderCode}`:
                cartCss = "checkout-controls-step-completed";
                availabilityCss = "checkout-controls-step-completed";
                checkoutCss = "checkout-controls-step-completed";
                summaryCss = "checkout-controls-step-completed";
                break;
            case `/paymentfailed/${this.props.orderCode}`:
                cartCss = "checkout-controls-step-completed";
                availabilityCss = "checkout-controls-step-completed";
                checkoutCss = "checkout-controls-step-completed";
                summaryCss = "checkout-controls-step-completed";
                break;
            default: cartCss = `checkout-controls-step-current`;
        }

        return (
            <Translation>
                {t =>
                    <Row className="g-padding checkout-header">
                        <div className="col px-0 text-center order-3 order-sm-2 my-4 my-md-0 d-flex justify-content-center align-items-center pt-1" style={{ display: "flex", flexDirection:"column" }}>
                            <div className="d-flex justify-content-between checkout-controls">
                                <div className={`${cartCss} checkout-controls-step`}>1</div>
                                <div className="line"></div>
                                <div className={`${availabilityCss} checkout-controls-step`}>2</div>
                                <div className="line"></div>
                                <div className={`${checkoutCss} checkout-controls-step`}>3</div>
                                <div className="line"></div>
                                <div className={`${summaryCss} checkout-controls-step`}>4</div>
                            </div>
                            <div className="d-flex justify-content-between checkout-labels">
                                <div className="col-3">{t("cart")}</div>
                                <div className="col-3">{t("availability")}</div>
                                <div className="col-3">{t("payment")}</div>
                                <div className="col-3">{t("summary")}</div>
                            </div>
                        </div>
                    </Row>
                }
            </Translation>
        );
    }
}

export default withRouter(CheckoutHeader);