import React, { Component } from 'react';

import "./Faq.scss"

class Faq extends Component {
    render() {
        return (
            <div className="container d-flex justify-content-center flex-column mt-5 mb-5 faq-policy">
                <div className="policy-heading">Introduzione
                </div>
                <div className="policy-title">
                    Che cosa è il servizio “Click & Coop” ?
                    <div className="policy-terms">
                        Click & Coop è un servizio che consente di fare la spesa on line utilizzando un PC, un tablet o uno smartphone.
                        Puoi ordinare la tua spesa comodamente, ovunque ti trovi, per poi passare a ritirarla presso il punto da te
                        selezionato, nella fascia oraria che hai scelto.
                    </div>
                </div>
                <div className="policy-title">
                    Come funziona?
                    <div className="policy-terms">
                        Accedi al portale web <a href="https://www.clickcoop.it" target="_blank" rel="noopener noreferrer">
                            www.clickcoop.it
                        </a>  o con l’APP Click&Coop scaricabile dagli store:
                        <ul>
                            <li>registrati al servizio</li>
                            <li>seleziona il punto vendita dove andrai a ritirare la spesa</li>
                            <li>aggiungi i prodotti al carrello virtuale</li>
                            <li>seleziona il punto di ritiro e la relativa fascia oraria di ritiro della spesa tra quelle disponibili</li>
                            <li>conferma il tuo ordine</li>
                            <li>procedi con il pagamento on line (oppure scegli di pagare al ritiro della spesa)</li>
                            <li>attendi la conferma via mail, dopodiché, passa a ritirare la tua spesa presso il punto di ritiro.</li>
                        </ul>
                        <div className="policy-title">
                            Il servizio è gratuito?
                            <div className="policy-terms">
                                Il servizio è completamente gratuito, ed è attivo per spese superiori a 30 €
                            </div>
                        </div>
                    </div>
                </div>
                <div className="policy-heading">Registrazione
                </div>
                <div className="policy-title">
                    Per fare la spesa è necessario registrarsi?
                    <div className="policy-terms">
                        Si,  la  registrazione  al  servizio  è  obbligatoria  per  poter  ordinare  e  ritirare  la  spesa.  Registrandoti  potremo
                        instaurare  un  rapporto  più  diretto  con  Te,  e  risponderemo  in  modo  migliore  ai  tuoi  bisogni  e  alle  tue
                        preferenze. Potrai acquistare in modo semplice e rapido memorizzando le informazioni di pagamento e ritiro,
                        salvando  le tue  liste  della spesa. Inoltre,  gli  utenti  registrati  potranno  beneficiare  di  promozioni  dedicate e
                        sconti esclusivi.
                    </div>
                </div>
                <div className="policy-title">
                    È possibile navigare all’interno del sito prima di effettuare la registrazione?
                    <div className="policy-terms">S
                        Sì, è possibile accedere al nostro negozio online come ospite. Potrai consultare tutti i prodotti, le promozioni
                        e altri servizi presenti su Click & Coop. <br />
                        Al momento dell’inserimento del primo prodotto nel carrello ti verrà richiesta la registrazione o l’inserimento
                        delle credenziali di accesso. </div>
                </div>
                <div className="policy-title">Cosa si deve fare per registrarsi ?
                    <div>
                        È necessario accedere all’area registrazione e seguire le indicazioni riportate.
                    </div>
                </div>
                <div className="policy-title">
                    Per registrarmi devo possedere la Carta In Cooperazione ?
                    <div className="policy-terms">
                        No, la Carta In Cooperazione non è necessaria per effettuare la registrazione.
                    </div>
                </div>
                <div className="policy-title">
                    Cosa posso fare se non ricordo la password con cui mi sono registrato ?
                    <div className="policy-terms">
                        Puoi cliccare su Recupera Password nell'area dedicata all'accesso o alla registrazione; riceverai via mail un
                        link che ti guiderà nel recupero della password.
                    </div>
                </div>
                <div className="policy-title">Come faccio a modificare la password?
                    <div className="policy-terms">
                        Nella sezione "profilo" è possibile modificare la password. Se vuoi modificare gli altri dati anagrafici inviaci il
                        form presente sul sito indicando “profilo e account”. Trovi il form nella sezione contatti.
                    </div>
                </div>
                <div className="policy-title">
                    Posso cancellare la mia utenza?
                    <div className="policy-terms">
                        Nella sezione "profilo" è possibile eliminare definitivamente il proprio account. Cliccando sull’apposito
                        bottone “elimina profilo” verrà inviata automaticamente la richiesta e ti comunicheremo tramite email
                        l’avvenuta cancellazione del tuo profilo.
                    </div>
                </div>
                <div className="policy-heading">Ordine
                </div>
                <div className="policy-title">
                    Come fare l’ordine della spesa ?
                    <div className="policy-terms" style={{ fontStyle: "italic" }}>
                        Seleziona il punto vendita tra quelli che offrono il servizio Click & Coop, aggiungi i prodotti al carrello virtuale,
                        scegli il punto di ritiro e la fascia oraria di ritiro della spesa tra quelle disponibili e infine invia il tuo ordine.
                        Successivamente riceverai una mail con il riepilogo dei prodotti ordinati e le indicazioni per il ritiro della spesa.
                    </div>
                </div>
                <div className="policy-title">
                    I prezzi sono gli stessi del negozio?
                    <div className="policy-terms">
                        I  prezzi  e  le  promozioni  praticate  in  Click  &  Coop saranno  esclusivi  e  quindi  potranno  differire  da  quelli  del
                        punto vendita.
                    </div>
                </div>
                <div className="policy-title">Ci sono dei limiti di importo e quantità per poter usufruire del Servizio?
                    <div className="policy-terms">
                        Il servizio è attivo solo per spese superiori a 30 € e non comporta costi aggiuntivi. Possono essere applicate
                        dei limiti alle quantità massime ordinabili per articolo, in modo da evitare accaparramenti
                    </div>
                </div>
                <div className="policy-title">
                    Quanto tempo trascorre tra l’invio dell’ordine e il ritiro della spesa?
                    <div className="policy-terms">
                        Il tempo che intercorre tra l’invio dell’ordine e il ritiro della spesa dipende dalle fasce disponibili. Una volta
                        selezionato il punto di ritiro ti verrà indicata la prima fascia oraria disponibile per il ritiro.
                    </div>
                </div>
                <div className="policy-title">
                    Quali sono gli orari per fare gli ordini ?
                    <div className="policy-terms">
                        È possibile fare l’ordine a qualsiasi ora del giorno. Il servizio è attivo H24.
                    </div>
                </div>
                <div className="policy-title">Quali sono le fasce orario per ritirare la spesa?
                    <div className="policy-terms">
                        Le fasce orarie variano a seconda del negozio e punto di ritiro scelto. È possibile vedere la prima fascia oraria
                        disponibile per il ritiro prima di iniziare la lista della spesa riferita al ritiro in negozio. La conferma della fascia
                        oraria di ritiro avviene alla chiusura dell’ordine
                    </div>
                </div>
                <div className="policy-title">
                    È possibile effettuare l’ordine oggi e ritirare domani?
                    <div className="policy-terms">
                        Il  servizio  consente  di  ordinare  la  spesa  online  e  ritirarla  il  giorno  seguente,  o  nei  giorni  successivi,
                        selezionando il giorno e l'orario di ritiro tra quelli disponibili.
                    </div>
                </div>
                <div className="policy-title">
                    Posso prenotare anche prodotti a peso variabile?
                    <div className="policy-terms">
                        Si, il servizio consente di prenotare prodotti a peso variabile, indicando nell’ordine la quantità desiderata, che
                        in fase di preparazione potrà variare nell’ordine di più o meno 30% .
                    </div>
                </div>
                <div className="policy-title">
                    Posso acquistare alcoolici?
                    <div className="policy-terms">
                        Il servizio Click & Coop è riservato ai maggiorenni, pertanto gli alcoolici possono essere ordinati. È da tenere
                        presente che al momento del ritiro della spesa potrebbe essere richiesto un documento identificativo.
                    </div>
                </div>
                <div className="policy-title">
                    Mentre sto facendo l’ordine posso modificare la fascia oraria del ritiro?
                    <div className="policy-terms">
                        Puoi scegliere la fascia oraria fino al momento di invio dell’ordine.
                    </div>
                </div>
                <div className="policy-title">
                    Posso creare le mie liste della spesa?
                    <div className="policy-terms">
                        Puoi creare una o più liste della spesa contenenti i tuoi prodotti preferiti, richiamarle al momento di fare un
                        nuovo ordine aggiungendo o togliendo prodotti. L’applicazione mette a disposizione una apposita sezione per
                        gestire le liste della spesa.
                    </div>
                </div>
                <div className="policy-title">
                    Posso iniziare un ordine, lasciarlo in sospeso, e terminarlo in un secondo momento?
                    <div className="policy-terms">
                        Sì, è possibile farlo, ma solamente quando si è registrati e loggati. Il sito salva il carrello man mano che viene
                        composto ed è possibile terminarlo in momenti e con dispositivi differenti.
                    </div>
                </div>
                <div className="policy-title">
                    Come faccio a sapere se il mio ordine è stato accettato?
                    <div className="policy-terms">
                        Subito dopo aver spedito il tuo ordine riceverai una mail di conferma contenente il numero dell’ordine, che ti
                        servirà per il ritiro della spesa, e la lista dei prodotti ordinati.
                    </div>
                </div>
                <div className="policy-title">
                    Vengo avvisato quando il mio ordine è pronto per il ritiro?
                    <div className="policy-terms">
                        Sì, verrai avvisato quando il tuo ordine è stato preparato. Provvederemo ad inviarti una mail con il riepilogo
                        della tua spesa, e l’orario di ritiro.
                    </div>
                </div>
                <div className="policy-title">Posso modificare l’ordine anche se l'ho già inviato?
                    <div className="policy-terms">
                        Si, è possibile effettuare modifiche all’ordine sino al momento della preparazione dello stesso, che
                        generalmente avverrà qualche ora prima del ritiro della spesa. Attenzione: dovrai riselezionare la fascia oraria
                        di ritiro.
                    </div>
                </div>
                <div className="policy-title">Posso annullare il mio ordine anche se l'ho già confermato ?
                    <div className="policy-terms">
                        L’ordine  può  essere  annullato  fino  al  momento  della  preparazione  dello  stesso,  che  generalmente  avverrà
                        qualche ora prima dell’orario di ritiro.
                    </div>
                </div>
                <div className="policy-title">
                    È possibile completare successivamente una spesa non ancora confermata?
                    <div className="policy-terms">
                        Si,  il  carrello  viene  salvato  man  mano  che  si  aggiungono  i  prodotti.  Puoi  inoltre,  dalla  pagina  del  carrello,
                        cliccare su “salva lista della spesa” e quando ti ricollegherai potrai continuare la tua spesa. NB: i prezzi dei
                        prodotti possono variare con il passare dei giorni.
                    </div>
                </div>
                <div className="policy-title">
                    Dove sarà allocata la mia spesa in attesa del mio arrivo?
                    <div className="policy-terms">
                        Rispettiamo la catena del freddo, già separando i prodotti mentre prepariamo la spesa in base alle diverse
                        modalità  di  conservazione  previste  per  ciascuno  di  essi;  conserviamo  i  prodotti  in  appositi  frigoriferi
                        rispettando le temperature previste dalle norme per le varie merceologie.
                    </div>
                </div>
                <div className="policy-title">Se ritardo il ritiro della spesa, rispetto all'orario da me indicato, cosa succede?
                    <div className="policy-terms">
                        Ti chiediamo di segnalare il ritardo contattando il punto vendita ai riferimenti mail o telefonici riportati nella
                        mail che conferma d’ordine. Se il ritardo non verrà comunicato, la spesa non ritirata sarà considerata come
                        "annullata".
                    </div>
                </div>
                <div className="policy-title">Cosa succede se non ritiro la spesa?
                    <div className="policy-terms">
                        Ci riserviamo di bloccare l'account del cliente che non ritira la spesa, rimuovendolo così dal servizio.
                    </div></div>
                <div className="policy-title">Cosa succede se alcuni dei prodotti ordinati non sono disponibili?
                    <div className="policy-terms">
                        Alcuni articoli potrebbero non essere disponibili al momento della preparazione dell’ordine. Per una serie di
                        prodotti, Trento Sviluppo ha previsto uno o più articoli sostitutivi da proporre in caso di mancata disponibilità
                        del prodotto scelto dal Cliente. Il Cliente in fase di ordine può decidere se accettare o meno la sostituzione.
                        Quando la tua spesa sarà pronta, riceverai una mail di conferma con la lista dei prodotti consegnati, degli
                        eventuali sostituti e dei prodotti non consegnati.
                    </div>
                </div>
                <div className="policy-heading">Ritiro e Pagamento
                </div>
                <div className="policy-title">
                    Quali sono le fasce orarie per ritirare la spesa?
                    <div className="policy-terms">
                        Le fasce orarie variano a seconda del punto vendita e di ritiro selezionato. Puoi visualizzarle prima di iniziare
                        la lista della spesa, e confermarle in chiusura della spesa. Attenzione se impieghi molto tempo per completare
                        la spesa le fasce inizialmente segnalate potrebbero non essere più disponibili
                    </div>
                </div>
                <div className="policy-title">
                    Può un'altra persona ritirare la spesa al posto mio?
                    <div className="policy-terms">
                        Si, l'incaricato al ritiro deve essere munito del numero di prenotazione che hai ricevuto via mail.
                    </div>
                </div>
                <div className="policy-title">
                    Devo arrivare con l’automobile per ritirare la spesa?
                    <div className="policy-terms">
                        Puoi arrivare con qualsiasi mezzo di trasporto, anche a piedi.
                    </div>
                </div>
                <div className="policy-title">
                    Come funziona il pagamento della spesa?
                    <div className="policy-terms">
                        Il  pagamento  può  essere  fatto  direttamente  on  line  al  momento  dell’ordine  oppure  al  momento  del  ritiro,
                        direttamente nel punto vendita, tramite i metodi di pagamento accettati normalmente in negozio.
                    </div>
                </div>
                <div className="policy-title">Come chiedo la fattura?
                    <div className="policy-terms">
                        La fattura può essere richiesta al momento del ritiro se hai selezionato il ritiro presso Coop Superstore di
                        Trento Sud. Se hai selezionato il punto di ritiro a Trento Nord, devi contattare il numero <a href="tel:+0461 362600">0461362600</a>.
                    </div>
                </div>
                <div className="policy-title">Cosa posso fare se ho altre domande?
                    <div className="policy-terms">
                        Puoi scriverci, compilando il form presente nella sezione contatti.
                    </div>
                </div>
            </div>

        );
    }
}

export default Faq;