import React from "react"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import "./breadcrumb.styles.scss";

const CustomBreadcrumb = ({ level0, level1, level2, history }) => {
    const { t } = useTranslation();
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <span onClick={() => history.push("/home")}>{t("home")}</span>
            </Breadcrumb.Item>
            {level0 &&
                <Breadcrumb.Item active={level0.active}>
                    <span>{t(level0.label)}</span>
                </Breadcrumb.Item>
            }
            {level1 &&
                <Breadcrumb.Item active={level1.active}>
                    <span onClick={() => history.push(level1.path)}>{t(level1.label)}</span>
                </Breadcrumb.Item>
            }
            {level2 &&
                <Breadcrumb.Item active={level2.active}>
                    <span onClick={() => history.push(level2.path)}>{t(level2.label)}</span>
                </Breadcrumb.Item>
            }
        </Breadcrumb >
    );
}

export default withRouter(CustomBreadcrumb);