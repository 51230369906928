import React from "react";
import { Link, withRouter } from "react-router-dom";
import { ReactComponent as Offerta } from "../../images/tags/offers.svg";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as actionCreators from "../../redux/store/actions"

import "./categories.styles.scss";

const Categories = ({ categories, changeCategory, history }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    return (
        <div className="categories">
          {/*   <div className="promo"
                onClick={() => {
                    dispatch(actionCreators.onlyPromotions(true))
                    history.push({ pathname: "/search", })
                }}
            >
                <Offerta />
                {t("category-promo")}
            </div> */}
            {categories && categories.map((item, index) => (
                <div className="categories-item" key={item.id}>
                    <Link
                        to={{ pathname: `/category/${item.id}` }}
                        onClick={() => changeCategory(item.id, index)}
                    >
                        {item.description}
                    </Link>
                </div>
            ))}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        categories: state.cart.categories,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        changeCategory: (categoryId, index) => dispatch(actionCreators.changeCategory(categoryId, index)),
    };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Categories));