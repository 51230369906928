import React from "react";
import StickyBox from "react-sticky-box";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import * as actionCreators from "../../../../redux/store/actions/index";
import BrownCard from "../../../../images/Browncard.jpg";
import { withRouter } from "react-router";
import CategoryTree from "../../../SearchCategory/CategoryTree/CategoryTree";
import "./home-value-browncard.styles.scss";
import { history } from "../../../../App";
import GoBackButton from "../../../../components/UI/Buttons/GoBackButton";

const HomeValuesBrownCard = ({ location }) => {

    const goToDetails = (tag) => {
        history.push({
            pathname: `/brand/${tag}`,
            state: {
                tag: `${tag}`,
            }
        })
    }

    return (
        <Translation>
            {t =>
                <div style={{ padding: "unset" }} className="page-grid mobile-view-search">
                    <div className="grid-left" offsettop={0} offsetbottom={0}>
                        <CategoryTree />
                    </div>
                    {/* <div style={{ paddingTop: "20px" }} className="grid-right"> */}
                    <div className="grid-right" >
                        <div className="home-value-browncard values-details-container">
                            <img style={{ width: "100%" }} src={BrownCard} alt="" />
                            <div className="home-value-browncard-button">
                                <button className="home-value-browncard-button-style" onClick={() => goToDetails("COOP_BENE_SI")}>SCOPRI LA LINEA</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Translation>
    );
}


const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        current: () => dispatch(actionCreators.current()),
        getSlists: () => dispatch(actionCreators.getSlists()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeValuesBrownCard));
