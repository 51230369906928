import React from "react";
import Slider from "react-slick";
// import HomeTag from "./HomeTag/HomeTag";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
//import { ReactComponent as Offerta } from "../../../../../../images/tags/offers.svg";
import { useSelector, useDispatch } from 'react-redux'
import * as actionCreators from "../../../redux/store/actions"

import "./recipie-tags.styles.scss";

const HeaderTags = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const tags = useSelector(state => state.categories.tags)

    // renderIcon = (id) => {
    //     switch (id) {
    //         case "Bio":
    //             return <Biologico />
    //         case "Senza_glutine":
    //             return <SenzaGlutine />
    //         case "Senza_lattosio":
    //             return <SenzaLattosio />
    //         case "Benessere":
    //             return <Benessere />
    //         case "Vegano":
    //             return <Vegano />

    //         default:
    //             return null;
    //     }
    // }

    return (
        <div className="recipie-tags ">
            {/* <Slider {...settings}> */}
                <div className="recipie-tags-item" onClick={() => {
                    dispatch(actionCreators.onlyPromotions(true))
                    props.history.push({ pathname: "/search", })
                }}>
                    {/* <Offerta/> */}
                    {/* <Offerta /> */}
                    {t("promo")}
                </div>
                {tags && tags.map(item => (
                    <div className="recipie-tags-item">
                        <Link className="link" to="/search"
                            onClick={() => dispatch(actionCreators.tag(item.id))} >
                            {/* {this.renderIcon(item.id)} */}
                            {/* <Offerta /> */}
                            {item.description}
                        </Link>
                    </div>

                ))}
            {/* </Slider> */}
        </div>
    );
}

export default withRouter(HeaderTags);

const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                // dots: true,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                // dots: true,
            }
        }
    ]
};





