/* eslint-disable */

import * as actionTypes from "../actions/actionTypes";
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    categoryId: "",
    mainCategory: "",
    subCategory: "",
    isSubCategoryOpen: null,
    thirdLevelCategory: "",
    activeKey: null,
    oldActiveKey: null,
    page: null,
    product: null,
    scroll: null,
    loadedProducts: null,
    onlyPromotions: false,
    onlyBranded: false,
    tag: "",
    searchInput:"",
    tags: null,
    filters: null
}

const categoriesReducer = createReducer(initialState, {
    [actionTypes.CHANGE_CATEGORY]: (state, action) => {
        state.categoryId = action.categoryId,
        state.mainCategory = action.categoryId,
        state.activeKey = action.activeKey
    },
    [actionTypes.CHANGE_SUB_CATEGORY]: (state, action) => {
        state.categoryId = action.categoryId,
        state.subCategory = action.subCategory
        state.isSubCategoryOpen = action.isSubCategoryOpen
    },
    [actionTypes.CHANGE_THIRD_LEVEL_CATEGORY]: (state, action) => {
        state.categoryId = action.categoryId,
        state.thirdLevelCategory = action.thirdLevelCategory
    },
    [actionTypes.ONLY_PROMOTIONS]: (state, action) => {
        state.onlyPromotions = action.onlyPromotions
    },
    [actionTypes.ONLY_BRANDED]: (state, action) => {
        state.onlyBranded = action.onlyBranded
    },
    [actionTypes.TAG]: (state, action) => {
        state.tag = action.tag
    },
    [actionTypes.SEARCHINPUT]: (state, action) => {
        state.searchInput = action.searchInput
    },
    [actionTypes.TAGS]: (state, action) => {
        state.tags = action.tags
    },
    [actionTypes.FILTERS]: (state, action) => {
        state.filters = action.filters
    },
    [actionTypes.CHANGE_ALL_CATEGORIES]: (state, action) => {
        state.categoryId = action.categoryId,
        state.mainCategory = action.mainCategory,
        state.subCategory = action.subCategory,
        state.thirdLevelCategory = action.thirdLevelCategory
        state.isSubCategoryOpen = action.isSubCategoryOpen,
        state.activeKey = action.activeKey
    },
    [actionTypes.SAVE_SCROLLEDPRODUCT]: (state, action) => {
        state.page = action.page,
        state.scroll = action.scroll,
        state.product = action.product,
        state.loadedProducts = action.loadedProducts,
        state.oldActiveKey = action.oldActiveKey
        state.totalPages = action.totalPages
    },
    [actionTypes.RESET_SCROLLEDPRODUCT]: (state, action) => {
        state.page = null,
        state.scroll = null,
        state.product = null,
        state.loadedProducts = null,
        state.oldActiveKey = null,
        state.totalPages = null
    },
})

export default categoriesReducer;

