import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Play } from '../../../../images/play.svg'
import "./tutorial.styles.scss"

const Tutorial = () => {
    const { t } = useTranslation();
    const [video, setVideo] = useState(0);
    const [show, setShow] = useState(false);

    const handleVideo = (id) => {
        setVideo(id);
        setShow(true);
    }

    return (
        <div className='tutorial-container'>
            <Modal show={show} centered={true} onHide={() => setShow(false)} dialogClassName="tutorial-modal">
                {video === 1 &&
                    <iframe src='https://www.youtube.com/embed/8fxqX48Dz7k' frameBorder="0" allowFullScreen className='modal-iframe'/>
                }
                {video === 2 &&
                    <iframe src='https://www.youtube.com/embed/x8qBYVA1QXw' frameBorder="0" allowFullScreen className='modal-iframe'/>
                }
                {video === 3 &&
                    <iframe src='https://www.youtube.com/embed/wbKR2rlAGJY' frameBorder="0" allowFullScreen className='modal-iframe'/>
                }
            </Modal>
            <div className='title'>
                {t("tutorial")}
                <hr />
            </div>
            <div className='subtitle'>
                {t("tutorial.subtitle")}
            </div>
            <div className='textStep-steps'>
                <div className='textStep-tutorials'>
                    <div className="description">
                        <div className='numbers'>1</div>
                        <div className='step'>{t("sign_in")}</div>
                    </div>
                    <div className='watchvideo' onClick={() => handleVideo(1)}>
                        {t("watchvideo")} <Play />
                    </div>
                </div>
                <hr />
                <div className='explain'>
                    {t("tutorial1")}
                </div>
                <div className='textStep-tutorials'>
                    <div className="description">
                        <div className='numbers'>2</div>
                        <div className='step'>{t("aboutus.step2")}</div>
                    </div>
                    <div className='watchvideo' onClick={() => handleVideo(2)}>
                        {t("watchvideo")} <Play />
                    </div>
                </div>
                <hr />
                <div className='explain'>
                    {t("tutorial2")}
                </div>
                <div className='textStep-tutorials'>
                    <div className="description">
                        <div className='numbers'>3</div>
                        <div className='step'>{t("aboutus.step3")}</div>
                    </div>
                    <div className='watchvideo' onClick={() => handleVideo(3)}>
                        {t("watchvideo")} <Play />
                    </div>
                </div>
                <hr />
                <div className='explain'>
                    {t("tutorial3")}
                </div>
            </div>
        </div>
    )
}

export default Tutorial