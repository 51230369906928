import config from '../../config/config';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import axios from 'axios'
import { authHeader } from '../../utils/authHeader';

export const ecommerceService = {
    categories, banners, deliveryModes, searchProducts, orders, fetchOrder, availability, featuredProducts, abortOrder, productsTags, productsTagsComplete, getCategory, filtersUpdate, getProductFromBarcode
};

function categories() {
    return (
        axios.get(`${config.baseUrl}/cli/categories`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function banners() {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners/search?type=TERRITORY`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function deliveryModes() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/deliveries`)
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function searchProducts(input, storeId, page, category, onlyPromotions, onlyBranded, tag) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/products/${storeId}`,
            {
                params: {
                    q: input,
                    p: page,
                    category: category,
                    onlyPromotions: onlyPromotions,
                    onlyBranded: onlyBranded,
                    tag: tag
                }
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function orders(page, filter) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/orders?s=${config.maxSize}&p=${page}&status=${filter}&sort=updated`,
            {
                headers: authHeader(),
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchOrder(orderId) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/orders/${orderId}/fetch`,
            {
                headers: authHeader(),
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function availability() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/slots/first/available`,
            {
                headers: authHeader(),
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function featuredProducts(storeId) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/products/${storeId}/featured`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function abortOrder(orderId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/abort`,
            {
                orderId: orderId,
            },
            {
                headers: authHeader(),
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    )
}

function productsTags() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/productsTags/profile`,
            {
                headers: authHeader(),
            })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function productsTagsComplete() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/productsTagsComplete`,
            {
                headers: authHeader(),
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function getProductFromBarcode(barcode) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/products/ref/${barcode}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function getCategory(categoryId) {
    return (
        axios.get(`${config.baseUrl}/cli/categories?categoryId=${categoryId}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function filtersUpdate(data) {
    console.log(data)
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/productsTags/save`,
            data,
            {
                headers: authHeader(),
            }
        )
            .then(handleResponse)
            .then(checkResponse)
    )
}