import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import axios from 'axios'

export default function banners(category) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners/search?type=SELECTION`)
            .then(handleResponse)
            .then(checkResponse)
    );
}