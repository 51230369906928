import React from "react";
//import CardRecipie from "../../../components/Products/CardRecipie/CardRecipie";
import Slider from "react-slick";
import { Translation } from "react-i18next";
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { connect } from "react-redux"
import { Row } from "react-bootstrap";

import "./featured-recipies.style.scss";
import { ReactComponent as ArrowRight } from "../../../images/arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../../images/arrow_left.svg";
import CardRecipie from "../../../components/Products/CardRecipie/CardRecipie";

class FeaturedRecipies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            products: []
        };
    }

    componentDidMount() {
        const storeId = this.props.cart ? this.props.cart.storeId :
            (this.props.defaultStore ? this.props.defaultStore.id : null);
        ecommerceService.featuredProducts(storeId).then(response => {
            this.setState({
                isLoaded: true,
                products: response.data
            })
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const storeId = this.props.cart ? this.props.cart.storeId :
            (this.props.defaultStore ? this.props.defaultStore.id : null);
        if (nextProps && nextProps.cart) {
            if (storeId !== nextProps.cart.storeId) {
                ecommerceService.featuredProducts(nextProps.cart.storeId).then(response => {
                    this.setState({
                        isLoaded: true,
                        products: response.data
                    })
                });
            }
        } else {
            ecommerceService.featuredProducts(this.props.defaultStore.id).then(response => {
                this.setState({
                    isLoaded: true,
                    products: response.data
                })
            });
        }
    }

    render() {
        const { products, isLoaded } = this.state;
        if (isLoaded) {
            return (
                <Translation>
                    {t =>
                        <div className="featured-recipies">
                            <h2 className="text-center">
                                {t("home.featuredRecipies")}
                            </h2>
                            <div className="search-product-view">
                                <Row className="no-gutters justify-content-center">
                                    {products && products.map((item) => (
                                        <CardRecipie key={item.id} item={item} />
                                    ))}
                                </Row>
                            </div>




                            {/* <Slider {...settings}>
                                {products && products.map((item) => (
                                    <CardProduct key={item.id} item={item} />
                                ))}
                            </Slider> */}
                            {/* <div className="carousel-dots"></div> */}
                            {/* <div className="products-grid">
                                {products && products.map((item) => (
                                    <CardProduct key={item.id} item={item} />
                                ))}
                            </div> */}
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}

function appendDots(dots) {
    return (<div>
        <div className="carousel-dots">
            <ul>{dots}</ul>
        </div>
    </div>);
}

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <SampleArrow><ArrowRight /></SampleArrow>,
    prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};

function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
}

/* const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    appendDots: appendDots,
    arrows: false,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }
    ]
}; */

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        defaultStore: state.user.defaultStore,
    };
}

export default connect(mapStateToProps)(FeaturedRecipies);
